<template>
  <UiModal
    class="post"
    :size="isPostMediaVisible ? 'xl' : 'lg'"
    @open="init"
    @close="$emit('close')"
  >
    <div>
      <div
        class="tw-flex tw-gap-4 tw-min-h-[40rem] md:tw-gap-6"
        :style="{ height: height ? `${height}px` : 'auto' }"
      >
        <div
          v-if="isPostMediaVisible"
          class="tw-basis-0 tw-grow tw-overflow-hidden"
        >
          <UiPostMedia
            ref="media"
            v-model="post"
            class="tw-h-full"
            :locked="!hasAccess"
            :sizes="{ lg: '50vw', xl: '40vw' }"
          />
        </div>

        <div class="tw-flex tw-basis-0 tw-grow tw-flex-col">
          <UiPost
            v-model="post"
            bare
            @del="del"
          />

          <div class="tw-grow tw-mt-4 tw-overflow-hidden">
            <UiComments
              v-if="hasAccess"
              class="-tw-mx-4 md:-tw-mx-6"
              contained
              :postId="post.id"
            >
              <UiPostInteractions
                v-model="post"
                :hasAccess="hasAccess"
              />
            </UiComments>
          </div>
        </div>
      </div>
    </div>
  </UiModal>
</template>

<script>
import Post from '@/components/models/Post';

import UiComments from '@/components/ui/UiComments.vue';
import UiModal from '@/components/ui/UiModal.vue';
import UiPost from '@/components/ui/UiPost.vue';
import UiPostInteractions from '@/components/ui/UiPostInteractions.vue';
import UiPostMedia from '@/components/ui/UiPostMedia.vue';

export default {
  components: {
    UiComments,
    UiModal,
    UiPost,
    UiPostInteractions,
    UiPostMedia,
  },
  props: {
    value: Post,
  },
  data() {
    return {
      height: null,
    };
  },
  computed: {
    post: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    hasAccess() {
      return this.post?.hasAccess;
    },
    isPostMediaVisible() {
      return this.post?.media.length > 0 || !this.hasAccess;
    },
  },
  watch: {
    $route() {
      this.visible = false;
    },
  },
  methods: {
    init() {
      this.height = null;
      this.loadPost();
    },
    loadPost() {
      this.$get(
        '/posts/' + this.post.id,
        (data) => {
          this.post = new Post(data);

          if (this.$refs.media) {
            this.height = this.$refs.media.$el.offsetHeight;
          } else {
            this.height = null;
          }
        },
        (errors) => {
          console.log(errors);
        },
      );
    },
    del() {
      this.$emit('del', this.post);
      this.$emit('close');
    },
  },
};
</script>
