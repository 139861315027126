<template>
  <div class="tw-flex tw-flex-col">
    <div class="tw-shrink-0 tw-flex tw-items-center tw-mb-4">
      <div class="tw-w-1/5">
        <button @click="$emit('close')">
          <UiIcon
            class="tw-h-6 tw-w-6"
            name="arrow-left"
          />
        </button>
      </div>

      <div class="tw-w-3/5 tw-flex tw-items-center tw-justify-center">
        <UiDropdown
          align="center"
        >
          <template #trigger>
            <div class="tw-flex tw-items-center">
              <div class="tw-font-semibold">
                {{ $t('general.media-vault') }}
              </div>
              <UiIcon
                v-if="categories.length > 0"
                class="tw-h-5 tw-w-5 tw-ml-1"
                name="chevron-down"
                solid
              />
            </div>
          </template>

          <UiDropdownItem
            v-for="category in categories"
            :key="category.id"
            @click="loadCategory(category)"
          >
            <span>
              <span :class="{ 'tw-font-bold': activeCategory && category.name === activeCategory.name }">
                {{ category.name }}
              </span>

              <UiVaultCategoryStats
                class="tw-mt-2"
                :mediaCount="category.media_count"
                :videoCount="category.video_count"
              />
            </span>
          </UiDropdownItem>
        </UiDropdown>
      </div>
    </div>

    <div
      v-if="isLoading || vault.length > 0"
      class="tw-flex-1 tw-overflow-auto"
    >
      <UiMediaGallery
        v-model="checked"
        :cols="{ initial: 3, xl: 3 }"
        :loading="isLoading"
        :vault="vault"
        type="numbered"
      />
    </div>

    <div
      v-else
      class="tw-mt-6 tw-text-center"
    >
      <img
        alt=""
        class="tw-inline-block tw-w-44"
        :src="require('@/assets/illustrations/not-found.svg')"
      >
      <div class="tw-mt-8">
        {{ $t('general.no-vault-media-yet') }}
      </div>
    </div>
  </div>
</template>

<script>
import UiMediaGallery from '@/components/ui/UiMediaGallery.vue';
import UiIcon from '@/components/ui/UiIcon.vue';
import UiDropdown from '@/components/ui/UiDropdown.vue';
import Media from '@/components/models/Media';
import UiDropdownItem from '@/components/ui/UiDropdownItem.vue';
import UiVaultCategoryStats from '@/components/ui/UiVaultCategoryStats.vue';
import VaultCategory from '@/components/models/VaultCategory';

export default {
  components: {
    UiDropdown,
    UiDropdownItem,
    UiIcon,
    UiMediaGallery,
    UiVaultCategoryStats,
  },
  props: {
    value: Array,
  },
  emits: ['close'],
  data() {
    return {
      activeCategory: null,
      vault: [],
      categories: [],
      isLoading: false,
    };
  },
  computed: {
    checked: {
      get() {
        return this.value;
      },
      set(checked) {
        this.$emit('input', checked);
      },
    },
    types() {
      return {
        image: 'image',
        video: 'video',
      };
    },
  },
  created() {
    this.loadCategories();
    this.loadVault('/');
  },
  methods: {
    loadCategories() {
      this.isLoadingCategories = true;

      this.$get(
        '/vault',
        (data) => {
          let categories = [];
          for (let category of data.data.categories) {
            categories.push(new VaultCategory(category));
          }
          this.categories = categories;
          this.isLoadingCategories = false;
        },
        (errors) => {
          console.log(errors);
        },
      );
    },
    loadVault(path) {
      this.isLoading = true;

      this.$get(
        '/vault?path=' + path,
        (data) => {
          let vault = [];
          for (let media of data.data) {
            vault.push(new Media(media));
          }
          this.vault = vault;
          this.isLoading = false;
        },
        (errors) => {
          console.log(errors);
        },
      );
    },
    loadCategory(category) {
      this.activeCategory = category;
      this.loadVault(this.activeCategory.path)
    },
  },
};
</script>
