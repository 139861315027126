<template>
  <div>
    <div>
      <div class="tw-flex tw-items-center">
        <div class="tw-flex tw-items-start tw-min-w-0">
          <UiAvatar
            :text="currentUser.initials"
            :src="currentUser.avatar_url"
            :to="currentUser.url"
            size="sm"
          />

          <div class="tw-ml-3 tw-overflow-hidden">
            <UiUsername
              :user="currentUser"
            />
            <div class="tw-text-gray-400">
              <template v-if="currentUser.isCreator">
                {{ isOnline ? $t('general.online') : $t('general.offline') }}
                <span
                  v-if="isOnline"
                  class="tw-inline-block tw-align-middle tw-ml-1 tw-h-[5px] tw-w-[5px] tw-rounded-full tw-bg-success-alt"
                />
              </template>
              <template v-else>
                @{{ currentUser.username }}
              </template>
            </div>
          </div>
        </div>

        <div
          v-if="currentUser.isCreator"
          class="tw-ml-auto tw-pl-2"
        >
          <UiToggle
            :checked="isOnline"
            @change="onToggleOnline"
          />
        </div>
      </div>
    </div>

    <div class="tw-mt-6 md:tw-mt-5 md:tw-space-y-2">
      <UiNavItem
        color="primary"
        icon="profile"
        :to="currentUser.url"
      >
        {{ $t('general.my-channel') }}
      </UiNavItem>
      <UiNavItem
        v-if="!currentUser.isCreator"
        color="success"
        icon="discount"
        to="/subscriptions"
      >
        {{ $t('general.subscriptions') }}
      </UiNavItem>
      <UiNavItem
        v-if="!currentUser.isCreator"
        color="info"
        icon="wallet"
        to="/payments"
      >
        {{ $t('general.payments') }}
        <!-- @TODO: Notify user when there is payment issue -->
        <!--
        <div class="tw-ml-2 tw-inline-block tw-h-2 tw-w-2 tw-bg-error tw-rounded-full tw-animate-pulse [tw-animation-delay:-0.5s]" />
        -->
      </UiNavItem>
      <UiNavItem
        v-if="currentUser.isCreator"
        color="warning"
        icon="bank"
        to="/payouts"
      >
        {{ $t('general.payouts') }}
      </UiNavItem>

      <UiSeparator
        class="tw-hidden tw-my-6 md:tw-block"
      />

      <UiNavItem
        v-if="currentUser.isCreator"
        color="primary"
        icon="heart"
        to="/lists"
      >
        {{ $t('general.lists') }}
      </UiNavItem>
      <UiNavItem
        color="info"
        icon="bookmark"
        to="/bookmarks"
      >
        {{ $t('general.bookmarks') }}
      </UiNavItem>
      <UiNavItem
        v-if="currentUser?.isCreator"
        color="info-alt"
        icon="folder"
        to="/vault"
      >
        {{ $t('general.media-vault') }}
      </UiNavItem>
      <UiNavItem
        color="success"
        icon="shield-done"
        to="/security"
      >
        {{ $t('general.security') }}
      </UiNavItem>
      <UiNavItem
        color="primary"
        icon="category"
        to="/settings/language"
      >
        {{ $t('general.language') }}
      </UiNavItem>

      <UiNavItem
        class="tw-text-primary-500"
        color="primary"
        icon="logout"
        @click="isLogoutModalOpen = true"
      >
        {{ $t('general.logout') }}
      </UiNavItem>
    </div>

    <div
      v-if="currentUser.shouldStartOnboarding"
      class="tw-mt-6"
    >
      <UiButton
        class="tw-w-full"
        variant="primary"
        @click="openCreatorModal"
      >
        {{ becomeCreatorCTA }}
      </UiButton>
    </div>

    <UiAlertModal
      v-if="isLogoutModalOpen"
      :title="$t('general.logout')"
      :message="$t('general.logout-message')"
      :cancel="$t('general.cancel')"
      :confirm="$t('general.logout-confirm')"
      @cancel="isLogoutModalOpen = false"
      @confirm="logout"
    />
  </div>
</template>

<script>
import UiAlertModal from '@/components/ui/UiAlertModal.vue';
import UiAvatar from '@/components/ui/UiAvatar.vue';
import UiNavItem from '@/components/ui/UiNavItem.vue';
import UiUsername from '@/components/ui/UiUsername.vue';
import UiButton from '@/components/ui/UiButton.vue';
import UiModal from '@/components/ui/UiModal.vue';
import UiToggle from '@/components/ui/UiToggle.vue';
import UiSeparator from '@/components/ui/UiSeparator.vue';
import Verification from "@/components/models/Verification";

export default {
  components: {
    UiSeparator,
    UiToggle,
    UiModal,
    UiButton,
    UiAlertModal,
    UiAvatar,
    UiNavItem,
    UiUsername,
  },
  data() {
    return {
      isLogoutModalOpen: false,
      verification: null,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
    isOnline() {
      return this.$store.state.online;
    },
    becomeCreatorCTA() {
      return this.verification && !this.verification.isApproved
        ? this.$t('general.check-application')
        : this.$t('general.become-creator')
    }
  },
  created() {
    this.loadStatus();
  },
  mounted() {
    if (this.currentUser?.shouldStartOnboarding) {
      this.$get(
        '/payouts/verification',
        (data) => {
            this.verification = data.verification
              ? new Verification(data.verification)
              : null;

            if (!this.verification && this.currentUser.signup_for_account_type !== null) {
              this.$store.state.showBecomeCreatorModal = true;
            }
          },
          (errors) => {
            console.log(errors);
          },
      );
    }
  },
  methods: {
    loadStatus() {
      if (!this.$store.state.currentUser?.isCreator) {
        return;
      }

      this.$get(
        '/profile/online',
        (data) => {
          this.$store.state.online = data.is_connected ?? false;
        },
        (errors) => {
          console.log(errors);
        },
      );
    },
    onToggleOnline(value) {
      this.$store.state.online = value;

      this.$post(
        '/profile/online',
        {},
        () => {},
        (errors) => {
          console.log(errors);
        },
      );
    },
    onBecomeCreator() {
      this.$router.push('/verify');
    },
    openCreatorModal() {
      // if (this.currentUser.email_verified === false) {
      //   return this.$toast({
      //     variant: 'error',
      //     title: this.$t('verify-email.heading'),
      //     description: this.$t('verify-email.error'),
      //   });
      // }

      if (this.verification && !this.verification.isApproved) {
        this.onBecomeCreator();

        return;
      }

      this.$store.state.showBecomeCreatorModal = true
    },
    logout() {
      this.$post(
        '/auth/logout',
        {},
        () => {},
        (errors) => {
          console.log(errors);
        },
      );
      this.$saveToken(null);
      this.$saveUser(null);
      
      if (this.$route.path !== '/') {
        this.$router.replace('/');
      }
    },
  },
};
</script>
