<template>
  <div class="tw-grow tw-flex tw-flex-col">
    <div class="tw-mb-6 tw-text-center md:tw-mb-10">
      <div v-if="verification.can_start_kyc && !isCompleted">
        <div class="tw-text-h5 tw-font-semibold md:tw-text-h2 md:tw-font-bold">
          {{ $t('general.proof-of-residency-title') }}
        </div>
        <div class="tw-mt-4">
          {{ $t('general.proof-of-residency-subtitle') }}
        </div>

        <div id="complycube-mount"></div>

        <UiButton
          class="tw-w-full tw-my-8"
          type="button"
          variant="primary"
          @click="startKyc"
        >
          {{ $t('general.kyc.start-verification') }}
        </UiButton>
      </div>

      <div v-else-if="verification.isApproved">
        {{ $t('general.kyc.completed') }}
        <div class="tw-block">
          <UiButton
              class="tw-mt-5"
              variant="primary"
              to="/"
          >
            {{ $t('general.continue') }}
          </UiButton>
        </div>
      </div>

      <div v-else-if="verification.isDeclined">
        {{ $t('general.kyc.declined') }}
        <div class="tw-block">
          <UiButton
              class="tw-mt-5"
              variant="primary"
              to="/"
          >
            {{ $t('general.continue') }}
          </UiButton>
        </div>
      </div>

      <div v-else-if="verification.isPending || isCompleted">
        {{ $t('general.kyc.pending') }}
        <div class="tw-block">
          <UiButton
              class="tw-mt-5"
              variant="primary"
              to="/"
          >
            {{ $t('general.continue') }}
          </UiButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Verification from '@/components/models/Verification';

import Countries from '@/components/helpers/Countries';
import UiButton from '@/components/ui/UiButton.vue';

export default {
  components: {
    UiButton,
  },
  props: {
    verification: Verification,
  },
  data() {
    return {
      errors: {},
      photo: null,
      complyCube: null,
      isCompleted: false,
    };
  },
  computed: {
    country() {
      return Countries[this.verification.country];
    },
  },
  mounted() {
    const plugin = document.createElement('script');
    plugin.setAttribute(
      'src',
      '//assets.complycube.com/web-sdk/v1/complycube.min.js',
    );
    plugin.async = true;
    document.head.appendChild(plugin);
  },
  methods: {
    startKyc() {
      // eslint-disable-next-line no-undef
      this.complyCube = window.ComplyCube.mount({
        token: this.verification.token,
        language: this.$i18n.locale,
        branding: {
          textBrand: 'Dropfeet KYC',
          appearance: {
            primaryButtonColor: '#ff4d5a',
            primaryButtonHoverColor: '#ff4d5a',
            primaryButtonBorderColor: '#ff4d5a',
            primaryButtonActiveColor: '#ff4d5a',
            secondaryButtonColor: '#ff4d5a',
            secondaryButtonHoverColor: '#ff4d5a',
            secondaryButtonActiveColor: '#ff4d5a',
            documentSelectorHoverBorderColor: '#ff4d5a',
            documentSelectorActiveBorderColor: '#ff4d5a',
            linkHoverColor: '#ff4d5a',
            linkActiveColor: '#ff4d5a',
            linkUnderlineColor: '#ff4d5a',
            infoPopupLinkHoverColor: '#ff4d5a',
            infoPopupLinkActiveColor: '#ff4d5a',
          },
        },
        stages: [
          'intro',
          {
            name: 'documentCapture',
            options: {
              crossDeviceOnly: false,
              documentTypes: {
                passport: true,
                national_identity_card: true,
                driving_license: false,
              },
            },
          },
          {
            name: 'faceCapture',
            options: {
              mode: 'photo'
            }
          },
          {
            name: 'completion',
            options: {
              // heading: "Thank you for completing the process",
              message: [this.$t('general.kyc.pending')],
            },
          },
        ],
        onComplete: (data) => {
          this.$post('/kyc/check', {
            document_id: data.documentCapture.documentId,
            live_photo_id: data.faceCapture.livePhotoId
          });
          this.isCompleted = true;
        },
        onModalClose: () => {
          this.complyCube.updateSettings({ isModalOpen: false });
        },
        onError: function ({ type, message }) {
          console.log(type, message);
        },
      });
    },
    onContinue() {
      let formData = new FormData();
      formData.append('photo', this.photo);

      this.$post(
        '/payouts/verification',
        formData,
        (data) => {
          this.localVerification = new Verification(data);
        },
        (errors) => {
          this.errors = errors;
        },
      );
    },
  },
};
</script>

<style>
@import 'https://assets.complycube.com/web-sdk/v1/style.css';
</style>
