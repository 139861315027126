<template>
  <div>
    <UiTable>
      <UiTableHeader>
        <UiTableRow>
          <UiTableHead>{{ $t('general.dispute-date') }}</UiTableHead>
          <UiTableHead>{{ $t('general.payment-date') }}</UiTableHead>
          <UiTableHead>{{ $t('general.amount') }}</UiTableHead>
          <UiTableHead>{{ $t('general.fee') }} / {{ $t('general.net') }}</UiTableHead>
        </UiTableRow>
      </UiTableHeader>
      <UiTableBody>
        <UiTableRow
          v-for="(payment, index) in payments"
          :key="index"
        >
          <UiTableCell>
            <div>
              <div>{{ payment.created_at.format('ll') }}</div>
              <div class="tw-text-sm">{{ payment.created_at.format('LT') }}</div>
            </div>

            <div class="tw-mt-2 tw-text-sm tw-text-gray-700">
              <i18n
                :path="getDescription(payment)"
                tag="span"
              >
                <template v-slot:user>
                  <UiLink :to="payment.user.url">
                    {{ payment.user.username }}
                  </UiLink>
                </template>
                <template v-slot:to>
                  <UiLink :to="payment.to.url">
                    {{ payment.to.username }}
                  </UiLink>
                </template>
                <template v-slot:post v-if="payment.items.post">
                  <UiLink :to="payment.items.post.url">
                    {{ $t('general.post') }}
                  </UiLink>
                </template>
                <template v-slot:message>
                  {{ $t('general.private-message') }}
                </template>
              </i18n>
            </div>
          </UiTableCell>
          <UiTableCell>
            <template v-if="payment.disputed_at">
              <div>{{ payment.disputed_at.format('ll') }}</div>
              <div class="tw-text-sm">{{ payment.disputed_at.format('LT') }}</div>
            </template>
          </UiTableCell>
          <UiTableCell>
            {{ $formatAmount(Math.abs(payment.amount)) }}
          </UiTableCell>
          <UiTableCell>
            <div>{{ $formatAmount(payment.commission_amount + payment.processing_amount) }}</div>
            <div>{{ $formatAmount(Math.abs(payment.amount_paid_to_creator)) }}</div>
            <div>
              <span v-if="payment.status === status.disputing">
                <UiLoader class="tw-inline-block" />
              </span>
              <UiIcon
                v-else
                v-tooltip="payment.status === status.dispute_lost ? $t('general.chargeback-dispute-lost-tooltip') : null"
                class="tw-inline-block tw-h-5 tw-w-5 tw-text-error"
                name="refresh"
              />
            </div>
          </UiTableCell>
        </UiTableRow>
      </UiTableBody>
    </UiTable>
  </div>
</template>

<script>
import Payment from '@/components/models/Payment';

import UiLink from '@/components/ui/UiLink.vue';
import UiTableCell from '@/components/ui/UiTableCell.vue';
import UiTableRow from '@/components/ui/UiTableRow.vue';
import UiIcon from '@/components/ui/UiIcon.vue';
import UiTableHeader from '@/components/ui/UiTableHeader.vue';
import UiTableHead from '@/components/ui/UiTableHead.vue';
import UiTable from '@/components/ui/UiTable.vue';
import UiTableBody from '@/components/ui/UiTableBody.vue';
import UiLoader from '@/components/ui/UiLoader.vue';

export default {
  components: {
    UiLoader,
    UiTableBody,
    UiTable,
    UiTableHead,
    UiTableHeader,
    UiIcon,
    UiLink,
    UiTableCell,
    UiTableRow,
  },
  props: {
    payments: Array,
  },
  computed: {
    status() {
      return {
        pending: Payment.STATUS_PENDING,
        complete: Payment.STATUS_COMPLETE,
        failed: Payment.STATUS_FAILED,
        refunded: Payment.STATUS_REFUNDED,
        disputing: Payment.STATUS_DISPUTING,
        dispute_lost: Payment.STATUS_DISPUTE_LOST,
      };
    },
  },
  methods: {
    getDescription(payment) {
      switch (payment.type) {
        case Payment.TYPE_SUBSCRIPTION_NEW:
          return 'general.payment-subscription-from';
        case Payment.TYPE_SUBSCRIPTION_RENEW:
          return 'general.payment-subscription-renew-from';
        case Payment.TYPE_POST:
          return 'general.payment-post-unlocked-by';
        case Payment.TYPE_MESSAGE:
          return 'general.payment-message-unlocked-by';
        case Payment.TYPE_TIP:
          return 'general.payment-tip-from';
      }
      return '';
    },
  },
};
</script>
