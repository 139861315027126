import dayjs from "dayjs";
var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

export default class Payout {

    static STATUS_PENDING = 'pending_review';
    static STATUS_REJECTED = 'rejected';
    static STATUS_PENDING_WIRE_TRANSFER = 'pending_wire_transfer';
    static STATUS_COMPLETE = 'completed';
    static STATUS_FAILED = 'failed';

    id = 0;
    amount = 0;
    processing_amount = 0;
    final_amount = 0;
    status = Payout.STATUS_PENDING;
    created_at = dayjs();
    processed_at = null;
    invoice_number = null;

    constructor(data) {
        this.id = data.id;
        this.status = data.status;
        this.created_at = dayjs(data.created_at);
        this.processed_at = data.processed_at ? dayjs(data.processed_at) : null;
        this.amount = data.amount;
        this.final_amount = data.final_amount;
        this.processing_amount = data.processing_amount;
        this.invoice_number = data.invoice_number;
    }

    get formattedProcessedAt() {
        return this.processed_at.format('ll')
    }

    get isPending() {
        return this.status === Payout.STATUS_PENDING
    }

    get isPendingWireTransfer() {
        return this.status === Payout.STATUS_PENDING_WIRE_TRANSFER
    }

    get isFailed() {
        return this.status === Payout.STATUS_FAILED;
    }

    get isRejected() {
        return this.status === Payout.STATUS_REJECTED;
    }

    get isCompleted() {
        return this.status === Payout.STATUS_COMPLETE;
    }
}