<template>
  <UiModal
    :title="$t('general.mass-message')"
    size="md"
    :cancel="$t('general.cancel')"
    :confirm="$t('general.send')"
    :disabled="include.length === 0 || isMediaUploading"
    @cancel="$router.push('/messages')"
    @close="$router.push('/messages')"
    @confirm="sendMessage"
  >
    <div class="tw-flex tw-flex-col">
      <div
        ref="conversation"
        class="tw-flex-1 tw-flex tw-overflow-auto tw-overscroll-contain tw-max-h-[18rem]"
      >
        <div class="tw-w-full tw-flex tw-flex-col">
          <div>
            <div class="tw-font-semibold tw-text-lg">
              {{ $t('general.include') }}
            </div>

            <div class="tw-mt-3 tw-space-y-3">
              <template v-if="isLoading">
                <div
                  v-for="i in 3"
                  :key="i"
                  class="tw-py-3 tw-px-4 tw-space-y-2"
                >
                  <UiSkeleton class="tw-h-3 tw-w-32"/>
                  <UiSkeleton class="tw-h-3 tw-w-16"/>
                </div>
              </template>

              <UiCheckboxCard
                v-for="(item, index) in lists"
                :key="index"
                v-model="include"
                :value="item.id"
              >
                <div>
                  <span class="font-weight-bold">{{ item.title }}</span
                  ><br />
                  <span class="text-muted">{{
                      $tc('general.x-people', item.listeesCount)
                    }}</span>
                </div>
              </UiCheckboxCard>
            </div>
          </div>
        </div>
      </div>

      <div class="tw-pt-6 tw-bg-white">
        <UiFormTextarea
          v-model="message"
          name="message"
          :errors="errors"
          :placeholder="$t('general.type-message')"
          rows="1"
        >
          <template #top>
            <UiMediaUploader
              ref="uploader"
              explicit
              layout="row"
              :value="media"
              vault
              @input="syncMedia"
              @upload-ended="isMediaUploading = false"
              @upload-started="isMediaUploading = true"
            />
          </template>
          <template #end>
            <div class="tw-flex tw-items-center tw-space-x-3">
              <button
                type="button"
                @click="isModalVaultOpen = true"
              >
                <UiIcon
                  name="folder"
                  curved
                />
              </button>
              <div @click="isModalPriceOpen = true">
                <UiBadge
                  v-if="price > 0"
                  class="-tw-my-2"
                  color="info"
                  icon="wallet"
                  responsive
                >
                  {{ priceFormat }}
                </UiBadge>
                <button
                  v-else
                  class="tw-block"
                  type="button"
                >
                  <UiIcon
                    name="dollar"
                    curved
                  />
                </button>
              </div>
              <button
                type="button"
                @click="mediaDropzoneClick"
              >
                <UiIcon
                  name="camera"
                  curved
                />
              </button>
            </div>
          </template>
        </UiFormTextarea>

        <div v-for="(value, key) in errors" :key="key">
          <UiFormErrors
            :errors="value"
          />
        </div>
      </div>
    </div>

    <UiModal
      v-if="isModalPriceOpen"
      :title="$t('general.message-price')"
      :cancel="$t('general.cancel')"
      :confirm="$t('general.add')"
      :disabled="hasPriceError"
      size="sm"
      @cancel="onClosePriceModal"
      @confirm="isModalPriceOpen = false"
      @close="onClosePriceModal"
    >
      <UiFormInput
        v-model="price"
        :label="$t('general.price')"
        name="price"
        :placeholder="$t('general.free')"
        :prepend="currency"
      />

      <p
        v-if="hasPriceError"
        class="tw-text-error tw-pt-4"
      >
        {{ $t('general.payment-processing.price-between-error', [5, 100]) }}
      </p>
    </UiModal>

    <UiModal
      v-if="isModalVaultOpen"
      size="xl"
      :cancel="$t('general.close')"
      :confirm="$t('general.upload')"
      :disabled="selectedMedia.length === 0"
      @cancel="closeVault"
      @close="closeVault"
      @confirm="addAllSelectedMedia"
    >
      <PageVault
        @select="(event) => selectedMedia = event"
      />
    </UiModal>
  </UiModal>
</template>

<script>
import 'swiper/swiper-bundle.css';
import Swiper from 'swiper';

import Media from '@/components/models/Media';
import Message from '@/components/models/Message';
import List from '@/components/models/List';

import UiMediaUploader from '@/components/ui/UiMediaUploader.vue';
import UiModal from '@/components/ui/UiModal.vue';
import UiCheckboxCard from '@/components/ui/UiCheckboxCard.vue';
import UiFormTextarea from '@/components/ui/UiFormTextarea.vue';
import UiIcon from '@/components/ui/UiIcon.vue';
import UiBadge from '@/components/ui/UiBadge.vue';
import UiFormInput from '@/components/ui/UiFormInput.vue';
import PageVault from '@/components/pages/PageVault.vue';
import UiFormErrors from '@/components/ui/UiFormErrors.vue';
import UiSkeleton from '@/components/ui/UiSkeleton.vue';

export default {
  components: {
    UiSkeleton,
    UiFormErrors,
    PageVault,
    UiFormInput,
    UiBadge,
    UiIcon,
    UiFormTextarea,
    UiCheckboxCard,
    UiModal,
    UiMediaUploader,
  },
  props: {
    value: [Message, null],
  },
  data() {
    return {
      price: null,
      hasPriceError: false,
      errors: {},
      message: '',
      media: [],
      lists: [],
      include: [],
      selectedMedia: [],
      isLoading: false,
      isModalPriceOpen: false,
      isModalVaultOpen: false,
      isMediaUploading: false,
    };
  },
  computed: {
    mass: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    currency() {
      return process.env.VUE_APP_CURRENCY_SIGN;
    },
    priceFormat() {
      return this.currency + this.price;
    },
  },
  watch: {
    price() {
      this.hasPriceError = false;

      if (this.price !== '0' && this.price !== '' && (this.price < 5 || this.price > 100)) {
        this.hasPriceError = true;
      }
    },
  },
  mounted() {
    this.loadLists();
  },
  methods: {
    loadLists() {
      this.isLoading = true;

      this.$get(
        '/lists/message',
        (data) => {
          this.isLoading = false;

          for (let d of data.lists) {
            const l = new List(d, this);
            if (l.listeesCount > 0) {
              this.lists.push(l);
            }
          }
          this.$nextTick(() => {
            new Swiper('.swiperx', {
              // Optional parameters
              direction: 'horizontal',
              freeMode: {
                enabled: true,
                sticky: false,
              },
              loop: false,
              slidesPerView: 'auto',
            });
          });
        },
        () => {
          this.isLoading = false;
        },
      );
    },
    mediaDropzoneClick() {
      this.$refs.uploader.click();
    },
    manualMedia() {
      this.$refs.uploader.manual((m) => typeof m.id === 'string');
    },
    addAllSelectedMedia() {
      this.selectedMedia.forEach((media) => {
        this.media.push(media);
      });
      this.manualMedia();
      this.closeVault();
    },
    syncMedia(media) {
      if (media.length < this.media.length) {
        // Some media have been deleted, remove them from `this.selectedMedia` also
        const ids = media.map((m) => m.id);
        this.selectedMedia = this.selectedMedia.filter((m) => ids.includes(m.id));
      }

      // New media have been uploaded externally here, no need to worry about `this.selectedMedia`

      this.media = media;
    },
    closeVault() {
      this.isModalVaultOpen = false;
      this.selectedMedia = [];
    },
    sendMessage() {
      this.errors = {};

      const isMedia = media => media.type === Media.TYPE_IMAGE || media.type === 'image';
      const isVideo = media => media.type === Media.TYPE_VIDEO || media.type === 'video';

      const videos = this.media
        .filter(m => isVideo(m))
        .map(m => {
          // The media selected from within the vault don't have a `uuid`
          // property but only an `id`, and the backend doesn't support videos
          // sent with an `id` property.
          return {
            ...m,
            uuid: m.uuid ?? m.id,
          };
        });

      const media = this.media
        .filter(m => isMedia(m))
        .map(m => ({
          id: m.id,
          screenshot: m.scr ? m.scr.id : null,
        }));

      const fields = {
        message: this.message,
      };

      if (media.length > 0) {
        fields.media = media;
      }

      if (this.price) {
        fields.price = this.price;
      }

      fields.include = this.include;

      if (videos.length > 0) {
        fields.videos = videos;
      }

      this.$post(
        '/messages',
        fields,
        (data) => {
          const m = new Message(data);
          m.recipients_count = 0;
          this.mass = m;
          this.$router.replace('/messages');
        },
        (errors, { response }) => {
          if (response.status === 429) {
            this.errors = {
              'message': [this.$t('message.errors.rate-limit')],
            };
          } else {
            this.errors = errors;
          }
        },
      );
    },
    onClosePriceModal() {
      this.isModalPriceOpen = false;
      if(this.hasPriceError) {
        this.price = ''
      }
    },
  },
};
</script>
