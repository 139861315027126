<template>
  <input
    v-model="inputVal"
    v-mask="mask"
    class="
      tw-w-auto tw-text-center tw-p-6 tw-font-bold tw-text-3xl tw-rounded-2xl tw-border-2 tw-border-primary-400
      focus:tw-outline-none focus:tw-border-primary-500 focus:tw-bg-primary-50
    "
  >
</template>

<script>
export default {
  props: {
    value: String,
    mask: [String, Array, Function],
    autofocus: Boolean
  },
  mounted() {
    if (this.autofocus) {
      this.$el.focus();
    }
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
};
</script>
