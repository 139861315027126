<template>
  <div class="tw-flex tw-items-center tw-justify-between tw-p-4">
    <div>
      <RouterLink
        to="/"
      >
        <img
          class="tw-w-auto tw-h-6"
          :src="require('@/assets/byfeet-logo.svg')"
        />
      </RouterLink>
    </div>

    <div>
      <div class="tw-flex tw-items-center tw-space-x-6">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    updates() {
      return this.$store.state.updates;
    },
  },
};
</script>
