<template>
  <div
    class="tw-group tw-relative tw-w-full"
    :class="toast.base()"
  >
    <div class="tw-space-y-1">
      <div
        v-if="title"
        :class="toast.title()"
      >
        {{ title }}
      </div>
      <div
        v-if="description"
        v-html="description"
      />
      <slot />
    </div>

    <button
      v-if="dismissible"
      class="tw-absolute tw-top-2 tw-right-2 tw-p-2 tw-opacity-0 group-hover:tw-opacity-100 tw-transition-opacity"
      @click="$emit('close')"
    >
      <UiIcon
        class="tw-h-4 tw-w-4"
        name="close"
      />
    </button>
  </div>
</template>

<script>
import { tv } from 'tailwind-variants';

import UiIcon from '@/components/ui/UiIcon.vue';

export default {
  components: {
    UiIcon,
  },
  props: {
    dismissible: {
      type: Boolean,
      default: true,
    },
    duration: {
      type: Number,
      default: 5000,
    },
    title: String,
    description: String,
    variant: String,
    icon: String,
  },
  computed: {
    className() {
      return tv({
        slots: {
          base: 'tw-p-4 tw-rounded-xl tw-shadow-md',
          title: 'tw-font-semibold',
        },
        variants: {
          variant: {
            default: {
              base: 'tw-text-black tw-bg-white',
            },
            success: {
              base: 'tw-bg-success-50 tw-border tw-border-success',
              title: 'tw-text-success',
            },
            error: {
              base: 'tw-bg-error-50 tw-border tw-border-error',
              title: 'tw-text-error',
            },
          },
        },
        defaultVariants: {
          variant: 'default',
        },
      });
    },
    toast() {
      return this.className({ variant: this.variant });
    },
  },
};
</script>
