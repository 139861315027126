<template>
  <div class="tw-p-3 tw-text-sm tw-rounded-2xl tw-bg-gray-50 md:tw-p-6 md:tw-text-base">
    <div class="tw-mb-4 tw-flex tw-items-center tw-justify-between">
      <div class="tw-font-semibold">
        {{ title || $t('general.write-caption') }}
      </div>

      <div
        v-if="editable"
        class="tw-flex tw-items-center tw-space-x-3"
      >
        <button
          @click="$emit('edit')"
        >
          <UiIcon
            name="edit"
          />
        </button>
        <button
          @click="$emit('remove')"
        >
          <UiIcon
            name="delete"
          />
        </button>
      </div>
    </div>

    <div class="tw-space-y-2">
      <div
        v-for="(option, index) in options"
        :key="index"
        class="tw-py-4 tw-px-5 tw-bg-white tw-rounded-xl"
        :class="{ 'tw-cursor-pointer': !editable }"
        @click="onVote(option)"
      >
        <div class="tw-flex tw-items-center">
          <div class="tw-grow">
            {{ option.option ?? option }}
          </div>

          <div v-if="showResults">
            {{ calcPercent(option) }}%
          </div>
        </div>

        <div
          v-if="showResults"
          class="tw-mt-2 tw-relative tw-bg-gray-50 tw-rounded-full tw-h-1"
        >
          <div
            class="tw-absolute tw-inset-0 tw-rounded-full tw-bg-primary-500"
            :style="{ width: `${calcPercent(option)}%` }"
          />
        </div>
      </div>
    </div>

    <div
      v-if="showResults"
      class="tw-mt-4 tw-font-semibold"
    >
      {{ $tc('general.x-votes', [totalVotes]) }}
    </div>
  </div>
</template>

<script>
import UiIcon from '@/components/ui/UiIcon.vue';

export default {
  components: { UiIcon },
  props: {
    title: String,
    options: Array,
    editable: Boolean,
    showResults: Boolean,
  },
  computed: {
    totalVotes() {
      let total = 0;

      for (let option of this.options) {
        if (option.votes_count) {
          total += option.votes_count;
        }
      }

      return total;
    },
  },
  methods: {
    calcPercent(option) {
      if (!this.showResults) {
        return 0;
      }

      for (let o of this.options) {
        if (o.id === option.id && o.votes_count !== undefined) {
          return Math.round((o.votes_count / this.totalVotes) * 100);
        }
      }

      return 0;
    },
    onVote(option) {
      if (option.id) {
        this.$emit('vote', option.id);
      }
    },
  },
};
</script>
