<template>
  <div class="md:tw-text-center">
    <div class="tw-max-w-[14rem] tw-font-bold tw-text-h3 md:tw-max-w-none md:tw-text-h2">
      {{ title }}
    </div>

    <img
      v-if="image"
      alt=""
      class="tw-mt-6 md:tw-hidden"
      :src="image"
    >

    <div
      v-if="message"
      class="tw-mt-3 md:tw-mt-4"
    >
      {{ message }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    message: String,
    image: String,
  },
};
</script>

