<template>
  <div>
    <div class="tw-relative tw-pb-[100%] tw-rounded-2xl">
      <div class="tw-absolute tw-inset-0 tw-h-full tw-w-full">
        <UiImage
          class="tw-rounded-2xl"
          fill
          :src="entry.url"
          :srcset="entry.variants"
          :sizes="{ initial: '100vw', md: '27vw', xl: '21vw' }"
        />
      </div>

      <div
        v-if="badge"
        class="tw-absolute tw-top-4 tw-right-4"
      >
        <div class="tw-flex tw-py-2 tw-px-3 tw-bg-white tw-text-primary-500 tw-rounded-full">
          <UiIcon
            name="star"
            solid
          />
          <span class="tw-ml-2">
            {{ badge }}
          </span>
        </div>
      </div>
    </div>

    <div class="tw-mt-2 tw-flex tw-items-center">
      <div class="tw-font-semibold tw-text-lg">
        {{ entry.username }}
      </div>

      <div class="tw-ml-auto">
        <button
          @click="$emit('share', entry)"
        >
          <UiIcon
            name="share"
          />
        </button>
      </div>
    </div>

    <div>
      <span class="tw-font-semibold tw-text-sm tw-text-gray-700">
        {{ $tc('general.x-votes', entry.votes_count) }}
      </span>
    </div>

    <div
      v-if="votable"
      class="tw-mt-2"
    >
      <UiButton
        class="tw-w-full tw-group"
        :variant="entry.has_voted ? 'secondary' : 'outline'"
        @click="vote"
      >
        <template v-if="entry.has_voted">
          <span class="group-hover:tw-hidden">
            {{ $t('general.voted') }}
          </span>
          <span class="tw-hidden group-hover:tw-inline">
            {{ $t('general.unvote') }}
          </span>
        </template>
        <template v-else>
          {{ $t('general.vote') }}
        </template>
      </UiButton>
    </div>
  </div>
</template>

<script>
import ContestEntry from '@/components/models/ContestEntry';

import UiIcon from '@/components/ui/UiIcon.vue';
import UiButton from '@/components/ui/UiButton.vue';
import UiImage from '@/components/ui/UiImage.vue';

export default {
  components: {
    UiImage,
    UiIcon,
    UiButton,
  },
  props: {
    value: ContestEntry,
    badge: String,
    votable: Boolean,
  },
  computed: {
    entry: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  methods: {
    vote() {
      this.$emit('vote', this.entry);
    },
  },
};
</script>