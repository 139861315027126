<template>
  <VDropdown
    auto-boundary-max-size
    class="tw-relative tw-text-left"
    :placement="placement"
  >
    <button
      class="tw-block tw-p-1 tw-bg-white tw-rounded-full"
      type="button"
    >
      <slot name="trigger">
        <UiIcon
          class="tw-h-6 tw-w-6"
          :name="icon"
        />
      </slot>
    </button>

    <template #popper>
      <div
        v-close-popper
        class="tw-px-1 tw-divide-y tw-divide-gray-200"
      >
        <slot />
      </div>
    </template>
  </VDropdown>
</template>

<script>
import UiIcon from '@/components/ui/UiIcon.vue';

export default {
  components: {
    UiIcon,
  },
  props: {
    align: {
      type: String,
      default: 'end',
    },
    icon: {
      type: String,
      default: 'more-circle',
    },
    contentHeight: String,
  },
  computed: {
    placement() {
      switch (this.align) {
        case 'start':
          return 'bottom-start';
        case 'center':
          return 'bottom';
        case 'end':
        default:
          return 'bottom-end';
      }
    },
  },
};
</script>
