<template>
  <UiCard>
    <UiNavigationBar
      bleed
      :title="$t('general.change-password')"
      to="/security"
    />

    <div>
      <form
        class="tw-w-full tw-space-y-4"
        @submit.prevent="saveSettings"
      >
        <UiFormInput
          type="password"
          name="old_password"
          v-model="oldPassword"
          :errors="errors"
          :label="$t('general.old-password')"
        />
        <UiFormInput
          type="password"
          name="new_password"
          v-model="newPassword"
          :errors="errors"
          :label="$t('general.new-password')"
        />
        <UiFormInput
          type="password"
          name="new_password_confirmation"
          v-model="newPasswordConfirm"
          :errors="errors"
          :label="$t('general.confirm-password')"
        />
      </form>

      <div class="tw-mt-6">
        <UiButton
          class="tw-w-full sm:tw-max-w-xs"
          variant="primary"
          @click.prevent="saveSettings"
        >
          {{ $t('general.change-password') }}
        </UiButton>
      </div>
    </div>

    <UiSuccessModal
      v-if="isSuccessModalOpen"
      icon="tick-square"
      :title="$t('general.password-success-title')"
      :message="$t('general.password-success-message')"
      :button="$t('general.ok')"
      @close="isSuccessModalOpen = false"
      @confirm="isSuccessModalOpen = false"
    />
  </UiCard>
</template>

<script>
import UiFormInput from '../ui/UiFormInput.vue';
import UiCard from '@/components/ui/UiCard.vue';
import UiNavigationBar from '@/components/ui/UiNavigationBar.vue';
import UiButton from '@/components/ui/UiButton.vue';
import UiSuccessModal from '@/components/ui/UiSuccessModal.vue';

export default {
  data: function () {
    return {
      oldPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
      errors: {},
      isSuccessModalOpen: false,
    };
  },
  components: {
    UiSuccessModal,
    UiButton,
    UiNavigationBar,
    UiCard,
    UiFormInput,
  },
  methods: {
    saveSettings() {
      this.errors = {};
      this.$post(
        '/profile/password',
        {
          old_password: this.oldPassword,
          new_password: this.newPassword,
          new_password_confirmation: this.newPasswordConfirm,
        },
        () => {
          this.isSuccessModalOpen = true;
        },
        (errors) => {
          this.errors = errors;
        },
      );
    },
  },
};
</script>
