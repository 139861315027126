<template>
  <div
    class="tw-flex"
  >
    <UiNotFound
      v-if="isUserNotFound"
      class="tw-w-full not-found"
    />

    <div
      v-if="user !== null"
      class="tw-w-full"
    >
      <UiCard>
        <div>
          <UiProfileCover
            v-if="user.cover_url !== null"
            :sizes="{ lg: '56vw', xl: '65vw' }"
            :src="user.cover_url"
            :srcset="user.cover_variants"
          />

          <div
            class="tw-mt-4 md:tw-mt-0 md:tw-relative md:tw-mx-auto"
            :class="{ 'md:tw-mt-[-50px] md:tw-z-[3]': user.cover_url !== null }"
          >
            <div class="tw-flex tw-items-center tw-gap-3 md:tw-flex-col">
              <UiAvatar
                class="md:tw-ring-4 md:tw-ring-white"
                :size="{ initial: 'sm', md: 'lg' }"
                :src="user.avatar_url"
                :text="user.initials"
                :to="user.url"
              />

              <div class="tw-flex tw-flex-col tw-items-start md:tw-items-center">
                <UiUsername
                  :asLink="false"
                  :user="user"
                />

                <div class="tw-text-gray-400 tw-truncate">
                  {{ '@' + user.username }}
                </div>
              </div>
            </div>

            <div class="tw-flex tw-flex-col tw-items-center">
              <div class="tw-mt-4 tw-flex -tw-mx-2 tw-divide-x tw-divide-gray-200">
                <div class="tw-px-6 tw-text-center">
                  <div class="tw-font-semibold tw-text-xl md:tw-text-2xl">
                    {{ $n(user.post_count, 'compact') }}
                  </div>
                  <div class="tw-text-gray-800">
                    {{ $tc('general.x-posts', user.post_count) }}
                  </div>
                </div>

                <div class="tw-px-6 tw-text-center">
                  <div class="tw-font-semibold tw-text-xl md:tw-text-2xl">
                    {{ $n(user.subscriber_count, 'compact') }}
                  </div>
                  <div class="tw-text-gray-800">
                    {{ $tc('general.x-fans', user.subscriber_count) }}
                  </div>
                </div>

                <div class="tw-px-6 tw-text-center">
                  <div class="tw-font-semibold tw-text-xl md:tw-text-2xl">
                    {{ $n(user.like_count, 'compact') }}
                  </div>
                  <div class="tw-text-gray-800">
                    {{ $tc('general.x-likes', user.like_count) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="user.bio || user.location || user.website"
          class="tw-mt-4 tw-space-y-3 tw-overflow-hidden md:tw-text-center"
        >
          <div
            v-if="user.bio"
            class="tw-mt-4 tw-whitespace-pre-line"
          >
            {{ user.bio }}
          </div>

          <div
            v-if="user.location"
            class="tw-text-gray-500"
          >
            {{ user.location }}
          </div>

          <div
            v-if="user.website"
          >
            <UiLink
              :href="user.website"
              target="_blank"
            >
              {{ user.website }}
            </UiLink>
          </div>
        </div>

        <!-- Interactions buttons -->
        <div class="tw-mt-6">
          <div
            class="tw-mx-auto tw-max-w-xl"
          >
            <div class="tw-flex tw-flex-wrap tw-gap-3 md:tw-justify-center md:tw-gap-4">
              <UiButton
                class="tw-basis-0 tw-grow"
                @click="onSubscribe"
                icon="star"
                variant="primary"
              >
                {{ $t('general.subscribe') }}
              </UiButton>
            </div>
          </div>
        </div>

      </UiCard>

      <div class="md:tw-mt-6">
        <div>
          <div class="tw-space-y-4">
            <div>
              <UiCard>
                <div
                  class="tw-mt-4 md:tw-mt-6"
                >
                  <div>
                    <div
                      class="tw-relative tw-h-0 tw-pb-[100%] md:tw-pb-[50%]"
                    >
                      <div class="tw-absolute tw-inset-0">
                        <div class="tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-py-5 tw-px-4 tw-space-y-3 md:tw-space-y-6 tw-text-gray-500 tw-bg-gray-200 tw-rounded-2xl">
                          <UiIcon
                            class="tw-h-20 tw-w-20 md:tw-h-24 md:tw-w-24"
                            name="lock"
                            solid
                          />

                          <div class="tw-flex tw-items-center tw-justify-center tw-space-x-3">
                            <div
                              class="tw-flex tw-items-center tw-space-x-1"
                            >
                              <UiIcon
                                class="tw-inline"
                                name="image"
                              />
                              <span>{{ $n(user.image_count, 'compact') }}</span>
                            </div>
                            <div
                              class="tw-flex tw-items-center tw-space-x-2"
                            >
                              <UiIcon
                                class="tw-inline"
                                name="video"
                              />
                              <span>{{ $n(user.video_count, 'compact') }}</span>
                            </div>
                          </div>

                          <UiButton
                            variant="primary"
                            @click="onSubscribe"
                          >
                            {{ $t('general.subscribe-to-see') }}
                          </UiButton>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </UiCard>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "~@/assets/scss/_variables.scss";

.nav-tabs .nav-link {
  border: none;
  border-bottom: 2px solid transparent;
  border-radius: 0;
  color: $secondary;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: $primary;
  border-color: $primary;
}
</style>

<script>
import User from '../models/User';

import UiAvatar from '../ui/UiAvatar.vue';
import UiButton from '../ui/UiButton.vue';
import UiCard from '../ui/UiCard.vue';
import UiIcon from '../ui/UiIcon.vue';
import UiUsername from '../ui/UiUsername.vue';
import UiProfileCover from '@/components/ui/UiProfileCover.vue';
import UiLink from '@/components/ui/UiLink.vue';
import UiNotFound from '@/components/ui/UiNotFound.vue';

export default {
  components: {
    UiLink,
    UiProfileCover,
    UiCard,
    UiButton,
    UiAvatar,
    UiIcon,
    UiUsername,
    UiNotFound,
  },
  data: function () {
    return {
      user: null,
      isUserNotFound: null,
    };
  },
  computed: {
    username: function () {
      return this.$route.params.username;
    },
  },
  watch: {
    username: function (oldV, newV) {
      if (oldV && newV && oldV != newV) {
        this.loadUser();
      }
    },
  },
  mounted() {
    this.loadUser();
  },
  methods: {
    loadUser() {
      this.$get(
        '/public/' + this.username,
        (data) => {
          this.user = new User(data);

          if (!this.user.isCreator) {
            this.$router.push('/');
          }
        },
        (errors) => {
          this.isUserNotFound = true;
          console.log(errors);
        },
      );
    },
    copyLink() {
      const link = `${process.env.VUE_APP_APP_URL}${this.user.url}`;
      this.$copyText(link);
      this.$bvToast.toast(this.$t('general.link-copied'), {
        autoHideDelay: 2000,
        title: this.$t('general.link'),
        solid: true,
        toaster: 'b-toaster-bottom-left',
      });
    },
    onSubscribe() {
      this.$redirector().push('/');
    },
  },
};
</script>
