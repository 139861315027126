<template>
  <div>
    <div
      v-if="label"
      class="tw-text-sm md:tw-text-lg md:tw-font-semibold tw-mb-3"
    >
      {{ label }}
    </div>

    <div
      class="tw-p-4 tw-flex tw-items-center tw-justify-between tw-bg-gray-50 tw-rounded-2xl"
      :class="{
        'tw-text-gray-600': disabled,
        'tw-cursor-pointer': !disabled,
      }"
      @click="onOpen"
    >
      <span
        class="tw-line-clamp-1"
        :class="{ 'tw-text-gray-500': !optionText }"
        :title="optionText"
      >
        {{ optionText ?? placeholder }}
      </span>

      <UiIcon
        name="chevron-down"
        solid
      />
    </div>

    <UiSlideOver
      v-if="isSlideOverOpen"
      position="bottom"
      @close="isSlideOverOpen = false"
    >
      <div class="tw-py-8 tw-px-5">
        <div
          v-for="(option) in computedOptions"
          :key="option.value"
          class="tw-py-3 tw-px-4 tw-cursor-pointer"
          @click="onOptionClick(option.value)"
        >
          <span>
            {{ option.text }}
          </span>
        </div>
      </div>
    </UiSlideOver>
  </div>
</template>

<script>
import UiSlideOver from '@/components/ui/UiSlideOver.vue';
import UiIcon from '@/components/ui/UiIcon.vue';

export default {
  components: {
    UiIcon,
    UiSlideOver,
  },
  props: {
    disabled: Boolean,
    label: String,
    options: [Array, Object],
    placeholder: String,
    value: [Number, String],
  },
  data() {
    return {
      isSlideOverOpen: false,
    };
  },
  computed: {
    computedOptions() {
      if (!(this.options instanceof Array)) {
        return Object.keys(this.options)
          .map((key) => ({
            value: key,
            text: this.options[key],
          }));
      }

      return this.options;
    },
    optionText() {
      return this.computedOptions.find((option) => option.value === this.value)?.text;
    },
    optionVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  methods: {
    onOptionClick(key) {
      this.optionVal = key;
      this.isSlideOverOpen = false;
    },
    onOpen() {
      if (!this.disabled) {
        this.isSlideOverOpen = true;
      }
    },
  },
};
</script>
