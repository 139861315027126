<template>
  <div>
    <UiLandingHeading
      class="tw-mb-10"
      :title="$t('general.sign-in-title')"
    />

    <form
      @submit.prevent="onEmailLogin"
    >
      <div class="tw-space-y-5">
        <UiFormInput
          v-model="email"
          :errors="errors"
          icon="message"
          name="email"
          :placeholder="$t('general.email')"
          type="email"
        />

        <div>
          <UiFormInput
            v-model="password"
            :errors="errors"
            icon="lock"
            name="password"
            :placeholder="$t('general.password')"
            type="password"
          />
          <UiFormErrors
            v-if="errors._ && errors._.length > 0"
            :errors="errors._"
          />
        </div>

        <div class="tw-flex tw-justify-end">
          <UiLink
            to="/forgot"
          >
            {{ $t('general.forgot-password') }}
          </UiLink>
        </div>

        <div class="tw-flex tw-space-x-3 px-2">
          <UiCheckbox
            :checked="rememberMe"
            @click.native.stop
            @change="rememberMe = $event"
          />
          <p class="tw-text-sm">
            {{ $t('general.remember-me') }}
          </p>
        </div>
      </div>

      <div class="tw-mt-6">
        <UiButton
          class="tw-w-full"
          variant="primary"
          type="submit"
        >
          {{ $t('general.sign-in') }}
        </UiButton>

        <div class="tw-hidden">
          <div class="tw-flex tw-items-center tw-my-6">
            <span class="tw-basis-0 tw-grow tw-h-px tw-bg-gray-200" />
            <span class="tw-basis-0 tw-grow tw-px-4 tw-whitespace-nowrap tw-text-gray-700 tw-font-semibold">
            {{ $t('general.or-continue-with') }}
          </span>
            <span class="tw-basis-0 tw-grow tw-h-px tw-bg-gray-200" />
          </div>

          <UiGoogleButton
              @login="onGoogleLogin"
              class="tw-hidden"
          />
        </div>
      </div>
    </form>

    <div class="tw-mt-24 tw-text-center">
      <span class="tw-text-gray-500">
        {{ $t('general.dont-have-an-account') }}
      </span>
      <UiLink
        class="tw-ml-4"
        to="/signup"
      >
        {{ $t('general.signup') }}
      </UiLink>
    </div>

    <div class="tw-my-10 tw-text-xs tw-text-center">
      <UiLink
        class="tw-ml-4"
        target="_blank"
        :href="$laravelUrl('privacy-policy')"
      >
        {{ $t('general.privacy-policy') }}
      </UiLink>
      <UiLink
        class="tw-ml-4"
        target="_blank"
        :href="$laravelUrl('cookie')"
      >
        {{ $t('general.cookie') }}
      </UiLink>
      <UiLink
        class="tw-ml-4"
        target="_blank"
        :href="$laravelUrl('terms-of-use')"
      >
        {{ $t('general.terms-of-use') }}
      </UiLink>
    </div>
  </div>
</template>

<script>
import User from '@/components/models/User';

import UiButton from '@/components/ui/UiButton.vue';
import UiFormInput from '@/components/ui/UiFormInput.vue';
import UiGoogleButton from '@/components/ui/UiGoogleButton.vue';
import UiLink from '@/components/ui/UiLink.vue';
import UiLandingHeading from '@/components/ui/UiLandingHeading.vue';
import UiFormErrors from '@/components/ui/UiFormErrors.vue';
import UiCheckbox from "@/components/ui/UiCheckbox.vue";

export default {
  components: {
    UiCheckbox,
    UiFormErrors,
    UiLandingHeading,
    UiGoogleButton,
    UiButton,
    UiLink,
    UiFormInput,
  },
  data() {
    return {
      rememberMe: false,
      email: '',
      password: '',
      errors: {},
    };
  },
  methods: {
    onEmailLogin() {
      this.login(User.CHANNEL_EMAIL);
    },
    onGoogleLogin(googleUser) {
      this.login(
        User.CHANNEL_GOOGLE,
        googleUser.getAuthResponse().access_token,
      );
    },
    login(type, token) {
      this.errors = {};
      let fields = {
        channel_type: type,
      };
      if (type === User.CHANNEL_EMAIL) {
        fields.email = this.email;
        fields.password = this.password;
        fields.remember_me = this.rememberMe;
      } else {
        fields.token = token;
      }
      this.$post(
        '/auth/login',
        fields,
        (data) => {
          window.scrollTo(0, 0);
          this.$saveToken(data.token);
          this.$saveUser(data.user);

          this.$redirector().redirect();
        },
        (errors, errorResponse) => {
          if (errorResponse.response.status === 429) {
            this.$toast({
              title: this.$t('general.error'),
              description: this.$t('general.too-many-requests'),
              variant: 'error',
            })
          }

          this.errors = errors;
        },
      );
    },
  },
};
</script>
