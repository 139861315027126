<template>
  <div class="tw-relative tw-w-full tw-bg-primary-500 tw-rounded-2xl">
    <div
      class="tw-absolute tw-inset-0 tw-rounded-2xl tw-overflow-hidden"
    >
      <UiImage
        v-if="user.cover_url"
        fill
        :sizes="sizes"
        :src="user.cover_url"
        :srcset="user.cover_variants"
      />
    </div>

    <div class="tw-isolate tw-flex tw-justify-end tw-p-4">
      <UiDropdown>
        <UiDropdownItem
          icon="paper"
          @click="copyLink"
        >
          {{ $t('general.copy-link-to-profile') }}
        </UiDropdownItem>
        <UiDropdownItem
          icon="edit-square"
          @click.prevent="addToList(user)"
        >
          {{ $t('general.add-to-list') }}
        </UiDropdownItem>
        <UiDropdownItem
          v-if="removable"
          icon="delete"
          @click="$emit('remove', user)"
        >
          {{ $t('general.remove-from-list') }}
        </UiDropdownItem>
      </UiDropdown>
    </div>

    <div class="tw-isolate tw-px-4 tw-py-3 tw-bg-[#202020]/30 tw-rounded-b-2xl">
      <div class="tw-flex tw-items-start">
        <div>
          <UiAvatar
            size="xs"
            :src="user.avatar_url"
            :text="user.initials"
            :to="user.url"
          />
        </div>

        <div class="tw-ml-2 tw-min-w-0 tw-text-white">
          <UiUsername
            :user="user"
          />

          <RouterLink
            class="tw-block tw-text-sm tw-truncate"
            :to="user.url"
            :title="'@' + user.username"
          >
            {{ '@' + user.username }}
          </RouterLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import User from '@/components/models/User';

import UiAvatar from '@/components/ui/UiAvatar.vue';
import UiUsername from '@/components/ui/UiUsername.vue';
import UiDropdown from '@/components/ui/UiDropdown.vue';
import UiDropdownItem from '@/components/ui/UiDropdownItem.vue';
import UiImage from '@/components/ui/UiImage.vue';

export default {
  components: {
    UiImage,
    UiDropdownItem,
    UiDropdown,
    UiAvatar,
    UiUsername,
  },
  props: {
    user: User,
    removable: Boolean,
    sizes: [String, Object],
  },
  methods: {
    copyLink() {
      const link = `${process.env.VUE_APP_APP_URL}${this.user.url}`;
      this.$copyText(link);
      this.$toast({
        title: this.$t('general.link'),
        description: this.$t('general.link-copied'),
      });
    },
    addToList(user) {
      this.$store.state.addToListUser = user;
    },
  },
};
</script>
