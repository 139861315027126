<template>
  <div
    v-if="errors && errors.length > 0"
    class="tw-mt-2 tw-text-sm tw-text-error md:tw-text-base"
  >
    <div
      v-for="error in errors"
      :key="error"
    >
      {{ error }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    errors: Array,
  },
};
</script>
