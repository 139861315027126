<template>
  <div class="tw-fixed tw-right-4 tw-bottom-4 tw-z-toast tw-w-full tw-max-w-sm">
    <TransitionGroup
      class="tw-flex tw-flex-col tw-items-end tw-gap-y-2"
      enter-active-class="tw-ease-out tw-duration-200"
      enter-class="tw-opacity-0 -tw-mb-20"
      enter-to-class="tw-opacity-100 tw-mb-0"
      leave-active-class="tw-ease-in tw-duration-200"
      leave-class="tw-opacity-100 tw-mb-0"
      leave-to-class="tw-opacity-0 -tw-mb-20"
      tag="div"
    >
      <UiToast
        v-for="(toast, index) in toasts"
        :key="toast._id"
        class="tw-transition-all tw-duration-100"
        :dismissible="toast.dismissible ?? true"
        :title="toast.title"
        :description="toast.description"
        :variant="toast.variant"
        @close="onClose(index)"
      />
    </TransitionGroup>
  </div>
</template>

<script>
import UiToast from '@/components/ui/UiToast.vue';

export default {
  components: {
    UiToast,
  },
  computed: {
    toasts() {
      return this.$store.state.toasts;
    },
  },
  methods: {
    onClose(index) {
      this.$store.state.toasts.splice(index, 1)
    },
  },
};
</script>
