<template>
  <UiCard>
    <UiNavigationBar
      class="md:tw-hidden"
      bleed
      :title="$t('general.subscriptions')"
      @back="$router.go(-1)"
    />

    <div class="tw-flex tw-items-center tw-space-x-3">
      <UiPill
        :checked="listStatus"
        :value="types.active"
        variant="primary"
        @change="updateList(types.active)"
      >
        {{ $t('general.active') }}
      </UiPill>
      <UiPill
        :checked="listStatus"
        :value="types.expired"
        variant="primary"
        @change="updateList(types.expired)"
      >
        {{ $t('general.expired') }}
      </UiPill>
    </div>

    <div class="tw-mt-6">
      <div class="tw-grid tw-gap-6 md:tw-grid-cols-2 xl:tw-grid-cols-3">
        <div
          v-for="(item, index) in subs"
          :key="index"
        >
          <UiSubscription
            class="tw-h-full"
            :user="item.sub"
            :subscription="item"
            @unsubscribe="unsubscribe"
            @subscribe="subscribe"
          />
        </div>
      </div>
    </div>
  </UiCard>
</template>

<script>
import Subscription from '@/components/models/Subscription';

import UiCard from '@/components/ui/UiCard.vue';
import UiSubscription from '@/components/ui/UiSubscription.vue';
import UiNavigationBar from '@/components/ui/UiNavigationBar.vue';
import UiPill from '@/components/ui/UiPill.vue';

const TYPE_ACTIVE = 'active';
const TYPE_EXPIRED = 'expired';

export default {
  components: {
    UiPill,
    UiNavigationBar,
    UiCard,
    UiSubscription,
  },
  data: function () {
    return {
      subs: [],
      page: 1,
      hasMore: false,
      isLoading: false,
      listStatus: TYPE_ACTIVE,
    };
  },
  computed: {
    types() {
      return {
        active: TYPE_ACTIVE,
        expired: TYPE_EXPIRED,
      };
    },
  },
  mounted() {
    this.loadList();
    window.addEventListener('scroll', this.updateScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.updateScroll);
  },
  methods: {
    reset() {
      this.isLoading = false;
      this.hasMore = false;
      this.page = 1;
      this.subs = [];
    },
    updateScroll() {
      const scrollPosition = window.innerHeight + window.scrollY;
      if (
        document.body.offsetHeight &&
        scrollPosition &&
        document.body.offsetHeight - scrollPosition <= 1000 &&
        !this.isLoading &&
        this.hasMore
      ) {
        this.loadMore();
      }
    },
    updateList(status) {
      this.listStatus = status;
      this.reset();
      this.loadList();
    },
    loadList() {
      this.isLoading = true;
      let subs = [...this.subs];
      if (this.page === 1) {
        this.$showSpinner();
      }
      this.$get(
        '/subscriptions' +
        '?page=' + this.page +
        '&status=' + this.listStatus,
        (data) => {
          for (let s of data.subs.data) {
            subs.push(new Subscription(s));
          }
          this.subs = subs;
          this.hasMore = data.subs.next_page_url != null;
          this.isLoading = false;
        },
        (errors) => {
          console.log(errors);
        },
      );
    },
    loadMore() {
      if (this.hasMore) {
        this.page = this.page + 1;
        this.loadList();
      }
    },
    unsubscribe(subscription) {
      this.$post(
        '/subscriptions/' + subscription.id,
        { _method: 'DELETE' },

        () => {
          subscription.cancel();
        },
        (errors) => {
          console.log(errors);
        },
      );
    },
    subscribe(subscription) {
      this.$post(
        '/subscribe/' + subscription.sub.id,
        {},

        (data) => {
          let subs = [...this.subs];
          let sub = new Subscription(data);
          for (let i in subs) {
            if (subs[i].id == subscription.id) {
              if (sub) {
                subs[i] = sub;
                break;
              }
            }
          }
          this.subs = subs;
        },
        (errors) => {
          console.log(errors);
        },
      );
    },
  },
};
</script>
