<template>
  <LayoutError
    v-if="isApi503 || $isEnabled('maintenance-mode')"
    :errorPage="503"
  />
  <RouterView v-else-if="isVerifyPage && isLoggedIn" />
  <LayoutAuthorized v-else-if="isLoggedIn" />
  <LayoutGuest v-else-if="isGuestPage" />
  <LayoutLanding v-else />
</template>

<script>
import LayoutAuthorized from '@/components/layout/user/LayoutAuthorized.vue';
import LayoutError from '@/components/layout/user/LayoutError.vue';
import LayoutGuest from '@/components/layout/user/LayoutGuest.vue';
import LayoutLanding from '@/components/layout/user/LayoutLanding.vue';

export default {
  components: {
    LayoutAuthorized,
    LayoutError,
    LayoutGuest,
    LayoutLanding,
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.token !== null;
    },
    isGuestPage() {
      return this.$route.name === 'profile'
        || this.$route.path.startsWith('/contest')
        || this.$route.path.startsWith('/impersonate');
    },
    isVerifyPage() {
      return this.$route.name === 'verify';
    },
    isApi503() {
      return this.$store.state.apiResponseCode === 503;
    },
    isPublicRoute() {
      const routes = [
        '/signup',
        '/password-reset',
        '/forgot'
      ];

      return routes.filter(route => route.startsWith(this.$route.path)).length > 0;
    }
  },
  mounted() {
    if (this.isLoggedIn) {
      this.$get(
        '/auth/me',
        (data) => {
          this.$saveUser(data);
          this.$root.$i18n.locale = data.locale;
        },
        () => {
        },
      );
    } else {
      if (!this.isGuestPage && !this.isPublicRoute) {
        this.$redirector().push('/');
      }
    }
  },
};
</script>
