<template>
  <div v-if="contest">
    <UiCard>
      <UiNavigationBar
        bleed
        :title="contest.name"
        @back="$router.go(-1)"
      >
        <span
          v-if="mayParticipate"
          class="tw-hidden sm:tw-inline"
        >
          <UiButton
            variant="primary"
            @click="onParticipate"
          >
            {{ $t('general.participate') }}
          </UiButton>
        </span>
      </UiNavigationBar>

      <div
        v-if="mayParticipate"
        class="tw-mt-2 tw-mb-6 sm:tw-hidden"
      >
        <UiButton
          class="tw-w-full"
          variant="primary"
          @click="onParticipate"
        >
          {{ $t('general.participate') }}
        </UiButton>
      </div>

      <div>
        <div class="tw-flex tw-flex-col tw-items-center">
          <div
            v-if="contest.isClosed"
            class="tw-text-center"
          >
            {{ $t('general.contest-closed') }}
          </div>

          <!-- Countdown -->
          <div
            v-else
            class="tw-flex tw-items-center tw-space-x-4"
          >
            <div
              v-for="(item, index) in countdown"
              :key="index"
              class="tw-flex tw-items-end"
            >
              <div class="tw-text-center">
                <div class="tw-font-semibold tw-text-gray-500">
                  {{ item.label }}
                </div>
                <div class="tw-font-bold tw-text-3xl tw-text-primary-500">
                  {{ item.value }}
                </div>
              </div>
              <div
                v-if="index < countdown.length - 1"
                class="tw-ml-4 tw-font-bold tw-text-3xl tw-text-primary-500"
              >
                :
              </div>
            </div>
          </div>

          <!-- Prizes -->
          <div class="tw-mt-6">
            <div class="tw-flex tw-items-start tw-space-x-4 md:tw-space-x-10">
              <div
                v-for="(prize) in prizes"
                :key="prize.label"
                class="tw-flex tw-flex-col tw-items-center"
              >
                <img
                  alt=""
                  class="tw-w-16 md:tw-w-20"
                  :src="prize.image"
                >
                <div class="tw-mt-4 tw-text-center">
                  <div class="tw-font-semibold tw-text-xl">
                    <span class="sm:tw-hidden">{{ $t(prize.short_label) }}</span>
                    <span class="tw-hidden sm:tw-inline">{{ $t(prize.label) }}</span>
                  </div>
                  <div class="tw-mt-1 tw-text-gray-800 md:tw-mt-2">
                    {{ $t('general.x-bonus-prize', [formatPrize(prize.value)]) }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Entries -->
          <div class="tw-mt-2 tw-w-full md:tw-mt-3">
            <div class="tw-z-bar tw-sticky tw-top-0 tw-pb-3 tw-bg-white md:tw-top-[--top] md:tw-pb-4">
              <div class="tw-max-w-xl tw-mx-auto tw-flex">
                <UiTabItem
                  v-for="sort in sorts"
                  :key="sort.value"
                  :active="sort.value === sortValue"
                  @click="updateEntries(sort.value)"
                >
                  {{ $t(sort.label) }}
                </UiTabItem>
                <UiTabItem
                  v-if="isLoggedIn"
                  :active="isTabMeActive"
                  @click="updateEntries(null)"
                >
                  {{ $t('general.me') }}
                </UiTabItem>
              </div>
            </div>

            <div class="tw-mt-1 md:tw-mt-2">
              <div
                v-if="isLoading || entries.length > 0"
                class="tw-grid tw-gap-5 md:tw-grid-cols-3 lg:tw-grid-cols-2 xl:tw-grid-cols-3"
              >
                <div
                  v-for="(entry, key) in entries"
                  :key="entry.id"
                >
                  <UiContestEntry
                    v-model="entries[key]"
                    :badge="getBadge(entry, key)"
                    :votable="!contest.isClosed"
                    @share="onShare"
                    @vote="onVote"
                  />
                </div>

                <template v-if="isLoading">
                  <div
                    v-for="i in skeletons"
                    :key="i"
                  >
                    <div class="tw-relative tw-pb-[100%]">
                      <UiSkeleton
                        class="tw-absolute tw-inset-0 tw-w-full tw-h-full"
                      />
                    </div>
                    <UiSkeleton class="tw-mt-3 tw-h-5 tw-w-40 tw-max-w-full" />
                    <UiSkeleton class="tw-mt-3 tw-h-4 tw-w-20 tw-max-w-full" />
                  </div>
                </template>
              </div>

              <div
                v-else-if="isTabMeActive && me"
                class="tw-flex tw-justify-center"
              >
                <div class="tw-w-full md:tw-w-1/3 lg:tw-w-1/2 xl:tw-w-1/3">
                  <UiContestEntry
                    v-model="me"
                    @share="onShare"
                  />
                </div>
              </div>

              <div
                v-else-if="!isLoading"
                class="tw-text-center"
              >
                {{ $t('general.no-entries') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </UiCard>

    <UiNewContestEntry
      ref="newEntry"
      v-if="isNewContestEntryOpen"
      @cancel="isNewContestEntryOpen = false"
      @close="isNewContestEntryOpen = false"
      @submit="postEntry"
    />
    <UiShareContestEntryModal
      v-if="shareEntryUsername !== null"
      v-model="shareEntry"
      :contest="contest"
      :username="shareEntryUsername"
      @close="shareEntryUsername = null"
      @vote="onVote"
    />

    <UiModal
        v-if="isVerifyProfileModalOpen"
        :confirm="$t('general.verify-identity')"
        size="lg"
        @cancel="isVerifyProfileModalOpen = false"
        @confirm="goToVerifyPage"
    >
      <div class="tw-flex tw-flex-col tw-items-center tw-text-center">
        <div class="tw-font-semibold tw-mt-6 tw-text-xl md:tw-font-bold md:tw-text-4xl">
          {{ $t('general.verify-identity') }}
        </div>
        <div class="tw-mt-4 md:tw-text-md">
          {{ $t('general.verification-contest') }}
        </div>

        <div class="tw-mt-4 md:tw-text-md">
          {{ $t('general.verification-contest-2') }}
        </div>

        <div class="tw-mt-4 md:tw-text-md">
          {{ $t('general.verification-contest-3') }}
        </div>

        <img
            alt=""
            class="tw-w-50 tw-mt-10 tw-mb-4"
            :src="require('@/assets/illustrations/verify.svg')"
        />
      </div>
    </UiModal>

  </div>
</template>

<script>
import dayjs from 'dayjs';
import Contest from '@/components/models/Contest';
import ContestEntry from '@/components/models/ContestEntry';

import UiNavigationBar from '@/components/ui/UiNavigationBar.vue';
import UiCard from '@/components/ui/UiCard.vue';
import UiButton from '@/components/ui/UiButton.vue';
import UiPosts from '@/components/ui/UiPosts.vue';
import UiTabItem from '@/components/ui/UiTabItem.vue';
import UiModal from '@/components/ui/UiModal.vue';
import UiIcon from '@/components/ui/UiIcon.vue';
import UiLockedMedia from '@/components/ui/UiLockedMedia.vue';
import UiContestEntry from '@/components/ui/UiContestEntry.vue';
import UiNewContestEntry from '@/components/ui/UiNewContestEntry.vue';
import UiShareContestEntryModal from '@/components/ui/UiShareContestEntryModal.vue';
import UiSkeleton from '@/components/ui/UiSkeleton.vue';

const SORT_MOST_VOTED = 'most-voted';
const SORT_RECENT = 'recent';
const SORT_TRENDING = 'trending';

export default {
  components: {
    UiSkeleton,
    UiShareContestEntryModal,
    UiNewContestEntry,
    UiContestEntry,
    UiLockedMedia,
    UiIcon,
    UiModal,
    UiTabItem,
    UiPosts,
    UiButton,
    UiCard,
    UiNavigationBar,
  },
  data: function () {
    return {
      contest: null,
      entries: [],
      me: null,
      page: 1,
      hasMore: false,
      isNotFound: null,
      isLoading: false,
      isLoaded: false,
      sortValue: SORT_MOST_VOTED,
      isTabMeActive: false,
      isNewContestEntryOpen: false,
      isVerifyProfileModalOpen: false,
      shareEntry: null,
      skeletons: 6,
      prizes: [
        {
          image: require('@/assets/illustrations/medal-1.svg'),
          label: 'general.place-1-winner',
          short_label: 'general.place-1',
          value: '3000',
        },
        {
          image: require('@/assets/illustrations/medal-2.svg'),
          label: 'general.place-2-winner',
          short_label: 'general.place-2',
          value: '2000',
        },
        {
          image: require('@/assets/illustrations/medal-3.svg'),
          label: 'general.place-3-winner',
          short_label: 'general.place-3',
          value: '1000',
        },
      ],
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.token !== null;
    },
    mayParticipate() {
      return !this.contest.isClosed && this.me === null
    },
    currentUser() {
      return this.$store.state.currentUser;
    },
    shareEntryUsername: {
      get() {
        return this.$route.params.username ?? null;
      },
      set(value) {
        if (value !== null) {
          this.$router.push({
            name: 'contest-entry',
            params: {
              slug: this.slug,
              username: value,
            },
          });
        } else {
          this.$router.push({
            name: 'contest',
            params: {
              slug: this.contest.slug,
            },
          });
          this.shareEntry = null;
        }
      },
    },
    countdown() {
      const diff = this.contest.closed_at.diff(dayjs(), 'second');
      const pad = (n) => n.toString().padStart(2, '0');

      return [
        { label: 'Days', value: Math.floor(diff / (3600 * 24)) },
        { label: 'Hours', value: pad(Math.floor(diff % (3600 * 24) / 3600)) },
        { label: 'Minutes', value: pad(Math.floor(diff % 3600 / 60)) },
      ];
    },
    sorts() {
      return [
        { label: 'general.most-voted', value: SORT_MOST_VOTED },
        { label: 'general.recent', value: SORT_RECENT },
        { label: 'general.trending', value: SORT_TRENDING },
      ];
    },
    slug() {
      return this.$route.params.slug;
    },
  },
  created() {
    this.loadContest();
    this.loadEntries();
    if (this.isLoggedIn) {
      this.loadMe();
    }
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.updateScroll);
  },
  methods: {
    reset() {
      this.isLoading = false;
      this.hasMore = false;
      this.page = 1;
      this.entries = [];
    },
    formatPrize(value) {
      return process.env.VUE_APP_CURRENCY_SIGN + this.$n(value);
    },
    updateScroll() {
      const scrollPosition = window.innerHeight + window.scrollY;
      if (
        document.body.offsetHeight &&
        scrollPosition &&
        document.body.offsetHeight - scrollPosition <= 1000 &&
        !this.isLoading &&
        this.hasMore
      ) {
        this.loadMore();
      }
    },
    loadContest() {
      this.isLoading = true;
      this.$get(
        '/contest/' + this.slug,
        (data) => {
          this.contest = new Contest(data.data);
        },
        (errors) => {
          this.isNotFound = true;
          console.log(errors);
        },
      );
    },
    loadMe() {
      this.isLoading = true;
      this.$get(
        '/contest/' + this.slug + '/' + this.currentUser.username,
        (data) => {
          this.me = new ContestEntry(data.data);
          this.isLoading = false;
          this.isLoaded = true;
          if (this.me.is_creator === false) {
            this.isVerifyProfileModalOpen = true;
          }
        },
        () => {
          this.me = null;
          this.isLoading = false;
          this.isLoaded = true;
        },
      );
    },
    loadEntries() {
      this.isLoading = true;
      this.$get(
        '/contest/'
        + this.slug
        + '/entries'
        + (this.sortValue ? '/' + this.sortValue : '')
        + '?page=' + this.page,
        (data) => {
          let entries = [...this.entries];
          for (let obj of data.data) {
            entries.push(new ContestEntry(obj));
          }
          this.entries = entries;
          this.hasMore = data.next_page_url !== null;
          this.isLoading = false;
        },
        (errors) => {
          console.log(errors);
          this.isLoading = false;
        },
      );
    },
    loadMore() {
      if (this.hasMore) {
        this.page = this.page + 1;
        this.loadEntries();
      }
    },
    updateEntries(value) {
      this.sortValue = value;
      this.reset();

      if (value === null) {
        this.isTabMeActive = true;
        this.loadMe();
      } else {
        this.isTabMeActive = false;
        this.loadEntries();
      }
    },
    getBadge(entry, key) {
      if (this.sortValue !== SORT_MOST_VOTED) return null;

      if (entry.votes_count > 0 && key < 3) {
        return this.$t('general.place-' + (key + 1));
      }
    },
    goToVerifyPage() {
      this.$router.push('/verify');
    },
    postEntry(data) {
      this.$refs.newEntry.clearError();
      this.$post(
        '/contest/' + this.contest.slug,
        data,
        () => {
          this.isNewContestEntryOpen = false;
          this.reset();
          this.loadEntries();
          this.$bvToast.toast(this.$t('general.entry-posted'), {
            autoHideDelay: 2000,
            title: this.$t('general.success'),
            solid: true,
            toaster: 'b-toaster-bottom-left',
          });
          if (this.currentUser.is_creator === false) {
            this.isVerifyProfileModalOpen = true;
          }
        },
        (errors) => {
          if (errors.image) {
            this.$refs.newEntry.setContentCheckFailed();
          }
        },
      );
    },
    onParticipate() {
      if (this.isLoggedIn) {
        this.isNewContestEntryOpen = true;
      } else {
        this.$redirector().push('/signup');
      }
    },
    onVote(entry) {
      if (this.isLoggedIn) {
        const hasVoted = entry.has_voted;
        const votesCount = entry.votes_count;

        if (entry.has_voted) {
          entry.votes_count--;
        } else {
          entry.votes_count++;
        }
        entry.has_voted = !entry.has_voted;

        this.$post(
          '/contest/' + this.contest.slug + '/' + entry.username,
          null,
          () => {},
          (errors) => {
            // Rollback
            entry.has_voted = hasVoted;
            entry.votes_count = votesCount;

            console.log(errors);
          },
        );
      } else {
        this.$redirector().push('/signup');
      }
    },
    onShare(entry) {
      this.shareEntry = entry;
      this.shareEntryUsername = entry.username;
    },
  },
};
</script>
