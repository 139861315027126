<template>
  <div>
    <div
      v-if="method?.flagged"
      class="tw-text-center tw-py-4 tw-lg:px-4"
    >
      <div class="tw-p-5 tw-bg-primary-100 tw-border-t tw-rounded-2xl tw-items-center tw-leading-none tw-lg:rounded-full tw-flex tw-lg:inline-flex" role="alert">
        <span
          class="tw-font-semibold tw-text-gray-700 tw-mr-2 tw-text-center tw-flex-auto"
          v-html="$t('general.payment-processing.payment-method-flagged')"
        />
      </div>
    </div>

    <div class="tw-flex tw-space-x-4 tw-overflow-x-auto">
      <div
        v-if="method !== null"
        class="tw-shrink-0 tw-w-64 md:tw-w-80"
      >
        <div class="tw-relative tw-w-full tw-pb-[56%] tw-bg-gradient-to-br tw-from-primary-400 tw-to-primary-500 tw-rounded-2xl">
          <div class="tw-absolute tw-inset-0 tw-p-6">
            <div class="tw-text-white">
              <div class="tw-text-lg">
                {{ $t('general.card') }}
              </div>

              <div class="tw-mt-4 tw-text-lg tw-font-semibold">
                {{ method.title }}
              </div>
            </div>

            <div class="tw-absolute tw-top-4 tw-right-4">
              <UiDropdown>
                <UiDropdownItem
                  icon="delete"
                  @click.prevent="del(method)"
                >
                  {{ $t('general.delete') }}
                </UiDropdownItem>
              </UiDropdown>
            </div>
          </div>
        </div>
      </div>

      <!--      <div-->
      <!--        class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-py-8 tw-px-12  tw-text-primary-500 tw-bg-primary-100 tw-rounded-2xl tw-cursor-pointer md:tw-py-12"-->
      <!--        @click.prevent="add"-->
      <!--      >-->
      <!--        <div class="tw-flex tw-items-center tw-justify-center tw-bg-primary-500 tw-text-white tw-rounded-full">-->
      <!--          <div>-->
      <!--            <svg class="tw-h-10 tw-w-10" width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">-->
      <!--              <path d="M12.0368 8.46265V15.6111" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />-->
      <!--              <path d="M15.6146 12.0368H8.4588" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />-->
      <!--            </svg>-->
      <!--          </div>-->
      <!--        </div>-->

      <!--        <div-->
      <!--          class="tw-mt-4 tw-whitespace-nowrap"-->
      <!--        >-->
      <!--          <template v-if="method !== null">-->
      <!--            {{ $t('general.update-payment-method') }}-->
      <!--          </template>-->
      <!--          <template v-else>-->
      <!--            {{ $t('general.add-payment-method') }}-->
      <!--          </template>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>

    <UiModal
      v-if="adding"
      :title="$t('general.add-card')"
      :cancel="$t('general.cancel')"
      :confirm="$t('general.add-card')"
      size="sm"
      @cancel="unadd"
      @confirm="save"
    >
      <b-form-invalid-feedback
        v-if="errors['_'] && errors['_'].length > 0"
        class="d-block"
      >
        <div
          class="alert alert-danger"
          v-for="error in errors['_']"
          :key="error"
        >
          {{ error }}
        </div>
      </b-form-invalid-feedback>

      <ui-credit-card
        ref="cc"
        :cc="cc"
        :amount="1"
        @error="error"
        @token="token"
      />

      <div class="tw-text-sm" v-html="$t('general.payment-processing.payment-method-update')" />
    </UiModal>
  </div>
</template>
<script>
import PaymentMethod from '@/components/models/PaymentMethod';

import UiCreditCard from '@/components/ui/UiCreditCard.vue';
import UiDropdown from '@/components/ui/UiDropdown.vue';
import UiDropdownItem from '@/components/ui/UiDropdownItem.vue';
import UiModal from '@/components/ui/UiModal.vue';

export default {
  components: {
    UiCreditCard,
    UiDropdown,
    UiDropdownItem,
    UiModal,
  },
  data() {
    return {
      method: null,
      errors: {},
      adding: false,
      cc: null,
    };
  },
  computed: {
    attach() {
      return this.$route.params.attach;
    },
  },
  mounted() {
    this.loadMethods();
    if (this.attach) {
      this.doAttach();
    }
  },
  methods: {
    add() {
      this.adding = true;
    },
    unadd() {
      this.adding = false;
      this.reset();
    },
    reset() {
      this.card_number = '';
      this.card_holder = '';
      this.expiration_month = '';
      this.expiration_year = '';
      this.cvc = '';
    },
    loadMethods() {
      this.$get(
        '/payments/method',
        (data) => {
          this.method = new PaymentMethod(data.method);
          this.cc = data.cc;
        },
        () => {
        },
      );
    },
    doAttach() {
      this.errors = {};
      this.$showSpinner();
      this.$post(
        '/payments/method',
        this.$route.query,
        (data) => {
          this.method = new PaymentMethod(data.method);
          this.unadd();
          this.$bvToast.toast(this.$t('general.payment-processing.payment-method-update-success'), {
            autoHideDelay: 5000,
            title: this.$t('general.success'),
            solid: true,
            toaster: 'b-toaster-bottom-center',
            variant: 'success',
          });
        },
        (errors) => {
          this.errors = errors;
        },
      );
    },
    token(title, token) {
      this.errors = {};
      this.$showSpinner();
      this.$post(
        '/payments/method',
        {
          token: token,
          title: title,
        },
        (data) => {
          this.method = new PaymentMethod(data);
          this.unadd();
          this.$bvToast.toast(this.$t('general.payment-processing.payment-method-update-success'), {
            autoHideDelay: 5000,
            title: this.$t('general.success'),
            solid: true,
            toaster: 'b-toaster-bottom-center',
            variant: 'success',
          });
        },
        (errors) => {
          this.errors = errors;
        },
      );
    },
    error(error) {
      this.errors = {};
      if (error) {
        this.errors = { _: [error] };
      }
    },
    save() {
      this.$refs.cc.save();
    },
    del(method) {
      this.$post(
        '/payments/method/' + method.id,
        {
          _method: 'DELETE',
        },
        (data) => {
          if (data.errorCode === 'attached_subscription') {
            this.$toast({
              title: this.$t('general.error'),
              description: this.$t('general.payment-method-attached-error'),
              variant: 'error',
            });
          } else {
            this.method = null;
          }
        },
        () => {
        },
      );
    },
  },
};
</script>
