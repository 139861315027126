<template>
  <div>
    <div>
      <!-- Desktop header -->
      <div class="tw-hidden md:tw-block tw-z-header tw-fixed tw-top-0 tw-inset-x-0">
        <div class="tw-bg-gray-50">
          <div class="tw-max-w-8xl tw-mx-auto tw-h-header tw-px-8 tw-flex tw-items-center tw-gap-6">
            <div class="tw-w-2/6">
              <UiLogo
                class="tw-w-auto tw-h-8 lg:tw-h-8"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Page -->
      <div
        class="tw-max-w-8xl tw-mx-auto sm:tw-px-6 md:tw-px-8 md:tw-pb-10 tw-pb-20"
      >
        <!-- App menu -->
        <div
          class="tw-hidden lg:tw-block tw-fixed tw-z-header tw-inset-0 tw-top-header tw-left-[max(0px,calc(50%-45rem))] tw-right-auto tw-pl-8 tw-pb-10 tw-overflow-y-auto"
        >
          <div class="tw-w-side">
            <UiCard>
              <div>
                <div class="tw-mt-6 md:tw-mt-0 md:tw-space-y-2">
                  <UiNavItem
                    color="success"
                    icon="home"
                    to="/"
                  >
                    {{ $t('general.home') }}
                  </UiNavItem>

                  <UiNavItem
                    color="primary"
                    icon="profile"
                    to="/"
                  >
                    {{ $t('general.login') }}
                  </UiNavItem>
                </div>
              </div>
            </UiCard>
          </div>
        </div>

        <!-- Main content -->
        <div class="md:tw-pt-header lg:tw-ml-side lg:tw-pl-6">
          <div class="tw-mx-auto xl:tw-max-w-none tw-max-w-3xl">
            <RouterView
              name="guest"
            />

            <div
              v-if="spinner"
              class="
                tw-fixed tw-top-1/2 tw-inset-x-0
                tw-flex tw-justify-center tw-z-spinner
                lg:tw-left-[22rem]
              "
            >
              <UiSpinner />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Bottom Tab Bar -->
    <div
      class="tw-z-header tw-fixed tw-inset-x-0 tw-bottom-[-1px] tw-w-full tw-pt-4 tw-pb-6 tw-flex tw-items-center tw-justify-evenly tw-bg-white tw-rounded-t-2xl tw-border-t tw-border-gray-100 md:tw-hidden"
    >
      <UiIconItem
        :active="$route.path === '/'"
        icon="home"
        :label="$t('general.home')"
        to="/"
      />

      <UiIconItem
        :active="$route.path === '/'"
        icon="profile"
        :label="$t('general.login')"
        to="/"
      />
    </div>

  </div>
</template>

<style>
@media screen(md) {
  body {
    background-color: theme('colors.gray.50');
  }
}
</style>

<script>
import UiCard from '@/components/ui/UiCard.vue';
import UiIconItem from '@/components/ui/UiIconItem.vue';
import UiLogo from '@/components/ui/UiLogo.vue';
import UiAvatar from '@/components/ui/UiAvatar.vue';
import UiNavItem from '@/components/ui/UiNavItem.vue';
import UiSpinner from '@/components/ui/UiSpinner.vue';
import UiIcon from '@/components/ui/UiIcon.vue';

export default {
  components: {
    UiIcon,
    UiSpinner,
    UiCard,
    UiIconItem,
    UiLogo,
    UiAvatar,
    UiNavItem,
  },
  computed: {
    spinner() {
      return this.$store.state.spinner == true;
    },
  },
};
</script>
