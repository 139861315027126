<template>
  <GoogleLogin
    class="tw-w-full tw-flex tw-items-center tw-justify-center tw-py-3 tw-px-4 tw-font-semibold tw-border tw-border-gray-200 tw-rounded-full"
    :onFailure="onFailure"
    :onSuccess="onSuccess"
    :params="google.params"
    type="button"
  >
    <div class="tw-h-6 tw-w-6 tw-mr-2">
      <b-img :src="google.icon" />
    </div>
    {{ $t('general.continue-with-google') }}
  </GoogleLogin>
</template>

<script>
import GoogleLogin from 'vue-google-login';

export default {
  components: {
    GoogleLogin,
  },
  computed: {
    google() {
      return {
        params: {
          client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
          scope: 'profile email',
        },
        renderParams: {
          longtitle: true,
          height: 36,
          theme: 'dark',
        },
        icon: require('@/assets/google.svg'),
      };
    },
  },
  methods: {
    onSuccess(googleUser) {
      this.$emit('login', googleUser);
    },
    onFailure() {
      this.$toast({
        title: this.$t('general.error'),
        description: this.$t('general.login-failed'),
        variant: 'error',
      });
    },
  },
};
</script>
