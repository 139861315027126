import Vue from 'vue';
import VueRouter from 'vue-router';
import Vuex from 'vuex';
import App from './App.vue';
import { BootstrapVue } from 'bootstrap-vue';
import VueClipboard from 'vue-clipboard2';
import VueMask from 'v-mask';
import Misc from './plugins/Misc';
import LoadScript from 'vue-plugin-load-script';
import VueSocialSharing from 'vue-social-sharing';
import FloatingVue from 'floating-vue';

import 'floating-vue/dist/style.css';
import '@/assets/scss/_theming.scss';
import '@/assets/css/main.css';

import * as Sentry from "@sentry/vue";
import VueGtag from "vue-gtag";

import i18n from './i18n';

import routes from './components/helpers/Router';

import { defaultConfig } from 'tailwind-variants';

defaultConfig.twMergeConfig = {
  prefix: 'tw-',
};

/*eslint no-useless-escape: "off"*/
// let locale = window.location.pathname.replace(/^\/([^\/]+).*/i,'$1');

const router = new VueRouter({
  routes,
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  // base: (locale.trim().length && locale != "/") ? '/' + locale : undefined
});

if (process.env.VUE_APP_ENV === 'production') {
  console.log('Sentry loaded');
  Sentry.init({
    Vue,
    dsn: "https://a0db67fa2ead33a9f69713f16bf3573b@o4506246177161216.ingest.us.sentry.io/4507533375569921",
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });

  Vue.use(VueGtag, {
    config: { id: "G-0GJFW0NH8W" }
  });
  console.log('GA loaded');
}

Vue.use(Vuex);

import stores from './components/helpers/Store';
// import './registerServiceWorker';

const store = new Vuex.Store(stores);

Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(BootstrapVue);
Vue.use(VueClipboard);
Vue.use(Misc);
Vue.use(VueMask);
Vue.use(LoadScript);
Vue.use(VueSocialSharing);
Vue.use(FloatingVue);

new Vue({
  router,
  i18n,
  store,
  render: h => h(App),
}).$mount('#app');
