<template>
  <div>
    <UiAlertModal
      size="lg"
      v-if="username !== null"
      :title="$t('block-user.header', { username: username })"
      :message="$t('block-user.message')"
      :cancel="$t('general.cancel')"
      :confirm="$t('block-user.cta')"
      @cancel="$emit('cancel')"
      @confirm="onBlock"
    >
      <UiFormTextarea
        class="tw-mt-5"
        :errors="errors"
        v-model="reason"
        :placeholder="$t('block-user.placeholder')"
        rows="2"
        name="reason"
      />
    </UiAlertModal>
  </div>
</template>

<script>
import UiAlertModal from "@/components/ui/UiAlertModal.vue";
import UiFormTextarea from "@/components/ui/UiFormTextarea.vue";

export default {
  components: { UiAlertModal, UiFormTextarea },
  data() {
    return {
      reason: '',
      errors: {},
    }
  },
  props: {
    username: {
      type: String
    },
  },
  computed: {
  },
  methods: {
    onBlock() {
      if (this.reason === '') {
        this.errors = {
          reason: [this.$t('errors.required')]
        }

        return;
      }

      this.$post(
          `/users/${this.username}/block`,
          {
            reason: this.reason
          },
          () => {
            this.$emit('success')
            this.$toast({
              title: this.$t('general.success'),
              description: this.$t('block-user.success'),
              variant: 'success',
            })
          },
          (errors) => {
            console.log(errors);
          },
      );
    }
  },
};
</script>
