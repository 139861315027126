<template>
  <div class="tw-relative tw-h-1.5 tw-overflow-hidden tw-rounded-full tw-bg-white/20">
    <div
      class="tw-h-full tw-w-full tw-flex-1 tw-bg-white tw-transition-all"
      :style="{ transform: `translateX(-${progress}%)` }"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: Number,
  },
  computed: {
    progress() {
      if (this.value === 0) {
        return 100
      }

      return 100 - this.value * 100;
    },
  },
};
</script>