<template>
  <div :class="{ '-tw-m-4 md:-tw-m-6': embed }">
    <!--
    Use the `overflow-auto` class here to establish a new block formatting
    context so the nested negative margins don't collapse.
    See https://stackoverflow.com/a/34477345/10640792
    -->
    <div class="tw-flex tw-items-start tw-gap-6 tw-overflow-auto">
      <!-- Categories list -->
      <UiCard
        class="tw-shrink-0 tw-h-full tw-w-full xl:tw-w-2/5"
        :class="{ 'tw-hidden xl:tw-block': activeCategory !== null }"
      >
        <UiNavigationBar
          bleed
          :title="$t('general.media-vault')"
          responsive
          @back="$router.go(-1)"
        >
          <div class="tw-flex tw-items-center tw-space-x-5">
            <UiIconItem
              v-if="!embed"
              icon="plus"
              @click="activeModal = modals.createCategory"
            />
          </div>
        </UiNavigationBar>

        <UiSeparator
          class="tw-mb-4"
        />

        <div v-if="isLoadingCategories">
          <div class="tw-space-y-6">
            <div
              v-for="index in 3"
              :key="index"
              class="tw-space-y-3"
            >
              <UiSkeleton class="tw-h-4 tw-max-w-32" />
              <UiSkeleton class="tw-h-5 tw-max-w-48" />
            </div>
          </div>
        </div>

        <div v-else-if="categories.length > 0">
          <div class="tw-space-y-4">
            <div
              v-for="category in categories"
              :key="category.name"
              class="tw-flex tw-cursor-pointer"
              @click="loadCategory(category)"
            >
              <div
                class="tw-w-[2px] tw-rounded-full"
                :class="{ 'tw-bg-primary-500': activeCategory && category.name === activeCategory.name }"
              />

              <div class="tw-ml-2">
                <div :class="{ 'tw-font-bold': activeCategory && category.name === activeCategory.name }">
                  {{ category.name }}
                </div>

                <UiVaultCategoryStats
                  class="tw-mt-2"
                  :mediaCount="category.media_count"
                  :videoCount="category.video_count"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          v-else
          class="tw-mt-6 tw-text-center"
        >
          <img
            alt=""
            class="tw-inline-block tw-w-44"
            :src="require('@/assets/illustrations/not-found.svg')"
          >
          <div class="tw-mt-8">
            {{ $t('general.no-categories-yet') }}
          </div>
        </div>
      </UiCard>

      <UiSeparator
        v-if="embed"
        class="tw-hidden tw-self-stretch tw-h-auto tw-my-6 xl:tw-block"
        orientation="vertical"
      />

      <UiCard
        class="tw-grow"
        :class="{ 'hidden xl:block': !activeCategory }"
      >
        <UiNavigationBar
          class="xl:tw-hidden"
          bleed
          :title="activeCategory ? activeCategory.name : ''"
          @back="onBack"
        >
          <div class="tw-flex tw-items-center tw-space-x-3">
            <UiButton
              size="sm"
              variant="secondary"
              @click="toggleIsSelecting"
            >
              {{ isSelecting ? $t('general.cancel') : $t('general.select') }}
            </UiButton>

            <UiDropdown
              v-if="!embed"
            >
              <UiDropdownItem
                icon="edit"
                @click="openModalRenameCategory"
              >
                {{ $t('general.rename') }}
              </UiDropdownItem>
              <UiDropdownItem
                icon="delete"
                @click="activeModal = modals.deleteCategory"
              >
                {{ $t('general.delete') }}
              </UiDropdownItem>
            </UiDropdown>
          </div>
        </UiNavigationBar>

        <!-- Desktop header -->
        <div class="tw-hidden xl:tw-block">
          <div class="tw-flex tw-items-center">
            <div class="tw-flex tw-items-center">
              <button
                v-if="activeCategory"
                class="tw-mr-2"
                @click="onBack"
              >
                <UiIcon
                  class="tw-h-6 tw-w-6"
                  name="arrow-left"
                />
              </button>
              <div class="tw-text-lg">
                <span class="tw-font-semibold">
                  {{ $t('general.media-vault') }}
                </span>
                <span v-if="activeCategory">
                  {{ activeCategory.name }}
                </span>
              </div>
            </div>

            <div
              v-if="!embed && activeCategory"
              class="tw-ml-auto"
            >
              <div class="tw-flex tw-items-center tw-space-x-4">
                <button
                  @click="openModalRenameCategory"
                >
                  <UiIcon
                    class="tw-h-6 tw-w-6"
                    name="edit"
                  />
                </button>
                <button
                  @click="activeModal = modals.deleteCategory"
                >
                  <UiIcon
                    class="tw-h-6 tw-w-6"
                    name="delete"
                  />
                </button>
              </div>
            </div>
          </div>

          <UiSeparator
            class="tw-my-4"
          />

          <div class="tw-flex tw-items-center tw-space-x-3">
            <UiPill
              :checked="activeFilter"
              :value="null"
              variant="primary"
              @change="setActiveFilter"
            >
              {{ $t('general.all') }}
            </UiPill>
            <UiPill
              :checked="activeFilter"
              :value="types.image"
              variant="primary"
              @change="setActiveFilter"
            >
              {{ $t('general.photo') }}
            </UiPill>
            <UiPill
              :checked="activeFilter"
              :value="types.video"
              variant="primary"
              @change="setActiveFilter"
            >
              {{ $t('general.video') }}
            </UiPill>
          </div>

          <div
            v-if="!embed && selectedMedia.length > 0"
            class="tw-mt-4"
          >
            <UiBadge
              color="primary"
              icon="close"
              @click="selectedMedia = []"
            >
              {{ $t('general.x-selected', [selectedMedia.length, maxMedia]) }}
            </UiBadge>
          </div>
        </div>

        <div class="tw-mt-4">
          <div class="tw-overflow-hidden">
            <div class="tw-h-full tw-overflow-auto xl:tw-aspect-square">
              <UiMediaGallery
                v-model="selectedMedia"
                :cols="{ initial: 3, md: 4, lg: 5, xl: 3 }"
                :loading="isLoadingCategory"
                :vault="categoryVault"
                :type="isSelecting || breakpoint >= screens.xl ? 'checkable' : undefined"
              />
            </div>
          </div>
        </div>

        <!-- Mobile selection bottom bar -->
        <div
          v-if="isSelecting && !embed"
          class="xl:tw-hidden"
        >
          <!-- TODO: rounded top, factorise with the bottom tab bar in LayoutAuthorized -->
          <div class="tw-z-header tw-fixed tw-inset-x-0 tw-bottom-[-1px] tw-w-full tw-py-4 tw-px-4 tw-bg-white">
            <div class="tw-flex tw-items-center">
              <div class="tw-w-1/5" />

              <div class="tw-grow tw-flex tw-justify-center">
                <UiBadge
                  color="primary"
                  icon="close"
                  @click="selectedMedia = []"
                >
                  {{ $t('general.x-selected', [selectedMedia.length, maxMedia]) }}
                </UiBadge>
              </div>

              <div class="tw-w-1/5">
                <div class="tw-flex tw-items-center tw-justify-end tw-space-x-3">
                  <button
                    class="tw-group"
                    :disabled="selectedMedia.length === 0"
                    @click="activeModal = modals.deleteMedia"
                  >
                    <UiIcon
                      class="tw-h-6 tw-w-6 group-disabled:tw-opacity-50"
                      name="delete"
                    />
                  </button>
                  <button
                    class="tw-group"
                    :disabled="selectedMedia.length === 0 || selectedMedia.length > maxMedia"
                    @click="openModalMoveMedia"
                  >
                    <UiIcon
                      class="tw-h-6 tw-w-6 group-disabled:tw-opacity-50"
                      name="swap"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="!embed && selectedMedia.length > 0"
          class="tw-mt-4 tw-hidden xl:tw-block"
        >
          <div class="tw-grid tw-grid-flow-col tw-auto-cols-fr tw-gap-8">
            <UiButton
              variant="secondary"
              @click="activeModal = modals.deleteMedia"
            >
              {{ $t('general.delete') }}
            </UiButton>
            <UiButton
              variant="primary"
              @click="openModalMoveMedia"
            >
              {{ $t('general.move-to-a-category') }}
            </UiButton>
          </div>
        </div>
      </UiCard>
    </div>

    <UiModal
      v-if="activeModal === modals.createCategory"
      key="modalCreateCategory"
      :title="$t('general.create-new-category')"
      size="md"
      :cancel="$t('general.cancel')"
      :confirm="$t('general.create')"
      :disabled="!newCategoryName"
      @cancel="closeModalCreateCategory"
      @close="closeModalCreateCategory"
      @confirm="createCategory"
    >
      <UiFormInput
        v-model="newCategoryName"
        autofocus
        :placeholder="$t('general.enter-category-name')"
        icon="star"
        @enter="createCategory"
      />
    </UiModal>

    <UiModal
      v-if="activeModal === modals.renameCategory"
      key="modalRenameCategory"
      :title="$t('general.rename-category')"
      size="md"
      :cancel="$t('general.cancel')"
      :confirm="$t('general.save')"
      :disabled="!newCategoryName"
      @cancel="closeModalRenameCategory"
      @close="closeModalRenameCategory"
      @confirm="renameCategory"
    >
      <UiFormInput
        v-model="newCategoryName"
        autofocus
        :placeholder="$t('general.enter-category-name')"
        icon="star"
        @enter="renameCategory"
      />
    </UiModal>

    <UiModal
      v-if="activeModal === modals.selectMedia"
      key="modalSelectMedia"
      size="lg"
      :cancel="$t('general.close')"
      :confirm="$t('general.move-to-category')"
      :disabled="selectedMedia.length === 0 || selectedMedia.length > maxMedia"
      @cancel="closeSelectMediaModal"
      @close="closeSelectMediaModal"
      @confirm="moveMedia(newCategoryName, selectedMedia)"
    >
      <div class="tw-flex tw-items-center">
        <div class="tw-text-lg tw-font-semibold">
          {{ newCategoryName }}
        </div>

        <div class="tw-ml-auto">
          <UiBadge
            color="primary"
            icon="close"
            @click="selectedMedia = []"
          >
            {{ $t('general.x-selected', [selectedMedia.length, 40]) }}
          </UiBadge>
        </div>
      </div>

      <UiSeparator
        class="tw-my-4"
      />

      <div class="tw-aspect-square tw-overflow-hidden">
        <div class="tw-h-full tw-overflow-auto">
          <UiMediaGallery
            v-model="selectedMedia"
            :cols="{ initial: 3, md: 4, lg: 5, xl: 3 }"
            :loading="isLoadingRoot"
            :vault="rootVault"
            type="checkable"
          />
        </div>
      </div>

      <UiSeparator
        class="tw-mt-4"
      />
    </UiModal>

    <UiModal
      v-if="activeModal === modals.moveMedia"
      key="modalMoveMedia"
      size="sm"
      action="close"
      :cancel="$t('general.new-category')"
      :confirm="$t('general.move-to-category')"
      :disabled="!selectedCategoryName"
      :title="$t('general.move-to-a-category')"
      @action="closeModalMoveMedia"
      @cancel="activeModal = modals.createCategory"
      @close="closeModalMoveMedia"
      @confirm="moveMedia(selectedCategoryName, selectedMedia)"
    >
      <div class="tw-space-y-4">
        <div
          v-for="category in categories"
          :key="category.name"
          class="tw-flex tw-items-center tw-cursor-pointer"
          @click="selectedCategoryName = category.name"
        >
          <UiRadio
            v-model="selectedCategoryName"
            :value="category.name"
            @click.native.stop
          />

          <div class="tw-ml-3">
            <div :class="{ 'tw-font-bold': category.name === selectedCategoryName }">
              {{ category.name }}
            </div>

            <UiVaultCategoryStats
              class="tw-mt-2"
              :mediaCount="category.media_count"
              :videoCount="category.video_count"
            />
          </div>
        </div>
      </div>
    </UiModal>

    <UiAlertModal
      v-if="activeModal === modals.deleteMedia"
      key="alertDeleteMedia"
      :title="$t('general.warning')"
      :message="$t('general.delete-media-message')"
      :cancel="$t('general.cancel')"
      :confirm="$t('general.yes-delete')"
      @cancel="activeModal = null"
      @confirm="moveMedia(null, selectedMedia)"
    />

    <UiAlertModal
      v-if="activeModal === modals.deleteCategory"
      key="alertDeleteCategory"
      :title="$t('general.delete-category-title', [activeCategory.name])"
      :message="$t('general.delete-category-message')"
      :cancel="$t('general.cancel')"
      :confirm="$t('general.delete')"
      @cancel="activeModal = null"
      @confirm="deleteCategory"
    />
  </div>
</template>

<script>
import Media from '@/components/models/Media';
import VaultCategory from '@/components/models/VaultCategory';

import UiAlertModal from '@/components/ui/UiAlertModal.vue';
import UiBadge from '@/components/ui/UiBadge.vue';
import UiButton from '@/components/ui/UiButton.vue';
import UiCard from '@/components/ui/UiCard.vue';
import UiDropdown from '@/components/ui/UiDropdown.vue';
import UiDropdownItem from '@/components/ui/UiDropdownItem.vue';
import UiFormInput from '@/components/ui/UiFormInput.vue';
import UiIcon from '@/components/ui/UiIcon.vue';
import UiIconItem from '@/components/ui/UiIconItem.vue';
import UiMediaGallery from '@/components/ui/UiMediaGallery.vue';
import UiModal from '@/components/ui/UiModal.vue';
import UiNavigationBar from '@/components/ui/UiNavigationBar.vue';
import UiPill from '@/components/ui/UiPill.vue';
import UiRadio from '@/components/ui/UiRadio.vue';
import UiSeparator from '@/components/ui/UiSeparator.vue';
import UiSkeleton from '@/components/ui/UiSkeleton.vue';
import UiVaultCategoryStats from '@/components/ui/UiVaultCategoryStats.vue';

export default {
  components: {
    UiVaultCategoryStats,
    UiSkeleton,
    UiDropdownItem,
    UiDropdown,
    UiRadio,
    UiAlertModal,
    UiButton,
    UiPill,
    UiBadge,
    UiCard,
    UiFormInput,
    UiIcon,
    UiIconItem,
    UiMediaGallery,
    UiModal,
    UiNavigationBar,
    UiSeparator,
  },
  data() {
    return {
      activeCategory: null,
      activeFilter: null,
      activeSort: null,
      selectedCategoryName: '',
      newCategoryName: '',
      categories: [],
      rootVault: [],
      categoryVault: [],
      selectedMedia: [],
      activeModal: null,
      isMoving: false,
      isSelecting: false,
      isLoadingCategory: false,
      isLoadingCategories: false,
      isLoadingRoot: false,
      breakpoint: 0,
      maxMedia: 40,
    };
  },
  created() {
    this.loadCategories();
    this.loadCategory(null);
  },
  mounted() {
    this.breakpoint = window.innerWidth;
    window.addEventListener('resize', () => {
      this.breakpoint = window.innerWidth;
    });
  },
  computed: {
    screens() {
      return {
        xl: 1280,
      };
    },
    types() {
      return {
        image: 'image',
        video: 'video',
      };
    },
    sorts() {
      return {
        alphabetically: 'alphabetically',
        date: 'date',
      };
    },
    modals() {
      return {
        createCategory: 'create-category',
        renameCategory: 'rename-category',
        selectMedia: 'select-media',
        moveMedia: 'move-media',
        deleteMedia: 'delete-media',
        deleteCategory: 'delete-category',
      };
    },
    embed() {
      // When the page should use an "embedded" layout
      return this.$route.path !== '/vault';
    },
  },
  watch: {
    selectedMedia(selectedMedia) {
      this.$emit('select', selectedMedia);
    },
  },
  methods: {
    reset() {
      this.newCategoryName = '';
      this.selectedCategoryName = '';
      this.rootVault = [];
      this.categoryVault = [];
      this.selectedMedia = [];
      this.isMoving = false;
      this.isSelecting = false;
    },
    setActiveFilter(type) {
      this.activeFilter = type;
      this.loadCategory(this.activeCategory);
    },
    loadCategories() {
      this.isLoadingCategories = true;

      this.$get(
        '/vault',
        (data) => {
          let categories = [];
          for (let category of data.data.categories) {
            categories.push(new VaultCategory(category));
          }
          this.categories = categories;
          this.isLoadingCategories = false;
        },
        (errors) => {
          console.log(errors);
        },
      );
    },
    loadRoot() {
      this.isLoadingRoot = true;

      this.$get(
        '/vault?path=/',
        (data) => {
          let rootVault = [];
          for (let media of data.data) {
            rootVault.push(new Media(media));
          }
          this.rootVault = rootVault;
          this.isLoadingRoot = false;
        },
        (errors) => {
          console.log(errors);
        },
      );
    },
    loadCategory(category) {
      this.reset();
      this.activeCategory = category;
      this.isLoadingCategory = true;

      this.$get(
        '/vault?path='
        + (category?.path || '/')
        + (this.activeFilter ? '&filter=' + this.activeFilter : ''),
        (data) => {
          if (data.data.length > 0) {
            const categoryVault = [];
            for (let media of data.data) {
              categoryVault.push(new Media(media));
            }
            this.categoryVault = categoryVault;
          } else {
            if (!this.activeFilter) {
              // If the category doesn't contain any media (not filtered), it
              // cannot exist and therefore cannot be selected.
              this.activeCategory = null;
            }

            this.categoryVault = [];
          }

          this.isLoadingCategory = false;
        },
        (errors) => {
          console.log(errors);
        },
      );
    },
    moveMedia(to, media) {
      // Separate the images from the videos to build the API call
      const images = media.filter((media) => media.type === 'image');
      const videos = media.filter((media) => media.type === 'video');

      this.$patch(
        '/vault',
        {
          image_uuid: images.map((image) => image.id),
          video_uuid: videos.map((video) => video.id),
          path: to ? '/' + to : null,
        },
        () => {
          this.closeModal();
          this.loadCategories();
          this.loadCategory(this.activeCategory);
        },
        (errors) => {
          console.log(errors);
        },
      );
    },
    createCategory() {
      if (!this.newCategoryName) {
        return;
      }

      if (this.isMoving) {
        // If media has been selected in a moving context,
        // we want to move them in the new category
        this.moveMedia(this.newCategoryName, this.selectedMedia);
      } else {
        // Else, show the media selection modal
        this.activeModal = this.modals.selectMedia;
        this.loadRoot();
      }
    },
    renameCategory() {
      if (!this.newCategoryName) {
        return;
      }

      this.activeCategory.name = this.newCategoryName;
      this.activeCategory.path = '/' + this.newCategoryName;
      this.moveMedia(this.newCategoryName, this.categoryVault);
    },
    deleteCategory() {
      this.moveMedia(null, this.categoryVault);
    },
    openModalRenameCategory() {
      this.activeModal = this.modals.renameCategory;
      this.newCategoryName = this.activeCategory.name;
    },
    openModalMoveMedia() {
      this.isMoving = true;
      this.activeModal = this.modals.moveMedia;
    },
    closeModal() {
      this.activeModal = null;
      this.reset();
    },
    closeModalCreateCategory() {
      this.activeModal = null;
      this.newCategoryName = '';
      this.selectedCategoryName = ''; // Reset `selectedCategoryName` in case we're coming from the `moveMedia` modal
    },
    closeModalRenameCategory() {
      this.activeModal = null;
      this.newCategoryName = '';
    },
    closeModalMoveMedia() {
      this.activeModal = null;
      this.selectedCategoryName = '';
      this.isMoving = false;
    },
    closeSelectMediaModal() {
      this.activeModal = null;
      this.selectedMedia = [];
      this.newCategoryName = '';
    },
    toggleIsSelecting() {
      this.isSelecting = !this.isSelecting;
      this.selectedMedia = [];
    },
    onBack() {
      this.activeCategory = null;
      this.loadCategory(null);
    },
  },
};
</script>
