import Bundle from './Bundle';

export default class User {
  static CHANNEL_EMAIL = 0;
  static CHANNEL_GOOGLE = 1;

  id = 0;
  email = null;
  name = '';
  username = '';
  avatar_url = null;
  cover_url = null;
  cover_variants = null;
  locale = null;
  bio = null;
  location = null;
  website = null;
  price = 0;
  vat_price = [];
  bundles = [];
  isSubscribed = false;
  signup_for_account_type = null;
  is_online = false;
  is_ban = false;
  is_creator = false;
  email_verified = false;
  should_prompt_security_code = false;
  like_count = 0;
  post_count = 0;
  subscriber_count = 0;
  image_count = 0;
  video_count = 0;
  created_at = new Date().toLocaleString('en-US');

  constructor(data) {
    if (!data) {
      return;
    }
    this.id = data.id;
    this.name = data.name;
    this.email = data.email ? data.email : null;
    this.username = data.username;
    this.avatar_url = data.avatar_url;
    this.cover_url = data.cover_url;
    this.cover_variants = data.cover_variants;
    this.bio = data.bio ? data.bio : null;
    this.signup_for_account_type = data.signup_for_account_type ? data.signup_for_account_type : null,
    this.should_prompt_security_code = data.should_prompt_security_code ? data.should_prompt_security_code : false;
    this.location = data.location ? data.location : null;
    this.locale = data.locale ? data.locale : 'en';
    this.website = data.website ? data.website : null;
    this.price = data.price ? data.price : null;
    this.vat_price = data.vat_price;
    this.isSubscribed = data.is_subscribed;
    this.is_online = Boolean(data.is_online);
    this.is_ban = data.is_ban;
    this.is_creator = data.is_creator;
    this.like_count = data.like_count;
    this.post_count = data.post_count;
    this.image_count = data.image_count;
    this.video_count = data.video_count;
    this.subscriber_count = data.subscriber_count;
    this.email_verified = data.email_verified;
    this.created_at = new Date(data.created_at).toLocaleString('en-US');

    if (data.bundles) {
      for (let b of data.bundles) {
        this.bundles.push(new Bundle(b));
      }
    }
  }

  get shouldStartOnboarding() {
    // tmp the null check should be removed in a week or two.
    // this is to mitigate old accounts when we were not classifying users upon signup
    return (this.signup_for_account_type === 'creator' || this.signup_for_account_type === null) && !this.is_creator
  }

  get initials() {
    const a = this.username.charAt(0).toUpperCase();
    const b = this.username.charAt(1);
    return a + b;
  }

  get url() {
    return `/${this.username}`;
  }

  get isFree() {
    return !this.price && true;
  }

  get baseAmountFormatted() {
    return process.env.VUE_APP_CURRENCY_SIGN + this.vat_price.amount;
  }

  get priceFormatted() {
    return process.env.VUE_APP_CURRENCY_SIGN + this.vat_price.amount_with_vat;
  }

  get isCreator() {
    return this.is_creator;
  }

  report(reporterEmail, locale) {
    let zendeskLocale = '';
    switch (locale) {
      case 'fr':
        zendeskLocale =  'fr';
        break;
      case 'en':
        zendeskLocale = 'en-us';
        break;
    }

    const url = `https://support.dropfeet.com/hc/${zendeskLocale}/requests/new?ticket_form_id=20200371253650
      &tf_subject=${
        encodeURIComponent('Content Report')
      }
      &tf_anonymous_requester_email=${
        encodeURIComponent(reporterEmail)
      }&tf_20200917945106=${
        encodeURIComponent(this.username)
      }`;

    window.open(url);
  }
}
