<template>
  <RouterLink
    v-if="to"
    class="tw-font-semibold tw-text-primary-500 hover:tw-underline"
    :to="to"
  >
    <slot />
  </RouterLink>

  <a
    v-else
    class="tw-font-semibold tw-text-primary-500 hover:tw-underline"
    :href="href"
    v-bind="$attrs"
  >
    <slot />
  </a>
</template>

<script>
export default {
  props: {
    href: String,
    to: [String, Object],
  },
};
</script>
