<template>
  <UiCard>
    <UiNavigationBar
      bleed
      :title="$t('general.edit-subscriptions')"
      @back="$router.go(-1)"
      clas="tw-hidden"
    />

    <div>
      <form
        class="tw-w-full"
        @submit.prevent="savePrice"
      >
        <UiFormInput
          v-model="user.price"
          :errors="errors"
          :label="$t('general.price')"
          name="price"
          :prepend="currency"
          type="text"
        />
        <UiButton
          v-if="user.price"
          class="tw-mt-6 tw-min-w-[7rem] tw-max-w-full"
          type="submit"
          variant="primary"
        >
          {{ $t('general.save') }}
        </UiButton>
      </form>
    </div>

    <div class="tw-mt-6 tw-flex tw-items-center tw-justify-between">
      <h5 class="tw-text-lg tw-font-semibold">
        {{ $t('general.subscription-bundles') }}
      </h5>

      <UiButton
        variant="primary"
        @click="isBundleModalOpen = true"
      >
        {{ $t('general.add-bundle') }}
      </UiButton>
    </div>

    <div class="tw-mt-6">
      <div
        v-if="user.bundles.length > 0"
        class="tw-grid tw-gap-5 md:tw-grid-cols-2 xl:tw-grid-cols-3"
      >
        <div
          v-for="(item, index) in user.bundles"
          :key="index"
          class="tw-flex tw-items-center tw-p-4 tw-bg-gray-50 tw-rounded-2xl"
        >
          <div class="tw-grow tw-mr-2">
            <div class="tw-text-sm tw-text-primary-500">
              {{ $t('general.include-x-percent', [item.discount]) }}
            </div>

            <div class="tw-mt-1">
              <span class="tw-text-lg tw-font-semibold">
                {{ `${currency}${item.vat_price.amount}` }} /
              </span>
              <span>
                {{ $tc('general.x-months', item.months) }}
              </span>
            </div>
          </div>

          <button @click="onRemoveBundle(item)">
            <UiIcon
              name="delete"
            />
          </button>
        </div>
      </div>

      <div
        v-else
        class="tw-text-center tw-text-lg"
      >
        {{ $t('general.no-bundles-yet') }}
      </div>
    </div>

    <UiModal
      v-if="isBundleModalOpen"
      size="md"
      :title="$t('general.add-bundle')"
      :cancel="$t('general.cancel')"
      :confirm="$t('general.add')"
      @cancel="isBundleModalOpen = false"
      @confirm="addBundle"
    >
      <div class="md:tw-hidden">
        <div class="tw-space-y-6">
          <UiMobileSelect
            v-model="newBundle.months"
            :label="$t('general.months')"
            :options="options.months"
            :placeholder="$t('general.months')"
          />

          <UiMobileSelect
            v-model="newBundle.discount"
            :label="$t('general.discount')"
            :options="options.discount"
            :placeholder="$t('general.discount')"
          />
        </div>
      </div>

      <div class="tw-hidden md:tw-block">
        <div class="tw-space-y-6">
          <div>
            <div class="tw-text-lg tw-font-semibold">
              {{ $t('general.months') }}
            </div>
            <div class="tw-mt-4 tw-flex tw-space-x-2">
              <UiPill
                v-for="(item, key) in options.months"
                :key="key"
                v-model="newBundle.months"
                :value="key"
                variant="neutral"
              >
                {{ item }}
              </UiPill>
            </div>
          </div>

          <div>
            <div class="tw-text-lg tw-font-semibold">
              {{ $t('general.discount') }}
            </div>
            <div class="tw-mt-4 tw-flex tw-space-x-2">
              <UiPill
                v-for="(item, key) in options.discount"
                :key="key"
                v-model="newBundle.discount"
                :value="key"
                variant="neutral"
              >
                {{ item }}
              </UiPill>
            </div>
          </div>
        </div>
      </div>
    </UiModal>

    <UiAlertModal
      v-if="isAlertModalOpen"
      :title="$t('general.delete-x-title', [this.$t('general.bundle')])"
      :message="$t('general.delete-x-message', [this.$t('general.bundle')])"
      :cancel="$t('general.cancel')"
      :confirm="$t('general.yes-delete')"
      @cancel="isAlertModalOpen = false"
      @confirm="removeBundle(bundleToRemove)"
    />

    <UiAlertModal
        v-if="isFreeToPaidAlertModalOpen"
        :title="$t('general.confirm-user-price-from-free.header')"
        :message="$t('general.confirm-user-price-from-free.text')"
        :cancel="$t('general.cancel')"
        :confirm="$t('general.confirm-user-price-from-free.confirm')"
        @cancel="isFreeToPaidAlertModalOpen = false"
        @confirm="savePrice"
    />
  </UiCard>
</template>

<script>
import UiCard from '@/components/ui/UiCard.vue';
import UiFormInput from '@/components/ui/UiFormInput.vue';
import UiNavigationBar from '@/components/ui/UiNavigationBar.vue';
import UiButton from '@/components/ui/UiButton.vue';
import UiIcon from '@/components/ui/UiIcon.vue';
import UiModal from '@/components/ui/UiModal.vue';
import UiPill from '@/components/ui/UiPill.vue';
import UiAlertModal from '@/components/ui/UiAlertModal.vue';
import UiMobileSelect from '@/components/ui/UiMobileSelect.vue';

export default {
  components: {
    UiMobileSelect,
    UiAlertModal,
    UiPill,
    UiModal,
    UiIcon,
    UiButton,
    UiNavigationBar,
    UiCard,
    UiFormInput,
  },
  data: function () {
    return {
      errors: {},
      newBundle: {
        discount: null,
        months: null,
      },
      originalPrice: null,
      options: {
        discount: {
          10: '10%',
          20: '20%',
          30: '30%',
          40: '40%',
          50: '50%',
        },
        months: {
          3: this.$tc('general.x-months', 3),
          6: this.$tc('general.x-months', 6),
          12: this.$tc('general.x-months', 12),
        },
      },
      bundleToRemove: null,
      isBundleModalOpen: false,
      isAlertModalOpen: false,
      isFreeToPaidAlertModalOpen: false,
      confirmSwitchToPaidSub: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.currentUser;
    },
    currency() {
      return process.env.VUE_APP_CURRENCY_SIGN;
    },
  },
  mounted() {
    this.fromFreeSub = this.user.price === null;
  },
  methods: {
    onRemoveBundle(bundle) {
      this.bundleToRemove = bundle;
      this.isAlertModalOpen = true;
    },
    savePrice() {
      if (
          !this.isFreeToPaidAlertModalOpen
          && this.user.price > 0
          && this.fromFreeSub
      ) {
        this.isFreeToPaidAlertModalOpen = true;

        return;
      }

      // Paid2Free disabled if subscribers.
      // Currently disabled as it needs subscriber_count from backend API
      // if (
      //     [0, '', 0.00, 0.0].includes(this.user.price)
      //     && this.user.subscriber_count > 0
      //     && !this.fromFreeSub
      // ) {
      //   this.$bvToast.toast(this.$t('paid2free-toast.content'), {
      //     autoHideDelay: 2000,
      //     title: this.$t('paid2free-toast.header'),
      //     solid: true,
      //     toaster: 'b-toaster-bottom-left',
      //   });
      //
      //   return;
      // }

      this.errors = {};
      this.$post(
        '/price',
        {
          price: this.user.price,
        },
        (data) => {
          this.isFreeToPaidAlertModalOpen = false;
          this.$saveUser(data);
          this.$bvToast.toast(this.$t('general.settings-saved'), {
            autoHideDelay: 2000,
            title: this.$t('general.success'),
            solid: true,
            toaster: 'b-toaster-bottom-left',
          });
        },
        (errors) => {
          this.errors = errors;
        },
      );
    },
    addBundle() {
      this.isBundleModalOpen = false;

      this.$post(
        '/price/bundle',
        {
          discount: this.newBundle.discount,
          months: this.newBundle.months,
        },

        (data) => {
          this.$saveUser(data);
        },
        (errors) => {
          console.log(errors);
        },
      );
    },
    removeBundle(bundle) {
      this.bundleToRemove = null;
      this.isAlertModalOpen = false;

      this.$post(
        '/price/bundle/' + bundle.id,
        {
          _method: 'DELETE',
        },

        (data) => {
          this.$saveUser(data);
          this.newBundle.discount = null;
          this.newBundle.months = null;
        },
        (errors) => {
          console.log(errors);
        },
      );
    },
  },
};
</script>
