<template>
  <div class="tw-flex tw-items-center">
    <div class="tw-flex tw-items-center tw-space-x-4 md:tw-space-x-6">
      <div class="tw-flex tw-items-center">
        <button
          :class="{ 'tw-text-gray-600': !hasAccess }"
          :disabled="!hasAccess"
          @click.prevent="like"
        >
          <UiIcon
            :gradient="post.isLiked !== false ? 'primary' : null"
            name="heart"
            :solid="post.isLiked !== false"
          />
        </button>
        <span
          v-if="post.likesCount > 0"
          class="tw-ml-2 tw-text-sm tw-font-semibold md:tw-ml-4 md:tw-text-base"
        >
          {{ post.likesCount }}
        </span>
      </div>

      <button
        class="tw-flex tw-items-center"
        :class="{ 'tw-text-gray-600': !hasAccess }"
        :disabled="!hasAccess"
        @click="$emit('preview')"
      >
        <UiIcon
          name="chat"
        />
        <span class="tw-ml-2 tw-text-sm tw-font-semibold md:tw-ml-4 md:tw-text-base">
          {{ post.commentsCount }}
        </span>
      </button>
    </div>

    <div class="tw-ml-auto">
      <button
        class="tw-flex"
        :class="{
          'tw-text-gray-600': !hasAccess,
        }"
        :disabled="!hasAccess"
        @click="bookmark"
      >
        <UiIcon
          :gradient="post.isBookmarked ? 'primary' : null"
          name="bookmark"
          :solid="post.isBookmarked"
        />
      </button>
    </div>
  </div>
</template>

<script>
import Post from '@/components/models/Post';

import UiIcon from '@/components/ui/UiIcon.vue';

export default {
  components: {
    UiIcon,
  },
  props: {
    value: Post,
    hasAccess: Boolean,
  },
  computed: {
    post: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  methods: {
    like() {
      this.$post(
        '/posts/' + this.post.id + '/like',
        {},
        (data) => {
          this.post.isLiked = data.is_liked;
          this.post.likesCount = data.likes_count;
        },
        (errors) => {
          console.log(errors);
        },
      );
    },
    bookmark() {
      this.$post(
        '/bookmarks/' + this.post.id,
        {},
        (data) => {
          this.post.isBookmarked = data.is_bookmarked;
        },
        (errors) => {
          console.log(errors);
        },
      );
    },
  },
};
</script>
