<template>
  <div
    :class="className({ rounded })"
  />
</template>

<script>
import { tv } from 'tailwind-variants';

export default {
  props: {
    rounded: {
      type: [String, Object], // 'default' | 'full' | 'none'
      default: 'default',
    },
  },
  computed: {
    className() {
      return tv(
        {
          base: 'tw-bg-gray-200 tw-animate-pulse',
          variants: {
            rounded: {
              default: 'tw-rounded-2xl',
              full: 'tw-rounded-full',
              none: 'tw-rounded-none',
            },
          },
        },
        {
          responsiveVariants: ['xl'],
        },
      );
    },
  },
};
</script>
