export default class Bundle {
  id = 0;
  discount = 0;
  months = 0;
  user = null;

  constructor(data) {
    if (!data) {
      return;
    }
    this.id = data.id;
    this.discount = data.discount;
    this.months = data.months;
    this.vat_price = data.vat_price;
  }

  get priceFormatted() {
    return process.env.VUE_APP_CURRENCY_SIGN + this.vat_price.amount_with_vat;
  }

  get baseAmountFormatted() {
    return process.env.VUE_APP_CURRENCY_SIGN + this.vat_price.amount;
  }
}
