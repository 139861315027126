export default class VaultCategory {

  id = 0;
  path = '';
  name = '';
  media_count = 0;
  video_count = 0;

  constructor(data) {
    if (!data) {
      return;
    }

    this.id = data.id;
    this.path = data.path;
    this.name = data.name;
    this.media_count = data.media_count;
    this.video_count = data.video_count;
  }

}