<template>
  <ui-cc-shift4
    ref="shift4"
    :amount="amount"
    :message="message"
    :method="method"
    @token="token"
    @error="error"
  />
</template>
<script>
import UiCcShift4 from './cc/UiCcShift4.vue';

export default {
  components: { UiCcShift4 },
  props: ['cc', 'amount', 'message', 'method'],
  methods: {
    token(title, token) {
      this.$emit('token', title, token);
    },
    error(error) {
      this.$emit('error', error);
    },
    save() {
      this.$refs.shift4.save();
    },
  },
};
</script>
