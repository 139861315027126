<template>
  <div class="tw-h-full tw-flex tw-flex-col">
    <div class="tw-grow tw-flex tw-flex-col tw-px-4 tw-overflow-hidden md:tw-px-6">
      <div
        ref="comments"
        class="tw-grow tw-border-t tw-border-gray-200 tw-py-4 tw-space-y-6 tw-overflow-auto"
      >
        <template v-if="comments.length > 0">
          <UiComment
            v-for="(item, index) in comments"
            :key="index"
            v-model="comments[index]"
            @reply="reply"
          />
        </template>

        <div
          v-else-if="!isLoading"
          class="tw-text-center"
        >
          {{ $t('general.no-comments-yet') }}
        </div>
      </div>
    </div>

    <div class="tw-w-full tw-sticky tw-bottom-0 tw-pt-6 tw-px-4 tw-bg-white tw-rounded-t-4xl tw-border-t tw-border-gray-100 md:tw-px-6">
      <div
        v-if="$slots.default"
        class="tw-pb-6"
      >
        <slot />
      </div>

      <form class="tw-w-full tw-flex tw-items-center tw-space-x-4">
        <UiAvatar
          size="sm"
          :src="currentUser.avatar_url"
          :text="currentUser.initials"
        />

        <div class="tw-grow">
          <UiFormTextarea
            v-model="message"
            class="tw-w-full"
            max-rows="2"
            rows="1"
            no-resize
            :placeholder="$t('general.your-comment')"
          />
        </div>

        <UiTextButton
          :disabled="message.length === 0"
          @click.prevent="submitComment"
        >
          {{ $t('general.post') }}
        </UiTextButton>
      </form>
    </div>
  </div>
</template>

<script>
import Comment from '@/components/models/Comment';

import UiAvatar from '@/components/ui/UiAvatar.vue';
import UiComment from '@/components/ui/UiComment.vue';
import UiFormTextarea from '@/components/ui/UiFormTextarea.vue';
import UiTextButton from '@/components/ui/UiTextButton.vue';

export default {
  components: {
    UiAvatar,
    UiComment,
    UiFormTextarea,
    UiTextButton,
  },
  data: function () {
    return {
      comments: [],
      message: '',
      replyTo: null,
      page: 1,
      hasMore: false,
      isLoading: false,
      scrollContainer: null,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
  },
  props: {
    contained: Boolean,
    postId: Number,
  },
  mounted() {
    this.loadComments();
    this.scrollContainer = this.contained ? this.$refs.comments : window;
    this.scrollContainer.addEventListener('scroll', this.updateScroll);
  },
  beforeDestroy() {
    this.scrollContainer.removeEventListener('scroll', this.updateScroll);
  },
  methods: {
    updateScroll() {
      const scrollPosition = this.contained
        ? this.scrollContainer.scrollHeight - this.scrollContainer.clientHeight - this.scrollContainer.scrollTop
        : document.body.offsetHeight - window.innerHeight - window.scrollY;

      if (
        scrollPosition &&
        scrollPosition <= 500 &&
        !this.isLoading &&
        this.hasMore
      ) {
        this.loadMore();
      }
    },
    loadComments() {
      this.isLoading = true;
      this.$get(
        '/comments/' + this.postId + '?page=' + this.page,
        (data) => {
          const comments = [...this.comments];
          for (let d of data.data) {
            comments.push(new Comment(d));
          }
          this.comments = comments;
          this.isLoading = false;
          this.hasMore = data.next_page_url != null;
        },
        (errors) => {
          console.log(errors);
        },
      );
    },
    loadMore() {
      if (this.hasMore) {
        this.page = this.page + 1;
        this.loadComments();
      }
    },
    submitComment() {
      this.$post(
        '/comments/' + this.postId,
        {
          message: this.message,
        },
        (data) => {
          const comments = [...this.comments];
          comments.push(new Comment(data));
          this.comments = comments;
          this.message = '';
          this.replyTo = null;
          this.$nextTick(function () {
            this.scrollContainer.scrollTo({
              top: this.scrollContainer.scrollHeight,
              behavior: 'smooth',
            });
          });
        },
        (errors, { response }) => {
          if (response.status === 429) {
            this.$toast({
              title: this.$t('general.error'),
              description: this.$t('comment.errors.rate-limit'),
              variant: 'error',
            })
          } else {
            errors.message?.forEach(msg => {
              this.$bvToast.toast(msg, {
                autoHideDelay: 2000,
                title: this.$t('general.error'),
                solid: true,
                variant: 'danger',
                toaster: 'b-toaster-bottom-right',
              });
            });
          }
        },
      );
    },
    reply(comment) {
      this.replyTo = comment;
      this.message = '@' + comment.user.username + ', ' + this.message;
    },
    unreply() {
      this.replyTo = null;
    },
  },
};
</script>
