<template>
  <div class="tw-h-[120px] tw-relative tw-w-full tw-bg-primary-500 tw-rounded-2xl tw-overflow-hidden md:tw-h-[250px]">
    <UiImage
      v-if="src"
      alt=""
      class="tw-absolute tw-inset-0 tw-h-full tw-w-full tw-object-cover"
      :sizes="sizes"
      :src="src"
      :srcset="srcset"
    />

    <div
      v-if="editable || progress"
      class="tw-z-[2] tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center"
      :class="[
        src ? 'tw-text-white tw-bg-black/40' : 'tw-text-gray-900',
        { 'tw-bg-black/40': progress },
      ]"
    >
      <UiProgress
        v-if="progress"
        class="tw-w-1/2"
        :value="progress"
      />

      <template
        v-else
      >
        <button @click="$emit('upload')">
          <UiIcon
            name="camera"
            solid
          />
        </button>

        <button
          v-if="src"
          class="tw-absolute tw-top-4 tw-right-4"
          @click="$emit('remove')"
        >
          <UiIcon
            name="close"
          />
        </button>
      </template>
    </div>
  </div>
</template>

<script>
import UiIcon from '@/components/ui/UiIcon.vue';
import UiImage from '@/components/ui/UiImage.vue';
import UiProgress from '@/components/ui/UiProgress.vue';

export default {
  components: {
    UiIcon,
    UiImage,
    UiProgress,
  },
  props: {
    src: String,
    srcset: String,
    sizes: [String, Object],
    editable: Boolean,
    progress: Number,
  },
};
</script>
