<template>
  <div>
    <div class="tw-mb-6 tw-text-center md:tw-mb-10">
      <div class="tw-text-h5 tw-font-semibold md:tw-text-h2 md:tw-font-bold">
        {{ $t('general.fill-your-profile') }}
      </div>
    </div>

    <form>
      <div class="tw-flex tw-justify-center">
        <UiAvatar
          editable
          size="lg"
          :src="user ? user.avatar_url : ''"
          @click="onAvatarClick"
        />
      </div>

      <div class="tw-flex tw-justify-center">
        <UiFormErrors
          v-if="showAvatarError"
          :errors="[$t('general.avatar-required')]"
        />
      </div>

      <div class="tw-mt-6 tw-space-y-3 tw-bg-white md:tw-space-y-4">
        <UiFormInput
          v-model="first_name"
          :errors="errors"
          name="first_name"
          :placeholder="$t('general.first-name')"
          type="text"
        />
        <UiFormInput
          v-model="last_name"
          :errors="errors"
          name="last_name"
          :placeholder="$t('general.last-name')"
          type="text"
        />
        <UiFormInput
          v-model="birthdate"
          :errors="errors"
          icon="calendar"
          iconPosition="end"
          name="birthdate"
          :placeholder="$t('general.birthdate')"
          type="text"
          v-mask="'##-##-####'"
        />

        <div>
          <UiFormSelect
            id="country"
            v-model="country"
            :options="countries"
            :placeholder="$t('general.country')"
          />
          <UiFormErrors
            :errors="errors['country']"
          />
        </div>

        <div>
          <UiFormSelect
            id="country"
            v-model="gender"
            :options="genders"
            :placeholder="$t('general.gender')"
          />
          <UiFormErrors
            :errors="errors['gender']"
          />
        </div>

        <UiFormInput
          v-model="address"
          :errors="errors"
          name="address"
          :placeholder="$t('general.address')"
          type="text"
        />
        <UiFormInput
          v-model="city"
          :errors="errors"
          name="city"
          :placeholder="$t('general.city')"
          type="text"
        />
        <UiFormInput
          v-model="state"
          :errors="errors"
          name="state"
          :placeholder="$t('general.state')"
          type="text"
        />
        <UiFormInput
          v-model="zip"
          :errors="errors"
          name="zip"
          :placeholder="$t('general.zip')"
          type="text"
        />
        <UiFormInput
            v-model="phoneNumber"
            :errors="errors"
            name="phone"
            :placeholder="$t('general.phone')"
            type="text"
        />

        <UiButton
          class="tw-w-full"
          type="button"
          variant="primary"
          @click="onContinue"
          :disabled="isSubmitting"
        >
          {{ $t('general.continue') }}
        </UiButton>
      </div>
    </form>

    <input
      ref="avatar"
      hidden
      type="file"
      @change="uploadAvatar"
    />
  </div>
</template>

<script>
import User from '@/components/models/User';
import Verification from '@/components/models/Verification';

import UiAvatar from '@/components/ui/UiAvatar.vue';
import UiButton from '@/components/ui/UiButton.vue';
import UiFormInput from '@/components/ui/UiFormInput.vue';
import UiFormErrors from '@/components/ui/UiFormErrors.vue';
import UiFormSelect from '@/components/ui/UiFormSelect.vue';
import UiFormTextarea from '@/components/ui/UiFormTextarea.vue';

import Countries from '@/components/helpers/Countries';

export default {
  components: {
    UiAvatar,
    UiButton,
    UiFormTextarea,
    UiFormSelect,
    UiFormErrors,
    UiFormInput,
  },
  props: {
    user: User,
    verification: Verification,
  },
  data() {
    return {
      errors: {},
      localVerification: this.verification,
      first_name: this.verification
        ? this.verification.info.first_name
        : '',
      last_name: this.verification ? this.verification.info.last_name : '',
      birthdate: this.verification ? this.verification.info.birthdate : '',
      country: this.verification ? this.verification.country : null,
      gender: this.verification ? this.verification.info.gender : null,
      address: this.verification ? this.verification.info.address : '',
      city: this.verification ? this.verification.info.city : '',
      state: this.verification ? this.verification.info.state : '',
      zip: this.verification ? this.verification.info.zip : '',
      phoneNumber: this.verification ? this.verification.info.zip : '',
      photo: null,
      editing: this.verification == null,
      showAvatarError: false,
      isSubmitting: false,
    };
  },
  computed: {
    countries() {
      let countries = [];
      for (let k in Countries) {
        countries.push({
          value: k,
          text: Countries[k],
        });
      }
      return countries;
    },
    genders() {
      return [
        {
          value: null,
          text: this.$t('general.gender'),
          disabled: true,
        },
        {
          value: 'male',
          text: this.$t('general.male'),
        },
        {
          value: 'female',
          text: this.$t('general.female'),
        },
      ];
    },
  },
  mounted() {
    this.setCountry();
  },
  methods: {
    setCountry() {
      if (!this.country) {
        const lang = navigator.language;
        let country =
          lang.length === 5
            ? lang.substring(3).toUpperCase()
            : lang.toUpperCase();
        for (let c of this.countries) {
          if (c.value === country) {
            this.country = country;
            break;
          }
        }
      }
    },
    getStatus() {
      if (!this.localVerification) {
        return '';
      }
      switch (this.localVerification.status) {
        case Verification.STATUS_PENDING:
          return this.$t('general.pending');
        case Verification.STATUS_DECLINED:
          return this.$t('general.declined');
      }
    },
    postVerification() {
      // Disable avatar for contest verification
      this.showAvatarError = false;

      if (this.user.avatar_url === null) {
        this.showAvatarError = true;

        return;
      }

      let formData = new FormData();

      formData.append('country', this.country);
      formData.append('first_name', this.first_name);
      formData.append('last_name', this.last_name);
      formData.append('birthdate', this.birthdate);
      formData.append('gender', this.gender);
      formData.append('address', this.address);
      formData.append('city', this.city);
      formData.append('state', this.state);
      formData.append('zip', this.zip);
      formData.append('phone', this.phoneNumber);

      return new Promise((resolve, reject) => {
        this.$post(
          '/payouts/verification',
          formData,
          (data) => {
            this.localVerification = new Verification(data);
            resolve(data);
          },
          (errors) => {
            this.errors = { ...this.errors, ...errors };
            reject(errors);
          },
        );
      });
    },
    postProfile() {
      return new Promise((resolve, reject) => {
        this.$post(
          '/profile',
          {
            name: this.user.name,
          },
          (data) => {
            this.$emit('update:user', new User(data));
            this.$saveUser(data);
            resolve(data);
          },
          (errors) => {
            this.errors = { ...this.errors, ...errors };
            reject(errors);
          },
        );
      });
    },
    onContinue() {
      this.isSubmitting = true;
      this.errors = {};

      Promise.all([
        this.postProfile(),
        this.postVerification(),
      ])
        .then(() => {
          this.$emit('next');
        })
        .catch(() => {
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    onAvatarClick() {
      this.$refs.avatar.click();
    },
    uploadAvatar() {
      this.$delegatedUpload(
        this.$refs.avatar.files[0],
        () => {

        },
        data => {
          this.$post(
            '/profile/image/avatar',
            {
              uuid: data.uuid,
              key: data.key,
              extension: data.extension,
            },
            (data) => {
              this.$emit('update:user', new User(data));
              this.$saveUser(data);
            },
            (errors) => {
              errors.image?.forEach(msg => {
                this.$bvToast.toast(msg, {
                  autoHideDelay: 2000,
                  title: this.$t('general.error'),
                  solid: true,
                  variant: 'danger',
                  toaster: 'b-toaster-bottom-right',
                });
              });
            },
          );
        },
      );
    },
  },
};
</script>
