<template>
  <div
    class="tw-relative"
  >
    <button
      class="tw-relative tw-w-full tw-py-2 tw-pl-5 tw-pr-16 tw-text-left tw-border tw-rounded-2xl"
      :class="isOpen ? 'tw-bg-primary-50 tw-border-primary-500' : 'tw-bg-white tw-border-gray-200'"
      type="button"
      @click.prevent="onToggle"
    >
      <span class="tw-block">
        <slot name="button" />
      </span>

      <span class="tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-5 tw-pointer-events-none">
        <UiIcon
          class="h-6 w-6"
          :name="isOpen ? 'chevron-up' : 'chevron-down'"
        />
      </span>
    </button>

    <transition
      leave-active-class="tw-transition tw-duration-100 tw-ease-in"
      leave-class="tw-opacity-100"
      leave-to-class="tw-opacity-0"
    >
      <div
        v-if="isOpen"
        class="tw-z-dropdown tw-absolute tw-mt-2 tw-p-3 tw-w-full tw-overflow-auto tw-rounded-2xl tw-bg-white tw-border tw-border-gray-200 tw-shadow-[0_4px_60px_0_rgba(0,0,0,0.1)] md:tw-p-5"
      >
        <slot :open="open" />
      </div>
    </transition>
  </div>
</template>

<script>
import UiIcon from '@/components/ui/UiIcon.vue';

export default {
  components: {
    UiIcon,
  },
  props: {
    open: Boolean,
  },
  data() {
    return {
      dataOpen: false,
    };
  },
  computed: {
    isOpen() {
      if (this.open !== undefined) {
        return this.open
      }

      return this.dataOpen
    }
  },
  methods: {
    onToggle() {
      if (this.open !== undefined) {
        this.$emit('toggle', !this.open)
      }

      this.dataOpen = !this.dataOpen
    }
  },
};
</script>
