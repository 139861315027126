<template>
  <div>
    <label
      v-if="label"
      :for="name"
      class="tw-text-sm md:tw-text-lg md:tw-font-semibold tw-mb-3"
    >
      {{ label }}
    </label>

    <div class="tw-relative">
      <select
        :id="name"
        v-model="optionVal"
        class="
          tw-w-full tw-p-4 tw-pr-10 tw-flex tw-items-center tw-justify-between tw-bg-gray-50 tw-rounded-2xl tw-cursor-pointer tw-appearance-none
          focus:tw-bg-primary-500/[.08] focus:tw-ring-1 focus:tw-ring-primary-500 focus:tw-outline-none
        "
        :class="{ 'tw-text-gray-500': !currentOption || currentOption.disabled }"
        v-bind="$attrs"
      >
        <option
          v-for="(option) in computedOptions"
          :key="option.value"
          :disabled="option.disabled"
          :hidden="option.hidden"
          :selected="option.selected"
          :value="option.value"
        >
          {{ option.text }}
        </option>
      </select>

      <div class="tw-absolute tw-top-0 tw-right-4 tw-h-full tw-flex tw-items-center">
        <UiIcon
          name="chevron-down"
          solid
        />
      </div>
    </div>

    <UiFormErrors
      v-if="errors"
      :errors="errors[name]"
    />
  </div>
</template>

<script>
import UiIcon from '@/components/ui/UiIcon.vue';
import UiFormErrors from '@/components/ui/UiFormErrors.vue';

export default {
  components: {
    UiFormErrors,
    UiIcon,
  },
  props: {
    name: String,
    label: String,
    options: [Array, Object],
    value: [Number, String],
    errors: Object,
  },
  computed: {
    computedOptions() {
      if (!(this.options instanceof Array)) {
        return Object.keys(this.options)
          .map((key) => ({
            value: key,
            text: this.options[key],
          }));
      }

      return this.options;
    },
    currentOption() {
      return this.computedOptions.find((option) => this.value === option.value);
    },
    optionVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
};
</script>
