<template>
  <div
    class="tw-basis-0 tw-grow tw-pt-3 tw-font-semibold tw-text-center tw-cursor-pointer md:tw-text-lg"
    :class="active ? 'tw-text-primary-500' : 'tw-text-gray-500'"
    @click="$emit('click')"
  >
    <div class="tw-flex tw-items-center tw-justify-center">
      <UiIcon
        v-if="icon"
        class="tw-mr-2"
        :name="icon"
        solid
      />

      <slot />
    </div>

    <div
      class="tw-mt-3 tw-h-1 tw-rounded-full"
      :class="active ? 'tw-bg-primary-500' : 'tw-bg-gray-200'"
    />
  </div>
</template>

<script>
import UiIcon from '@/components/ui/UiIcon.vue';

export default {
  components: {
    UiIcon,
  },
  props: {
    active: Boolean,
    icon: String,
  },
};
</script>
