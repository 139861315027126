<template>
  <div
    :class="className({ variant })"
    @click="active = value"
  >
    <UiIcon
      v-if="icon"
      class="tw-shrink-0 tw-mr-2 tw-h-5 tw-w-5"
      :name="icon"
      solid
    />

    <slot />
  </div>
</template>

<script>
import { tv } from 'tailwind-variants';

import UiIcon from '@/components/ui/UiIcon.vue';

export default {
  components: {
    UiIcon,
  },
  props: {
    checked: [String, Number],
    value: [String, Number],
    variant: {
      type: String,
      required: true,
    },
    icon: String,
  },
  model: {
    prop: 'checked',
    event: 'change',
  },
  computed: {
    active: {
      get() {
        return this.checked === this.value;
      },
      set(value) {
        this.$emit('change', value);
      },
    },
    className() {
      return tv({
        base: 'tw-inline-flex tw-items-center tw-py-2 tw-px-6 tw-font-semibold tw-text-lg tw-leading-6 tw-whitespace-nowrap tw-border tw-rounded-full tw-cursor-pointer',
        variants: {
          variant: {
            primary: this.active ? 'tw-bg-primary-500 tw-text-white' : 'tw-text-primary-500 tw-border-primary-500',
            neutral: this.active ? 'tw-bg-primary-500 tw-text-white' : 'tw-bg-white tw-text-gray-500 tw-border-gray-200 hover:tw-bg-gray-50',
          },
        },
      })
    },
  },
};
</script>
