<template>
  <div class="tw-w-full">
    <div class="tw-flex tw-items-center">
      <div class="tw-grow tw-flex tw-items-center">
        <UiAvatar
          size="xs"
          :src="user.avatar_url"
          :text="user.initials"
          :to="user.url"
        />

        <div class="tw-ml-3 tw-flex tw-flex-col tw-items-start">
          <UiUsername
            :user="user"
          />
          <RouterLink
            class="tw-text-sm tw-text-gray-700"
            :to="user.url"
          >
            @{{ user.username }}
          </RouterLink>
        </div>
      </div>

      <UiDropdown>
        <UiDropdownItem
          icon="paper"
          @click="copyLink"
        >
          {{$t('general.copy-link-to-profile') }}
        </UiDropdownItem>
        <UiDropdownItem
          icon="edit-square"
          @click="addToList(user)"
        >
          {{ $t('general.add-to-list') }}
        </UiDropdownItem>
        <UiDropdownItem
          v-if="removable"
          icon="delete"
          @click="$emit('remove', user)"
        >
          {{ $t('general.remove-from-list') }}
        </UiDropdownItem>
      </UiDropdown>
    </div>
  </div>
</template>

<script>
import User from '@/components/models/User';

import UiAvatar from '@/components/ui/UiAvatar.vue';
import UiUsername from '@/components/ui/UiUsername.vue';
import UiDropdown from '@/components/ui/UiDropdown.vue';
import UiDropdownItem from '@/components/ui/UiDropdownItem.vue';

export default {
  components: {
    UiDropdownItem,
    UiDropdown,
    UiAvatar,
    UiUsername,
  },
  props: {
    user: User,
    removable: Boolean,
  },
  methods: {
    copyLink() {
      const link = `${process.env.VUE_APP_APP_URL}${this.user.url}`;
      this.$copyText(link);
      this.$bvToast.toast(this.$t('general.link-copied'), {
        autoHideDelay: 2000,
        title: this.$t('general.link'),
        solid: true,
        toaster: 'b-toaster-bottom-left',
      });
    },
    addToList(user) {
      this.$store.state.addToListUser = user;
    },
  },
};
</script>
