<template>
  <UiCard>
    <UiNavigationBar
      bleed
      :title="$t('general.lists')"
      @back="$router.go(-1)"
    />

    <div class="tw-space-y-3">
      <RouterLink
        v-for="(item, index) in lists"
        :key="index"
        class="tw-block tw-py-3 tw-px-6 tw-rounded-2xl tw-border tw-border-gray-200 hover:tw-bg-gray-50"
        :to="'/lists/' + item.id"
      >
        <div class="md:tw-text-lg">
          {{ item.title }}
        </div>

        <div class="tw-text-sm tw-text-gray-500 md:tw-text-base">
          {{ $tc('general.x-profiles', [item.listeesCount]) }}
        </div>
      </RouterLink>
    </div>
  </UiCard>
</template>

<script>
import List from '../models/List';
import UiCard from '@/components/ui/UiCard.vue';
import UiNavigationBar from '@/components/ui/UiNavigationBar.vue';

export default {
  components: { UiNavigationBar, UiCard },
  data: function () {
    return {
      lists: [],
    };
  },
  mounted() {
    this.loadLists();
  },
  methods: {
    loadLists() {
      let lists = [];
      this.$get(
        '/lists',
        (data) => {
          for (let l of data.lists) {
            lists.push(new List(l, this));
          }
          this.lists = lists;
        },
        (errors) => {
          console.log(errors);
        },
      );
    },
  },
};
</script>
