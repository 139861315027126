<template>
  <form @submit.prevent="submitForm">
    <UiLandingHeading
      class="tw-mb-6"
      :title="$t('general.password-forgot-title')"
      :message="$t('general.password-forgot-message')"
    />

    <UiFormInput
      type="text"
      name="email"
      v-model="email"
      :errors="errors"
      icon="message"
      :placeholder="$t('general.email')"
    />
    <UiFormErrors
      v-if="errors._ && errors._.length > 0"
      :errors="errors._"
    />

    <UiButton
      class="tw-w-full tw-mt-6"
      type="submit"
      variant="primary"
    >
      {{ $t('general.continue') }}
    </UiButton>
  </form>
</template>
<script>
import UiFormInput from '../ui/UiFormInput.vue';
import UiButton from '@/components/ui/UiButton.vue';
import UiLandingHeading from '@/components/ui/UiLandingHeading.vue';
import UiFormErrors from '@/components/ui/UiFormErrors.vue';

export default {
  components: { UiFormErrors, UiLandingHeading, UiButton, UiFormInput },
  data() {
    return {
      email: '',
      errors: {},
    };
  },
  methods: {
    submitForm() {
      this.errors = {};
      this.$post(
        '/auth/reset-password',
        {
          email: this.email,
        },
        () => {
          this.$toast({
            title: this.$t('general.success'),
            description: this.$t('general.password-reset-link-sent'),
            variant: 'success',
          })
        },
        (errors, errorResponse) => {
          if (errorResponse.response.status === 429) {
            this.$toast({
              title: this.$t('general.error'),
              description: this.$t('general.password-reset-link-too-many-requests'),
              variant: 'error',
            })
          }
          this.errors = errors;
        },
      );
    },
  },
};
</script>
