<template>
  <div class="tw-p-4 tw-rounded-2xl tw-bg-gray-50 md:tw-p-0 md:tw-bg-transparent">
    <div class="tw-mb-3 tw-pb-3 tw-font-semibold tw-text-xl tw-border-b tw-border-gray-200 md:tw-mb-4 md:tw-pb-4 md:tw-text-2xl">
      {{ $t('general.earnings') }}
    </div>

    <div class="tw-space-y-2">
      <div
        v-for="chart in charts"
        :key="chart.id"
        class="tw-p-2 tw-bg-gray-50 tw-rounded-xl"
      >
        <div class="tw-flex tw-items-baseline tw-gap-1 md:tw-flex-col">
          <div class="md:tw-text-lg">
            {{ $t(chart.label) }}
          </div>
          <div class="tw-font-semibold tw-text-xl tw-text-primary-500 md:tw-text-2xl">
            {{ $formatAmount(chart.total) }}
          </div>
        </div>

        <div class="tw-mt-2">
          <LineChart
            :id="chart.id"
            class="tw-overflow-hidden tw-rounded-lg"
            :chartData="chart.data"
            :chartOptions="options"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Line } from 'vue-chartjs';
import { Chart as ChartJS, CategoryScale, Filler, LineElement, LinearScale, PointElement } from 'chart.js';

ChartJS.register(CategoryScale, Filler, LineElement, LinearScale, PointElement);

export default {
  components: {
    LineChart: Line,
  },
  props: {
    stats: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      options: {
        responsive: true,
        aspectRatio: 5,
        layout: { padding: -8 },
        elements: {
          point: { radius: 0 },
          line: { borderWidth: 1 },
        },
        plugins: {
          legend: { display: false },
          tooltip: { enabled: false },
        },
        scales: {
          x: {
            title: { display: false },
            grid: { display: false, drawBorder: false },
            ticks: { display: false },
          },
          y: {
            title: { display: false },
            grid: { display: false, drawBorder: false },
            ticks: { display: false },
          },
        },
      },
      labels: {
        'total': 'general.total',
        'subscriptions': 'general.subscriptions',
        'tips': 'general.tips',
        'posts': 'general.posts',
        'messages': 'general.messages',
      },
      colors: [
        { border: 'rgb(36, 107, 253)', background: 'rgba(104, 127, 200, .25)' },
        { border: 'rgb(255, 77, 103)', background: 'rgba(255, 77, 103, .25)' },
        { border: 'rgb(251, 148, 0)', background: 'rgba(250, 237, 209, .25)' },
        { border: 'rgb(27, 172, 75)', background: 'rgba(210, 250, 209, .25)' },
        { border: 'rgb(190, 21, 250)', background: 'rgba(230, 209, 250, .25)' },
      ],
    };
  },
  computed: {
    charts() {
      const charts = this.stats.charts;
      const stats = this.stats.stats;

      return Object.entries(this.labels)
        .map(([key, value], index) => {
          const labels = Object.keys(charts[key]);
          const values = Object.values(charts[key]);
          const color = this.colors[index % this.colors.length];

          return {
            id: key,
            label: value,
            total: stats[key],
            data: {
              labels,
              datasets: [
                {
                  data: values,
                  fill: true,
                  tension: 0.5,
                  borderColor: color.border,
                  backgroundColor: color.background,
                },
              ],
            },
          };
        });
    },
  },
};
</script>
