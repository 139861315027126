<template>
  <div>
    <UiLandingHeading
      class="tw-mb-10"
      :title="$t('general.sign-up-title')"
    />

    <form
      @submit.prevent="submitForm"
    >
      <div class="tw-space-y-5">
        <UiFormInput
          v-model="name"
          :errors="errors"
          icon="profile"
          iconPosition="start"
          name="name"
          :placeholder="$t('general.name')"
          type="text"
        />

        <UiFormInput
          v-model="email"
          :errors="errors"
          icon="message"
          iconPosition="start"
          name="email"
          :placeholder="$t('general.email')"
          type="email"
        />

        <UiFormInput
          v-model="password"
          :errors="errors"
          icon="lock"
          iconPosition="start"
          name="password"
          :placeholder="$t('general.password')"
          type="password"
        />

        <UiFormSelect
          v-model="country"
          :errors="errors"
          name="country"
          :options="countries"
        />

        <UiFormInput
            v-model="affiliate"
            :errors="errors"
            icon="3-user"
            iconPosition="start"
            name="affiliate"
            :placeholder="$t('general.affiliate')"
            type="text"
        />

        <UiSeparator />

        <div class="tw-flex tw-flex-col tw-space-y-3">
          <p class="tw-font-semibold">
            {{ $t('general.signup-question') }}
          </p>
          <UiRadio
            v-model="accountType"
            :label="$t('general.signup-buy')"
            value="fan"
          />

          <UiRadio
            v-model="accountType"
            :label="$t('general.signup-sell')"
            value="creator"
          />
        </div>

        <UiSeparator />

        <UiFormErrors
          :errors="errors._"
        />

        <i18n
          path="general.signup-agree"
          tag="div"
        >
          <template v-slot:terms>
            <UiLink
              :href="$laravelUrl('terms-of-use')"
              target="_blank"
            >{{
                $t('general.terms-of-use')
              }}</UiLink>
          </template>
          <template v-slot:privacy>
            <UiLink
              :href="$laravelUrl('privacy-policy')"
              target="_blank"
            >{{
                $t('general.privacy-policy')
              }}</UiLink>
          </template>
        </i18n>
      </div>

      <UiButton
        class="tw-w-full tw-mt-6"
        type="submit"
        variant="primary"
      >
        {{ $t('general.signup') }}
      </UiButton>
    </form>

    <div class="tw-hidden">
      <div class="tw-flex tw-items-center tw-my-6">
        <span class="tw-basis-0 tw-grow tw-h-px tw-bg-gray-200" />
        <span class="tw-basis-0 tw-grow tw-px-4 tw-whitespace-nowrap tw-text-gray-700 tw-font-semibold">
        {{ $t('general.or-continue-with') }}
      </span>
        <span class="tw-basis-0 tw-grow tw-h-px tw-bg-gray-200" />
      </div>

      <UiGoogleButton class="tw-hidden" />
    </div>

    <div class="tw-mt-10 tw-text-center">
      <span class="tw-text-gray-500">
        {{ $t('general.already-have-an-account') }}
      </span>
      <UiLink
        class="tw-ml-4"
        to="/"
      >
        {{ $t('general.sign-in') }}
      </UiLink>
    </div>
  </div>
</template>
<script>
import UiFormInput from '@/components/ui/UiFormInput.vue';
import UiLink from '@/components/ui/UiLink.vue';
import UiGoogleButton from '@/components/ui/UiGoogleButton.vue';
import UiButton from '@/components/ui/UiButton.vue';
import UiLandingHeading from '@/components/ui/UiLandingHeading.vue';
import UiFormErrors from '@/components/ui/UiFormErrors.vue';
import UiFormSelect from '@/components/ui/UiFormSelect.vue';

import Countries from '@/components/helpers/Countries';
import UiRadio from "@/components/ui/UiRadio.vue";
import UiSeparator from "@/components/ui/UiSeparator.vue";

export default {
  components: {
    UiSeparator,
    UiRadio,
    UiFormSelect,
    UiFormErrors,
    UiLandingHeading,
    UiButton,
    UiGoogleButton,
    UiLink,
    UiFormInput,
  },
  data() {
    return {
      accountType: null,
      name: '',
      email: '',
      password: '',
      affiliate: '',
      country: null,
      errors: {},
    };
  },
  computed: {
    url() {
      return process.env.VUE_APP_APP_URL;
    },
    countries() {
      let countries = [{
        value: null,
        text: this.$t('general.country'),
        disabled: true,
        selected: true,
        hidden: true,
      }];
      for (let k in Countries) {
        countries.push({
          value: k,
          text: Countries[k],
        });
      }
      return countries;
    },
  },
  mounted() {
    if (this.$route.query.a) {
      this.affiliate = this.$route.query.a;
    }
  },
  methods: {
    submitForm() {
      if (null === this.accountType) {
        this.errors = {
          _: [this.$t('general.signup-question-validation')]
        };

        return;
      }

      this.errors = {};
      this.$post(
        '/auth/signup',
        {
          email: this.email,
          password: this.password,
          name: this.name,
          country: this.country,
          affiliate: this.affiliate,
          account_type: this.accountType
        },
        (data) => {
          this.$saveToken(data.token);
          this.$saveUser(data.user);

          this.$redirector().redirect('/');
        },
        (errors, errorResponse) => {
          if (errorResponse.response.status === 429) {
            this.$toast({
              title: this.$t('general.error'),
              description: this.$t('general.too-many-requests'),
              variant: 'error',
            })
          }

          this.errors = errors;
        },
      );
    },
  },
};
</script>
