<template>
  <div>
    <AppSuggestions />
    <div class="tw-mt-10 tw-text-xs tw-text-center tw-z-50">
      <UiLink
          target="_blank"
          :href="$laravelUrl('privacy-policy')"
      >
        {{ $t('general.privacy-policy') }}
      </UiLink>
      <UiLink
          class="tw-ml-4"
          target="_blank"
          :href="$laravelUrl('cookie')"
      >
        {{ $t('general.cookie') }}
      </UiLink>
      <UiLink
          class="tw-ml-4"
          target="_blank"
          :href="$laravelUrl('terms-of-use')"
      >
        {{ $t('general.terms-of-use') }}
      </UiLink>
    </div>
  </div>
</template>

<script>
import AppSuggestions from '@/components/layout/AppSuggestions.vue';
import UiLink from "../ui/UiLink";

export default {
  components: {
    AppSuggestions,
    UiLink
  },
};
</script>
