<template>
  <div :class="method !== null ? '' : 'tw-text-center tw-my-5'">
    <b-spinner
      v-if="loading"
      variant="secondary"
    />
    <div
      ref="shift4"
      :key="reloadKey"
    />
  </div>
</template>
<script>
export default {
  props: ['amount', 'message', 'method'],
  data: function () {
    return {
      loading: true,
      elements: null,
      shift4: null,
      reloadKey: 0,
    };
  },
  watch: {
    method() {
      this.loading = true;
      this.forceRerender();
    }
  },
  mounted() {
    this.$loadScript('https://js.dev.shift4.com/shift4.js').then(() => {
      this.loadElement();
    });
  },
  computed: {
    item() {
      return this.$store.state.buyItem;
    },
  },
  methods: {
    async forceRerender() {
      this.reloadKey += 1;
      await this.$nextTick();
      this.loadElement();
    },
    loadElement() {
      // Initialize Shift4 object with your public key
      this.shift4 = window.Shift4(process.env.VUE_APP_SHIFT4_PUBLIC_KEY);
      if (!this.method?.gateway_token) {
        this.elements = this.shift4.createComponent('card');
        this.elements.mount(this.$refs.shift4);
        this.elements.focus();
      }
      this.loading = false;
    },
    onError(error) {
      this.forceRerender();
      if (error instanceof Object && error['message']) {
        this.$emit(
            'error',
            error['message'],
        );
      }
    },
    onSuccess(token) {
      this.forceRerender();
      this.$emit(
          'token',
          '****' + token.last4,
          token.id,
      );
    },
    save() {
      var elements = this.elements;
      var amountCents = this.amount ? this.amount * 100 : this.amount;
      var request = {
        amount: Math.ceil(amountCents),
        currency: process.env.VUE_APP_CURRENCY,
      }

      if (this.method?.gateway_token) {
        this.shift4.verifyThreeDSecure(Object.assign(request, { card: this.method.gateway_token }))
          .then(this.onSuccess)
          .catch(this.onError);
      } else {
        this.shift4.createToken(elements)
          .then(token => {
            return this.shift4.verifyThreeDSecure(Object.assign(request, { card: token.id }));
          })
          .then(this.onSuccess)
          .catch(this.onError);
      }
    },
  },
};
</script>
