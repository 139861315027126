<template>
  <div
    class="tw-z-header tw-fixed tw-inset-0 tw-inset-x-[max(0px,calc(50%-45rem))] tw-flex md:tw-pb-10 md:tw-px-8 md:tw-top-[--top]"
    :class="{ 'tw-pb-16': !showChatId }"
  >
    <!-- Left side -->
    <div
      class="tw-h-full tw-w-full tw-max-w-full lg:tw-w-auto"
      :class="{ 'tw-hidden lg:tw-block': showChatId !== null || isComposing }"
    >
      <div class="tw-h-full tw-w-full lg:tw-w-[26rem] lg:tw-max-w-full">
        <UiCard class="tw-h-full tw-flex tw-flex-col">
          <UiNavigationBar
            bleed
            :title="$t('general.messages')"
          >
            <RouterLink
              v-if="currentUser.isCreator"
              to="/messages/compose"
            >
              <UiIcon
                name="plus"
              />
            </RouterLink>
          </UiNavigationBar>

          <div class="tw-flex tw-pb-3">
            <UiTabItem
              :active="activeTab === 'all'"
              @click="activeTab = 'all'"
            >
              {{ $t('general.all') }}
            </UiTabItem>
            <UiTabItem
              :active="activeTab === 'unread'"
              @click="activeTab = 'unread'"
            >
              {{ $t('general.unread') }}
            </UiTabItem>
            <!--
            <UiTabItem
              :active="false"
              @click="updateData(tabs.chargebacks)"
            >
              Starred
            </UiTabItem>
            -->
          </div>

          <div
            v-if="mass"
            class="tw-mb-4"
          >
            {{
              mass.recipients_count > 0
                ? mass.mass_complete
                  ? $t('general.mass-message-complete', [
                    mass.messageTrimmed,
                    mass.recipients_count,
                  ])
                  : $t('general.mass-message-sending', [
                    mass.messageTrimmed,
                    mass.recipients_count,
                  ])
                : $t('general.mass-message-started', [mass.messageTrimmed])
            }}
          </div>

          <!-- Messages list -->
          <div
            ref="conversations"
            class="tw-overflow-auto tw-space-y-3"
          >
            <RouterLink
              v-for="chat in chats"
              :key="chat.id"
              class="tw-block tw-p-2 tw-rounded-2xl md:tw-p-3"
              :class="$route.path === `/messages/${chat.party.username}` ? 'tw-bg-primary-100' : 'hover:tw-bg-gray-50'"
              :to="`/messages/${chat.party.username}`"
              @click.native="chat.message.isRead = true"
            >
              <div class="tw-group tw-flex tw-items-center tw-relative tw-cursor-pointer tw-w-full tw-text-sm md:tw-text-base">
                <UiAvatar
                  class="tw-shrink-0"
                  :text="chat.party.initials"
                  :src="chat.party.avatar_url"
                  size="sm"
                />

                <div class="tw-grow tw-flex tw-ml-3 tw-truncate md:tw-ml-4">
                  <div class="tw-grow tw-mr-4 tw-truncate">
                    <div class="tw-font-semibold">
                      {{ chat.party.name }}
                    </div>
                    <div class="tw-mt-1 tw-text-gray-500 tw-truncate">
                      {{ chat.message.message }}
                    </div>
                  </div>

                  <div class="tw-flex tw-flex-col tw-items-end tw-justify-between">
                    <div class="tw-flex tw-items-center">
                      <div
                        v-if="!chat.message.isRead && !isOwner(chat.message)"
                        class="tw-my-1 tw-w-3 tw-h-3 tw-bg-primary-500 tw-rounded-full"
                      />
                      <UiIcon
                        class="tw-h-4 tw-cursor-pointer"
                        name="delete"
                        @click.native.prevent="conversationToRemove = chat.party.username"
                      />
                    </div>


                    <div class="tw-mt-auto tw-text-gray-500">
                      {{
                        chat.message.timeRelated
                          ? $t(chat.message.timeRelated)
                          : chat.message.timeNormal
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </RouterLink>

            <div v-if="isLoading">
              <div
                v-for="i in 3"
                :key="i"
                class="tw-flex tw-items-center tw-p-2 md:tw-p-3"
              >
                <UiSkeleton
                  class="tw-size-16"
                  rounded="full"
                />
                <div class="tw-ml-3 tw-space-y-3 md:tw-ml-4">
                  <UiSkeleton class="tw-h-3 tw-w-24" />
                  <UiSkeleton class="tw-h-3 tw-w-40" />
                </div>
              </div>
            </div>
          </div>
        </UiCard>
      </div>
    </div>

    <div
      class="tw-grow tw-h-full lg:tw-pl-6"
      :class="{ 'tw-hidden lg:tw-block': showChatId === null && !isComposing }"
    >
      <div class="tw-h-full">
        <UiCard class="tw-h-full">
          <div
            v-if="showChatId === null && !isComposing"
            class="tw-h-full tw-flex tw-flex-col tw-items-center tw-justify-center"
          >
            <img
              alt=""
              class="tw-block tw-w-full tw-max-w-[16rem]"
              :src="require('@/assets/illustrations/compose.svg')"
            >
            <div class="tw-mt-6">
              {{ $t('general.compose-message') }}
            </div>
          </div>

          <PageChat
            v-if="showChatId !== null"
            ref="chat"
            v-model="chats"
            :chatId="showChatId"
            @delete="conversationToRemove = showChatId"
            @block="removeChat"
          />

          <PageMassMessage
            v-if="isComposing"
            v-model="mass"
          />
        </UiCard>
      </div>
    </div>

    <UiAlertModal
      v-if="conversationToRemove !== null"
      :title="$t('message.remove-confirmation.header', { name: conversationToRemove })"
      :message="$t('message.remove-confirmation.content')"
      :cancel="$t('general.cancel')"
      :confirm="$t('general.yes-delete')"
      @cancel="conversationToRemove = null"
      @confirm="onDelete"
    />
  </div>
</template>

<script>
import Message from '@/components/models/Message';
import User from '@/components/models/User';

import PageChat from '@/components/pages/PageChat.vue';
import PageMassMessage from '@/components/pages/PageMassMessage.vue';

import UiAlertModal from '@/components/ui/UiAlertModal.vue';
import UiAvatar from '@/components/ui/UiAvatar.vue';
import UiCard from '@/components/ui/UiCard.vue';
import UiIcon from '@/components/ui/UiIcon.vue';
import UiNavigationBar from '@/components/ui/UiNavigationBar.vue';
import UiSkeleton from '@/components/ui/UiSkeleton.vue';
import UiTabItem from '@/components/ui/UiTabItem.vue';

export default {
  components: {
    PageChat,
    PageMassMessage,
    UiAlertModal,
    UiAvatar,
    UiCard,
    UiIcon,
    UiNavigationBar,
    UiSkeleton,
    UiTabItem,
  },
  data: function () {
    return {
      page: 1,
      hasMore: false,
      isLoading: false,
      activeTab: 'all',
      chats: [],
      mass: null,
      showChatId: null,
      doCompose: false,
      conversationToRemove: null,
    };
  },
  computed: {
    chatId() {
      return this.$route.params.username && this.$route.params.username !== 'compose'
        ? this.$route.params.username
        : null;
    },
    isComposing() {
      return this.$route.params.username === 'compose';
    },
    pusher() {
      return this.$store.state.pusher;
    },
    currentUser() {
      return this.$store.state.currentUser;
    },
  },
  watch: {
    activeTab() {
      this.chats = [];
      this.page = 1;
      this.loadChats();
    },
    chatId(v) {
      this.showChatId = v;
    },
    pusher(oldVal, newVal) {
      // Pusher has been weirdly integrated.
      // This avoid registering twice the event handlers
      // To be fixed later on.
      if (newVal !== null) {
        this.listen();
      }
    },
  },
  mounted() {
    this.loadChats();
    this.listen();
    this.$refs.conversations.addEventListener('scroll', this.updateScroll);
  },
  beforeDestroy() {
    this.unlisten();
    this.$refs.conversations.addEventListener('scroll', this.updateScroll);
  },
  methods: {
    updateScroll() {
      if (
        this.$refs.conversations &&
        this.$refs.conversations.scrollHeight - this.$refs.conversations.clientHeight - this.$refs.conversations.scrollTop <= 500 &&
        !this.isLoading &&
        this.hasMore
      ) {
        this.loadMore();
      }
    },
    listen() {
      if (this.pusher) {
        this.pusher.bind('message', this.handleNewMessage);
        this.pusher.bind('message.read', this.handleMessageRead);
        this.pusher.bind(
          'message.mass.complete',
          this.handleMassMessageComplete,
        );
      }
    },
    unlisten() {
      if (this.pusher) {
        this.pusher.unbind('message', this.handleNewMessage);
        this.pusher.unbind('message.read', this.handleMessageRead);
        this.pusher.unbind(
          'message.mass.complete',
          this.handleMassMessageComplete,
        );
      }
    },
    handleNewMessage(data) {
      const newMessage = new Message(data.message);

      if (newMessage.user.username == this.showChatId) {
        this.$refs.chat.reloadFirst();
      } else {
        let valid = [];
        let chats = [...this.chats];
        let found = false;
        for (let c of chats) {
          if (c.party.id == newMessage.user.id) {
            c.message = newMessage;
            found = true;
            valid.unshift(c);
          } else {
            valid.push(c);
          }
        }
        if (!found) {
          valid.unshift({
            party: newMessage.user,
            message: newMessage,
          });
        }

        this.chats = valid;
      }
    },
    handleMessageRead(data) {
      if (data.username == this.showChatId) {
        this.$refs.chat.readMessages();
      }
    },
    handleMassMessageComplete(data) {
      const m = new Message(data.message);
      m.recipients_count = data.message.recipients_count;
      m.mass_complete = true;
      this.mass = m;
    },
    isOwner(message) {
      return message.user.id == this.$store.state.currentUser.id;
    },
    loadMore() {
      if (this.hasMore) {
        this.page = this.page + 1;
        this.loadChats();
      }
    },
    onDelete() {
      this.cleanChat(this.conversationToRemove);
      this.removeChat(this.conversationToRemove);
    },
    removeChat(username) {
      const index = this.chats.findIndex(chat => chat.party.username === username);
      this.chats.splice(index, 1);

      if (this.$route.params.username === this.conversationToRemove) {
        this.$router.replace('/messages');
      }

      this.conversationToRemove = null;
    },
    cleanChat(username) {
      this.$post(
        '/messages/' + username,
        {
          _method: 'DELETE',
        },
        () => {
        },
        (errors) => {
          console.log(errors);
        },
      );
    },
    loadChats() {
      this.isLoading = true;

      this.$get(
        '/messages?filter=' + this.activeTab + '&page=' + this.page,
        (data) => {
          // let chats = [];
          for (let obj of data.chats.data) {
            this.chats.push({
              party: new User(obj.party),
              message: new Message(obj),
            });
          }
          this.isLoading = false;
          this.hasMore = data.chats.next_page_url !== null;
          if (data.mass) {
            this.mass = new Message(data.mass);
            this.mass.recipients_count = data.mass.recipients_count;
          }
          this.$nextTick(function () {
            this.showChatId = this.chatId;
          });
        },
        (errors, err) => {
          console.log(errors, err);
        },
      );
    },
  },
};
</script>
