<template>
  <component
    :is="to ? 'RouterLink' : 'div'"
    class="tw-flex tw-items-center tw-justify-between tw-p-2 tw-rounded-2xl tw-cursor-pointer"
    :class="active ? 'tw-bg-primary-100' : 'hover:tw-bg-gray-50'"
    :to="to"
    @click="$emit('click')"
  >
    <div class="tw-flex tw-items-center tw-truncate">
      <div :class="badgeClassName({ color, active })">
        <UiIcon
          :gradient="color"
          :name="icon"
          solid
        />
      </div>

      <div class="tw-ml-6 tw-font-semibold tw-truncate">
        <slot />
      </div>
    </div>

    <div
      v-if="to"
      class="tw-ml-4"
    >
      <UiIcon
        name="chevron-right"
      />
    </div>
  </component>
</template>

<script>
import { tv } from 'tailwind-variants';
import UiIcon from '@/components/ui/UiIcon.vue';

export default {
  components: {
    UiIcon,
  },
  props: {
    color: String,
    icon: String,
    to: [String, Object],
  },
  computed: {
    active() {
      return this.$route.path === this.to;
    },
    badgeClassName() {
      return tv({
        base: 'tw-shrink-0 tw-p-4 tw-rounded-full md:tw-p-3',
        variants: {
          color: {
            primary: 'tw-bg-primary-50',
            success: 'tw-bg-success-50',
            info: 'tw-bg-info-50',
            'info-alt': 'tw-bg-info-50',
            warning: 'tw-bg-warning-50',
          },
          active: {
            true: 'tw-bg-white',
          },
        },
      });
    },
  },
};
</script>
