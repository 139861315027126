<template>
  <div>
    <UiPosts
      v-if="isLoading || posts.length > 0"
      v-model="posts"
      :loading="isLoading"
      :sizes="{ lg: '55vw', xl: '38vw' }"
    />

    <UiCard
     v-else
    >
      <div class="tw-p-8 tw-text-center tw-flex tw-flex-col tw-items-center">
        <p class="font-weight-bold tw-pb-4">
          {{ $t('empty-feed.heading') }}
        </p>
        <p class="tw-pb-8">
          {{ $t('empty-feed.text') }}
        </p>

        <img class="md:tw-w-1/2" :src="emptyFeed">

        <UiButton
          variant="primary"
          class="tw-px-10 tw-mt-10"
          @click="$router.push('/creators')"
        >
          {{ $t('empty-feed.cta') }}
        </UiButton>
      </div>
    </UiCard>
  </div>
</template>
<script>
import Post from '@/components/models/Post';

import UiPosts from '@/components/ui/UiPosts.vue';
import UiSkeleton from "@/components/ui/UiSkeleton.vue";
import UiCard from "@/components/ui/UiCard.vue";
import UiButton from "@/components/ui/UiButton.vue";

export default {
  components: {
    UiButton,
    UiCard,
    UiSkeleton,
    UiPosts,
  },
  data: function () {
    return {
      posts: [],
      page: 1,
      hasMore: false,
      isLoading: false,
    };
  },
  computed: {
    emptyFeed() {
      return require('@/assets/illustrations/empty-feed.png');
    },
  },
  mounted() {
    this.loadPosts();
    window.addEventListener('scroll', this.updateScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.updateScroll);
  },
  methods: {
    updateScroll() {
      const scrollPosition = window.innerHeight + window.scrollY;
      if (
        document.body.offsetHeight &&
        scrollPosition &&
        document.body.offsetHeight - scrollPosition <= 1000 &&
        !this.isLoading &&
        this.hasMore
      ) {
        this.loadMore();
      }
    },
    loadPosts() {
      this.isLoading = true;
      this.$get(
        '/posts?page=' + this.page,
        (data) => {
          let posts = [...this.posts];
          for (let obj of data.data) {
            posts.push(new Post(obj));
          }
          this.posts = posts;
          this.hasMore = data.next_page_url != null;
          this.isLoading = false;
        },
        (errors) => {
          console.log(errors);
        },
      );
    },
    loadMore() {
      if (this.hasMore) {
        this.page = this.page + 1;
        this.loadPosts();
      }
    },
  },
};
</script>
