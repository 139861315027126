<template>
  <div
    class="tw-flex tw-items-end tw-gap-4 tw-p-4 tw-rounded-2xl tw-break-words"
    :class="messageClass"
  >
    <div class="tw-w-full">
      <slot />
    </div>

    <div class="tw-flex tw-items-center">
      <span
        class="tw-whitespace-nowrap"
        :class="{ 'tw-text-gray-600': !author }"
      >
        {{ message.time }}
      </span>

      <UiIcon
        v-if="author"
        class="tw-ml-1"
        :class="{ 'tw-opacity-30': !message.isRead }"
        name="ticks"
      />
    </div>
  </div>
</template>

<script>
import Message from '@/components/models/Message';
import UiIcon from '@/components/ui/UiIcon.vue';

export default {
  components: { UiIcon },
  props: {
    author: Boolean,
    notice: Boolean,
    message: Message,
  },
  computed: {
    messageClass() {
      if (this.notice) {
        return 'tw-bg-gray-50 tw-rounded-tl-none tw-border-primary-500 tw-border'
      }

      return this.author ? 'tw-text-white tw-bg-gradient-to-r tw-from-primary-400 tw-to-primary-500 tw-rounded-tr-none' : 'tw-bg-gray-50 tw-rounded-tl-none'
    }
  }
};
</script>
