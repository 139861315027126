<template>
  <div
    class="tw-my-1.5 tw-flex tw-flex-col md:tw-my-2"
    :class="isAuthor ? 'tw-items-end' : 'tw-items-start'"
  >
    <div class="tw-w-full tw-max-w-xs md:tw-max-w-md">
      <div
        class="tw-w-full tw-flex tw-flex-col tw-space-y-3 md:tw-space-y-4"
        :class="isAuthor ? 'tw-items-end' : 'tw-items-start'"
      >
        <UiMessageItem
          v-if="media.length > 0 && message.hasAccess"
          :author="isAuthor"
          class="tw-flex-col tw-self-stretch"
          :message="message"
        >
          <div class="tw-flex tw-flex-col tw-gap-1 md:tw-flex-row md:tw-gap-2">
            <div
              v-for="(item, index) in media"
              :key="item.id"
              class="tw-basis-0 tw-grow tw-cursor-pointer"
              @click="$showPhotoSwipe(message.media, 0)"
            >
              <div
                class="tw-relative tw-h-0 tw-rounded-xl tw-overflow-hidden"
                :class="{
                  'tw-pb-[100%]': message.media.length === 1,
                  'tw-pb-[50%] md:tw-pb-[100%]': message.media.length > 1,
                  'tw-rounded-b-none md:tw-rounded-xl': message.media.length === 2 && index === 0,
                  'tw-rounded-t-none md:tw-rounded-xl': message.media.length === 2 && index === message.media.length - 1,
                }"
              >
                <UiImage
                  v-if="item.type >= 0"
                  class="tw-absolute tw-inset-0 tw-h-full tw-w-full tw-object-cover tw-overflow-hidden"
                  :src="item.type === 0 ? item.url : item.screenshot"
                  :srcset="item.type === 0 ? item.signed_urls : null"
                  :sizes="
                    item.type === 0
                      ? { initial: '75vw', sm: '44vw', md: '54vw', lg: '40vw', xl: '32vw', '2xl': '27vw' }
                      : null
                  "
                />
                <div class="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center">
                  <span
                    v-if="item.type < 0"
                    class="tw-text-4xl"
                  >
                    {{ '+' + (message.media.length - media.length + 1) }}
                  </span>
                  <UiIcon
                    v-if="item.type === 1"
                    class="tw-h-16 tw-w-16"
                    name="play"
                    solid
                  />
                </div>
              </div>
            </div>
          </div>
        </UiMessageItem>

        <UiMessageItem
          v-else-if="!message.hasAccess && message.media.length > 0"
          :author="isAuthor"
          class="tw-flex-col tw-self-stretch"
          :message="message"
        >
          <UiLockedMedia
            :images="message.images"
            :price="message.priceFormatted"
            :videos="message.videos"
            @unlock="unlock"
          />
        </UiMessageItem>

        <UiMessageItem
          v-if="message.price && (fanHasPaid || isAuthor)"
          :message="message"
          :author="isAuthor"
          :notice="true"
        >
          <span v-if="isAuthor">
             <span v-if="fanHasPaid">
              {{ $t('general.notice-paid-content-confirm-creator', { amount: message.baseAmountFormatted }) }}
            </span>
            <span v-else>
              {{ $t('general.notice-paid-content', { amount: message.baseAmountFormatted }) }}
            </span>
          </span>

          <span v-else-if="fanHasPaid">
            {{ $t('general.notice-paid-content-confirm-fan', { amount: message.baseAmountFormatted }) }}
          </span>

        </UiMessageItem>

        <UiMessageItem
          v-if="message.items.payment && message.price === null"
          :author="isAuthor"
          :message="message"
        >
          <i18n
            :path="
              'general.' +
              (message.items.payment.items.post
                ? isAuthor
                  ? 'tip-message-post-out'
                  : 'tip-message-post-in'
                : 'tip-message-tip')
            "
            tag="div"
          >
            <template v-slot:amount>
              {{ $formatAmount(message.items.payment.amount) }}
            </template>
            <template
              v-if="message.items.payment.items.post"
              v-slot:post
            >
              <b-link :to="message.items.payment.items.post.url">
                {{ $t('general.post') }}
              </b-link>
            </template>
          </i18n>
        </UiMessageItem>

        <UiMessageItem
          v-if="message.message"
          :author="isAuthor"
          :message="message"
        >
          {{ message.message }}
        </UiMessageItem>
      </div>
    </div>
  </div>
</template>

<script>
import Message from '../models/Message';
import Payment from '../models/Payment';

import UiIcon from '@/components/ui/UiIcon.vue';
import UiLockedMedia from '@/components/ui/UiLockedMedia.vue';
import UiMessageItem from '@/components/ui/UiMessageItem.vue';
import UiImage from '@/components/ui/UiImage.vue';

export default {
  components: {
    UiImage,
    UiIcon,
    UiLockedMedia,
    UiMessageItem,
  },
  props: ['value'],
  computed: {
    fanHasPaid() {
      return this.message.items.payment?.type === Payment.TYPE_MESSAGE
    },
    message: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    isAuthor() {
      return this.message.user.id === this.$store.state.currentUser.id;
    },
    media() {
      let m = [];
      const l = this.message.media.length;
      if (l > 4) {
        m = this.message.media.slice(0, 3);
        m.push({
          type: -1,
        });
      } else if (l == 3) {
        m = this.message.media.slice(0, 1);
        m.push({
          type: -1,
        });
      } else {
        m = this.message.media;
      }
      return m;
    },
  },
  methods: {
    unlock() {
      this.$buyItem({
        type: Payment.TYPE_MESSAGE,
        message: this.message,
        success: (data) => {
          this.message = new Message(data);
        },
      });
    },
  },
};
</script>
