import dayjs from 'dayjs';

const duration = require('dayjs/plugin/duration');
const localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(duration);
dayjs.extend(localizedFormat);

export default class Media {

  static TYPE_IMAGE = 0;
  static TYPE_VIDEO = 1;

  id = 0;
  type = Media.TYPE_IMAGE;
  created_at = dayjs();
  url = '';
  signed_urls = '';
  screenshot = '';
  player = '';
  duration = null;

  constructor(data) {
    if (!data) {
      return;
    }

    this.init(data);
  }

  init(data) {
    this.id = data.id;
    this.type = data.type;
    this.created_at = dayjs(data.created_at);
    this.url = data.url;
    this.player = data.player;
    this.signed_urls = data.signed_urls;
    this.screenshot = data.screenshot;
    this.duration = data.duration ? dayjs.duration(data.duration * 1000).format('mm:ss') : null;
  }

}
