<template>
  <div
    class="tw-h-6 tw-w-6 tw-flex tw-items-center tw-justify-center tw-border-[3px] tw-border-primary-500 tw-rounded-lg tw-cursor-pointer"
    :class="{ 'tw-bg-primary-500': active }"
    @click="active = !active"
  >
    <svg
      v-if="active"
      class="tw-text-white tw-h-3 tw-w-3"
      viewBox="0 0 13 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 5.00002L5 8.00002L11 2.00002"
        stroke="currentColor"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    checked: [Boolean, Array],
    value: String,
  },
  model: {
    prop: 'checked',
    event: 'change',
  },
  computed: {
    active: {
      get() {
        if (this.checked instanceof Array) {
          return this.checked.includes(this.value);
        }

        return this.checked;
      },
      set(active) {
        if (this.checked instanceof Array) {
          let newValue = [...this.checked];

          if (active) {
            newValue.push(this.value);
          } else {
            newValue.splice(newValue.indexOf(this.value), 1);
          }

          this.$emit('change', newValue);
        } else {
          this.$emit('change', active);
        }
      },
    },
  },
};
</script>
