<template>
  <div :class="{ 'tw-relative tw-pb-[100%]': squared }">
    <!-- Locked media -->
    <UiLockedMedia
      v-if="locked"
      class="tw-h-full"
      :class="{ 'tw-absolute tw-inset-0 tw-h-full tw-w-full': squared }"
      :images="post.images"
      :price="post.price && post.priceFormatted"
      :videos="post.videos"
      @unlock="unlock"
    />

    <!-- Media -->
    <div
      v-else
      class="tw-flex tw-items-start tw-h-full tw-overflow-hidden tw-text-center"
      :class="squared ? 'tw-absolute tw-inset-0 tw-h-full tw-w-full' : 'tw-relative'"
    >
      <div
        v-if="post.media.length > 1"
        ref="swiper"
        class="swiper tw-h-full tw-w-full"
      >
        <!-- Controls -->
        <div
          v-if="swiper !== null"
          class="
            tw-absolute tw-top-1/2 tw-inset-x-0 -tw-translate-y-1/2 tw-z-[2]
            tw-px-6 tw-flex tw-items-center tw-pointer-events-none
          "
        >
          <button
            v-if="!swiper.isBeginning"
            class="tw-p-2 tw-bg-white tw-rounded-full tw-pointer-events-auto"
            @click="swiper.slidePrev()"
          >
            <UiIcon
              name="arrow-left"
            />
          </button>
          <button
            v-if="!swiper.isEnd"
            class="tw-ml-auto tw-p-2 tw-bg-white tw-rounded-full tw-pointer-events-auto"
            @click="swiper.slideNext()"
          >
            <UiIcon
              name="arrow-right"
            />
          </button>
        </div>

        <!-- Slides -->
        <div
          class="swiper-wrapper"
          :class="{ 'tw-items-center': !squared }"
        >
          <div
            v-for="(item, key) in post.media"
            :key="key"
            class="swiper-slide tw-w-full tw-flex tw-flex-none tw-h-[unset] tw-rounded-2xl tw-overflow-hidden"
            :class="{ 'tw-self-stretch': !squared && item.type === 1 }"
          >
            <UiImage
              v-if="item.type === 0"
              class="tw-relative tw-inline-block tw-w-full tw-object-cover tw-cursor-pointer tw-z-[1]"
              :src="item.url"
              :srcset="item.signed_urls"
              :sizes="sizes"
              @click.prevent="$showPhotoSwipe(post.media, item.id)"
            />

            <div
              v-else-if="item.type === 1"
              class="
                tw-relative tw-inset-0 tw-h-full tw-w-full tw-pb-[100%]
                tw-flex tw-flex-col tw-items-center tw-justify-center
                tw-bg-black tw-overflow-hidden tw-z-[1]
              "
            >
              <iframe
                :src="item.player"
                class="tw-absolute tw-inset-0 tw-h-full tw-w-full"
                width="100%"
                height="100%"
                frameborder="0"
                scrolling="no"
                allowfullscreen="true"
              >
              </iframe>
            </div>
          </div>
        </div>
      </div>

      <div
        v-else
        class="tw-h-full tw-w-full"
      >
        <UiImage
          v-if="post.media[0].type === 0"
          class="tw-relative tw-inline-block tw-w-full tw-object-cover tw-rounded-2xl tw-cursor-pointer tw-z-[1]"
          :class="{ 'tw-h-full': squared }"
          :sizes="sizes"
          :src="post.media[0].url"
          :srcset="post.media[0].signed_urls"
          @click.prevent="$showPhotoSwipe(post.media, 0)"
        />

        <div
          v-else-if="post.media[0].type === 1"
          class="
            tw-relative tw-inset-0 tw-h-full tw-w-full tw-pb-[100%]
            tw-flex tw-flex-col tw-items-center tw-justify-center
            tw-bg-black tw-rounded-2xl tw-overflow-hidden tw-z-[1]
          "
        >
          <iframe
            :src="post.media[0].player"
            class="tw-absolute tw-inset-0 tw-h-full tw-w-full"
            width="100%"
            height="100%"
            frameborder="0"
            scrolling="no"
            allowfullscreen="true"
          >
          </iframe>
        </div>
      </div>

      <!-- Pagination dots -->
      <div
        v-if="post.media.length > 1"
        class="tw-absolute tw-bottom-10 tw-left-1/2 -tw-translate-x-1/2 tw-flex tw-items-center tw-justify-center tw-space-x-3 tw-z-[1]"
      >
        <span
          v-for="(media, index) in post.media"
          :key="media.id"
          class="tw-h-2 tw-w-2 tw-rounded-full"
          :class="index + 1 === slide ? 'tw-bg-gradient-to-r tw-from-primary-400 tw-to-primary-500' : 'tw-bg-gray-200'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import 'swiper/swiper-bundle.css';
import 'video.js/dist/video-js.css';

import { Swiper } from 'swiper';
import videojs from 'video.js';

import Post from '@/components/models/Post';
import UiLockedMedia from '@/components/ui/UiLockedMedia.vue';
import Payment from '@/components/models/Payment';
import UiIcon from '@/components/ui/UiIcon.vue';
import UiImage from '@/components/ui/UiImage.vue';

export default {
  components: {
    UiImage,
    UiIcon,
    UiLockedMedia,
  },
  props: {
    value: Post,
    locked: Boolean,
    sizes: [String, Object],
    squared: Boolean,
  },
  data() {
    return {
      slide: 1,
      players: [],
      swiper: null,
    };
  },
  computed: {
    post: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      var that = this;
      if (that.$refs.swiper) {
        this.swiper = new Swiper(that.$refs.swiper, {
          // Optional parameters
          direction: 'horizontal',
          noSwipingClass: 'vjs-control',
        });
        this.swiper.on('activeIndexChange', function () {
          that.slide = this.activeIndex + 1;
        });
        this.swiper.on('slideChange', function () {
          for (let p of that.players) {
            p.pause();
          }
        });
      }
      if (that.$refs.video) {
        if (Array.isArray(that.$refs.video)) {
          for (let v of that.$refs.video) {
            that.players.push(videojs(v, {}));
          }
        } else {
          that.players.push(videojs(that.$refs.video, {}));
        }
      }
    },
    unlock() {
      if (this.post.isFree) {
        this.$buyItem({
          type: null,
          user: this.post.user,
        });
      } else {
        this.$buyItem({
          type: Payment.TYPE_POST,
          post: this.post,
          success: (data) => {
            this.post = new Post(data);
            this.$nextTick(() => {
              this.init();
            });
          },
        });
      }
    },
  },
};
</script>
