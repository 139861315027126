<template>
  <UiCard>
    <UiNavigationBar
      bleed
      :title="$t('general.our-creators')"
      responsive
      @back="$router.go(-1)"
    />

    <div>
      <div class="tw-overflow-hidden">
        <div class="tw-flex tw-items-center tw-space-x-3 tw-overflow-auto">
          <UiPill
            v-for="filter in filters"
            :key="filter.value"
            :checked="filterValue"
            :icon="filter.icon"
            :value="filter.value"
            variant="primary"
            @change="updateCreators"
          >
            {{ $t(filter.label) }}
          </UiPill>
        </div>
      </div>

      <div class="tw-mt-6">
        <div
          v-if="creators.length > 0"
          class="tw-grid tw-grid-cols-2 tw-gap-1.5 md:tw-grid-cols-3 md:tw-gap-4 xl:tw-gap-6"
        >
          <UiCreatorCard
            v-for="creator in creators"
            :key="creator.id"
            :creator="creator"
            @preview="previewCreator = $event"
          />
        </div>

        <div
          v-if="isLoading"
          class="tw-flex tw-justify-center tw-py-16"
        >
          <UiSpinner />
        </div>
      </div>
    </div>

    <UiModal
      v-if="previewCreator !== null"
      size="sm"
      @close="previewCreator = null"
    >
      <div class="-tw-m-6">
        <UiCreatorCard
          closeable
          unveiled
          :creator="previewCreator"
          @close="previewCreator = null"
        />
      </div>
    </UiModal>
  </UiCard>
</template>

<script>
import Creator from '@/components/models/Creator';

import UiCard from '@/components/ui/UiCard.vue';
import UiCreatorCard from '@/components/ui/UiCreatorCard.vue';
import UiIcon from '@/components/ui/UiIcon.vue';
import UiModal from '@/components/ui/UiModal.vue';
import UiNavigationBar from '@/components/ui/UiNavigationBar.vue';
import UiPill from '@/components/ui/UiPill.vue';
import UiSpinner from '@/components/ui/UiSpinner.vue';

const FILTER_ALL = 'all';
const FILTER_ONLINE = 'online';
const FILTER_POPULAR = 'popular';
const FILTER_NEW = 'new';

export default {
  components: {
    UiCard,
    UiCreatorCard,
    UiIcon,
    UiModal,
    UiNavigationBar,
    UiPill,
    UiSpinner,
  },
  data() {
    return {
      creators: [],
      filterValue: FILTER_ALL,
      hasMore: false,
      page: 1,
      isLoading: false,
      previewCreator: null,
    };
  },
  computed: {
    filters() {
      return [
        { label: 'general.all', icon: 'badge-done', value: FILTER_ALL },
        { label: 'general.online', icon: 'orbit', value: FILTER_ONLINE },
        { label: 'general.popular', icon: 'gem', value: FILTER_POPULAR },
        { label: 'general.new', icon: 'rabbit', value: FILTER_NEW },
      ];
    },
  },
  created() {
    this.loadCreators();
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.updateScroll);
  },
  methods: {
    reset() {
      this.isLoading = false;
      this.hasMore = false;
      this.page = 1;
      this.creators = [];
    },
    updateScroll() {
      const scrollPosition = window.innerHeight + window.scrollY;

      if (
        document.body.offsetHeight &&
        scrollPosition &&
        document.body.offsetHeight - scrollPosition <= 1000 &&
        !this.isLoading &&
        this.hasMore
      ) {
        this.loadMore();
      }
    },
    loadCreators() {
      this.isLoading = true;
      this.$get(
        '/explorer'
        + (this.filterValue ? '/' + this.filterValue : '')
        + '?page=' + this.page,
        (data) => {
          let creators = [...this.creators];
          for (let obj of data.data) {
            creators.push(new Creator(obj));
          }
          this.creators = creators;
          this.hasMore = data.meta.current_page !== data.meta.last_page;
          this.isLoading = false;
        },
        (errors) => {
          console.log(errors);
          this.isLoading = false;
        },
      );
    },
    loadMore() {
      if (this.hasMore) {
        this.page = this.page + 1;
        this.loadCreators();
      }
    },
    updateCreators(value) {
      this.filterValue = value;
      this.reset();
      this.loadCreators();
    },
  },
};
</script>
