<template>
  <div class="tw-text-center tw-p-5 tw-text-xl tw-font-bold">Impersonating...</div>
</template>

<script>
export default {
  components: {
  },
  mounted() {
    // Delete impersonate token
    localStorage.token = this.$route.params.token;
    localStorage.impersonating = true;

    this.$store.state.token = this.$route.params.token;
    this.$store.state.impersonating = localStorage.impersonating;

    window.location.href = '/';
  },
};
</script>
