<template>
  <UiCard class="tw-sticky tw-top-0 tw-shadow">
    <div>
      <div class="tw-flex tw-items-start tw-w-full">
        <div class="tw-grow tw-font-semibold tw-text-h6">
          {{ $t('general.suggested-profiles') }}
        </div>

        <div class="tw-flex tw-items-center tw-space-x-2.5">
          <button
            v-if="swiper"
            class="disabled:tw-opacity-50"
            :disabled="swiper.activeIndex === 0"
            @click.prevent="slideLeft"
          >
            <UiIcon
              name="chevron-left"
            />
          </button>
          <button
            v-if="swiper"
            class="disabled:tw-opacity-50"
            :disabled="swiper.activeIndex === swiper.slides.length - 1"
            @click.prevent="slideRight"
          >
            <UiIcon
              name="chevron-right"
            />
          </button>
        </div>
      </div>

      <div
        ref="swiper"
        class="swiper tw-w-full tw-mt-6 tw-overflow-hidden"
      >
        <div class="swiper-wrapper">
          <div
            v-for="(item, key) in chunkedArr"
            :key="key"
            class="swiper-slide tw-flex tw-flex-col tw-space-y-3"
          >
            <UiUserCard
              v-for="(user, k) in item"
              :key="k"
              :user="user"
              :sizes="{ xl: '22vw', '2xl': '18vw' }"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="swiper && swiper.slides.length > 1"
      class="tw-mt-6 tw-flex tw-items-center tw-justify-center tw-space-x-3"
    >
      <span
        v-for="(slide, index) in swiper.slides.length"
        :key="index"
        class="tw-shrink-0 tw-h-2 tw-w-2 tw-rounded-full tw-cursor-pointer"
        :class="index === swiper.activeIndex ? 'tw-bg-gradient-to-r tw-from-primary-400 tw-to-primary-500' : 'tw-bg-gray-200'"
        @click="swiper.slideTo(index)"
      />
    </div>
  </UiCard>
</template>

<script>
import { Swiper } from 'swiper';
import 'swiper/swiper-bundle.css';

import User from '@/components/models/User';

import UiCard from '@/components/ui/UiCard.vue';
import UiIcon from '@/components/ui/UiIcon.vue';
import UiUserCard from '@/components/ui/UiUserCard.vue';

export default {
  components: {
    UiCard,
    UiIcon,
    UiUserCard,
  },
  data: function () {
    return {
      users: [],
      swiper: null,
    };
  },
  mounted() {
    this.swiper = new Swiper(this.$refs.swiper, {
      // Optional parameters
      direction: 'horizontal',
    });
    this.loadSuggestions();
  },
  computed: {
    chunkedArr() {
      const result = [];
      for (let i = 0; i < this.users.length; i += 3) {
        result.push(this.users.slice(i, i + 3));
      }
      return result;
    },
  },
  methods: {
    loadSuggestions() {
      this.$get(
        '/users',
        (data) => {
          let users = [];
          for (let obj of data.users) {
            users.push(new User(obj));
          }
          this.users = users;
          this.$nextTick(function () {
            this.swiper.update();
          });
        },
        (errors) => {
          console.log(errors);
        },
      );
    },
    slideLeft() {
      this.swiper.slidePrev();
    },
    slideRight() {
      this.swiper.slideNext();
    },
  },
};
</script>
