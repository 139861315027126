<template>
  <div>
    <div class="tw-fixed tw-z-modal">
      <transition
        enter-active-class="tw-ease-out tw-duration-300"
        enter-class="tw-opacity-0"
        enter-to-class="tw-opacity-100"
        leave-active-class="tw-ease-in tw-duration-200"
        leave-class="tw-opacity-100"
        leave-to-class="tw-opacity-0"
      >
        <div
          v-if="isMounted"
          class="tw-fixed tw-inset-0 tw-bg-[#09101d]/70 tw-transition-opacity"
        />
      </transition>

      <div class="tw-fixed tw-inset-0 tw-z-modal tw-overflow-y-auto">
        <div
          class="tw-flex tw-min-h-full tw-items-center tw-justify-center tw-p-4 tw-text-center"
          @click.self="$emit('close')"
        >
          <transition
            enter-active-class="tw-transform tw-ease-out tw-duration-300"
            enter-class="tw-opacity-0 tw-scale-95"
            enter-to-class="tw-opacity-100 tw-scale-100"
            leave-active-class="tw-transform tw-ease-in tw-duration-200"
            leave-class="tw-opacity-100 tw-scale-100"
            leave-to-class="tw-opacity-0 tw-scale-95"
          >
            <div
              v-if="isMounted"
              class="
                tw-relative tw-transition-all tw-w-full tw-overflow-hidden
                tw-p-6 tw-bg-white tw-rounded-2xl tw-text-left tw-shadow-xl
                sm:tw-my-8
              "
              :class="{
                'sm:tw-max-w-md': size === 'sm',
                'sm:tw-max-w-lg': size === 'md',
                'sm:tw-max-w-2xl': size === 'lg',
                'sm:tw-max-w-5xl': size === 'xl',
                'sm:tw-max-w-6xl': size === '2xl',
              }"
            >
              <div
                v-if="title || action"
                class="tw-flex tw-items-center tw-justify-between"
              >
                <div
                  v-if="title"
                  class="tw-font-semibold tw-text-xl sm:tw-text-2xl"
                >
                  {{ title }}
                </div>

                <div
                  v-if="action"
                  class="tw-cursor-pointer"
                  @click="$emit('action')"
                >
                  <UiIcon
                    class="tw-h-6 tw-w-6"
                    :name="action"
                  />
                </div>
              </div>

              <div
                v-if="subtitle"
                class="tw-mt-2 sm:tw-text-lg"
              >
                {{ subtitle }}
              </div>

              <div :class="{ 'tw-mt-6': title || action || subtitle }">
                <slot />
              </div>

              <div
                v-if="cancel || confirm"
                class="tw-mt-6 tw-flex tw-flex-col-reverse tw-gap-3 sm:tw-grid sm:tw-grid-flow-col sm:tw-auto-cols-fr"
              >
                <UiButton
                  v-if="cancel"
                  variant="secondary"
                  @click="$emit('cancel')"
                >
                  {{ cancel }}
                </UiButton>
                <UiButton
                  v-if="confirm"
                  :disabled="disabled"
                  variant="primary"
                  @click="$emit('confirm')"
                >
                  {{ confirm }}
                </UiButton>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UiButton from '@/components/ui/UiButton.vue';
import UiIcon from '@/components/ui/UiIcon.vue';

export default {
  components: {
    UiIcon,
    UiButton,
  },
  props: {
    action: String,
    title: String,
    subtitle: String,
    size: {
      type: String, // 'sm' | 'md' | 'lg' | 'xl' | '2xl'
      required: true,
    },
    cancel: String,
    confirm: String,
    disabled: Boolean,
  },
  data() {
    return {
      isMounted: false,
    };
  },
  mounted() {
    if (this.$store.state.dialogs <= 0) {
      document.body.style.overflow = 'hidden';
    }

    this.$store.state.dialogs++;
    this.isMounted = true;
    this.$emit('open');
  },
  beforeDestroy() {
    this.$store.state.dialogs--;

    if (this.$store.state.dialogs <= 0) {
      document.body.style.overflow = null;
    }
  },
};
</script>
