<template>
  <UiModal
    v-if="entry"
    size="2xl"
    @close="$emit('close')"
  >
    <div class="md:tw-grid md:tw-grid-cols-2 md:tw-gap-10">
      <div class="tw-hidden md:tw-block">
        <UiImage
          alt=""
          class="tw-rounded-2xl"
          fill
          :sizes="{ md: '45vw', '2xl': '35vw' }"
          :src="entry.url"
          :srcset="entry.signed_urls"
        />
      </div>

      <div>
        <div
          v-if="!user"
          class="tw-flex tw-items-center"
        >
          <UiSkeleton
            rounded="full"
            :style="{ width: '60px', height: '60px' }"
          />

          <div class="tw-ml-3">
            <UiSkeleton class="tw-h-4 tw-w-32" />
            <UiSkeleton class="tw-mt-2 tw-h-4 tw-w-16" />
          </div>
        </div>

        <div
          v-else
          class="tw-flex tw-items-center tw-min-w-0"
        >
          <UiAvatar
            :online="user.is_online"
            size="sm"
            :src="user.avatar_url"
            :text="user.initials"
            :to="user.url"
          />

          <div class="tw-ml-3 tw-flex tw-flex-col tw-items-start tw-overflow-hidden">
            <UiUsername
              :user="user"
            />
            <div class="tw-text-sm tw-text-gray-700">
              <span class="tw-truncate">
                {{ $tc('general.x-votes', entry.votes_count) }}
              </span>
            </div>
          </div>
        </div>

        <div class="tw-mt-6 md:tw-hidden">
          <img
            alt=""
            class="tw-rounded-2xl"
            :src="entry.url"
          >
        </div>

        <div class="tw-mt-6">
          <div class="md:tw-max-w-xs">
            <UiButton
              class="tw-w-full tw-group"
              :variant="entry.has_voted ? 'secondary' : 'outline'"
              @click="vote"
            >
              <template v-if="entry.has_voted">
                <span class="group-hover:tw-hidden">
                  {{ $t('general.voted') }}
                </span>
                <span class="tw-hidden group-hover:tw-inline">
                  {{ $t('general.unvote') }}
                </span>
              </template>
              <template v-else>
                {{ $t('general.vote') }}
              </template>
            </UiButton>
          </div>
        </div>

        <div class="tw-mt-6">
          <div class="tw-font-semibold tw-text-xl md:tw-text-2xl">
            {{ $t('general.share-link') }}
          </div>

          <div class="tw-mt-4 tw-flex tw-items-center tw-space-x-4">
            <ShareNetwork
              v-for="network in networks"
              :key="network.network"
              class="tw-p-3 tw-text-white tw-rounded-full hover:tw-text-white"
              :network="network.network"
              :style="{ backgroundColor: network.color }"
              :url="link"
              :title="sharing.title"
              :hashtags="sharing.hashtags"
            >
              <UiIcon
                :name="network.icon"
                solid
              />
            </ShareNetwork>
          </div>

          <div class="tw-flex tw-mt-6">
            <div
              class="tw-py-4 tw-px-3 tw-bg-gray-50 tw-rounded-l-xl tw-truncate"
              :title="link"
            >
              {{ link }}
            </div>
            <button
              class="tw-p-4 tw-text-white tw-bg-primary-500 tw-rounded-r-xl"
              @click="copyLink"
            >
              <UiIcon
                name="copy"
                solid
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  </UiModal>
</template>

<script>
import Contest from '@/components/models/Contest';
import ContestEntry from '@/components/models/ContestEntry';
import User from '@/components/models/User';

import UiAvatar from '@/components/ui/UiAvatar.vue';
import UiUsername from '@/components/ui/UiUsername.vue';
import UiModal from '@/components/ui/UiModal.vue';
import UiButton from '@/components/ui/UiButton.vue';
import UiIcon from '@/components/ui/UiIcon.vue';
import UiFormInput from '@/components/ui/UiFormInput.vue';
import UiSkeleton from '@/components/ui/UiSkeleton.vue';
import UiImage from '@/components/ui/UiImage.vue';

export default {
  components: {
    UiImage,
    UiSkeleton,
    UiFormInput,
    UiIcon,
    UiButton,
    UiModal,
    UiUsername,
    UiAvatar,
  },
  props: {
    value: ContestEntry,
    contest: Contest,
    username: String,
  },
  data() {
    return {
      entry: null,
      user: null,
      networks: [
        { network: 'twitter', name: 'Twitter', icon: 'twitter', color: '#1d9bf0' },
        { network: 'facebook', name: 'Facebook', icon: 'facebook', color: '#1877f2' },
        { network: 'whatsapp', name: 'Whatsapp', icon: 'whatsapp', color: '#25d366' },
        { network: 'telegram', name: 'Telegram', icon: 'telegram', color: '#26a5e4' },
      ],
    };
  },
  computed: {
    link() {
      return `${process.env.VUE_APP_APP_URL}/contest/${this.contest.slug}/${this.entry.username}`;
    },
    sharing() {
      return {
        url: this.link,
        title: this.$t('general.contest-share-title', [this.entry.username, this.contest.name]),
        hashtags: 'Dropfeet,MissFeet',
      };
    },
  },
  created() {
    if (this.value) {
      this.entry = this.value;
    } else {
      this.loadEntry();
    }

    // TODO: Consider having the user directly in the entry
    this.loadUser();
  },
  methods: {
    loadEntry() {
      this.$get(
        '/contest/' + this.contest.slug + '/' + this.username,
        (data) => {
          this.entry = new ContestEntry(data.data);
        },
        (errors) => {
          console.log(errors);
          this.entry = null;
        },
      );
    },
    loadUser() {
      this.$get(
        '/public/' + this.username,
        (data) => {
          this.user = new User(data);
        },
        (errors) => {
          console.log(errors);
        },
      );
    },
    vote() {
      this.$emit('vote', this.entry);
    },
    copyLink() {
      this.$copyText(this.link);
      this.$bvToast.toast(this.$t('general.link-copied'), {
        autoHideDelay: 2000,
        title: this.$t('general.link'),
        solid: true,
        toaster: 'b-toaster-bottom-left',
      });
    },
  },
};
</script>
