<template>
  <div>
    <label
      v-if="label"
      class="tw-text-sm md:tw-text-lg md:tw-font-semibold tw-mb-3"
      :for="name"
    >
      {{ label }}
    </label>

    <div
      class="tw-py-4 tw-px-5 tw-border tw-rounded-xl"
      :class="isFocused ? 'tw-bg-primary-500/[.08] tw-border-primary-500' : 'tw-bg-gray-50 tw-border-transparent'"
    >
      <slot name="top" />

      <div class="tw-flex tw-items-start">
        <textarea
          :id="name"
          v-model="inputVal"
          v-autogrow
          class="tw-w-full tw-grow tw-text-base tw-bg-transparent tw-resize-none placeholder:tw-text-gray-500 focus:tw-outline-none"
          :placeholder="placeholder"
          v-bind="$attrs"
          @blur="isFocused = false"
          @focus="isFocused = true"
        />

        <slot name="end" />
      </div>
    </div>

    <div
      v-if="errors && errors[name] && errors[name].length > 0"
      :id="name + '-errors'"
      class="tw-mt-2 tw-text-sm md:tw-text-base tw-text-error"
    >
      <div
        v-for="error in errors[name]"
        :key="error"
      >
        {{ error }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: [
    'label',
    'name',
    'value',
    'errors',
    'placeholder',
  ],
  data() {
    return {
      isFocused: false,
    };
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
};
</script>
