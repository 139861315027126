<template>
  <div class="tw-mx-4 tw-py-1 tw-flex">
    <component
      :is="component"
      class="-tw-mx-4 tw-p-4 tw-grow tw-flex tw-items-center tw-bg-white tw-text-gray-900 tw-rounded-xl tw-cursor-pointer hover:tw-bg-gray-50"
      tabindex="0"
      :to="to"
      @click="(event) => $emit('click', event)"
    >
      <span
        v-if="icon"
        class="tw-mr-2"
      >
        <UiIcon
          :name="icon"
          curved
        />
      </span>

      <slot />
    </component>
  </div>
</template>

<script>
import UiIcon from '@/components/ui/UiIcon.vue';

export default {
  components: { UiIcon },
  props: {
    icon: String,
    to: [Object, String],
  },
  computed: {
    component() {
      if (this.to) {
        return 'RouterLink';
      }

      if (this.$listeners.click) {
        return 'button';
      }

      return 'div';
    },
  },
};
</script>
