<template>
  <form @submit.prevent="submitForm">
    <UiLandingHeading
      :title="$t('general.password-create-title')"
      :message="$t('general.password-create-message')"
      :image="require('@/assets/illustrations/password-reset-mobile.svg')"
    />

    <div
      v-if="successMessage"
      class="tw-mt-6 tw-text-success tw-space-y-3 md:tw-mt-16 md:tw-space-y-4"
    >
      {{ successMessage }}

      <UiButton
        class="tw-w-full tw-mt-6"
        variant="primary"
        @click="redirectToLogin"
      >
        {{ $t('general.login') }}
      </UiButton>
    </div>

    <div
      v-else-if="errorMessage"
      class="tw-mt-6 tw-text-error tw-space-y-3 md:tw-mt-16 md:tw-space-y-4"
    >
      {{ errorMessage }}

      <UiButton
          class="tw-w-full tw-mt-6"
          variant="primary"
          @click="$router.push('/forgot')"
      >
        {{ $t('general.password-reset-error-cta') }}
      </UiButton>
    </div>

    <div v-else>
      <div class="tw-mt-6 tw-space-y-3 md:tw-mt-16 md:tw-space-y-4">
        <UiFormInput
            v-model="password"
            :errors="errors"
            icon="lock"
            name="password"
            :placeholder="$t('general.new-password')"
            type="password"
        />
        <div>
          <UiFormInput
              v-model="passwordConfirm"
              :errors="errors"
              icon="lock"
              name="password_confirmation"
              :placeholder="$t('general.confirm-password')"
              type="password"
          />
          <UiFormErrors
              v-if="errors._ && errors._.length > 0"
              :errors="errors._"
          />
        </div>
      </div>

      <UiButton
          class="tw-w-full tw-mt-6"
          variant="primary"
          type="submit"
          :disabled="successMessage || errorMessage"
      >
        {{ $t('general.continue') }}
      </UiButton>
    </div>


  </form>
</template>

<script>
import UiFormInput from '@/components/ui/UiFormInput.vue';
import UiButton from '@/components/ui/UiButton.vue';
import UiLandingHeading from '@/components/ui/UiLandingHeading.vue';
import UiFormErrors from '@/components/ui/UiFormErrors.vue';

export default {
  components: { UiFormErrors, UiLandingHeading, UiButton, UiFormInput },
  data: function () {
    return {
      errorMessage: null,
      successMessage: null,
      password: '',
      passwordConfirm: '',
      errors: {},
    };
  },
  mounted() {
    if(!this.$route.query.signature) {
      window.location.href = '/';
    }
  },
  methods: {
    redirectToLogin() {
      this.$router.push('/');
    },
    submitForm() {
      if (this.disabled) {
        return;
      }

      this.errors = {};
      this.$redirector().clear();
      this.$post(
        '/auth/password',
        {
          signature: this.$route.query.signature,
          password: this.password,
          password_confirmation: this.passwordConfirm,
        },
        () => {
          this.successMessage = this.$t('general.password-success-title')
        },
        (errors, errorResponse) => {
          switch (errorResponse.response.status) {
            case 400:
              this.errorMessage = this.$t('general.password-invalid-signature');
              break;

            case 410:
              this.errorMessage = this.$t('general.password-token-expired');
              break;

            case 429:
              this.errorMessage = this.$t('general.too-many-requests');
              break;
          }

          this.errors = errors;
        },
      );
    },
  },
};
</script>
