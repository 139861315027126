<template>
  <div>
    <div>
      <!-- Page -->
      <PageErrorApi503  v-if="errorPage === 503" />
      <div
        v-if="spinner"
        class="tw-fixed tw-top-1/2 tw-left-1/2 -tw-translate-1/2 tw-z-spinner"
      >
        <b-spinner
          variant="secondary"
        />
      </div>
    </div>
  </div>
</template>

<style>
@media screen(md) {
  body {
    background-color: theme('colors.gray.50');
  }
}
</style>

<script>
import PageErrorApi503 from '@/components/pages/PageErrorApi503.vue';

export default {
  components: {
    PageErrorApi503
  },
  computed: {
    spinner() {
      return this.$store.state.spinner == true;
    },
  },
  props: {
    errorPage: Number,
  },
};
</script>
