var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-my-1.5 tw-flex tw-flex-col md:tw-my-2",class:_vm.isAuthor ? 'tw-items-end' : 'tw-items-start'},[_c('div',{staticClass:"tw-w-full tw-max-w-xs md:tw-max-w-md"},[_c('div',{staticClass:"tw-w-full tw-flex tw-flex-col tw-space-y-3 md:tw-space-y-4",class:_vm.isAuthor ? 'tw-items-end' : 'tw-items-start'},[(_vm.media.length > 0 && _vm.message.hasAccess)?_c('UiMessageItem',{staticClass:"tw-flex-col tw-self-stretch",attrs:{"author":_vm.isAuthor,"message":_vm.message}},[_c('div',{staticClass:"tw-flex tw-flex-col tw-gap-1 md:tw-flex-row md:tw-gap-2"},_vm._l((_vm.media),function(item,index){return _c('div',{key:item.id,staticClass:"tw-basis-0 tw-grow tw-cursor-pointer",on:{"click":function($event){return _vm.$showPhotoSwipe(_vm.message.media, 0)}}},[_c('div',{staticClass:"tw-relative tw-h-0 tw-rounded-xl tw-overflow-hidden",class:{
                'tw-pb-[100%]': _vm.message.media.length === 1,
                'tw-pb-[50%] md:tw-pb-[100%]': _vm.message.media.length > 1,
                'tw-rounded-b-none md:tw-rounded-xl': _vm.message.media.length === 2 && index === 0,
                'tw-rounded-t-none md:tw-rounded-xl': _vm.message.media.length === 2 && index === _vm.message.media.length - 1,
              }},[(item.type >= 0)?_c('UiImage',{staticClass:"tw-absolute tw-inset-0 tw-h-full tw-w-full tw-object-cover tw-overflow-hidden",attrs:{"src":item.type === 0 ? item.url : item.screenshot,"srcset":item.type === 0 ? item.signed_urls : null,"sizes":item.type === 0
                    ? { initial: '75vw', sm: '44vw', md: '54vw', lg: '40vw', xl: '32vw', '2xl': '27vw' }
                    : null}}):_vm._e(),_c('div',{staticClass:"tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center"},[(item.type < 0)?_c('span',{staticClass:"tw-text-4xl"},[_vm._v(" "+_vm._s('+' + (_vm.message.media.length - _vm.media.length + 1))+" ")]):_vm._e(),(item.type === 1)?_c('UiIcon',{staticClass:"tw-h-16 tw-w-16",attrs:{"name":"play","solid":""}}):_vm._e()],1)],1)])}),0)]):(!_vm.message.hasAccess && _vm.message.media.length > 0)?_c('UiMessageItem',{staticClass:"tw-flex-col tw-self-stretch",attrs:{"author":_vm.isAuthor,"message":_vm.message}},[_c('UiLockedMedia',{attrs:{"images":_vm.message.images,"price":_vm.message.priceFormatted,"videos":_vm.message.videos},on:{"unlock":_vm.unlock}})],1):_vm._e(),(_vm.message.price && (_vm.fanHasPaid || _vm.isAuthor))?_c('UiMessageItem',{attrs:{"message":_vm.message,"author":_vm.isAuthor,"notice":true}},[(_vm.isAuthor)?_c('span',[(_vm.fanHasPaid)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('general.notice-paid-content-confirm-creator', { amount: _vm.message.baseAmountFormatted }))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('general.notice-paid-content', { amount: _vm.message.baseAmountFormatted }))+" ")])]):(_vm.fanHasPaid)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('general.notice-paid-content-confirm-fan', { amount: _vm.message.baseAmountFormatted }))+" ")]):_vm._e()]):_vm._e(),(_vm.message.items.payment && _vm.message.price === null)?_c('UiMessageItem',{attrs:{"author":_vm.isAuthor,"message":_vm.message}},[_c('i18n',{attrs:{"path":'general.' +
            (_vm.message.items.payment.items.post
              ? _vm.isAuthor
                ? 'tip-message-post-out'
                : 'tip-message-post-in'
              : 'tip-message-tip'),"tag":"div"},scopedSlots:_vm._u([{key:"amount",fn:function(){return [_vm._v(" "+_vm._s(_vm.$formatAmount(_vm.message.items.payment.amount))+" ")]},proxy:true},(_vm.message.items.payment.items.post)?{key:"post",fn:function(){return [_c('b-link',{attrs:{"to":_vm.message.items.payment.items.post.url}},[_vm._v(" "+_vm._s(_vm.$t('general.post'))+" ")])]},proxy:true}:null],null,true)})],1):_vm._e(),(_vm.message.message)?_c('UiMessageItem',{attrs:{"author":_vm.isAuthor,"message":_vm.message}},[_vm._v(" "+_vm._s(_vm.message.message)+" ")]):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }