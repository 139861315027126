import User from './User';
import Bundle from './Bundle';
import Post from './Post';
import Comment from './Comment';

import dayjs from 'dayjs';

var localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(localizedFormat);

export default class Payment {

  static TYPE_SUBSCRIPTION_NEW = 'new_sub';
  static TYPE_SUBSCRIPTION_RENEW = 'renew_sub';
  static TYPE_POST = 'post';
  static TYPE_MESSAGE = 'message';
  static TYPE_TIP = 'tip';

  static STATUS_PENDING = 'pending';
  static STATUS_COMPLETE = 'completed';
  static STATUS_FAILED = 'failed';
  static STATUS_REFUNDED = 'refunded';
  static STATUS_DISPUTING = 'disputing';
  static STATUS_DISPUTE_LOST = 'dispute_lost';

  id = 0;
  type = Payment.TYPE_SUBSCRIPTION_NEW;
  status = Payment.STATUS_PENDING;
  amount = 0;
  commission_amount = 0;
  processing_amount = 0;
  user = null;
  to = null;
  in_probation_period = false;
  created_at = dayjs();
  disputed_at = null;
  items = {};

  constructor(data) {
    this.id = data.id;
    this.type = data.type;
    this.status = data.status;
    this.amount = data.amount;
    this.amount_with_vat = data.amount_with_vat;
    this.vat_amount = data.vat_amount;
    this.amount_paid_to_creator = data.amount_paid_to_creator;
    this.commission_amount = data.commission_amount;
    this.processing_amount = data.processing_amount;
    this.in_probation_period = data.in_probation_period;
    this.created_at = dayjs(data.created_at);

    this.user = new User(data.user);
    this.to = new User(data.to);

    if (data.disputed_at) {
      this.disputed_at = dayjs(data.disputed_at);
    }

    if (data.items) {
      for (let t in data.items) {
        switch (t) {
          case 'post':
            this.items.post = new Post(data.items[t]);
            break;
          case 'comment':
            this.items.comment = new Comment(data.items[t]);
            break;
          case 'sub':
            this.items.sub = new User(data.items[t]);
            break;
          case 'bundle':
            this.items.bundle = new Bundle(data.items[t]);
            break;
        }
      }
    }
  }

  get feeAmount() {
    return this.amount;
  }

  get net() {
    return this.amount - this.feeAmount;
  }
}