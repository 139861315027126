<template>
  <div class="tw-space-y-6">
    <UiCallout icon="danger-circle">
      {{ $t('general.transactions-delay') }}
    </UiCallout>

    <div>
      <UiTable>
        <UiTableHeader>
          <UiTableRow>
            <UiTableHead>{{ $t('general.date') }}</UiTableHead>
            <UiTableHead>{{ $t('general.invoice') }}</UiTableHead>
            <UiTableHead>{{ $t('general.amount') }}</UiTableHead>
            <UiTableHead>{{ $t('general.fee') }}</UiTableHead>
            <UiTableHead>{{ $t('general.net') }}</UiTableHead>
          </UiTableRow>
        </UiTableHeader>

        <UiTableBody>
          <template
            v-for="(payout, index) in payouts"
          >
            <UiTableRow
              :key="'data' + index"
              :divide="false"
            >
              <UiTableCell class="tw-text-sm tw-text-gray-700">
                <div>
                  {{ payout.created_at.format('LLL') }}
                </div>
                <div>
                  <div
                    v-if="payout.isPending"
                    class="tw-text-gray-700"
                  >
                    {{ $t('general.payout-request-processing') }}
                  </div>

                  <div v-if="payout.isPendingWireTransfer">
                    {{ $t('general.payout-request-wire-sent') }}
                  </div>

                  <div
                      v-if="payout.isFailed || payout.isRejected"
                      class="tw-text-error"
                  >
                    {{ $t('general.payout-request-wire-failed') }}
                  </div>

                  <div v-if="payout.isCompleted">
                    {{ $t('general.payout-request-processed') }}
                  </div>
                </div>
              </UiTableCell>
              <UiTableCell>
                #{{ payout.invoice_number }}
              </UiTableCell>
              <UiTableCell>
                <div>
                  {{ $formatAmount(Math.abs(payout.amount)) }}
                </div>
              </UiTableCell>
              <UiTableCell>
                <div>
                  {{ $formatAmount(Math.abs(payout.processing_amount)) }}
                </div>
              </UiTableCell>
              <UiTableCell class="tw-w-100">
                <div>
                  {{ $formatAmount(Math.abs(payout.final_amount)) }}
                </div>
                <div>
                  <span v-if="payout.isPending || payout.isPendingWireTransfer">
                    <UiLoader class="tw-inline-block" />
                  </span>
                  <UiIcon
                    v-else-if="payout.isFailed || payout.isRejected"
                    class="tw-inline-block tw-h-5 tw-w-5 tw-text-error"
                    name="close"
                  />
                  <UiIcon
                    v-else
                    class="tw-inline-block tw-h-5 tw-w-5 tw-text-success"
                    name="tick-square"
                  />
                </div>
              </UiTableCell>
            </UiTableRow>
            <UiTableRow
              v-if="payout.isCompleted"
              :key="'button' + index"
            >
              <UiTableCell
                class="tw-pt-0"
                colspan="3"
              >
                <div class="tw-flex tw-justify-center">
                  <UiButton
                    icon="download"
                    size="sm"
                    variant="outline"
                    @click="downloadInvoice(payout)"
                  >
                    {{ $t('general.download-invoice') }}
                  </UiButton>
                </div>
              </UiTableCell>
            </UiTableRow>
          </template>
        </UiTableBody>
      </UiTable>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

import Payout from '@/components/models/Payout';

import UiTableRow from '@/components/ui/UiTableRow.vue';
import UiTable from '@/components/ui/UiTable.vue';
import UiTableHead from '@/components/ui/UiTableHead.vue';
import UiTableHeader from '@/components/ui/UiTableHeader.vue';
import UiLoader from '@/components/ui/UiLoader.vue';
import UiTableCell from '@/components/ui/UiTableCell.vue';
import UiTableBody from '@/components/ui/UiTableBody.vue';
import UiIcon from '@/components/ui/UiIcon.vue';
import UiButton from '@/components/ui/UiButton.vue';
import UiCallout from '@/components/ui/UiCallout.vue';

export default {
  components: {
    UiCallout,
    UiButton,
    UiIcon,
    UiTableBody,
    UiTableCell,
    UiLoader,
    UiTableHeader,
    UiTableHead,
    UiTable,
    UiTableRow,
  },
  props: {
    payouts: Array,
  },
  computed: {
    status() {
      return {
        pending: Payout.STATUS_PENDING,
        rejected: Payout.STATUS_REJECTED,
        pending_wire_transfer: Payout.STATUS_PENDING_WIRE_TRANSFER,
        complete: Payout.STATUS_COMPLETE,
      };
    },
  },
  methods: {
    downloadInvoice(payout) {
      axios.post(
          process.env.VUE_APP_API_URL + '/v1/payouts/invoice/' + payout.invoice_number,
          null,
          {
            responseType: 'blob',
            headers: {
              Authorization: 'Bearer ' + this.$store.state.token
            },
          },
      )
      .then((res) =>  {
        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'receipt_' + payout.invoice_number + '_' + this.$store.state.currentUser.name + '.pdf')
        document.body.appendChild(link)
        link.click()
      })
    },
  },
};
</script>
