<template>
  <div>
    <div class="tw-flow-root">
      <div class="-tw-my-6 tw-divide-y tw-divide-gray-200">
        <!-- Current Balance -->
        <div class="tw-py-6">
          <div class="tw-text-xl tw-font-semibold xl:tw-text-2xl">
            {{ $t('general.current-balance') }}
          </div>
          <div class="tw-mt-2">
            <span class="xl:tw-text-lg">
              {{ $t('general.available') }}
            </span>
            <span class="tw-text-lg tw-text-primary-500 tw-font-semibold xl:tw-text-2xl">
              {{ $formatAmount(stats.balance) }}
            </span>
          </div>
        </div>

        <!-- Pending Balance -->
        <div
          v-if="stats.pending_balance"
          class="tw-py-6 tw-text-gray-500"
        >
          <div class="tw-text-xl tw-font-semibold xl:tw-text-2xl">
            {{ $t('general.pending-balance') }}
          </div>
          <div class="tw-mt-2">
            <span class="xl:tw-text-lg">
              {{ $t('general.available') }}
            </span>
            <span class="tw-inline-flex tw-items-center tw-space-x-2">
              <span class="tw-text-lg tw-font-semibold xl:tw-text-2xl">
                {{ $formatAmount(stats.pending_balance) }}
              </span>
              <span v-tooltip="$t('general.pending-balance-info')">
                <UiIcon
                  class="tw-h-6 tw-w-6"
                  name="danger-circle"
                  solid
                  gradient="dark"
                />
              </span>
            </span>
          </div>
        </div>

        <!-- Manual Payouts -->
        <div class="tw-py-6">
          <div class="tw-text-xl tw-font-semibold xl:tw-text-2xl">
            {{ $t('general.manual-payouts') }}
          </div>
          <div
            v-if="!stats.withdraw"
            class="tw-mt-5 tw-flex tw-justify-center"
          >
            <UiButton
              :disabled="method === null"
              variant="primary"
              @click="isModalOpen = true"
            >
              {{ $t('general.request-withdrawal') }}
            </UiButton>
          </div>
          <div v-else-if="stats.withdraw">
            {{ $t('general.pending-withdrawal', [$formatAmount(stats.withdraw.amount)]) }}
          </div>
        </div>
      </div>
    </div>

    <UiModal
      v-if="isModalOpen"
      size="sm"
      :title="$t('general.request-withdrawal')"
      :cancel="$t('general.cancel')"
      :confirm="$t('general.submit')"
      :disabled="amount === ''"
      @cancel="isModalOpen = false"
      @confirm="promptCode"
      @close="isModalOpen = false"
    >
      <UiSecurityCodeModal
        v-if="promptSecurityCode"
        :title="$t('security-code.verify-header')"
        :cancel="true"
        @submit="submitRequest($event)"
        @cancel="promptSecurityCode = false"
        class="tw-text-sm"
      >
        <div class="tw-pb-4">
          {{ $t('security-code.verify-text') }}
        </div>
      </UiSecurityCodeModal>
      <div class="tw-text-center">
        <div class="tw-space-y-4 md:tw-space-y-6">
          <template v-if="!stats.withdraw && canWithdraw">
            <div class="tw-text-center md:tw-font-semibold md:tw-text-lg">
              {{ $t('general.enter-amount-to-withdraw') }}
            </div>

            <div>
              <UiDisplayInput
                id="amount"
                v-model="amount"
                :mask="currencyMask"
                class="tw-w-full"
                :placeholder="$formatAmount(settings.payout)"
                type="text"
                autofocus
              />
              <UiFormErrors
                v-if="errors['amount'] && errors['amount'].length > 0"
                :errors="errors['amount']"
              />
            </div>
          </template>

          <div class="tw-font-semibold md:tw-text-lg">
            {{ $t('general.available-balance', [$formatAmount(stats.balance)]) }}
          </div>

          <div class="tw-border-t tw-border-gray-200" />

          <div>
            {{ $t('general.minimum-withdrawal', [$formatAmount(settings.payout)]) }}
          </div>

          <div>
            {{ $t('general.withdrawal-fee', [$formatAmount(settings.fee)]) }}
          </div>
        </div>
      </div>
    </UiModal>
  </div>
</template>

<script>
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import Payout from '@/components/models/Payout';

import UiDisplayInput from '@/components/ui/UiDisplayInput.vue';
import UiButton from '@/components/ui/UiButton.vue';
import UiFormErrors from '@/components/ui/UiFormErrors.vue';
import UiModal from '@/components/ui/UiModal.vue';
import UiIcon from '@/components/ui/UiIcon.vue';
import UiSecurityCodeModal from "@/components/ui/UiSecurityCodeModal.vue";

export default {
  components: {
    UiSecurityCodeModal,
    UiIcon,
    UiModal,
    UiFormErrors,
    UiButton,
    UiDisplayInput,
  },
  props: ['value', 'settings', 'method'],
  data() {
    return {
      amount: '',
      errors: {},
      isModalOpen: false,
      promptSecurityCode: false,
    };
  },
  computed: {
    stats: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    canWithdraw() {
      return this.stats.balance >= this.settings.payout;
    },
    currencyMask() {
      return createNumberMask({
        prefix: process.env.VUE_APP_CURRENCY_SIGN,
        allowDecimal: true,
        includeThousandsSeparator: true,
        allowNegative: false,
      });
    },
    amountValue() {
      return parseInt(this.amount.replace(/[^0-9]/g, ''));
    },
  },
  methods: {
    promptCode() {
      // Used to unset submit button focus and clear autofocus for security code
      document.activeElement.blur();
      this.promptSecurityCode = true
    },
    submitRequest(securityCode) {
      this.$post(
        '/payouts',
        {
          amount: this.amountValue,
          security_code: securityCode,
        },
        (data) => {
          let s = { ...this.stats };
          s.withdraw = new Payout(data);
          this.stats = s;
          this.isModalOpen = false;
          this.promptSecurityCode = false;
          this.$toast({
            title: this.$t('general.success'),
            description: this.$t('general.requested-withdrawal'),
            variant: 'success',
          })
        },
        (errors, { response }) => {
          this.promptSecurityCode = false
          if (response.status === 403) {
            this.$toast({
              title: this.$t('general.error'),
              description: this.$t('security-code.verify-error'),
              variant: 'error',
            })
          } else if (response.status === 429) {
            this.$toast({
              title: this.$t('general.error'),
              description: this.$t('general.too-many-requests'),
              variant: 'error',
            })
          } else {
            this.errors = errors;
          }
        },
      );
    },
  },
};
</script>
