<template>
  <button
    class="tw-p-2.5 tw-font-semibold tw-text-primary-500"
    :class="{ 'tw-opacity-75': disabled }"
    :disabled="disabled"
    @click="(event) => $emit('click', event)"
  >
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    disabled: Boolean,
  },
};
</script>
