export default class Creator {
  
  username = '';
  id = null;
  name = '';
  is_online = false;
  avatar_url = null;
  cover_url = null;
  
  constructor(data) {
    if (!data) {
      return;
    }
    this.id = data.id;
    this.username = data.username;
    this.name = data.name;
    this.is_online = data.is_online;
    this.avatar_url = data.avatar_url;
    this.cover_url = data.cover_url;
  }
  
}