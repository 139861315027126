<template>
  <img
    decoding="async"
    :class="{ 'tw-h-full tw-w-full tw-object-cover': fill }"
    :src="src"
    :srcset="localSrcset"
    :sizes="localSizes"
    @click="(event) => $emit('click', event)"
  >
</template>

<script>
export default {
  props: {
    fill: Boolean,
    src: {
      type: String,
      required: true,
    },
    srcset: String,
    sizes: [String, Object],
  },
  data() {
    return {
      defaultSizes: '100w',
      screens: {
        sm: '640px',
        md: '768px',
        lg: '1024px',
        xl: '1280px',
        '2xl': '1536px',
      },
    };
  },
  computed: {
    localSrcset() {
      if (!this.sizes) {
        // No need for an srcset if there's no sizes
        return null;
      }

      return this.srcset;
    },
    localSizes() {
      if (!this.localSrcset) {
        // No need for sizes if there's no srcset
        return null;
      }

      if (!this.sizes) {
        return this.defaultSizes;
      }

      if (typeof this.sizes === 'string') {
        return this.sizes;
      }

      // Loop through reversed screens array
      const sourceSizes = Object.entries(this.screens).reverse().reduce((result, [key, value]) => {
        if (this.sizes[key] !== undefined) {
          result.push(`(min-width: ${value}) ${this.sizes[key]}`);
        }

        return result;
      }, []);

      // The last value will be the default that is chosen when none of the media
      // conditions are true. So if an initial value is specified, put it as the last
      // default value. Put a value of '100vw' otherwise.
      sourceSizes.push(this.sizes.initial ?? this.defaultSizes);

      return sourceSizes.join(', ');
    },
  },
};
</script>
