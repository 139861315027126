<template>
  <div>
    <UiCard>
      <UiNavigationBar
        bleed
        responsive
        :title="postId ? $t('general.edit-post') : $t('general.new-post')"
        @back="$router.go(-1)"
      >
        <UiButton
            size="sm"
            variant="primary"
            icon="send"
            @click="submitPost"
            :disabled="!message || !canPost"
        >
          {{ $t('general.post') }}
        </UiButton>
      </UiNavigationBar>

      <!-- Poll -->
      <UiPoll
        v-if="poll.length > 0"
        class="tw-hidden md:tw-w-full md:tw-mb-6 md:tw-block"
        editable
        :options="poll"
        :title="message"
        @edit="isPollModalOpen = true"
        @remove="pollRemove"
      />

      <!-- Message -->
      <UiFormTextarea
        v-model="message"
        :placeholder="$t('general.write-caption')"
      />

      <!-- Errors -->
      <div
        v-if="errors"
        id="errors"
        class="tw-mt-2 tw-text-sm md:tw-text-base tw-text-error"
      >
        <div
          v-for="(value, key) in errors"
          :key="key"
        >
          <div
            v-for="(error, k) in value"
            :key="k"
          >
            {{ error }}
          </div>
        </div>
      </div>

      <!-- Action buttons -->
      <div class="tw-mt-4 tw-flex tw-items-center tw-space-x-2 md:tw-space-x-3">
        <UiBadge
          color="primary"
          icon="image"
          responsive
          @click="mediaDropzoneClick"
        >
          {{ $t('general.media') }}
        </UiBadge>
        <UiBadge
          color="info"
          icon="wallet"
          responsive
          @click="isPriceModalOpen = true"
        >
          {{ price > 0 ? priceFormat() : $t('general.price') }}
        </UiBadge>
        <UiBadge
          color="warning"
          icon="time-circle"
          responsive
          @click="isExpireModalOpen = true"
        >
          {{ expire ? $tc('general.x-days', expire) : $t('general.expiration') }}
        </UiBadge>
        <UiBadge
          color="success"
          icon="chart"
          responsive
          @click="onPollAdd"
        >
          {{ $t('general.poll') }}
        </UiBadge>
        <UiBadge
          color="primary"
          icon="calendar"
          responsive
          @click="isScheduleModalOpen = true"
        >
          {{ scheduleDate !== null ? scheduleFormat() : $t('general.schedule') }}
        </UiBadge>
      </div>

      <!-- Media -->
      <UiMediaUploader
        ref="uploader"
        v-model="media"
        @upload-ended="canPost = true"
        @upload-started="canPost = false"
      />

      <!-- Mobile fields -->
      <div class="tw-mt-6 tw-divide-y tw-divide-gray-200 md:tw-hidden">
        <div
          v-if="price > 0"
          class="tw-py-4"
        >
          <div class="tw-mb-3 tw-font-semibold">
            {{ $t('general.price') }}
          </div>
          <div class="tw-flex tw-items-center tw-space-x-3">
            <UiFormInput
              id="price"
              v-model="price"
              type="number"
              class="tw-grow"
              name="price"
              :placeholder="$t('general.free')"
            />
            <button @click="requestPriceRemove">
              <UiIcon
                name="delete"
              />
            </button>
          </div>
        </div>

        <div
          v-if="expire > 0"
          class="tw-py-4"
        >
          <div class="tw-mb-3 tw-font-semibold">
            {{ $t('general.expiration-date') }}
          </div>
          <div class="tw-flex tw-items-center tw-space-x-3">
            <UiFormInput
              id="expiration"
              class="tw-grow"
              name="expiration"
              :placeholder="$t('general.expiration-date')"
              :readonly="true"
              :value="$tc('general.x-days', expire)"
            />
            <button @click="requestExpireRemove">
              <UiIcon
                name="delete"
              />
            </button>
          </div>
        </div>

        <div
          v-if="scheduleDate !== null"
          class="tw-py-4"
        >
          <div class="tw-mb-3 tw-font-semibold">
            {{ $t('general.schedule') }}
          </div>
          <div class="tw-flex tw-items-center tw-space-x-3">
            <UiFormInput
              id="expiration"
              class="tw-grow"
              name="expiration"
              :placeholder="$t('general.expiration-date')"
              :readonly="true"
              :value="scheduleFormat()"
            />
            <button @click="requestScheduleRemove">
              <UiIcon
                name="delete"
              />
            </button>
          </div>
        </div>

        <div
          v-if="poll.length > 0"
          class="tw-py-4"
        >
          <div class="tw-mb-3 tw-flex tw-items-center tw-space-x-3">
            <div class="tw-grow tw-font-semibold">
              {{ $t('general.poll') }}
            </div>
            <button @click="requestPollRemove">
              <UiIcon
                name="delete"
              />
            </button>
          </div>
          <div class="tw-space-y-3">
            <UiFormInput
              v-for="(option, index) in poll"
              :key="index"
              v-model="poll[index]"
              :dismissible="true"
              name="options[]"
              :placeholder="$t('general.answer')"
              @dismiss="pollRemoveOption(index)"
            />
            <UiTextButton
              v-if="poll.length < 5"
              @click="pollAddOption"
            >
              {{ $t('general.add-answer') }}
            </UiTextButton>
            <div v-else>
              {{ $t('general.max-answers') }}
            </div>
          </div>
        </div>
      </div>
    </UiCard>

    <!-- Modals -->
    <UiModal
      v-if="isPriceModalOpen"
      id="modalPrice"
      :title="$t('general.post-price')"
      :cancel="$t('general.cancel')"
      :confirm="$t('general.add')"
      size="sm"
      @cancel="onPriceModalCancel"
      @confirm="isPriceModalOpen = false"
      @close="isPriceModalOpen = false"
    >
      <UiFormInput
        id="price"
        v-model="price"
        type="number"
        :label="$t('general.price')"
        name="price"
        :placeholder="$t('general.free')"
        :prepend="currencySign"
      />
    </UiModal>

    <UiModal
      v-if="isExpireModalOpen"
      id="modalExpire"
      :title="$t('general.expiration-period')"
      :cancel="$t('general.cancel')"
      :confirm="$t('general.add')"
      size="md"
      @cancel="onExpireModalCancel"
      @confirm="isExpireModalOpen = false"
      @close="isExpireModalOpen = false"
    >
      <div class="sm:tw-hidden">
        <UiMobileSelect
          v-model="expire"
          :placeholder="$t('general.choose-period')"
          :options="expireOptions"
        />
      </div>

      <div class="tw-hidden sm:tw-block">
        <div class="tw-flex tw-space-x-2">
          <UiPill
            v-for="(option, key) in expireOptions"
            :key="key"
            v-model="expire"
            :value="key"
            variant="neutral"
          >
            {{ option }}
          </UiPill>
        </div>
      </div>
    </UiModal>

    <UiModal
      v-if="isPollModalOpen"
      id="modalExpire"
      :title="$t('general.poll')"
      :cancel="$t('general.cancel')"
      :confirm="$t('general.add')"
      size="sm"
      @cancel="onPollModalCancel"
      @confirm="isPollModalOpen = false"
      @close="isPollModalOpen = false"
    >
      <div
        v-if="poll.length > 0"
        class="tw-w-full"
      >
        <div class="tw-space-y-3">
          <UiFormInput
            v-for="(option, index) in poll"
            :key="index"
            v-model="poll[index]"
            :dismissible="true"
            :label="index === 0 && $t('general.answer-options')"
            name="options[]"
            :placeholder="$t('general.answer')"
            @dismiss="pollRemoveOption(index)"
          />
          <UiTextButton
            v-if="poll.length < 5"
            @click="pollAddOption"
          >
            {{ $t('general.add-answer') }}
          </UiTextButton>
          <div v-else>
            {{ $t('general.max-answers') }}
          </div>
        </div>
      </div>
    </UiModal>

    <UiModal
      v-if="isScheduleModalOpen"
      :cancel="$t('general.cancel')"
      :confirm="$t('general.add')"
      size="lg"
      @cancel="onScheduleModalCancel"
      @confirm="isScheduleModalOpen = false"
    >
      <div>
        <VDatePicker
          v-model="scheduleDate"
          color="red"
          :columns="$screens({ default: 1, md: 2 })"
          is-expanded
          mode="dateTime"
        />
      </div>
    </UiModal>

    <UiAlertModal
      v-if="isAlertModalOpen"
      :title="alertModalTitle"
      :message="alertModalMessage"
      :cancel="$t('general.cancel')"
      :confirm="$t('general.yes-delete')"
      @cancel="isAlertModalOpen = false"
      @confirm="alertModalConfirmHandler"
      @close="isAlertModalOpen = false"
    />
  </div>
</template>
<style scoped lang="scss">
textarea {
  border: none;
  scroll-behavior: smooth;
}

textarea:focus {
  border: none;
  box-shadow: none;
}
</style>
<script>
import dayjs from 'dayjs';

var localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(localizedFormat);

import Media from '@/components/models/Media';

import VDatePicker from 'v-calendar/lib/components/date-picker.umd';

import UiBadge from '@/components/ui/UiBadge.vue';
import UiCard from '@/components/ui/UiCard.vue';
import UiFormTextarea from '@/components/ui/UiFormTextarea.vue';
import UiMediaUploader from '@/components/ui/UiMediaUploader.vue';
import UiNavigationBar from '@/components/ui/UiNavigationBar.vue';
import UiTextButton from '@/components/ui/UiTextButton.vue';
import UiModal from '@/components/ui/UiModal.vue';
import UiFormInput from '@/components/ui/UiFormInput.vue';
import UiPoll from '@/components/ui/UiPoll.vue';
import UiIcon from '@/components/ui/UiIcon.vue';
import UiAlertModal from '@/components/ui/UiAlertModal.vue';
import UiPill from '@/components/ui/UiPill.vue';
import UiMobileSelect from '@/components/ui/UiMobileSelect.vue';
import UiButton from "@/components/ui/UiButton.vue";

const SCREEN_MD = 768;

export default {
  components: {
    UiButton,
    UiMobileSelect,
    UiPill,
    UiAlertModal,
    UiIcon,
    UiPoll,
    UiFormInput,
    UiModal,
    UiBadge,
    UiCard,
    UiFormTextarea,
    UiMediaUploader,
    UiNavigationBar,
    UiTextButton,
    VDatePicker,
  },
  data: function () {
    return {
      canPost: true,
      errors: {},
      message: '',
      currencySign: process.env.VUE_APP_CURRENCY_SIGN,
      media: [],
      poll: [],
      expire: 0,
      expireOptions: {
        3: this.$tc('general.x-days', 3),
        7: this.$tc('general.x-days', 7),
        30: this.$tc('general.x-days', 30),
      },
      scheduleDate: null,
      price: null,
      isPriceModalOpen: false,
      isExpireModalOpen: false,
      isPollModalOpen: false,
      isScheduleModalOpen: false,
      isAlertModalOpen: false,
      alertModalTitle: '',
      alertModalMessage: '',
      alertModalConfirmHandler: null,
    };
  },
  computed: {
    postId() {
      return this.$route.params.id;
    },
  },
  mounted() {
    if(!this.$store.state.currentUser.isCreator) {
      this.$router.push('/verify');
    }
    
    if (this.postId) {
      this.loadPost();
    }
  },
  watch: {
    postId: function (newV, oldV) {
      if (oldV != newV) {
        this.reset();
        if (newV) {
          this.loadPost();
        }
      }
    },
  },
  methods: {
    reset() {
      this.errors = {};
      this.message = '';
      this.media = [];
      this.poll = [];
      this.expire = 0;
      this.scheduleDate = null;
      this.price = null;
      this.$refs.uploader.clean();
    },
    loadPost() {
      this.$showSpinner();
      this.$get(
        '/posts/' + this.postId,
        (data) => {
          this.message = data.message;
          for (let m of data.media) {
            if (m.type === Media.TYPE_VIDEO) {
              m.url = m.screenshot
            }
          }
          this.media = data.media;
          this.$nextTick(function () {
            this.$refs.uploader.manual();
          });

          for (let p of data.poll) {
            this.poll.push(p.option);
          }
          this.expire = data.expires;
          if (data.schedule) {
            this.scheduleDate = data.schedule
          }
          this.price = data.price;
        },
        (errors) => {
          console.log(errors);
        },
      );
    },
    mediaDropzoneClick() {
      this.$refs.uploader.click();
    },
    pollAdd() {
      if (this.poll.length === 0) {
        this.poll = ['', ''];
      }
    },
    pollRemove() {
      this.poll = [];
    },
    pollAddOption() {
      this.poll.push('');
    },
    pollRemoveOption(index) {
      this.poll.splice(index, 1);
    },
    onPollAdd() {
      if (window.innerWidth >= SCREEN_MD) {
        this.isPollModalOpen = true;
      }

      this.pollAdd();
    },
    onPollModalCancel() {
      this.isPollModalOpen = false;
      this.pollRemove();
    },
    requestPollRemove() {
      this.isAlertModalOpen = true;
      this.alertModalTitle = this.$t('general.delete-x-title', [this.$t('general.poll')]);
      this.alertModalMessage = this.$t('general.delete-x-message', [this.$t('general.poll').toLowerCase()]);
      this.alertModalConfirmHandler = () => {
        this.isAlertModalOpen = false;
        this.pollRemove();
      };
    },
    scheduleFormat() {
      return this.scheduleDate !== null
        ? dayjs(this.scheduleDate).format('ll')
        : '';
    },
    scheduleFormatIso() {
      return this.scheduleDate !== null
        ? dayjs(this.scheduleDate).toISOString()
        : null;
    },
    scheduleRemove() {
      this.scheduleDate = null;
    },
    requestScheduleRemove() {
      this.isAlertModalOpen = true;
      this.alertModalTitle = this.$t('general.delete-x-title', [this.$t('general.schedule')]);
      this.alertModalMessage = this.$t('general.delete-x-message', [this.$t('general.schedule').toLowerCase()]);
      this.alertModalConfirmHandler = () => {
        this.isAlertModalOpen = false;
        this.scheduleRemove();
      };
    },
    onScheduleModalCancel() {
      this.isScheduleModalOpen = false;
      this.scheduleRemove();
    },
    expireRemove() {
      this.expire = 0;
    },
    onExpireModalCancel() {
      this.isExpireModalOpen = false;
      this.expireRemove();
    },
    requestExpireRemove() {
      this.isAlertModalOpen = true;
      this.alertModalTitle = this.$t('general.delete-x-title', [this.$t('general.expiration-date')]);
      this.alertModalMessage = this.$t('general.delete-x-message', [this.$t('general.expiration-date').toLowerCase()]);
      this.alertModalConfirmHandler = () => {
        this.isAlertModalOpen = false;
        this.expireRemove();
      };
    },
    priceFormat() {
      return this.currencySign + this.price;
    },
    priceRemove() {
      this.price = null;
    },
    onPriceModalCancel() {
      this.isPriceModalOpen = false;
      this.priceRemove();
    },
    requestPriceRemove() {
      this.isAlertModalOpen = true;
      this.alertModalTitle = this.$t('general.delete-x-title', [this.$t('general.price')]);
      this.alertModalMessage = this.$t('general.delete-x-message', [this.$t('general.price').toLowerCase()]);
      this.alertModalConfirmHandler = () => {
        this.isAlertModalOpen = false;
        this.priceRemove();
      };
    },
    submitPost() {
      this.errors = {};

      const videos = this.media.filter(media => media.type === Media.TYPE_VIDEO);

      let media = [];

      for (let m of this.media.filter(media => media.type !== Media.TYPE_VIDEO)) {
        media.push({
          id: m.id,
          screenshot: m.scr ? m.scr.id : null,
        });
      }

      this.errors = {};
      let fields = {
        message: this.message,
      };

      if (media.length > 0) {
        fields.media = media;
      }

      if (this.poll && this.poll.length > 0) {
        fields.poll = this.poll;
      }

      if (this.expire) {
        fields.expires = this.expire;
      }

      if (this.scheduleFormatIso() != null) {
        fields.schedule = this.scheduleFormatIso();
      }

      if (this.price) {
        fields.price = this.price;
      }

      if (this.postId) {
        fields._method = 'PUT';
      }

      if (videos.length > 0) {
        fields.videos = videos;
      }

      this.$post(
        '/posts' + (this.postId ? '/' + this.postId : ''),
        fields,
        () => {
          if (this.postId) {
            this.$router.go(-1);
          } else {
            this.$router.replace('/');
          }
        },
        (errors) => {
          this.errors = errors;
        },
      );
    },
  },
};
</script>
