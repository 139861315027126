<template>
  <UiModal
    :cancel="cancel"
    :confirm="confirm"
    :size="size"
    v-on="$listeners"
  >
    <div class="tw-text-center">
      <div class="tw-text-2xl tw-font-semibold">
        {{ title }}
      </div>
      <div class="tw-mt-3">
        {{ message }}
      </div>
      <slot/>
    </div>
  </UiModal>
</template>

<script>
import UiModal from '@/components/ui/UiModal.vue';

export default {
  components: {
    UiModal,
  },
  props: {
    size: {
      type: String,
      default: 'sm'
    },
    title: String,
    message: String,
    cancel: String,
    confirm: String,
  },
};
</script>
