<template>
  <div>
    <div
      v-if="loading"
      :class="grid({ cols })"
    >
      <UiSkeleton
        v-for="index in 6"
        :key="index"
        class="tw-aspect-square"
        :rounded="{ initial: 'default', xl: 'none' }"
      />
    </div>

    <div
      v-else-if="vault.length > 0"
      :class="grid({ cols })"
    >
      <div
        v-for="media in vault"
        :key="media.id"
        class="tw-relative tw-aspect-square tw-overflow-hidden tw-rounded-2xl xl:tw-rounded-none"
        @click="onSelectMedia(media)"
      >
        <img
          alt=""
          class="tw-w-full tw-h-full tw-object-cover"
          :class="{ 'tw-opacity-75': type && isChecked(media) }"
          :src="media.type === types.image ? media.url : media.screenshot"
        >

        <div
          class="tw-absolute tw-inset-0 tw-border-2 tw-rounded-[inherit] tw-cursor-pointer"
          :class="[
            type === 'checkable' && isChecked(media) ? 'tw-border-primary-500': 'tw-border-transparent',
          ]"
        >
          <div
            v-if="type !== 'numbered'"
            class="
              tw-absolute tw-top-1 tw-left-1 tw-py-0.5 tw-px-1.5 tw-text-sm tw-bg-white tw-text-primary-500 tw-rounded-lg
              lg:tw-left-2 lg:tw-top-2 lg:tw-py-1 lg:tw-px-2 lg:tw-text-base
            "
          >
            {{ media.created_at.format('L') }}
          </div>

          <div
            v-if="type === 'numbered' && isChecked(media)"
            class="tw-absolute tw-top-1 tw-right-1 tw-py-0.5 tw-px-1.5 tw-min-w-6 tw-text-center tw-text-sm tw-bg-primary-500 tw-text-white tw-rounded-lg"
          >
            {{ checked.findIndex((m) => m.id === media.id) + 1 }}
          </div>

          <div
            v-if="media.type === types.video"
            class="tw-absolute tw-left-2 tw-bottom-2 tw-flex tw-items-center tw-space-x-1 tw-text-white"
          >
            <UiIcon
              name="video"
              solid
            />
            <div>
              {{ media.duration }}
            </div>
          </div>

          <UiCheckbox
            v-if="type === 'checkable'"
            :checked="isChecked(media)"
            class="tw-absolute tw-right-2 tw-bottom-2 lg:tw-top-2"
            :value="media.id"
            @click.native.stop
            @change="onSelectMedia(media)"
          />
        </div>
      </div>
    </div>

    <div
      v-else
      class="tw-mt-6 tw-text-center"
    >
      <img
        alt=""
        class="tw-inline-block tw-w-44"
        :src="require('@/assets/illustrations/not-found.svg')"
      >
      <div class="tw-mt-8">
        {{ $t('general.no-vault-media-yet') }}
      </div>
    </div>
  </div>
</template>

<script>
import UiCheckbox from '@/components/ui/UiCheckbox.vue';
import UiIcon from '@/components/ui/UiIcon.vue';
import UiSkeleton from '@/components/ui/UiSkeleton.vue';
import { tv } from 'tailwind-variants';

export default {
  components: {
    UiSkeleton,
    UiIcon,
    UiCheckbox,
  },
  props: {
    vault: Array,
    value: Array,
    loading: Boolean,
    type: String, // 'checkable' | 'numbered'
    cols: [Number, Object],
  },
  computed: {
    checked: {
      get() {
        return this.value;
      },
      set(checked) {
        this.$emit('input', checked);
      },
    },
    types() {
      return {
        image: 'image',
        video: 'video',
      };
    },
    selectable() {
      return this.type !== undefined;
    },
    grid() {
      return tv(
        {
          base: 'tw-grid tw-gap-1',
          variants: {
            cols: {
              3: 'tw-grid-cols-3',
              4: 'tw-grid-cols-4',
              5: 'tw-grid-cols-5',
            },
          },
        },
        {
          responsiveVariants: ['md', 'lg', 'xl'],
        },
      );
    },
  },
  methods: {
    isChecked(media) {
      return this.checked.findIndex((m) => m.id === media.id) > -1;
    },
    onSelectMedia(media) {
      if (this.selectable) {
        const newValue = [...this.checked];
        const index = newValue.findIndex((m) => m.id === media.id);

        if (index > -1) {
          newValue.splice(index, 1);
        } else {
          newValue.push(media);
        }

        this.checked = newValue;
      } else {
        this.$showPhotoSwipe(this.vault, media.id);
      }
    },
  },
};
</script>
