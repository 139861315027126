<template>
  <UiCard>
    <UiNavigationBar
      bleed
      :title="$t('general.change-email')"
      to="/security"
    />

    <div>
      <form
        class="tw-w-full tw-space-y-4"
        @submit.prevent="saveSettings"
      >
        <ui-form-input
          type="email"
          name="email"
          v-model="email"
          :errors="errors"
          :label="$t('general.email')"
        />
        <ui-form-input
          type="password"
          name="password"
          v-model="password"
          :errors="errors"
          :label="$t('general.password')"
        />
      </form>

      <div class="tw-mt-6">
        <UiButton
          class="tw-w-full sm:tw-max-w-xs"
          variant="primary"
          @click.prevent="saveSettings"
        >
          {{ $t('general.change-email') }}
        </UiButton>
      </div>
    </div>
  </UiCard>
</template>
<script>
import UiButton from '@/components/ui/UiButton.vue';
import UiCard from '@/components/ui/UiCard.vue';
import UiFormInput from '../ui/UiFormInput.vue';
import UiNavigationBar from '@/components/ui/UiNavigationBar.vue';

export default {
  components: {
    UiButton,
    UiNavigationBar,
    UiCard,
    UiFormInput,
  },
  data: function () {
    return {
      email: '',
      password: '',
      errors: {},
    };
  },
  mounted() {
    this.loadUser();
  },
  methods: {
    loadUser() {
      this.$get(
        '/auth/me',
        (data) => {
          this.email = data.email;
        },
        (errors) => {
          console.log(errors);
        },
      );
    },
    saveSettings() {
      this.errors = {};
      this.$post(
        '/profile/email',
        {
          email: this.email,
          password: this.password,
        },
        () => {
          this.$bvToast.toast(this.$t('general.settings-saved'), {
            autoHideDelay: 2000,
            title: this.$t('general.success'),
            solid: true,
            toaster: 'b-toaster-bottom-left',
          });
        },
        (errors) => {
          this.errors = errors;
        },
      );
    },
  },
};
</script>
