<template>
  <div class="settings" v-if="user != null">
    <div>
      <UiCard>
        <UiNavigationBar
          bleed
          :title="$t('general.edit-profile')"
          @back="$router.go(-1)"
        />

        <UiProfileCover
          editable
          :progress="type === 'cover' && uploadProgress !== null ? uploadProgress : null"
          :sizes="{ lg: '56vw', xl: '65vw' }"
          :src="user.cover_url"
          :srcset="user.cover_variants"
          @remove="onRequestRemoval('cover')"
          @upload="onRequestUpload('cover')"
        />

        <div class="tw-mt-6 tw-flex tw-justify-center md:tw-justify-start">
          <div class="tw-relative tw-rounded-full">
            <UiAvatar
              :size="{ initial: 'md', md: 'lg' }"
              :src="user.avatar_url"
              text=" "
            />
            <div
              class="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center tw-rounded-full"
              :class="user.avatar_url ? 'tw-text-white tw-bg-black/40' : 'tw-text-gray-900 tw-bg-gray-50'"
            >
              <UiProgress
                v-if="type === 'avatar' && uploadProgress !== null"
                class="tw-w-4/5"
                :value="uploadProgress"
              />

              <div
                v-else
                class="tw-flex tw-items-center tw-space-x-2"
              >
                <button @click="onRequestUpload('avatar')">
                  <UiIcon
                    name="camera"
                    solid
                  />
                </button>
                <button
                  v-if="user.avatar_url"
                  @click="onRequestRemoval('avatar')"
                >
                  <UiIcon
                    name="close"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="tw-mt-6">
          <form
            class="tw-w-full"
            @submit.prevent="saveSettings"
          >
            <div class="tw-flex tw-flex-wrap tw-gap-4">
              <UiFormInput
                v-model="user.username"
                class="tw-basis-full md:tw-basis-0 md:tw-grow"
                :errors="errors"
                :label="$t('general.username')"
                name="username"
                :placeholder="$t('general.username')"
                type="text"
              />
              <UiFormInput
                v-model="user.name"
                class="tw-basis-full md:tw-basis-0 md:tw-grow"
                :errors="errors"
                :label="$t('general.display-name')"
                name="name"
                :placeholder="$t('general.display-name')"
                type="text"
              />
              <UiFormTextarea
                name="bio"
                v-model="user.bio"
                class="tw-basis-full"
                :errors="errors"
                :label="$t('general.bio')"
                :placeholder="$t('general.bio')"
              />
              <UiFormInput
                v-model="user.location"
                class="tw-basis-full"
                :errors="errors"
                :label="$t('general.location')"
                name="location"
                :placeholder="$t('general.location')"
                type="text"
              />
              <UiFormInput
                v-model="user.website"
                class="tw-basis-full"
                :errors="errors"
                :label="$t('general.website')"
                name="website"
                :placeholder="$t('general.website')"
                type="url"
              />
            </div>

            <UiButton
              class="tw-mt-6 tw-w-full sm:tw-w-auto sm:tw-min-w-[15rem] sm:tw-max-w-full"
              type="submit"
              variant="primary"
            >
              {{ $t('general.update') }}
            </UiButton>
          </form>
        </div>
      </UiCard>
    </div>

    <UiAlertModal
      v-if="isDeletePhotoModalOpen"
      :title="$t('general.delete-x-title', [$t('general.photo')])"
      :message="$t('general.delete-x-message', [$t('general.photo')])"
      :cancel="$t('general.cancel')"
      :confirm="$t('general.delete')"
      @cancel="isDeletePhotoModalOpen = false"
      @confirm="remove"
    />

    <input
      ref="file"
      hidden
      type="file"
      @change="upload"
    />
  </div>
</template>

<script>
import User from '@/components/models/User';

import UiFormInput from '@/components/ui/UiFormInput.vue';
import UiFormTextarea from '@/components/ui/UiFormTextarea.vue';
import UiProfileCover from '@/components/ui/UiProfileCover.vue';
import UiAvatar from '@/components/ui/UiAvatar.vue';
import UiIcon from '@/components/ui/UiIcon.vue';
import UiCard from '@/components/ui/UiCard.vue';
import UiButton from '@/components/ui/UiButton.vue';
import UiNavigationBar from '@/components/ui/UiNavigationBar.vue';
import UiAlertModal from '@/components/ui/UiAlertModal.vue';
import UiProgress from '@/components/ui/UiProgress.vue';

export default {
  data: function () {
    return {
      user: null,
      errors: {},
      type: null,
      isDeletePhotoModalOpen: false,
      uploadProgress: null,
    };
  },
  components: {
    UiProgress,
    UiAlertModal,
    UiNavigationBar,
    UiButton,
    UiCard,
    UiIcon,
    UiAvatar,
    UiProfileCover,
    UiFormInput,
    UiFormTextarea,
  },
  mounted() {
    this.loadUser();
  },
  methods: {
    loadUser() {
      this.$get(
        '/auth/me',
        (data) => {
          this.user = new User(data);
        },
        (errors) => {
          console.log(errors);
        },
      );
    },
    saveSettings() {
      this.errors = {};
      this.$post(
        '/profile',
        {
          username: this.user.username,
          name: this.user.name,
          bio: this.user.bio,
          location: this.user.location,
          website: this.user.website,
        },
        (data) => {
          this.user = new User(data);
          this.$saveUser(data);
          this.$bvToast.toast(this.$t('general.settings-saved'), {
            autoHideDelay: 2000,
            title: this.$t('general.success'),
            solid: true,
            toaster: 'b-toaster-bottom-left',
          });
        },
        (errors) => {
          this.errors = errors;
        },
      );
    },
    upload() {
      this.$delegatedUpload(
        this.$refs.file.files[0],
        (progress) => {
          this.uploadProgress = progress;
        },
        (data) => {
          this.$post(
            '/profile/image/' + this.type,
            {
              uuid: data.uuid,
              key: data.key,
              extension: data.extension,
            },
            (data) => {
              this.uploadProgress = null;
              this.user = new User(data);
              this.$saveUser(data);
            },
            (errors) => {
              this.uploadProgress = 0;
              errors.image?.forEach(msg => {
                this.$toast({
                  title: this.$t('general.error'),
                  description: msg,
                  variant: 'error',
                })
              });
            },
          );
        },
      );
    },
    remove() {
      this.isDeletePhotoModalOpen = false;
      this.$post(
        '/profile/image/' + this.type,
        {},
        (data) => {
          this.user = new User(data);
          this.$saveUser(data);
        },
        (errors) => {
          console.log(errors);
        },
      );
    },
    onRequestUpload(type) {
      this.type = type;
      this.$refs.file.click();
    },
    onRequestRemoval(type) {
      this.type = type;
      this.isDeletePhotoModalOpen = true;
    },
  },
};
</script>
