import dayjs from 'dayjs';

export default class Contest {

  uuid = null;
  user_id = null;
  username = '';
  is_creator = false;
  path = '';
  url = '';
  variants = '';
  votes_count = 0;
  has_voted = false;
  contest_uuid = '';
  created_at = null;
  updated_at = null;

  constructor(data) {
    if (!data) {
      return;
    }

    this.uuid = data.uuid;
    this.user_id = data.user_id;
    this.username = data.username;
    this.is_creator = data.is_creator;
    this.path = data.path;
    this.url = data.url;
    this.variants = data.variants;
    this.votes_count = data.votes_count;
    this.has_voted = data.has_voted;
    this.contest_uuid = data.contest_uuid;
    this.created_at = dayjs(data.created_at);
    this.updated_at = dayjs(data.updated_at);
  }

}
