var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.notifications),function(item,index){return _c('div',{key:index,staticClass:"tw-my-3"},[(
          index === 0 ||
          (index > 0 &&
            !_vm.notifications[index - 1].created_at.isSame(
              item.created_at,
              'day'
            ))
        )?_c('div',{staticClass:"tw-mb-4 tw-font-semibold md:tw-text-center md:tw-text-lg",class:{ 'tw-mt-6': index > 0 }},[_vm._v(" "+_vm._s(item.created_at.format('ll'))+" ")]):_vm._e(),(item.items.user)?_c('RouterLink',{staticClass:"tw-w-full tw-flex tw-items-center",attrs:{"to":item.url}},[(!item.isViewed)?_c('span',{staticClass:"tw-h-[6px] tw-w-[6px] tw-shrink-0 tw-bg-black tw-rounded-full tw-mr-4 tw-mt-1"}):_vm._e(),_c('UiAvatar',{attrs:{"to":item.items.user.url,"text":item.items.user.initials,"src":item.items.user.avatar_url,"size":"sm"}}),_c('div',{staticClass:"tw-ml-3 tw-overflow-hidden"},[_c('UiUsername',{staticClass:"tw-overflow-hidden",attrs:{"user":item.items.user}}),_c('div',{staticClass:"tw-text-gray-400"},[_vm._v(" "+_vm._s(_vm.$t(item.description[0], item.description[1]))+" ")])],1)],1):_c('div',{staticClass:"tw-w-full tw-flex tw-items-center"},[(!item.isViewed)?_c('span',{staticClass:"tw-h-[6px] tw-w-[6px] tw-shrink-0 tw-bg-black tw-rounded-full tw-mr-4 tw-mt-1"}):_vm._e(),_c('div',{staticClass:"tw-ml-3 tw-overflow-hidden"},[_c('div',{staticClass:"tw-flex tw-items-center tw-text-base md:tw-text-lg"},[_c('span',{staticClass:"tw-truncate tw-font-bold"},[_vm._v(" System ")])]),_c('div',{staticClass:"tw-text-gray-400"},[_vm._v(" "+_vm._s(_vm.$t(item.description[0], item.description[1]))+" ")])])])],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }