<template>
  <UiModal
    :cancel="$t('general.cancel')"
    :confirm="$t('general.verify-identity')"
    size="md"
    @cancel="$store.state.showBecomeCreatorModal = false"
    @confirm="confirm"
  >
    <div class="tw-flex tw-flex-col tw-items-center tw-text-center">
      <div class="tw-font-semibold tw-mt-6 tw-text-xl md:tw-font-bold md:tw-text-4xl">
        {{ $t('general.become-creator') }}
      </div>
      <div class="tw-mt-4 md:tw-text-lg">
        {{ $t('general.verification-info') }}
      </div>

      <img
        alt=""
        class="tw-w-full tw-mt-10 tw-mb-4"
        :src="require('@/assets/illustrations/verify.svg')"
      />
    </div>
  </UiModal>
</template>

<script>
import UiModal from "@/components/ui/UiModal.vue";

export default {
  components: {UiModal},
  methods: {
    confirm() {
      this.$store.state.showBecomeCreatorModal = false;
      this.$router.push('/verify')
    }
  }
}
</script>