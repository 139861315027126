<template>
  <UiModal
    size="sm"
    :title="title"
    :confirm="$t('general.continue')"
    :cancel="cancel ? $t('general.cancel') : null"
    :disabled="pinCode === '' || pinCode === '0000' || pinCode.length !== 4"
    @confirm="$emit('submit', pinCode)"
    @cancel="$emit('cancel')"
  >
    <slot />

    <UiCard class="tw-shadow-[0px_4px_60px_rgba(4,6,15,0.05)]">
      <div class="tw-flex tw-justify-center">
        <UiPinInput
          class="tw-block"
          @update="pinCode = $event"
        />
      </div>
    </UiCard>
  </UiModal>
</template>

<script>
import UiModal from "@/components/ui/UiModal.vue";
import UiPinInput from "@/components/ui/UiPinInput.vue";
import UiCard from "@/components/ui/UiCard.vue";

export default {
  components: { UiCard, UiModal, UiPinInput },
  props: {
    title: String,
    cancel: Boolean,
  },
  data() {
    return {
      pinCode: '',
    }
  },
}
</script>
