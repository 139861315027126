<template>
  <UiNotFound class="tw-w-full not-found" />
</template>

<script>
import UiNotFound from '@/components/ui/UiNotFound.vue';

export default {
  components: {
    UiNotFound,
  },
};
</script>
