<template>
  <div>
    <UiModal
      v-if="visible"
      action="plus"
      :confirm="$t('general.save')"
      :title="$t('general.add-to-list')"
      size="sm"
      @action="isNewListModalOpen = true"
      @open="loadLists"
      @confirm="visible = false"
      @close="visible = false"
    >
      <div class="tw-space-y-4">
        <div
          v-for="(item, index) in lists"
          :key="index"
        >
          <UiCheckboxCard
            v-model="contains[item.id]"
            @change="add(item.id)"
          >
            {{ item.title }}
          </UiCheckboxCard>
        </div>
      </div>
    </UiModal>

    <UiModal
      v-if="isNewListModalOpen"
      size="sm"
      :title="$t('general.new-list')"
      :cancel="$t('general.cancel')"
      :confirm="$t('general.save')"
      @cancel="isNewListModalOpen = false"
      @confirm="createNewList"
    >
      <form
        @submit.prevent="createNewList"
      >
        <UiFormInput
          v-model="newListTitle"
          :errors="errors"
          name="title"
          :placeholder="$t('general.enter-list-title')"
          type="text"
        />
      </form>
    </UiModal>
  </div>
</template>
<script>
import UiFormInput from './UiFormInput.vue';
import List from '../models/List';
import UiModal from '@/components/ui/UiModal.vue';
import UiCheckboxCard from '@/components/ui/UiCheckboxCard.vue';

export default {
  components: { UiCheckboxCard, UiModal, UiFormInput },
  data: function () {
    return {
      lists: [],
      contains: {},
      newListTitle: '',
      errors: {},
      isNewListModalOpen: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.addToListUser;
    },
    visible: {
      get() {
        return this.$store.state.addToListUser !== null;
      },
      set() {
        this.$store.state.addToListUser = null;
      },
    },
  },
  methods: {
    loadLists() {
      let lists = [];
      this.contains = {};
      this.$get(
        '/lists/user/' + this.user.id,
        (data) => {
          for (let l of data.lists) {
            lists.push(new List(l, this));
          }
          this.lists = lists;
          for (let c of data.contains) {
            this.$set(this.contains, c, true)
          }
        },
        (errors) => {
          console.log(errors);
        },
      );
    },
    add(listId) {
      this.$post(
        '/lists/' + this.user.id + '/' + listId,
        {},
        (data) => {
          if (data.status) {
            this.contains[listId] = true;
          } else {
            this.contains[listId] = false;
          }
        },
        (errors) => {
          console.log(errors);
        },
      );
    },
    createNewList() {
      this.isNewListModalOpen = false;

      this.$post(
        '/lists',
        {
          title: this.newListTitle,
        },
        (data) => {
          this.lists.push(new List(data, this));
        },
        (errors) => {
          this.errors = errors;
        },
      );
    },
  },
};
</script>
