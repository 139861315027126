<template>
  <UiModal
    size="sm"
    @close="$emit('close')"
  >
    <div class="tw-flex tw-flex-col tw-items-center tw-justify-center">
      <div class="tw-relative">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 186 180"
          class="tw-block tw-w-full"
        >
          <circle cx="95.5" cy="90.5" r="70.5" fill="url(#paint0_linear_1350_15178)" />
          <circle cx="178.467" cy="27.5" r="7.5" fill="#FF94A4" />
          <circle cx="20.0332" cy="10" r="10" fill="#FF94A4" />
          <circle cx="10.0332" cy="133" r="5" fill="#FF94A4" />
          <circle cx="165.533" cy="160.5" r="2.5" fill="#FF94A4" />
          <circle cx="106.533" cy="4.5" r="2.5" fill="#FF94A4" />
          <circle cx="62.5332" cy="176.5" r="3.5" fill="#FF94A4" />
          <circle cx="122.033" cy="171" r="1" fill="#FF94A4" />
          <circle cx="170.533" cy="110.5" r="2.5" fill="#FF94A4" />
          <circle cx="1.0332" cy="75" r="1" fill="#FF94A4" />
          <defs>
            <linearGradient id="paint0_linear_1350_15178" x1="166" y1="161" x2="-1.77734" y2="112.355" gradientUnits="userSpaceOnUse">
              <stop offset="0" stop-color="#FF4D67" />
              <stop offset="1" stop-color="#FF8A9B" />
            </linearGradient>
          </defs>
        </svg>

        <UiIcon
          class="tw-absolute tw-top-1/2 tw-left-1/2 -tw-translate-y-1/2 -tw-translate-x-1/2 tw-h-16 tw-w-16 tw-text-white"
          :name="icon"
          solid
        />
      </div>

      <div class="tw-text-center">
        <div class="tw-mt-8 tw-text-h3 tw-font-bold tw-text-primary-500">
          {{ title }}
        </div>

        <div class="tw-mt-4 tw-text-lg">
          {{ message }}
        </div>

        <div class="tw-mt-6">
          <UiButton
            class="tw-w-full"
            variant="primary"
            @click="$emit('confirm')"
          >
            {{ button }}
          </UiButton>
        </div>
      </div>
    </div>
  </UiModal>
</template>

<script>
import UiModal from '@/components/ui/UiModal.vue';
import UiButton from '@/components/ui/UiButton.vue';
import UiIcon from '@/components/ui/UiIcon.vue';

export default {
  components: {
    UiIcon,
    UiButton,
    UiModal,
  },
  props: {
    icon: String,
    title: String,
    message: String,
    button: String,
  },
};
</script>
