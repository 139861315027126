import User from '@/components/models/User';

export default {
  state: {
    swiperItems: [],
    swiperOptions: {},
    currentUser: localStorage.currentUser != null ? new User(JSON.parse(localStorage.currentUser)) : null,
    token: localStorage.token != null ? localStorage.token : null,
    impersonating: typeof localStorage.impersonating !== 'undefined' ? localStorage.impersonating : false,
    addToListUser: null,
    buyItem: null,
    showBecomeCreatorModal: false,
    updates: {
      notifications: 0,
      messages: 0,
    },
    featureFlags: [],
    spinner: false,
    apiResponseCode: 0,
    pusher: null,
    dialogs: 0,
    online: false,
    toasts: [],
  },
};
