var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"tw-fixed tw-z-modal"},[_c('transition',{attrs:{"enter-active-class":"tw-ease-out tw-duration-300","enter-class":"tw-opacity-0","enter-to-class":"tw-opacity-100","leave-active-class":"tw-ease-in tw-duration-200","leave-class":"tw-opacity-100","leave-to-class":"tw-opacity-0"}},[(_vm.isMounted)?_c('div',{staticClass:"tw-fixed tw-inset-0 tw-bg-[#09101d]/70 tw-transition-opacity"}):_vm._e()]),_c('div',{staticClass:"tw-fixed tw-inset-0 tw-z-modal tw-overflow-y-auto"},[_c('div',{staticClass:"tw-flex tw-min-h-full tw-items-center tw-justify-center tw-p-4 tw-text-center",on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.$emit('close')}}},[_c('transition',{attrs:{"enter-active-class":"tw-transform tw-ease-out tw-duration-300","enter-class":"tw-opacity-0 tw-scale-95","enter-to-class":"tw-opacity-100 tw-scale-100","leave-active-class":"tw-transform tw-ease-in tw-duration-200","leave-class":"tw-opacity-100 tw-scale-100","leave-to-class":"tw-opacity-0 tw-scale-95"}},[(_vm.isMounted)?_c('div',{staticClass:"tw-relative tw-transition-all tw-w-full tw-overflow-hidden tw-p-6 tw-bg-white tw-rounded-2xl tw-text-left tw-shadow-xl sm:tw-my-8",class:{
              'sm:tw-max-w-md': _vm.size === 'sm',
              'sm:tw-max-w-lg': _vm.size === 'md',
              'sm:tw-max-w-2xl': _vm.size === 'lg',
              'sm:tw-max-w-5xl': _vm.size === 'xl',
              'sm:tw-max-w-6xl': _vm.size === '2xl',
            }},[(_vm.title || _vm.action)?_c('div',{staticClass:"tw-flex tw-items-center tw-justify-between"},[(_vm.title)?_c('div',{staticClass:"tw-font-semibold tw-text-xl sm:tw-text-2xl"},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),(_vm.action)?_c('div',{staticClass:"tw-cursor-pointer",on:{"click":function($event){return _vm.$emit('action')}}},[_c('UiIcon',{staticClass:"tw-h-6 tw-w-6",attrs:{"name":_vm.action}})],1):_vm._e()]):_vm._e(),(_vm.subtitle)?_c('div',{staticClass:"tw-mt-2 sm:tw-text-lg"},[_vm._v(" "+_vm._s(_vm.subtitle)+" ")]):_vm._e(),_c('div',{class:{ 'tw-mt-6': _vm.title || _vm.action || _vm.subtitle }},[_vm._t("default")],2),(_vm.cancel || _vm.confirm)?_c('div',{staticClass:"tw-mt-6 tw-flex tw-flex-col-reverse tw-gap-3 sm:tw-grid sm:tw-grid-flow-col sm:tw-auto-cols-fr"},[(_vm.cancel)?_c('UiButton',{attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v(" "+_vm._s(_vm.cancel)+" ")]):_vm._e(),(_vm.confirm)?_c('UiButton',{attrs:{"disabled":_vm.disabled,"variant":"primary"},on:{"click":function($event){return _vm.$emit('confirm')}}},[_vm._v(" "+_vm._s(_vm.confirm)+" ")]):_vm._e()],1):_vm._e()]):_vm._e()])],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }