<template>
  <component
    :is="to ? 'RouterLink' : 'div'"
    class="tw-flex tw-flex-col tw-items-center tw-cursor-pointer"
    :class="active ? 'tw-text-primary-500' : 'tw-text-gray-500'"
    :to="to"
    @click="(event) => $emit('click', event)"
  >
    <div class="tw-relative">
      <UiIcon
        :name="icon"
        :solid="active"
      />
      <span
        v-if="badge"
        class="tw-absolute tw-top-0 tw-right-0 tw-w-2 tw-h-2 tw-rounded-full tw-bg-primary-500"
      />
    </div>

    <span
      v-if="label"
      class="tw-text-[10px]"
    >
      {{ label }}
    </span>
  </component>
</template>

<script>
import UiIcon from './UiIcon.vue';

export default {
  components: {
    UiIcon,
  },
  props: {
    active: Boolean,
    badge: Boolean,
    icon: String,
    label: String,
    to: String,
  },
};
</script>
