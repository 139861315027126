<template>
  <div class="tw-w-full">
    <vue-dropzone
      ref="mediaDropzone"
      id="dropzone"
      :options="dropzoneOptions"
      :include-styling="false"
      @vdropzone-complete="mediaComplete"
      @vdropzone-removed-file="mediaRemoved"
      @vdropzone-file-added="mediaAdded"
      @vdropzone-error="mediaError"
    />

    <div
      v-if="layout === 'row'"
      class="swiper tw-w-full tw-overflow-hidden"
    >
      <div class="swiper-wrapper">
        <div class="swiper-slide tw-w-[unset] tw-flex tw-flex-none">
          <div
            class="dropzonePreviewContent dz-row tw-flex tw-flex-none min-w-[7.5rem]"
          />
        </div>
      </div>
    </div>

    <div v-else>
      <div
        class="dropzonePreviewContent dz-col tw-flex tw-flex-col"
      />
    </div>

    <UiFormErrors
      v-if="errors._ && errors._.length > 0"
      :errors="errors._"
    />
  </div>
</template>

<style lang="postcss">
/* Global styles */
.dz-progress.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: unset;
  right: unset;
}

.dz-preview {
  flex: 0 0 80px;
  width: 80px;
  height: 80px;
  position: relative;
  line-height: 1;
  cursor: pointer;
  max-width: 100%;
  overflow: hidden;
}

.dz-image {
  width: 100%;
  height: 100%;
}

.dz-preview img {
  background-size: cover;
  width: 100% !important;
  height: 100% !important;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -o-object-fit: cover !important;
  object-fit: cover !important;
}

.dz-complete .dz-edit {
  opacity: 1;
  transition: all 0.4s ease-in;
  visibility: visible;
}

.dz-progress {
  position: absolute;
  bottom: 10%;
  left: 0;
  right: 0;
}

.playB {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.2s linear;
  z-index: 100;
}

.dz-processing {
  .dz-progress {
    opacity: 1;
    transition: all 0.2s linear;
  }

  .playB {
    opacity: 0;
  }
}

.dz-complete {
  .dz-progress {
    opacity: 0;
    transition: opacity 0.4s ease-in;
  }

  .playB {
    opacity: 1;
  }
}

.dz-preview.dz-complete.dz-error {
  .dz-progress, .dz-edit, .playB {
    opacity: 0;
  }

  .dz-image {
    opacity: 0.3;
  }
}

/* Row styles */
.dz-row {
  .dz-remove {
    position: absolute;
    top: 4px;
    right: 4px;
    padding: 4px;
    background-color: white;
    border-radius: 9999px;
  }

  .dz-edit {
    position: absolute;
    bottom: 5%;
    right: 5%;
    visibility: hidden;
    opacity: 0;
    z-index: 100;
  }
}

/* Column styles */
.dz-col {
  .dz-filename {
    font-weight: 600;
  }

  .dz-size,
  .dz-size strong {
    font-weight: normal;
  }
}
</style>

<style scoped lang="scss">
#modalScreenshot {
  .modal-body {
    .screenshots {
      flex: 1;
      display: grid;
      grid-gap: 2px;
      grid-template-columns: repeat(3, 1fr);
      grid-auto-rows: min-content;

      .item {
        > div {
          height: 0;
          padding-bottom: 100%;
          position: relative;

          img {
            overflow: hidden;
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            top: 0;
            left: 0;
          }

          .check {
            position: absolute;
            top: 5%;
            right: 5%;
          }
        }
      }
    }
  }
}
</style>

<script>
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';

import { VideoUploader } from '@api.video/video-uploader';

import Media from '@/components/models/Media';
import UiFormErrors from '@/components/ui/UiFormErrors.vue';
// import Vapor from "laravel-vapor";

export default {
  components: {
    UiFormErrors,
    vueDropzone: vue2Dropzone,
  },
  props: {
    value: Array,
    layout: {
      type: String,
      default: 'col',
    },
    explicit: Boolean,
    vault: Boolean,
  },
  data() {
    return {
      errors: {},
      uploadCount: 0,
      swiper: null,
      screenshots: null,
      dropzoneOptions: {
        url:
          process.env.VUE_APP_API_URL +
          '/' +
          process.env.VUE_APP_API_VERSION +
          '/media',
        thumbnailWidth: 240,
        thumbnailHeight: 240,
        autoProcessQueue: false,
        addRemoveLinks: false,
        paramName: 'media',
        previewTemplate: this.template(),
        method: 'post',
        parallelUploads: 1,
        headers: {
          Authorization: 'Bearer ' + this.$store.state.token,
          'Cache-Control': null,
          'X-Requested-With': null,
        },
        previewsContainer: '.dropzonePreviewContent',
        maxFilesize: process.env.VUE_APP_MEDIA_MAXSIZE,
        acceptedFiles: process.env.VUE_APP_MEDIA_MIMES,
        timeout: 0,
      },
    };
  },
  computed: {
    media: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  mounted() {
    if (this.layout === 'row') {
      this.swiper = new Swiper('.swiper', {
        // Optional parameters
        direction: 'horizontal',
        freeMode: {
          enabled: true,
          sticky: false,
        },
        loop: false,
        slidesPerView: 'auto',
      });
    }
  },
  methods: {
    /**
     * @param validate {(media: Media) => boolean}
     */
    manual(validate = () => true) {
      // First we need to add any of the media that are not currently in the
      // mediaDropzone. We'll loop through the given media, checking to see
      // if they exist in the array of current files, and if not we will
      // manually add.
      const current = this.manuallyAddedFiles();

      const mediaIds = this.media.map((m) => m.id);

      // Next, we will take the differences of the current and given ids and
      // remove all the files that exist in the "current" array but are not
      // in the array of the new ids given by `this.media` which will complete
      // the sync.
      const remove = current.filter((f) => !mediaIds.includes(f.name));

      if (remove.length > 0) {
        this.removeFiles(remove);
      }

      // Now we are finally ready to manually add the new media.
      const currentIds = current.map((f) => f.name);

      this.media.forEach((m) => {
        if (validate(m) && !currentIds.includes(m.id)) {
          this.$refs.mediaDropzone.manuallyAddFile(
            { name: m.id, size: 1 },
            m.type === 'video' ? m.screenshot : m.url,
          );
        }
      });
    },
    manuallyAddedFiles() {
      return this.$refs.mediaDropzone
        .getRejectedFiles() // Manually added files are marked as "rejected" by the dropzone though they are OK
        .filter((f) => f.manuallyAdded);
    },
    click() {
      this.$refs.mediaDropzone.$el.click();
    },
    clean() {
      this.$refs.mediaDropzone.removeAllFiles();
    },
    removeFiles(files) {
      files.forEach((file) => {
        this.$refs.mediaDropzone.removeFile(file);
      });
    },
    template() {
      if (this.layout === 'row') {
        return `<div class="dz-preview dz-file-preview tw-mr-2 tw-mb-3 tw-bg-black/10 tw-rounded-2xl tw-border">
          <div class="dz-image">
              <img data-dz-thumbnail src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=" />
          </div>
          <div class="dz-progress spinner">
            <span data-v-6f498ff4="" aria-hidden="true" class="spinner-border spinner-border-sm tw-text-white"><!----></span>
          </div>
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" class="playB tw-text-white">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.15105 6H15.8738C19.1032 6 21.3578 8.22538 21.3578 11.4146V20.5854C21.3578 23.7746 19.1032 26 15.8738 26H8.15105C4.92168 26 2.66699 23.7746 2.66699 20.5854V11.4146C2.66699 8.22538 4.92168 6 8.15105 6ZM26.611 9.17198C27.1963 8.87418 27.883 8.90531 28.4416 9.25726C29.0003 9.60785 29.3337 10.217 29.3337 10.883V21.1178C29.3337 21.7852 29.0003 22.393 28.4416 22.7436C28.1363 22.9344 27.795 23.0319 27.451 23.0319C27.1643 23.0319 26.8776 22.9642 26.6096 22.8275L24.6349 21.8312C23.9043 21.4603 23.4509 20.7158 23.4509 19.8887V12.1107C23.4509 11.2823 23.9043 10.5378 24.6349 10.1696L26.611 9.17198Z" fill="white"/>
          </svg>
          <a data-dz-remove href="#" target="_self" class="dz-remove fs-4"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.6673 3.3335L3.33398 12.6668" stroke="#212121" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.6673 12.6668L3.33398 3.3335" stroke="#212121" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg></a>
          <a data-dz-edit href="#" target="_self" class="dz-edit fs-4">
            <svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img" aria-label="pencil fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi-pencil-fill b-icon bi"><g ><path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"></path></g></svg>
          </a>
          <div class="progress tw-mx-2 dz-progress" style="height: 4px;">
              <div data-dz-uploadprogress class="progress-bar dz-upload"></div>
          </div>
        </div>`;
      }

      return `<div class="tw-flex tw-items-center">
        <div class="tw-flex tw-items-center">
          <div class="dz-preview dz-file-preview tw-mt-3 tw-bg-black/10 tw-rounded-2xl md:tw-mt-4">
            <div class="dz-image">
                <img data-dz-thumbnail src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=">
            </div>
            <div class="dz-progress spinner">
              <span data-v-6f498ff4="" aria-hidden="true" class="spinner-border spinner-border-sm tw-text-white"><!----></span>
            </div>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" class="playB tw-text-white">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M8.15105 6H15.8738C19.1032 6 21.3578 8.22538 21.3578 11.4146V20.5854C21.3578 23.7746 19.1032 26 15.8738 26H8.15105C4.92168 26 2.66699 23.7746 2.66699 20.5854V11.4146C2.66699 8.22538 4.92168 6 8.15105 6ZM26.611 9.17198C27.1963 8.87418 27.883 8.90531 28.4416 9.25726C29.0003 9.60785 29.3337 10.217 29.3337 10.883V21.1178C29.3337 21.7852 29.0003 22.393 28.4416 22.7436C28.1363 22.9344 27.795 23.0319 27.451 23.0319C27.1643 23.0319 26.8776 22.9642 26.6096 22.8275L24.6349 21.8312C23.9043 21.4603 23.4509 20.7158 23.4509 19.8887V12.1107C23.4509 11.2823 23.9043 10.5378 24.6349 10.1696L26.611 9.17198Z" fill="white"/>
            </svg>
            <a data-dz-edit href="#" target="_self" class="dz-edit fs-4">
              <svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img" aria-label="pencil fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi-pencil-fill b-icon bi"><g ><path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"></path></g></svg>
            </a>
            <div class="progress tw-mx-2 dz-progress" style="height: 4px;">
                <div data-dz-uploadprogress class="progress-bar dz-upload"></div>
            </div>
          </div>

          <div class="dz-details tw-ml-3 tw-mt-3 md:tw-mt-4">
            <div class="dz-filename"><span data-dz-name></span></div>
            <div class="dz-size"><span data-dz-size></span></div>
          </div>
        </div>

        <div class="tw-ml-auto tw-mt-3 md:tw-mt-4">
          <a data-dz-remove href="#" target="_self" class="dz-remove fs-4"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" focusable="false">
            <path d="M19.3248 9.46582C19.3248 9.46582 18.7818 16.2008 18.4668 19.0378C18.3168 20.3928 17.4798 21.1868 16.1088 21.2118C13.4998 21.2588 10.8878 21.2618 8.27979 21.2068C6.96079 21.1798 6.13779 20.3758 5.99079 19.0448C5.67379 16.1828 5.13379 9.46582 5.13379 9.46582" stroke="#212121" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M20.708 6.23926H3.75" stroke="#212121" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M17.4406 6.23998C16.6556 6.23998 15.9796 5.68498 15.8256 4.91598L15.5826 3.69998C15.4326 3.13898 14.9246 2.75098 14.3456 2.75098H10.1126C9.53358 2.75098 9.02558 3.13898 8.87558 3.69998L8.63258 4.91598C8.47858 5.68498 7.80258 6.23998 7.01758 6.23998" stroke="#212121" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg></a>
        </div>
      </div>`;
    },
    dataFromResponse(response) {
      let data = null;
      if (response && response.manuallyAdded) {
        for (let m of this.media) {
          if (m.id === response.name) {
            data = m;
            break;
          }
        }
      } else if (
        response &&
        response.xhr &&
        response.xhr.status &&
        response.xhr.response &&
        response.xhr.status === 200
      ) {
        data = JSON.parse(response.xhr.response);
      }
      return data;
    },
    mediaError(_, message) {
      if (message.errors && message.errors._ && message.errors._.constructor === Array) {
        this.errors = message.errors;
      } else {
        this.errors = { _: [message] };
      }
    },
    uploadStarted() {
      if (this.uploadCount === 0) {
        this.$emit('upload-started');
      }

      this.uploadCount++;
    },
    uploadEnded() {
      this.uploadCount !== 0 && this.uploadCount--;

      if (this.uploadCount === 0) {
        this.$emit('upload-ended');
      }
    },
    mediaAdded(file) {
      if (typeof file.status === 'undefined') {
        return;
      }

      this.uploadStarted();
      file.previewElement.querySelector('.dz-remove').style.display = 'none';

      new Promise(resolve => setTimeout(resolve, 1000)).then(() => {
        if (file.type?.match('video/*')) {
          this.$post(
            '/video',
            {
              ae: this.explicit,
              name: file.name,
              vault: this.vault,
            },
            ({ data }) => {
              // ApiVideo uploader
              const dataVideo = data;
              const uploader = new VideoUploader({
                file: file,
                uploadToken: data.token,
                videoId: data.provider_video_id,
                chunkSize: 1024 * 1024 * 5, // 10MB
                maxVideoDuration: 60 * 10, // 10 Mins
                retries: 10,
                public: false,
              });
              uploader.upload().catch(error => {
                if (error.message === 'The submitted video is too long.') {
                  this.$refs.mediaDropzone.removeFile(file);
                  this.$bvToast.toast(this.$t('errors.video-too-long'), {
                    autoHideDelay: 5000,
                    title: this.$t('general.error'),
                    solid: true,
                    variant: 'danger',
                    toaster: 'b-toaster-bottom-right',
                  });
                  this.uploadEnded();
                }
              });
              uploader.onPlayable(() => {
                this.$get(
                  '/video/' + data.uuid + '/stream',
                  async ({ data }) => {
                    file.previewElement.dataset.media_id = dataVideo.uuid;
                    file.previewElement.querySelector('[data-dz-thumbnail]').style.backgroundImage = `url('${data.thumbnail}')`;
                    file.previewElement.querySelector('.dz-progress').style.display = 'none';
                    file.previewElement.querySelector('.progress').style.display = 'none';
                    file.previewElement.querySelector('.dz-remove').style.display = null;
                    this.media.push({
                      type: Media.TYPE_VIDEO,
                      uuid: dataVideo.uuid,
                    });
                    this.uploadEnded();

                    // tmp disabled as we no longer need buffer to get screenshot rdy
                    // setTimeout(() => {
                    //   file.previewElement.querySelector('[data-dz-thumbnail]').style.backgroundImage = 'url(\'' + data.thumbnail + '\')';
                    //   file.previewElement.querySelector('.dz-progress').style.display = 'none';
                    //   file.previewElement.querySelector('.progress').style.display = 'none';
                    //   this.media.push({
                    //     type: Media.TYPE_VIDEO,
                    //     uuid: dataVideo.uuid,
                    //   });
                    //   this.uploadEnded();
                    // }, 2000);
                  },
                  () => {
                    this.uploadEnded();
                  },
                );
              });
              uploader.onProgress(event => {
                file.previewElement.querySelector('[data-dz-uploadprogress]').style.width = (event.uploadedBytes / event.totalBytes) * 100 + '%';
              });

              const healthCheck = setInterval(() => {
                this.$get(
                  '/video/health-check/' + data.provider_video_id,
                  ({ data }) => {
                    if (data.alive === false) {
                      clearInterval(healthCheck);
                      this.showUploadError(file);
                      uploader.cancel();
                    }
                  },
                );
              }, 5000);
            },
            (data) => console.log(data, 'fail'),
          );
        } else {
          let type;

          if (file.type?.match('image/*')) {
            type = Media.TYPE_IMAGE;
          } else {
            this.$bvToast.toast(this.$t('errors.media-not-supported'), {
              autoHideDelay: 2000,
              title: this.$t('general.error'),
              solid: true,
              variant: 'danger',
              toaster: 'b-toaster-bottom-right',
            });
            this.uploadEnded();

            return;
          }

          // Check image size
          const fileSize = file.size / (1024 * 1024);

          if (fileSize > 10) {
            this.showUploadError(file, this.$t('errors.image-too-big'));
            this.uploadEnded();

            return;
          }

          this.$delegatedUpload(
            file,
            (progressEvent) => {
              const calculatedProgress = progressEvent * 100;
              file.previewElement.querySelector('[data-dz-uploadprogress]').style.width = calculatedProgress + '%';

              if (calculatedProgress === 100) {
                file.previewElement.querySelector('.dz-progress').style.display = 'none';
                file.previewElement.querySelector('.progress').style.display = 'none';
              }
            },
            (data) => {
              file.previewElement.querySelector('.dz-progress.spinner').style.display = null;

              this.$post('/media', {
                mime: file.type,
                name: file.name,
                key: data.key,
                uuid: data.uuid,
                extension: file.name.split('.').pop(),
                type: type,
                ae: this.explicit,
                vault: this.vault,
              }, (mediaResponse) => {
                file.previewElement.dataset.media_id = mediaResponse.id;
                file.previewElement.querySelector('.dz-progress.spinner').style.display = 'none';
                file.previewElement.querySelector('.dz-remove').style.display = null;

                this.media.push(mediaResponse);
                this.uploadEnded();
              }, (fields, error) => {
                file.previewElement.querySelector('.dz-progress.spinner').style.display = 'none';
                file.previewElement.querySelector('.dz-remove').style.display = null;

                if (error.response.status === 422) {
                  this.showUploadError(file);
                }

                if (error.response.status === 500) {
                  this.showUploadError(file, this.$t('moderation.error'));
                }

                this.uploadEnded();
              });
            });
        }
      });

      this.swiper?.update();
      this.errors = {};
    },
    showUploadError(file, customMsg) {
      const message = customMsg ? customMsg : this.$t('moderation.unapproved');
      file.previewElement.querySelector('.dz-progress').style.display = 'none';
      file.previewElement.querySelector('.progress').style.display = 'none';
      file.previewElement.querySelector('[data-dz-remove]').remove();
      file.previewElement.querySelector('.dz-image').classList.add('tw-blur-sm');
      file.previewElement.querySelector('[data-dz-size]').innerHTML = message;
      this.$toast({
        title: this.$t('general.error'),
        description: this.$t(message),
        duration: 10000,
        variant: 'error',
      });
    },
    mediaComplete(response) {
      let data = this.dataFromResponse(response);

      if (data) {
        response.previewElement.dataset.media_id = data.id;
        response.previewElement.addEventListener('click', () => {
          this.showPhotoSwipe(data.id);
        });

        const eEl = response.previewElement.querySelector('[data-dz-edit]');
        const pEl = response.previewElement.querySelector('.playB');

        if (data.type === Media.TYPE_VIDEO) {
          if (!data.scr) {
            data.scr = data.thumbs[0];
            data.screenshot = data.scr.url;
          }
          const tEl = response.previewElement.querySelector(
            '[data-dz-thumbnail]',
          );
          if (tEl) {
            tEl.style.backgroundImage = 'url(\'' + data.scr.url + '\')';
          }
        } else {
          const tEl = response.previewElement.querySelector(
            '[data-dz-thumbnail]',
          );

          if (tEl) {
            tEl.style.backgroundImage = 'url(\'' + data.url + '\')';
          }

          if (eEl) {
            eEl.style.display = 'none';
          }
          if (pEl) {
            pEl.style.display = 'none';
          }
        }

        if (!response.manuallyAdded) {
          this.media.push(data);
        }
      }
    },
    mediaRemoved(response) {
      const data = this.dataFromResponse(response);
      const mediaId = response.previewElement.dataset.media_id
      const id = data?.id || Number(mediaId) || mediaId;
      if (id) {
        this.media = this.media.filter(function (m) {
          // media can be a video (uuid) or an image (id)
          return m.uuid ? m.uuid !== id : m.id !== id;
        });
      }
      this.swiper?.update();
      this.errors = {};
    },
    showPhotoSwipe(id) {
      this.$showPhotoSwipe(this.media, id);
    },
  },
};
</script>
