<template>
  <UiListbox
    :open="isOpen"
    @toggle="onToggle"
  >
    <template #button>
      <span class="tw-flex tw-items-center">
        <span class="tw-shrink-0 tw-h-6 tw-w-6 tw-mr-3 tw-flex tw-items-center tw-justify-center tw-bg-primary-50 tw-rounded-full">
          <UiIcon
            class="tw-h-4 tw-w-4 tw-text-primary-500"
            name="calendar"
            solid
          />
        </span>

        <span>
          <span class="tw-block">
            {{ currentOption.label }}
          </span>
          <span class="tw-block tw-text-xs tw-text-gray-500 sm:tw-text-sm">
            {{ formattedRange.start }}
            -
            {{ formattedRange.end }}
          </span>
        </span>
      </span>
    </template>

    <div>
      <div v-if="isCustomizing">
        <div class="sm:tw-hidden">
          <div class="tw-flow-root">
            <div class="-tw-my-2 tw-divide-y tw-divide-gray-200">
              <div class="tw-py-2">
                <div class="tw-flex tw-items-center tw-justify-between">
                  <div>
                    {{ $t('general.starts') }}
                  </div>
                  <button
                    class="tw-py-1 tw-px-2 tw-bg-gray-200 tw-rounded-lg"
                    :class="activePanel === panels.start ? 'tw-text-primary-500 tw-bg-primary-50' : 'tw-text-gray-700 tw-bg-gray-200'"
                    @click="togglePanel(panels.start)"
                  >
                    {{ formattedRange.start }}
                  </button>
                </div>

                <div
                  v-show="activePanel === panels.start"
                  class="tw-mt-2 tw-pt-2 tw-border-t tw-border-gray-200"
                >
                  <VDatePicker
                    color="primary"
                    is-expanded
                    is-required
                    :max-date="computedRange.end"
                    :value="computedRange.start"
                    @input="computedRange = { start: $event }"
                  />
                </div>
              </div>

              <div class="tw-py-2">
                <div class="tw-flex tw-items-center tw-justify-between">
                  <div>
                    {{ $t('general.ends') }}
                  </div>
                  <button
                    class="tw-py-1 tw-px-2 tw-bg-gray-200 tw-rounded-lg"
                    :class="activePanel === panels.end ? 'tw-text-primary-500 tw-bg-primary-50' : 'tw-text-gray-700 tw-bg-gray-200'"
                    @click="togglePanel(panels.end)"
                  >
                    {{ formattedRange.end }}
                  </button>
                </div>

                <div
                  v-show="activePanel === panels.end"
                  class="tw-mt-2 tw-pt-2 tw-border-t tw-border-gray-200"
                >
                  <VDatePicker
                    color="primary"
                    is-expanded
                    is-required
                    :min-date="computedRange.start"
                    :value="computedRange.end"
                    @input="computedRange = { end: $event }"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="tw-hidden sm:tw-block">
          <div class="tw-flex">
            <div class="tw-w-1/2">
              <VDatePicker
                :attributes="[{
                  highlight: {
                    start: { fillMode: 'solid' },
                    base: { fillMode: 'light' },
                    end: { fillMode: 'outline' },
                  },
                  dates: computedRange
                }]"
                color="primary"
                is-expanded
                is-required
                :max-date="computedRange.end"
                :value="computedRange.start"
                @input="computedRange = { start: $event }"
              />
            </div>
            <div class="tw-w-1/2">
              <VDatePicker
                :attributes="[{
                  highlight: {
                    start: { fillMode: 'outline' },
                    base: { fillMode: 'light' },
                    end: { fillMode: 'solid' },
                  },
                  dates: computedRange
                }]"
                color="primary"
                is-expanded
                is-required
                :min-date="computedRange.start"
                :value="computedRange.end"
                @input="computedRange = { end: $event }"
              />
            </div>
          </div>
        </div>

        <div class="tw-mt-5 sm:tw-mt-6">
          <div class="tw-grid tw-grid-cols-2 tw-gap-x-8">
            <UiButton
              variant="secondary"
              @click="onCancelCustom"
            >
              {{ $t('general.cancel') }}
            </UiButton>
            <UiButton
              variant="primary"
              @click="onSubmitCustom"
            >
              {{ $t('general.apply') }}
            </UiButton>
          </div>
        </div>
      </div>

      <div
        v-else
        class="tw-flow-root"
      >
        <div class="-tw-my-2 tw-divide-y tw-divide-gray-200 sm:-tw-my-4">
          <UiListboxGroup>
            <UiListboxOption
              :active="optionVal === 'custom'"
              @click="isCustomizing = true"
            >
              {{ $t('general.custom') }}
            </UiListboxOption>
          </UiListboxGroup>
          <UiListboxGroup
            v-for="(chunk, index) in options"
            :key="index"
          >
            <UiListboxOption
              v-for="option in chunk"
              :key="option.value"
              :active="option.value === optionVal"
              @click="onOptionChange(option)"
            >
              {{ option.label }}
            </UiListboxOption>
          </UiListboxGroup>
        </div>
      </div>
    </div>
  </UiListbox>
</template>

<script>
import dayjs from 'dayjs';
import VDatePicker from 'v-calendar/lib/components/date-picker.umd';

import UiListbox from '@/components/ui/UiListbox.vue';
import UiIcon from '@/components/ui/UiIcon.vue';
import UiListboxOption from '@/components/ui/UiListboxOption.vue';
import UiButton from '@/components/ui/UiButton.vue';
import UiListboxGroup from '@/components/ui/UiListboxGroup.vue';

const PANEL_START = 'start';
const PANEL_END = 'end';

export default {
  components: {
    UiListboxGroup,
    UiButton,
    UiListboxOption,
    UiIcon,
    UiListbox,
    VDatePicker,
  },
  props: {
    value: Object,
  },
  data() {
    return {
      isOpen: false,
      isCustomizing: false,
      activePanel: null,
      dataRange: null,
      options: [
        [
          { label: this.$t('general.last-x-hours', [24]), value: 1, unit: 'day' },
          { label: this.$t('general.last-x-days', [7]), value: 7, unit: 'day' },
          { label: this.$t('general.last-x-days', [30]), value: 30, unit: 'day' },
          { label: this.$t('general.last-x-days', [90]), value: 90, unit: 'day' },
          { label: this.$t('general.last-x-days', [180]), value: 180, unit: 'day' },
          { label: this.$t('general.last-x-days', [365]), value: 365, unit: 'day' },
        ],
        [
          { label: '2023', value: 2023, unit: 'year' },
          { label: '2022', value: 2022, unit: 'year' },
          { label: this.$t('general.all-time'), value: null },
        ],
      ],
      optionVal: null,
    };
  },
  computed: {
    range: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    currentOption() {
      if (this.optionVal === 'custom') {
        return { label: this.$t('general.custom') };
      }

      return this.options.flat().find((option) => this.optionVal === option.value);
    },
    computedRange: {
      get() {
        if (this.dataRange === null) {
          return {
            start: this.$store.state.currentUser.created_at,
            end: new Date(),
          };
        }

        return this.dataRange;
      },
      set(value) {
        this.dataRange = value ? {
          ...this.computedRange,
          ...value,
        } : null;
      },
    },
    formattedRange() {
      const format = 'LL';

      return {
        start: dayjs(this.computedRange.start).format(format),
        end: dayjs(this.computedRange.end).format(format),
      };
    },
    panels() {
      return {
        start: PANEL_START,
        end: PANEL_END,
      };
    },
  },
  created() {
    this.dataRange = this.value;
  },
  methods: {
    onToggle(open) {
      this.isOpen = open;
      this.isCustomizing = false;
    },
    togglePanel(panel) {
      this.activePanel = this.activePanel === panel ? null : panel;
    },
    submit() {
      this.isOpen = false;
      this.isCustomizing = false;
      this.range = this.dataRange;
    },
    onSubmitCustom() {
      this.optionVal = 'custom';
      this.submit();
    },
    onCancelCustom() {
      this.isCustomizing = false
      this.dataRange = this.range
    },
    onOptionChange(option) {
      this.optionVal = option.value;

      if (typeof option.value === 'number') {
        if (option.unit === 'day') {
          this.dataRange = {
            start: new Date(
              dayjs()
                .subtract(option.value, option.unit)
                .toString(),
            ),
            end: new Date(),
          };
        } else if (option.unit === 'year') {
          this.dataRange = {
            start: new Date(
              dayjs()
                .year(option.value)
                .startOf('year')
                .toString(),
            ),
            end: new Date(
              dayjs()
                .year(option.value)
                .endOf('year')
                .toString(),
            ),
          };
        }
      } else {
        this.dataRange = null;
      }

      this.submit();
    },
  },
};
</script>
