<template>
  <component
    :is="asLink ? 'RouterLink' : 'div'"
    class="tw-flex tw-items-center tw-text-base md:tw-text-lg"
    :to="user.url"
  >
    <span
      class="tw-truncate"
      :class="{ 'tw-font-bold': bold }"
      :title="user.name"
    >
      {{ user.name }}
    </span>

    <UiIcon
      v-if="user.isCreator"
      class="tw-shrink-0 tw-ml-1 tw-text-primary-500"
      name="badge-done"
      solid
    />

    <span
      v-if="full"
      class="tw-ml-2 tw-text-gray-500 tw-truncate"
      :title="'@' + user.username"
    >
      {{ '@' + user.username }}
    </span>
  </component>
</template>
<script>
import User from '@/components/models/User';
import UiIcon from '@/components/ui/UiIcon.vue';

export default {
  components: {
    UiIcon,
  },
  props: {
    user: User,
    full: {
      type: Boolean,
      default: false,
    },
    asLink: {
      type: Boolean,
      default: true,
    },
    bold: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
