<template>
  <UiModal
    :cancel="$t('general.cancel')"
    :confirm="$t('general.post')"
    size="md"
    @cancel="onCancel"
    @close="$emit('close')"
    @confirm="onConfirm"
  >
    <div
      class="tw-text-center tw-font-semibold tw-text-xl sm:tw-text-2xl"
    >
      {{ $t('general.participate-in-contest') }}
    </div>

    <div class="tw-mt-6">
      <div class="tw-mx-auto tw-max-w-[300px] tw-border-2 tw-border-primary-500 tw-border-dashed tw-rounded-2xl tw-overflow-hidden">
        <div
          v-if="photo"
          class="tw-relative tw-h-full tw-w-full"
        >
          <img
            alt=""
            class="tw-h-full tw-w-full"
            :src="photo"
          >
          <div class="tw-absolute tw-top-4 tw-right-4">
            <button
              class="tw-h-10 tw-w-10 tw-flex tw-items-center tw-justify-center tw-text-white tw-bg-error tw-rounded-full"
              @click="deletePhoto"
            >
              <UiIcon
                name="delete"
              />
            </button>
          </div>
        </div>

        <div
          v-else
          class="tw-min-h-[300px] tw-flex tw-h-full tw-flex-col tw-items-center tw-justify-center tw-p-4 tw-text-center tw-cursor-pointer"
          @click="onDropzoneClick"
          @dragover.prevent
          @drop.prevent="onDropzoneDrop"
        >
          <div>
            <UiIcon
              class="tw-h-10 tw-w-10"
              name="cloud-upload"
              gradient="primary"
              solid
            />
          </div>

          <div class="tw-mt-2 tw-font-semibold">
            {{ $t('general.drop-files-1') }}
            <span class="tw-text-primary-500">
              {{ $t('general.drop-files-2') }}
            </span>
          </div>

          <div class="tw-mt-2 tw-text-sm tw-text-gray-700">
            <div>{{ $t('general.max-file-size', [50]) }}</div>
            <div>{{ $t('general.supported-types', ['JPG, JPEG, PNG, WEBP']) }}</div>
          </div>
        </div>
      </div>
    </div>

    <input
      ref="photo"
      accept="image/*"
      hidden
      type="file"
      @change="onFileUpload"
    />

    <div
        v-if="unsupportedFormat"
        v-html="$t('errors.media-not-supported')"
        class="tw-mt-4 tw-font-semibold tw-text-center"
    />

    <div
      v-if="contentCheckFailed"
      v-html="$t('errors.explicit-content-contest')"
      class="tw-mt-4 tw-font-semibold tw-text-center"
    />
  </UiModal>
</template>

<script>
import UiModal from '@/components/ui/UiModal.vue';
import UiIcon from '@/components/ui/UiIcon.vue';
import UiButton from '@/components/ui/UiButton.vue';

export default {
  components: {
    UiButton,
    UiIcon,
    UiModal,
  },
  data() {
    return {
      photo: null,
      uuid: null,
      key: null,
      extension: null,
      contentCheckFailed: false,
      unsupportedFormat: false,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
  },
  methods: {
    uploadPhoto(file) {
      this.contentCheckFailed = false;
      this.unsupportedFormat = false;

      if (!file.type.match('image/*')) {
        this.unsupportedFormat = true;

        return;
      }

      this.$delegatedUpload(
        file,
        () => {
        },
        (data) => {
          this.photo = URL.createObjectURL(file);
          this.uuid = data.uuid;
          this.key = data.key;
          this.extension = data.extension;
        },
        (errors) => {
          errors.image?.forEach(msg => {
            this.$bvToast.toast(msg, {
              autoHideDelay: 2000,
              title: this.$t('general.error'),
              solid: true,
              variant: 'danger',
              toaster: 'b-toaster-bottom-right',
            });
          });
        },
      );
    },
    onFileUpload() {
      this.uploadPhoto(this.$refs.photo.files[0]);
    },
    onDropzoneClick() {
      this.$refs.photo.click();
    },
    onDropzoneDrop(event) {
      if (event.dataTransfer.items) {
        this.uploadPhoto(event.dataTransfer.items[0].getAsFile());
      } else {
        this.uploadPhoto(event.dataTransfer.items[0]);
      }
    },
    deletePhoto() {
      this.photo = null;
      this.uuid = null;
      this.key = null;
      this.extension = null;
    },
    onCancel() {
      this.deletePhoto();
      this.$emit('cancel');
    },
    onConfirm() {
      if (this.photo) {
        this.$emit('submit', {
          uuid: this.uuid,
          key: this.key,
          extension: this.extension
        });
      }
    },
    setContentCheckFailed() {
      this.contentCheckFailed = true;
      this.deletePhoto();
    },
    clearError() {
      this.contentCheckFailed = false;
    }
  },
};
</script>
