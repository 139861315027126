<template>
  <div>
    <label
      v-if="label"
      class="tw-text-sm md:tw-text-lg md:tw-font-semibold tw-mb-3"
      :for="name"
    >
      {{ label }}
    </label>

    <div
      class="tw-flex tw-items-center tw-border tw-px-2 tw-rounded-xl tw-overflow-hidden"
      :class="isFocused ? 'tw-bg-primary-500/[.08] tw-border-primary-500' : 'tw-bg-gray-50 tw-border-transparent'"
    >
      <div
        v-if="prepend"
        class="tw-self-stretch tw-flex tw-items-center -tw-ml-2 tw-px-5 tw-border-transparent"
        :class="isFocused ? 'tw-text-white tw-bg-primary-500' : 'tw-text-gray-900 tw-bg-gray-200'"
      >
        <div class="tw-text-lg tw-font-semibold">
          {{ prepend }}
        </div>
      </div>

      <UiIcon
        v-if="icon && iconPosition === 'start'"
        class="tw-ml-1"
        :class="isFocused ? 'tw-text-primary-500' : 'tw-text-gray-500'"
        :name="icon"
        :solid="iconStyle !== 'outline'"
      />

      <input
        ref="input"
        :id="name"
        v-model="inputVal"
        v-mask="mask"
        class="tw-grow tw-py-4 tw-px-3 tw-text-base tw-bg-transparent placeholder:tw-text-gray-500 focus:tw-outline-none"
        :class="{ 'tw-text-gray-600': disabled }"
        :disabled="disabled"
        :placeholder="placeholder"
        :readonly="readonly"
        :type="dataType"
        @blur="isFocused = false"
        @focus="isFocused = true"
        @keydown.enter="$emit('enter')"
      >

      <button
        v-if="type === 'password'"
        class="tw-mr-3 tw-cursor-pointer"
        type="button"
        @click="dataType = dataType === 'password' ? 'text' : 'password'"
      >
        <UiIcon
          :name="dataType === 'password' ? 'hide' : 'show'"
          :class="isFocused ? 'tw-text-primary-500' : 'tw-text-gray-500'"
          solid
        />
      </button>

      <UiIcon
        v-if="icon && iconPosition === 'end'"
        class="tw-mr-3"
        :class="isFocused ? 'tw-text-primary-500' : 'tw-text-gray-500'"
        :name="icon"
        :solid="iconStyle !== 'outline'"
      />

      <button
        v-if="dismissible"
        class="tw-mr-3 tw-cursor-pointer"
        type="button"
        @click="$emit('dismiss')"
      >
        <UiIcon
          name="close"
        />
      </button>
    </div>

    <UiFormErrors
      v-if="errors"
      :errors="errors[name]"
    />
  </div>
</template>

<script>
import UiIcon from '@/components/ui/UiIcon.vue';
import UiFormErrors from '@/components/ui/UiFormErrors.vue';

export default {
  components: {
    UiFormErrors,
    UiIcon,
  },
  props: {
    label: String,
    name: String,
    value: String,
    errors: Object,
    type: String,
    placeholder: String,
    prepend: String,
    disabled: Boolean,
    mask: [String, Object],
    description: String,
    icon: String,
    iconPosition: {
      type: String,
      default: 'start',
    },
    iconStyle: {
      type: String,
      default: 'solid',
    },
    dismissible: Boolean,
    readonly: Boolean,
    autofocus: Boolean,
  },
  data() {
    return {
      dataType: this.type,
      isFocused: false,
    };
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        if (this.type === 'url' && val !== '' && !val.startsWith('https://')) {
          val = 'https://' + val;
        }

        this.$emit('input', val);
      },
    },
  },
  mounted() {
    if (this.autofocus) {
      this.$refs.input.focus()
    }
  }
};
</script>
