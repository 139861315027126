<template>
  <UiModal
    :title="$t('general.subscribe')"
    :subtitle="$t('general.choose-x-subscription-period', [user.name])"
    size="sm"
    :cancel="$t('general.cancel')"
    :confirm="$t('general.continue')"
    @cancel="$emit('cancel')"
    @confirm="onConfirm"
  >
    <div class="tw-space-y-3">
      <UiRadioCard
        v-model="selectedSubscription"
        :value="-1"
      >
        <div>
          <span class="tw-text-lg tw-font-semibold">
            {{ `${currency}${user.vat_price.amount_with_vat}` }} /
          </span>
          <span class="tw-text-sm">
            {{ $tc('general.month') }}
          </span>
        </div>
        <div class="tw-text-sm tw-text-gray-500">
          {{ $t('general.unsubscribe-anytime') }}
        </div>
      </UiRadioCard>
      <UiRadioCard
        v-for="(item, index) in user.bundles"
        :key="index"
        v-model="selectedSubscription"
        :value="item.id"
      >
        <div>
          <span class="tw-text-lg tw-font-semibold">
            {{ `${currency}${item.vat_price.amount_with_vat}` }} /
          </span>
          <span class="tw-text-sm">
            {{ $tc('general.x-months', item.months) }}
            ({{ '-' + item.discount + '%' }})
          </span>
        </div>
        <div class="tw-text-sm tw-text-gray-500">
          {{ $t('general.unsubscribe-anytime') }}
        </div>
      </UiRadioCard>
    </div>
  </UiModal>
</template>

<script>
import UiModal from '@/components/ui/UiModal.vue';
import UiRadioCard from '@/components/ui/UiRadioCard.vue';

export default {
  components: {
    UiRadioCard,
    UiModal,
  },
  props: {
    user: Object,
  },
  data() {
    return {
      selectedSubscription: -1,
    };
  },
  computed: {
    currency() {
      return process.env.VUE_APP_CURRENCY_SIGN;
    },
  },
  methods: {
    onConfirm() {
      const bundle = this.user.bundles.find((bundle) => bundle.id === this.selectedSubscription);
      this.$emit('confirm', bundle);
    },
  },
};
</script>
