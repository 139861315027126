<template>
  <div class="tw-flex tw-gap-x-3">
    <input
      :key="digit"
      v-for="digit in digits"
      @keyup="keyUp"
      @keydown="keyDown"
      type="number"
      maxlength="1"
      :class="inputClass"
    />
  </div>
</template>

<script>
  export default {
    data() {
      return {
        digits: 4,
      }
    },
    computed: {
      inputClass() {
        return 'tw-py-4 tw-bg-gray-50 tw-border tw-rounded-xl tw-font-bold tw-px-3 tw-text-center tw-w-10 focus:tw-outline-none focus:tw-border-primary-500';
      }
    },
    mounted() {
      // Forces focus when component's lifecycle updates.
      this.$el.querySelector('input').focus()
    },
    methods: {
      computeCode() {
        const inputs = this.$el.querySelectorAll('input')
        let pinCode = '';

        for (let i = 0; i < inputs.length; i++) {
          pinCode += inputs[i].value
        }

        return pinCode;
      },
      keyDown(event) {
        if (event.keyCode === 9 || event.keyCode === 13) {
          return;
        }

        event.srcElement.value = '';
      },
      keyUp(event) {
        const maxLength = parseInt(event.srcElement.attributes['maxlength'].value)
        const myLength = event.srcElement.value.length;

        if (
          event.keyCode === 8 && myLength === 0
          && event.srcElement.previousElementSibling
        ) {
          event.srcElement.previousElementSibling.focus()
          event.srcElement.value = '';
          event.preventDefault();
        }

        if (myLength >= maxLength && event.srcElement.nextElementSibling) {
          event.srcElement.nextElementSibling.focus()
        }

        this.$emit('update', this.computeCode());
      }
    }
  }
</script>

