<template>
  <div
    v-if="isVisible"
    class="tw-fixed tw-z-toast tw-inset-4 tw-top-auto"
  >
    <UiToast
      :dismissible="false"
      :title="$t('general.cookies')"
    >
      <i18n
        path="general.cookies-consent"
        tag="div"
      >
        <template v-slot:privacy>
          <a
            class="tw-underline"
            :href="url + '/privacy.html'"
            target="_blank"
          >
            {{ $t('general.privacy-policy') }}
          </a>
        </template>
      </i18n>
      <div class="text-right mt-2">
        <UiButton
          variant="primary"
          @click.prevent="allow"
        >
          {{ $t('general.ok') }}
        </UiButton>
      </div>
    </UiToast>
  </div>
</template>
<script>
import UiButton from '@/components/ui/UiButton.vue';
import UiToast from '@/components/ui/UiToast.vue';

export default {
  components: {
    UiButton,
    UiToast,
  },
  data() {
    return {
      isVisible: false,
    };
  },
  computed: {
    url() {
      return process.env.VUE_APP_APP_URL;
    },
  },
  mounted() {
    this.checkAllow();
  },
  methods: {
    checkAllow() {
      if (!localStorage.allowCookies) {
        this.isVisible = true;
      }
    },
    allow() {
      localStorage.allowCookies = true;
      this.isVisible = false;
    },
  },
};
</script>
