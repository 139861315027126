<template>
  <button
    :class="classSlots.base()"
    type="button"
    @click="(event) => $emit('click', event)"
  >
    <UiIcon
      :class="classSlots.icon()"
      :name="icon"
      solid
    />
    <span
      class="tw-ml-2"
      :class="{ 'tw-hidden sm:tw-inline': responsive }"
    >
      <slot />
    </span>
  </button>
</template>

<script>
import { tv } from 'tailwind-variants';

import UiIcon from '@/components/ui/UiIcon.vue';

export default {
  components: { UiIcon },
  props: {
    icon: String,
    color: {
      type: String,
      required: true,
    },
    responsive: Boolean,
  },
  computed: {
    className() {
      return tv({
        slots: {
          base: 'tw-flex tw-items-center tw-py-2 tw-px-3 tw-text-center tw-font-semibold tw-rounded-full',
          icon: '',
        },
        variants: {
          color: {
            primary: {
              base: 'tw-bg-primary-100',
              icon: 'tw-text-primary-500',
            },
            info: {
              base: 'tw-bg-info-50',
              icon: 'tw-text-info',
            },
            warning: {
              base: 'tw-bg-info-50',
              icon: 'tw-text-warning',
            },
            success: {
              base: 'tw-bg-success-50',
              icon: 'tw-text-success',
            }
          }
        }
      })
    },
    classSlots() {
      return this.className({ color: this.color })
    },
  },
};
</script>
