<template>
  <div class="tw-h-full">
    <div class="tw-h-full tw-flex tw-flex-col">
      <UiNavigationBar
        v-if="party"
        bleed
        responsive
        to="/messages"
      >
        <template #title>
          <div class="tw-flex tw-items-center tw-space-x-3">
            <UiAvatar
              :size="{ initial: 'xs', md: 'sm' }"
              :src="party.avatar_url"
              :text="party.initials"
              :to="party.url"
            />
            <UiUsername
              :user="party"
            />
          </div>
        </template>

        <UiDropdown>
          <UiDropdownItem
            icon="paper"
            @click.prevent="copyLink"
          >
            {{ $t('general.copy-link-to-profile') }}
          </UiDropdownItem>
          <UiDropdownItem
            icon="heart"
            @click.prevent="addToList(party)"
          >
            {{ $t('general.add-to-list') }}
          </UiDropdownItem>
          <UiDropdownItem
            icon="delete"
            @click.prevent="$emit('delete')"
          >
            {{ $t('general.delete') }}
          </UiDropdownItem>
          <UiDropdownItem
            v-if="currentUser.isCreator"
            icon="danger-circle"
            @click.prevent="userToBlock = party.username"
          >
            {{ $t('block-user.cta') }}
          </UiDropdownItem>
        </UiDropdown>
      </UiNavigationBar>

      <div
        ref="conversation"
        class="tw-flex-1 tw-overflow-auto tw-overscroll-contain"
      >
        <div class="tw-w-full tw-flex tw-flex-col-reverse">
          <div
            v-for="(message, index) in messages"
            :key="message.id"
          >
            <div
              v-if="
                index === messages.length - 1 ||
                (index < messages.length - 1 &&
                  !messages[index + 1].created_at.isSame(
                    message.created_at,
                    'day'
                  ))
              "
              class="tw-flex tw-justify-center tw-py-4 md:tw-py-6"
            >
              <div class="tw-inline tw-py-1 tw-px-3 tw-rounded-lg tw-text-gray-500 tw-bg-gray-50 md:tw-px-4">
                {{ $t(message.dayRelated) }}
              </div>
            </div>

            <UiMessage
              v-model="messages[index]"
            />
          </div>
        </div>
      </div>

      <!-- Message input -->
      <div class="tw-max-h-full tw-flex tw-flex-col tw-pt-4 tw-bg-white tw-overflow-auto">
        <div
          v-if="isMediaPickerOpen"
          class="tw-flex-1 tw-mb-4 tw-overflow-hidden"
        >
          <UiMediaPicker
            :value="selectedMedia"
            class="tw-h-full tw-overflow-hidden"
            @input="syncSelectedMedia"
            @close="closeVault"
          />
        </div>

        <div class="tw-flex">
          <div class="tw-grow">
            <UiFormTextarea
              v-model="message"
              :errors="errors"
              name="message"
              :placeholder="$t('general.type-message')"
              rows="1"
              cols="1"
            >
              <template #top>
                <UiMediaUploader
                  ref="uploader"
                  explicit
                  layout="row"
                  :value="media"
                  vault
                  @input="syncMedia"
                  @upload-ended="isMediaUploading = false"
                  @upload-started="isMediaUploading = true"
                />
              </template>

              <template #end>
                <div class="tw-flex tw-items-center tw-space-x-3">
                  <button
                    v-if="!currentUser.isCreator"
                    type="button"
                    @click="tip"
                  >
                    <UiIcon
                      name="wallet"
                      curved
                    />
                  </button>
                  <template v-if="currentUser.isCreator">
                    <button
                      class="lg:tw-hidden"
                      type="button"
                      @click="isMediaPickerOpen = !isMediaPickerOpen"
                    >
                      <UiIcon
                        name="folder"
                        curved
                      />
                    </button>
                    <button
                      class="tw-hidden lg:tw-inline-block"
                      type="button"
                      @click="isModalVaultOpen = true"
                    >
                      <UiIcon
                        name="folder"
                        curved
                      />
                    </button>
                  </template>
                  <div
                    v-if="currentUser.isCreator"
                    @click="isModalPriceOpen = true"
                  >
                    <UiBadge
                      v-if="price > 0"
                      class="-tw-my-2"
                      color="info"
                      icon="wallet"
                      responsive
                    >
                      {{ priceFormat() }}
                    </UiBadge>
                    <button
                      v-else
                      class="tw-block"
                      type="button"
                    >
                      <UiIcon
                        name="dollar"
                        curved
                      />
                    </button>
                  </div>
                  <button
                    type="button"
                    @click="mediaDropzoneClick"
                  >
                    <UiIcon
                      name="camera"
                      curved
                    />
                  </button>
                </div>
              </template>
            </UiFormTextarea>
          </div>

          <div class="tw-ml-3 md:tw-ml-4">
            <button
              class="tw-p-4 tw-rounded-full tw-bg-gradient-to-r tw-from-primary-400 tw-to-primary-500"
              :disabled="!message && !media.length || isMediaUploading"
              @click.prevent="sendMessage"
            >
              <UiIcon
                class="tw-text-white"
                :class="{ 'tw-opacity-50': !message && !media.length || isMediaUploading }"
                name="send"
                solid
              />
            </button>
          </div>
        </div>

        <UiModal
          v-if="isModalPriceOpen"
          :title="$t('general.message-price')"
          :cancel="$t('general.cancel')"
          :confirm="$t('general.add')"
          :disabled="hasPriceError"
          size="sm"
          @cancel="onClosePriceModal"
          @confirm="isModalPriceOpen = false"
          @close="onClosePriceModal"
        >
          <UiFormInput
            v-model="price"
            type="number"
            :label="$t('general.price')"
            name="price"
            :placeholder="$t('general.free')"
            :prepend="currency"
          />

          <p
            v-if="hasPriceError"
            class="tw-text-error tw-pt-4"
          >
            {{ $t('general.payment-processing.price-between-error', [5, 100]) }}
          </p>
        </UiModal>

        <UiModal
          v-if="isModalVaultOpen"
          size="xl"
          :cancel="$t('general.close')"
          :confirm="$t('general.upload')"
          :disabled="selectedMedia.length === 0"
          @cancel="closeVault"
          @close="closeVault"
          @confirm="addAllSelectedMedia"
        >
          <PageVault
            @select="(event) => selectedMedia = event"
          />
        </UiModal>

        <UiBlockUser
          :username="userToBlock"
          @cancel="userToBlock = null"
          @success="onBlockUser"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Media from '@/components/models/Media';
import Message from '@/components/models/Message';
import Payment from '@/components/models/Payment';
import User from '@/components/models/User';

import UiFormTextarea from '@/components/ui/UiFormTextarea.vue';
import UiMessage from '@/components/ui/UiMessage.vue';
import UiMediaUploader from '@/components/ui/UiMediaUploader.vue';
import UiNavigationBar from '@/components/ui/UiNavigationBar.vue';
import UiIcon from '@/components/ui/UiIcon.vue';
import UiDropdown from '@/components/ui/UiDropdown.vue';
import UiDropdownItem from '@/components/ui/UiDropdownItem.vue';
import UiModal from '@/components/ui/UiModal.vue';
import UiFormInput from '@/components/ui/UiFormInput.vue';
import UiBadge from '@/components/ui/UiBadge.vue';
import UiAvatar from '@/components/ui/UiAvatar.vue';
import UiUsername from '@/components/ui/UiUsername.vue';
import UiMediaPicker from '@/components/ui/UiMediaPicker.vue';
import PageVault from '@/components/pages/PageVault.vue';
import UiBlockUser from '@/components/ui/UiBlockUser.vue';

export default {
  components: {
    PageVault,
    UiMediaPicker,
    UiUsername,
    UiAvatar,
    UiBlockUser,
    UiBadge,
    UiFormInput,
    UiModal,
    UiDropdownItem,
    UiDropdown,
    UiIcon,
    UiFormTextarea,
    UiMessage,
    UiMediaUploader,
    UiNavigationBar,
  },
  props: {
    value: Array,
    chatId: String,
  },
  data: function () {
    return {
      messages: [],
      price: null,
      hasPriceError: false,
      errors: {},
      message: '',
      media: [],
      party: null,
      page: 1,
      hasMore: false,
      isLoading: false,
      isLoadingVault: false,
      addedManually: 0,
      vault: [],
      selectedMedia: [],
      isMediaUploading: false,
      isMediaPickerOpen: false,
      isModalPriceOpen: false,
      isModalVaultOpen: false,
      userToBlock: null,
    };
  },
  computed: {
    currency() {
      return process.env.VUE_APP_CURRENCY_SIGN;
    },
    currentUser() {
      return this.$store.state.currentUser;
    },
    chats: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  watch: {
    price() {
      this.hasPriceError = false;

      if (this.price !== '0' && this.price !== '' && (this.price < 5 || this.price > 100)) {
        this.hasPriceError = true;
      }
    },
    chatId() {
      this.init();
    },
  },
  mounted() {
    this.init();
    this.$refs.conversation.addEventListener('scroll', this.updateScroll);
  },
  beforeDestroy() {
    this.$refs.conversation.removeEventListener('scroll', this.updateScroll);
  },
  methods: {
    init() {
      this.messages = [];
      this.price = null;
      this.errors = {};
      this.message = '';
      this.media = [];
      this.party = null;
      let chats = [...this.chats];
      for (let c of chats) {
        if (c.party.id === this.chatId) {
          this.party = c.party;
          c.message.isRead = true;
        }
      }
      this.page = 1;
      this.hasMore = false;
      this.isLoading = false;
      this.chats = chats;
      this.loadMessages();
    },
    updateScroll() {
      if (
        this.$refs.conversation &&
        this.$refs.conversation.scrollTop <= 200 &&
        !this.isLoading &&
        this.hasMore
      ) {
        this.loadMore();
      }
    },
    readMessages() {
      for (let m of this.messages) {
        m.isRead = true;
      }
    },
    loadMessages(page) {
      this.isLoading = true;
      this.$get(
        '/messages/' + this.chatId + '?page=' + (page ? page : this.page),
        (data) => {
          const recent =
            this.messages.length > 0 ? this.messages[0].created_at : null;
          parser: for (let obj of data.messages.data) {
            const m = new Message(obj);
            for (let mm of this.messages) {
              if (mm.id === m.id) {
                continue parser;
              }
            }
            if (!recent || m.created_at.isBefore(recent)) {
              this.messages.push(m);
            } else {
              this.addMessage(m);
            }
          }
          this.isLoading = false;
          if (!page) {
            this.hasMore = data.messages.next_page_url != null;
          }
          this.party = this.party ? this.party : new User(data.party);
          if (this.page === 1) {
            this.$nextTick(function () {
              this.$refs.conversation.scrollTo({
                top: this.$refs.conversation.scrollHeight,
                behavior: 'instant',
              });
            });
          }
        },
        (errors, { response }) => {
          if (response.status === 404) {
            this.$router.replace('/messages');
          }
        },
      );
    },
    reloadFirst() {
      this.loadMessages(1);
    },
    loadMore() {
      if (this.hasMore) {
        this.page = this.page + 1;
        if (this.addedManually >= 20) {
          this.page = this.page + 1;
          this.addedManually = 0;
        }
        this.loadMessages();
      }
    },
    mediaDropzoneClick() {
      this.$refs.uploader.click();
    },
    priceFormat() {
      return this.currency + this.price;
    },
    addMessage(message) {
      this.messages.unshift(message);
      if (message.user.id !== this.$store.state.currentUser.id) {
        message.isRead = true;
      }
      let valid = [];
      let chats = [...this.chats];
      let found = false;
      for (let c of chats) {
        if (c.party.id === this.party.id) {
          c.message = message;
          found = true;
          valid.unshift(c);
        } else {
          valid.push(c);
        }
      }
      if (!found) {
        valid.unshift({
          party: this.party,
          message: message,
        });
      }

      this.chats = valid;
      this.$nextTick(function () {
        this.$refs.conversation.scrollTo({
          top: this.$refs.conversation.scrollHeight,
          behavior: 'smooth',
        });
      });

      this.addedManually++;
    },
    sendMessage() {
      this.errors = {};

      const isMedia = media => media.type === Media.TYPE_IMAGE || media.type === 'image';
      const isVideo = media => media.type === Media.TYPE_VIDEO || media.type === 'video';

      const videos = this.media
        .filter(m => isVideo(m))
        .map(m => {
          // The media selected from within the vault don't have a `uuid`
          // property but only an `id`, and the backend doesn't support videos
          // sent with an `id` property.
          return {
            ...m,
            uuid: m.uuid ?? m.id,
          };
        });

      const media = this.media
        .filter(m => isMedia(m))
        .map(m => ({
          id: m.id,
          screenshot: m.scr ? m.scr.id : null,
        }));

      const fields = {
        message: this.message,
      };

      if (media.length > 0) {
        fields.media = media;
      }

      if (this.price) {
        fields.price = this.price;
      }

      if (videos.length > 0) {
        fields.videos = videos;
      }

      this.$post(
        '/messages/' + this.party.username,
        fields,
        (data) => {
          let message = new Message(data);
          this.addMessage(message);
          this.$refs.uploader.clean();
          this.message = '';
          this.media = [];
          this.price = null;
          this.closeVault();
        },
        (errors, { response }) => {
          if (response.status === 429) {
            this.errors = {
              'message': [this.$t('message.errors.rate-limit')],
            };
          } else {
            this.errors = errors;
          }
        },
      );
    },
    tip() {
      this.$buyItem({
        type: Payment.TYPE_TIP,
        user: this.party,
      });
    },
    copyLink() {
      const link = `${process.env.VUE_APP_APP_URL}${this.party.url}`;
      this.$copyText(link);
      this.$bvToast.toast(this.$t('general.link-copied'), {
        autoHideDelay: 2000,
        title: this.$t('general.link'),
        solid: true,
        toaster: 'b-toaster-bottom-left',
      });
    },
    addToList(user) {
      this.$store.state.addToListUser = user;
    },
    manualMedia() {
      // We could've checked that the media we want to add is not in the array
      // of uploaded files, but due to how media are handled by the dropzone,
      // while the media id is a number, its version in the dropzone only has
      // the file `name` that is a string. We then check if the media id is a
      // string to ensure it is not an already uploaded media.
      this.$refs.uploader.manual((m) => typeof m.id === 'string');
    },
    addAllSelectedMedia() {
      this.selectedMedia.forEach((media) => {
        this.media.push(media);
      });
      this.manualMedia();
      this.closeVault();
    },
    syncMedia(media) {
      if (media.length < this.media.length) {
        // Some media have been deleted, remove them from `this.selectedMedia` also
        const ids = media.map((m) => m.id);
        this.selectedMedia = this.selectedMedia.filter((m) => ids.includes(m.id));
      }

      // New media have been uploaded externally here, no need to worry about `this.selectedMedia`

      this.media = media;
    },
    async syncSelectedMedia(selectedMedia) {
      if (selectedMedia.length > this.selectedMedia.length) {
        // New media have been manually selected, add them to `this.media` if
        // they weren't already manually added
        const manual = this.$refs.uploader.manuallyAddedFiles();
        const manualIds = manual.map((f) => f.name);

        selectedMedia.forEach((m) => {
          if (!manualIds.includes(m.id)) {
            this.media.push(m);
          }
        });
      } else {
        // Some media have been unselected, eject them from `this.media`
        const manual = this.$refs.uploader.manuallyAddedFiles();
        const manualIds = manual.map((f) => f.name);

        // Uploaded files are just non-manually added files
        const uploaded = this.media.filter((m) => !manualIds.includes(m.id));
        const uploadedIds = uploaded.map((m) => m.id);

        const selectedIds = selectedMedia.map((m) => m.id);

        this.media = this.media.filter((m) => {
          // Keep only uploaded files and still-selected manually added files.
          // Unselected files won't be kept.
          return uploadedIds.includes(m.id) || selectedIds.includes(m.id);
        });
      }

      this.selectedMedia = selectedMedia;

      // We need to wait for the next tick so the UiMediaUploader can properly
      // display new manually added media
      await this.$nextTick();

      this.manualMedia();
    },
    closeVault() {
      this.isMediaPickerOpen = false;
      this.isModalVaultOpen = false;
      this.selectedMedia = [];
    },
    onBlockUser() {
      this.$emit('block', this.userToBlock);
      this.userToBlock = null;
    },
    onClosePriceModal() {
        this.isModalPriceOpen = false;
        if(this.hasPriceError) {
          this.price = ''
        }
    },
  },
};
</script>
