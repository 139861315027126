<template>
  <div>
    <div
      v-for="(item, index) in payments"
      :key="index"
      class="tw-my-6"
    >
      <div
        v-if="
            index === 0 ||
            (index > 0 &&
              !payments[index - 1].created_at.isSame(
                item.created_at,
                'day'
              ))
          "
        class="tw-text-center tw-font-semibold md:tw-text-lg"
        :class="{ 'tw-pt-6 tw-border-t tw-border-gray-200': index > 0 }"
      >
        {{ item.created_at.format('ll') }}
      </div>

      <div class="tw-flex tw-items-center tw-justify-between">
        <div class="tw-font-semibold md:tw-text-lg">
          {{ item.to.name }}
        </div>

        <div class="tw-text-lg tw-font-semibold tw-text-error">
          {{ $formatAmount(item.amount_with_vat) }}<br>
        </div>
      </div>

      <div class="tw-mt-2 tw-text-gray-700">
        <i18n :path="getDescription(item)" tag="span">
          <template v-slot:user>
            <RouterLink :to="item.user.url">{{ item.user.username }}</RouterLink>
          </template>
          <template v-slot:to>
            <RouterLink :to="item.to.url">{{ item.to.username }}</RouterLink>
          </template>
          <template v-slot:post v-if="item.items.post">
            <RouterLink :to="item.items.post.url">{{ $t('general.post') }}</RouterLink>
          </template>
          <template v-slot:message>
            {{ $t('general.private-message') }}
          </template>
        </i18n>
      </div>
    </div>
  </div>
</template>

<script>
import Payment from '@/components/models/Payment';

export default {
  data: function () {
    return {
      payments: [],
      page: 1,
      hasMore: false,
      isLoading: false,
    };
  },
  mounted() {
    this.loadPayments();
    window.addEventListener('scroll', this.updateScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.updateScroll);
  },
  methods: {
    updateScroll() {
      const scrollPosition = window.innerHeight + window.scrollY;
      if (
        document.body.offsetHeight &&
        scrollPosition &&
        document.body.offsetHeight - scrollPosition <= 1000 &&
        !this.isLoading &&
        this.hasMore
      ) {
        this.loadMore();
      }
    },
    loadMore() {
      if (this.hasMore) {
        this.page = this.page + 1;
        this.loadPayments();
      }
    },
    loadPayments() {
      this.isLoading = true;
      this.$get(
        '/payments?page=' + this.page,
        (data) => {
          try {
            let payments = [...this.payments];
            for (let obj of data.data) {
              payments.push(new Payment(obj));
            }
            this.payments = payments;
            this.hasMore = data.next_page_url != null;
            this.isLoading = false;
          } catch (e) {
            console.log(e);
          }
        },
        (errors) => {
          console.log(errors);
        },
      );
    },
    getDescription2(payment) {
      switch (payment.type) {
        case Payment.TYPE_SUBSCRIPTION_NEW:
          return this.$t('general.payment-subscription-to', [
            `<a href="${payment.to.url}">${payment.to.username}</a>`,
          ]);
        case Payment.TYPE_SUBSCRIPTION_RENEW:
          return this.$t('general.payment-subscription-renew-to', [
            `<a href="${payment.to.url}">${payment.to.username}</a>`,
          ]);
        case Payment.TYPE_POST:
          return this.$t('general.payment-unlocked', [
            `<a href="${payment.items.post.url}">${this.$t(
              'general.post',
            )}</a>`,
          ]);
        case Payment.TYPE_MESSAGE:
          return this.$t('general.payment-unlocked-from', [
            this.$t('general.private-message'),
            `<a href="${payment.to.url}">${payment.to.username}</a>`,
          ]);
      }
      return '';
    },
    getDescription(payment) {
      switch (payment.type) {
        case Payment.TYPE_SUBSCRIPTION_NEW:
          return 'general.payment-subscription-to';
        case Payment.TYPE_SUBSCRIPTION_RENEW:
          return 'general.payment-subscription-renew-to';
        case Payment.TYPE_POST:
          return 'general.payment-post-unlocked';
        case Payment.TYPE_MESSAGE:
          return 'general.payment-message-unlocked';
        case Payment.TYPE_TIP:
          return 'general.payment-tip-to';
      }
      return '';
    },
  },
};
</script>
