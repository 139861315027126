<template>
  <div
    class="tw-py-3 tw-px-4 tw-flex tw-items-start tw-border tw-rounded-2xl tw-cursor-pointer"
    :class="active ? 'tw-bg-primary-50 tw-border-primary-500' : 'tw-bg-white tw-border-gray-200 hover:tw-bg-gray-50'"
    @click="active = value"
  >
    <div class="tw-grow">
      <slot />
    </div>

    <div
      class="
        tw-relative tw-h-6 tw-w-6 tw-bg-white tw-rounded-full tw-border-[3px] tw-border-primary-500
        after:content-[''] after:tw-absolute after:tw-top-1/2 after:tw-left-1/2 after:-tw-translate-y-1/2 after:-tw-translate-x-1/2
        after:tw-h-3.5 after:tw-w-3.5 after:tw-rounded-full after:tw-bg-primary-500
      "
      :class="active ? 'after:tw-opacity-100' : 'after:tw-opacity-0'"
    />
  </div>
</template>

<script>
export default {
  props: [
    'checked',
    'value',
  ],

  model: {
    prop: 'checked',
    event: 'change',
  },

  computed: {
    active: {
      get() {
        return this.checked === this.value;
      },
      set(value) {
        this.$emit('change', value);
      },
    },
  },
};
</script>
