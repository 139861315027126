<template>
  <div class="content tw-flex tw-flex-col tw-bg-white">
    <div class="tw-grow tw-flex">
      <div class="tw-hidden tw-w-1/2 tw-py-10 tw-px-8 tw-text-center tw-bg-primary-100 md:tw-flex md:tw-items-center md:tw-justify-center">
        <div class="tw-w-full tw-max-w-lg tw-flex tw-flex-col tw-items-center">
          <div>
            <img
              class="tw-w-full"
              :src="landing"
            />
          </div>

          <div
            v-if="isSloganVisible"
            class="tw-mt-20"
          >
            <h1 class="tw-font-bold tw-text-h3">
              {{ $t('general.signup-slogan-title') }}
            </h1>
            <h2 class="tw-max-w-sm tw-mx-auto tw-mt-6 tw-text-h6 tw-text-gray-600">
              {{ $t('general.signup-slogan') }}
            </h2>
          </div>
        </div>
      </div>

      <div class="tw-w-full tw-flex tw-items-center tw-justify-center tw-py-10 tw-px-4 md:tw-w-1/2 md:tw-px-8">
        <div class="tw-w-full tw-max-w-sm tw-mx-auto">
          <RouterView
            name="login"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    assets() {
      return {
        '/password-reset': require('@/assets/illustrations/password-reset.svg'),
      };
    },
    landing() {
      if (this.assets[this.$route.path]) {
        return this.assets[this.$route.path];
      }

      return require('@/assets/illustrations/landing.png');
    },
    isSloganVisible() {
      return !this.assets[this.$route.path];
    },
  },
};
</script>
