<template>
  <div
    class="tw-h-[--banner-height] tw-w-full tw-flex tw-items-center tw-justify-center tw-px-8"
    :class="className({ color })"
  >
    <div class="tw-mx-auto">
      <slot />
    </div>

    <span
      v-if="$slots.actions"
      class="tw-flex tw-items-center tw-space-x-3"
    >
      <slot name="actions" />
    </span>
  </div>
</template>

<script>
import { tv } from 'tailwind-variants';

export default {
  props: {
    color: String,
    icon: String,
  },
  computed: {
    className() {
      return tv({
        variants: {
          color: {
            primary: 'tw-text-white tw-bg-primary-500',
          },
        },
      });
    },
  },
};
</script>
