<template>
  <div id="app">
    <UiIcons class="tw-hidden" />
    <UiGradients class="tw-sr-only tw-h-0" />

    <RouterView />

    <UiPhotoSwipe
      v-if="swiperItems.length > 0"
      :options="swiperOptions"
      :items="swiperItems"
      @close="hidePhotoSwipe"
    />

    <!--
    The following div needs to be height 0 because of the flex-columned layout.
    Otherwise, the space gets distributed with these and the main content doesn't
    take up the full height of the screen.
    -->
    <div class="!tw-h-0">
      <UiAddToList />
      <UiBuy />
      <UiBecomeCreatorModal v-if="$store.state.showBecomeCreatorModal" />
      <UiCookies />
      <UiToaster />
    </div>
  </div>
</template>
<script>
import Pusher from 'pusher-js';

import UiAddToList from '@/components/ui/UiAddToList.vue';
import UiBuy from '@/components/ui/UiBuy.vue';
import UiCookies from '@/components/ui/UiCookies.vue';
import UiGradients from '@/components/ui/UiGradients.vue';
import UiIcons from '@/components/ui/UiIcons.vue';
import UiPhotoSwipe from '@/components/ui/UiPhotoSwipe.vue';
import UiToaster from '@/components/ui/UiToaster.vue';
import UiBecomeCreatorModal from '@/components/ui/UiBecomeCreatorModal.vue';

export default {
  components: {
    UiBecomeCreatorModal,
    UiAddToList,
    UiBuy,
    UiCookies,
    UiGradients,
    UiIcons,
    UiPhotoSwipe,
    UiToaster,
  },
  computed: {
    swiperItems() {
      return this.$store.state.swiperItems;
    },
    swiperOptions() {
      return this.$store.state.swiperOptions;
    },
    currentUser() {
      return this.$store.state.currentUser;
    },
  },
  mounted() {
    this.listen();
    // Fetch feature flag.
    // @TODO: App should not be loading before fetching feature flags.
    this.$get('/ff', (data) => {
      this.$store.state.featureFlags = JSON.parse(atob(data));
    });
  },
  watch: {
    currentUser() {
      this.listen();
    },
  },
  methods: {
    hidePhotoSwipe() {
      this.$store.state.swiperItems = [];
    },
    listen() {
      if (this.currentUser) {
        this.$store.state.pusher = new Pusher(
          process.env.VUE_APP_PUSHER_APP_KEY,
          {
            cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
            authEndpoint:
              process.env.VUE_APP_API_URL +
              '/' +
              process.env.VUE_APP_API_VERSION +
              '/broadcasting/auth',
            auth: {
              headers: { Authorization: 'Bearer ' + this.$store.state.token },
            },
          },
        );
        this.$store.state.pusher.subscribe(
          'private-users.' + this.currentUser.id,
        );
        this.$store.state.pusher.bind_global((_, data) => {
          if (data.updates) {
            this.$store.state.updates = {
              notifications: data.updates.notifications,
              messages: data.updates.messages,
            };
          }
        });
      }
    },
  },
};
</script>

<style>
#app {
  isolation: isolate;
}
</style>