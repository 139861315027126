<template>
  <div
    v-if="comment != null"
    class="tw-w-full tw-break-words"
  >
    <div class="tw-flex tw-items-start tw-space-x-2">
      <UiAvatar
        size="xs"
        :src="comment.user.avatar_url"
        :text="comment.user.initials"
        :to="comment.user.url"
      />

      <div>
        <UiUsername
          :user="comment.user"
        />
        <div class="tw-text-base tw-text-gray-700">
          @{{ comment.user.username }}
        </div>
      </div>
    </div>

    <div class="tw-mt-3 tw-overflow-hidden">
      <div
        class="tw-whitespace-pre-line"
        v-html="comment.messageParsed"
      />
    </div>

    <div class="tw-mt-3 tw-flex tw-space-x-6">
      <div class="tw-flex tw-items-center">
        <button
          @click.prevent="like"
        >
          <UiIcon
            :class="{ 'tw-text-primary-500': comment.isLiked !== false }"
            name="heart"
            :solid="comment.isLiked !== false"
          />
        </button>
        <span
          v-if="comment.likesCount > 0"
          class="tw-ml-2 tw-text-sm md:tw-ml-4 md:tw-text-base"
        >
          {{ comment.likesCount }}
        </span>
      </div>

      <button
        class="tw-font-semibold"
        @click="reply"
      >
        {{ $t('general.reply') }}
      </button>
      <button
        v-if="isOwner"
        @click="del"
        class="tw-font-semibold"
      >
        {{ $t('general.delete') }}
      </button>

      <div class="text-muted mr-2">
        {{ comment.ago }}
      </div>
    </div>
  </div>
</template>
<script>
import Comment from '../models/Comment';
import UiUsername from './UiUsername.vue';
import UiAvatar from '@/components/ui/UiAvatar.vue';
import UiIcon from '@/components/ui/UiIcon.vue';

export default {
  components: { UiIcon, UiAvatar, UiUsername },
  props: {
    value: Comment,
  },
  computed: {
    comment: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    isOwner: function () {
      return (
        this.value && this.value.user.id == this.$store.state.currentUser.id
      );
    },
  },
  methods: {
    reply() {
      this.$emit('reply', this.comment);
    },
    like() {
      this.$post(
        '/comments/' + this.comment.id + '/like',
        {},

        (data) => {
          this.comment.isLiked = data.is_liked;
          this.comment.likesCount = data.likes_count;
        },
        (errors) => {
          console.log(errors);
        },
      );
    },
    del() {
      this.$post(
        '/comments/' + this.comment.id,
        {
          _method: 'DELETE',
        },
        () => {
          this.comment = null;
        },
        (errors) => {
          console.log(errors);
        },
      );
    },
  },
};
</script>
