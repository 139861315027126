import dayjs from 'dayjs';

export default class Contest {

  name = '';
  slug = '';
  started_at = null;
  closed_at = null;

  constructor(data) {
    if (!data) {
      return;
    }

    this.name = data.name;
    this.slug = data.slug;
    this.started_at = dayjs(data.started_at);
    this.closed_at = dayjs(data.closed_at);
  }

  get url() {
    return `/contest/${this.slug}`;
  }

  get isClosed() {
    return this.closed_at.isBefore(dayjs());
  }

}
