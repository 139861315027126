<template>
  <div>
    <UiCard>
      <UiNavigationBar
        bleed
        responsive
        :title="$t('general.payment-history')"
        @back="$router.go(-1)"
      />

      <div>
        <UiPaymentMethods />
      </div>

      <div class="tw-mt-6">
        <UiPaymentsHistory />
      </div>
    </UiCard>
  </div>
</template>

<script>
import UiCard from '@/components/ui/UiCard.vue';
import UiNavigationBar from '@/components/ui/UiNavigationBar.vue';
import UiPaymentMethods from '@/components/ui/UiPaymentMethods.vue';
import UiPaymentsHistory from '@/components/ui/UiPaymentsHistory.vue';

export default {
  components: {
    UiCard,
    UiNavigationBar,
    UiPaymentMethods,
    UiPaymentsHistory,
  },
};
</script>
