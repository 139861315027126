import { render, staticRenderFns } from "./PageEmail.vue?vue&type=template&id=61813048&"
import script from "./PageEmail.vue?vue&type=script&lang=js&"
export * from "./PageEmail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports