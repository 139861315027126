<template>
  <div>
    <div class="tw-h-screen tw-flex tw-justify-center tw-items-center tw-p-4">
      <div class="tw-text-center">
        <ui-logo
            class="tw-w-auto tw-mx-auto tw-h-10 lg:tw-h-12"
        />
        <div class="tw-mt-4 tw-whitespace-pre-line tw-text-gray-500">
          {{ $t('errors.api-503') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UiLogo from '@/components/ui/UiLogo.vue';

export default {
  components: {
    UiLogo,
  },
};
</script>
