<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    :stroke="solid ? null : 'currentColor'"
    :fill="fill"
  >
    <use
      :href="href"
      :xlink:href="href"
    />
  </svg>
</template>

<script>
export default {
  props: {
    name: String,
    solid: Boolean,
    curved: Boolean,
    gradient: String,
  },
  computed: {
    suffix() {
      if (this.solid) {
        return '-solid';
      }

      if (this.curved) {
        return '-curved';
      }

      return '-outline';
    },
    href() {
      return `#icon-${this.name}${this.suffix}`;
    },
    fill() {
      return this.gradient
        ? `url(#${this.gradient})`
        : this.solid
          ? 'currentColor'
          : 'none';
    },
  },
};
</script>
