<template>
  <label class="tw-relative tw-inline-flex tw-items-center tw-cursor-pointer">
    <input
      v-model="active"
      class="tw-sr-only tw-peer"
      type="checkbox"
    >
    <span
      class="
        tw-w-11 tw-h-6 tw-bg-gray-200 tw-rounded-full
        after:tw-content-[''] after:tw-absolute after:tw-top-[2px] after:tw-left-[2px] after:tw-h-5 after:tw-w-5 after:tw-bg-white after:tw-rounded-full after:tw-transition-all
        tw-peer peer-checked:tw-bg-primary-500 peer-checked:after:tw-translate-x-full
      "
    />
  </label>
</template>

<script>
export default {
  props: [
    'checked',
    'value',
  ],
  model: {
    prop: 'checked',
    event: 'change',
  },
  computed: {
    active: {
      get() {
        return this.checked;
      },
      set(active) {
        this.$emit('change', active);
      },
    },
  },
};
</script>
