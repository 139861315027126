import User from '@/components/models/User';

export const HASHTAG_RESULT = 0;

export const SEARCH_RESULT = 1;

export const USER_RESULT = 2;

export const recent = [
  {
    name: 'beautifulgirl',
    posts_count: 12000000,
    type: HASHTAG_RESULT,
  },
  {
    name: 'Angelina',
    posts_count: 447_000_000,
    type: SEARCH_RESULT,
  },
  {
    user: new User({
      name: 'Angelina',
      username: 'angelina',
    }),
    type: USER_RESULT,
  },
];

export const results = [
  {
    name: 'angelinajoliefans',
    posts_count: 12000000,
    type: HASHTAG_RESULT,
  },
  {
    name: 'angelina',
    posts_count: 12000000,
    type: HASHTAG_RESULT,
  },
  {
    name: 'angelinagarcia',
    posts_count: 12000000,
    type: HASHTAG_RESULT,
  },
  {
    user: new User({
      name: 'Angelina',
      username: 'angelina',
    }),
    type: USER_RESULT,
  },
  {
    user: new User({
      name: 'Julia',
      username: 'julia_angelina',
    }),
    type: USER_RESULT,
  },
  {
    user: new User({
      name: 'Angelina Jolie',
      username: 'angelina_jolie',
    }),
    type: USER_RESULT,
  },
]
