<template>
  <div v-if="visible">
    <UiSubscribeModal
      v-if="item.type === null"
      :user="item.user"
      @cancel="visible = false"
      @confirm="subscribe"
    />

    <UiModal
      v-else-if="isTip && !isTipReady"
      size="sm"
      :title="$t('general.send-tip')"
      :subtitle="$t('general.support-creator-tip')"
      :cancel="$t('general.cancel')"
      :confirm="$t('general.continue')"
      :disabled="Object.keys(errors).length > 0"
      @cancel="visible = false"
      @confirm="isTipReady = amount !== ''"
    >
      <div>
        <div class="tw-flex tw-items-center">
          <UiAvatar
            size="sm"
            :src="item.user.avatar_url"
            :text="item.user.initials"
            :to="item.user.url"
          />
          <div class="tw-ml-3 tw-flex tw-flex-col tw-overflow-hidden">
            <UiUsername
              :asLink="false"
              :user="item.user"
            />
            <span class="tw-text-gray-700">
              {{ '@' + item.user.username }}
            </span>
          </div>
        </div>

        <div class="tw-mt-8">
          <UiFormInput
            v-model="amount"
            :errors="errors"
            :label="$t('general.amount')"
            name="amount"
            placeholder="10"
            :prepend="currency"
            type="number"
          />
          <UiFormTextarea
            v-model="message"
            class="tw-mt-8"
            :errors="errors"
            :label="$t('general.add-a-note-optional')"
            name="message"
            :placeholder="$t('general.note')"
          />
          <UiFormErrors
            v-if="errors._ && errors._.length > 0"
            :errors="errors._"
          />
        </div>
      </div>
    </UiModal>

    <UiModal
      v-else
      size="sm"
      :title="title"
      :subtitle="description"
      :cancel="isTip ? '' : $t('general.cancel')"
      :confirm="isTip ? $t('general.send-tip') : $t('general.continue')"
      :disabled="isBuying"
      @cancel="visible = false"
      @close="visible = false"
      @confirm="buy"
    >
      <div v-if="formattedAmountWithVat">
        <div class="tw-flex tw-items-center tw-justify-center tw-p-6 tw-rounded-2xl tw-border-2 tw-border-primary-400">
          <div>
            <span class="tw-font-semibold tw-text-h4 md:tw-text-h3">
              {{ formattedAmountWithVat }}
            </span>
            <span v-if="months">
              <span class="tw-font-semibold tw-text-h4 md:tw-text-h3">
                /
              </span>
              <span class="tw-text-sm">
                {{ $tc('general.x-months', months) }}
              </span>
            </span>
          </div>

          <UiIcon
            class="tw-ml-4 tw-h-8 tw-w-8 tw-text-primary-500"
            name="crown"
            solid
          />
        </div>

        <div class="tw-mt-6 md:tw-mt-8">
          <UiCard class="tw-shadow-[0px_4px_60px_rgba(4,6,15,0.05)]">
            <div class="tw-space-y-5">
              <div class="tw-flex tw-justify-between">
                <div class="tw-text-gray-500">{{ $t('general.amount') }}</div>
                <div class="tw-font-semibold">{{ baseAmountFormatted }}</div>
              </div>
              <div class="tw-flex tw-justify-between">
                <div class="tw-text-gray-500">{{ $t('general.vat', { vat: vatRate }) }}</div>
                <div class="tw-font-semibold">
                  {{ vatAmount }}
                </div>
              </div>
              <div class="tw-border-t tw-border-gray-200" />
              <div class="tw-flex tw-justify-between">
                <div class="tw-text-gray-500">{{ $t('general.total') }}</div>
                <div class="tw-font-semibold">{{ formattedAmountWithVat }}</div>
              </div>
            </div>
          </UiCard>
        </div>
      </div>

      <UiCard class="tw-my-5 tw-shadow-[0px_4px_60px_rgba(4,6,15,0.05)]">
        <div v-if="useAnotherMethod">
          <div
            class="d-flex align-items-center tw-text-sm tw-mb-5 tw-cursor-pointer"
            v-if="method !== null"
            @click="useAnotherMethod = false"
          >
            <UiIcon class="tw-mr-1 tw-h-1/3" name="arrow-left"/>
            {{ $t('general.back') }}
          </div>
          <div class="d-flex align-items-center">
            <i
              class="bi bi-credit-card mr-2"
              style="font-size: 150%; line-height: 1"
            />
            {{ $t('general.credit-card') }}
          </div>
          <div class="tw-border-b tw-mt-2 tw-border-gray-200"></div>
        </div>

        <ui-credit-card
          :class="{'tw-hidden': !useAnotherMethod}"
          v-if="(!isTip || amount)"
          ref="cc"
          :amount="calculatedTipWithVat?.amount_with_vat || amountWithVat"
          :message="message"
          :method="useAnotherMethod ? null : method"
          @token="token"
          @error="error"
        />

<!--        <div v-if="useAnotherMethod">-->
<!--          <div-->
<!--              v-if="isNewSub"-->
<!--              class="tw-text-sm"-->
<!--          >-->
<!--            {{ this.$t('general.payment-processing.sub-vault-cc') }}-->
<!--          </div>-->

<!--          <b-form-checkbox-->
<!--              class="tw-text-sm"-->
<!--              v-if="!isNewSub"-->
<!--              id="checkbox-1"-->
<!--              v-model="vaultCc"-->
<!--              name="checkbox-1"-->
<!--              :value="true"-->
<!--              :unchecked-value="false"-->
<!--          >-->
<!--            {{ this.$t('general.payment-processing.vault-cc') }}-->
<!--          </b-form-checkbox>-->
<!--        </div>-->

        <div class="tw-text-sm" v-if="!useAnotherMethod">
          <span v-html="$t('general.payment-processing.pay-using-vault', { paymentMethod: method.card_provider + ' ' + method.title }) "/>
          <UiButton
            class="tw-w-full tw-group tw-mt-5"
            variant="outline"
            size="sm"
            @click="useAnotherMethod = true"
            icon="wallet"
          >
            {{ $t('general.payment-processing.pay-using-new-method') }}
          </UiButton>
        </div>
      </UiCard>

      <UiFormErrors
        class="tw-my-2"
        v-if="errors._ && errors._.length > 0"
        :errors="errors._"
      />
    </UiModal>

    <UiSuccessModal
      v-if="isSuccessModalOpen"
      v-bind="successModalProps"
      @confirm="successModalConfirm"
      @close="visible = false"
    />
  </div>
</template>

<style scoped lang="scss">
.gateway {
  img {
    height: 1.5em;
  }
}
</style>
<script>
import Payment from '@/components/models/Payment';
import User from '@/components/models/User';

import UiCreditCard from '@/components/ui/UiCreditCard.vue';
import UiFormInput from '@/components/ui/UiFormInput.vue';
import UiModal from '@/components/ui/UiModal.vue';
import UiUsername from '@/components/ui/UiUsername.vue';
import UiFormTextarea from '@/components/ui/UiFormTextarea.vue';
import UiAvatar from '@/components/ui/UiAvatar.vue';
import UiSubscribeModal from '@/components/ui/UiSubscribeModal.vue';
import UiCard from '@/components/ui/UiCard.vue';
import UiIcon from '@/components/ui/UiIcon.vue';
import UiSuccessModal from '@/components/ui/UiSuccessModal.vue';
import UiFormErrors from '@/components/ui/UiFormErrors.vue';
import UiButton from "@/components/ui/UiButton.vue";

export default {
  components: {
    UiButton,
    UiFormErrors,
    UiSuccessModal,
    UiIcon,
    UiCard,
    UiSubscribeModal,
    UiAvatar,
    UiFormTextarea,
    UiCreditCard,
    UiFormInput,
    UiModal,
    UiUsername,
  },
  data: function () {
    return {
      gateway: 'cc',
      gateways: [],
      method: null,
      vaultCc: true,
      useAnotherMethod: true,
      isBuying: false,
      errors: {},
      amount: '',
      calculatedTipWithVat: null,
      message: '',
      info: {
        paypal: {
          logo: require('@/assets/paypal.svg'),
        },
      },
      isTipReady: false,
      isSuccessModalOpen: false,
      successModalProps: {
        icon: '',
        title: '',
        message: '',
        button: '',
      },
      successModalConfirm: null,
    };
  },
  computed: {
    currency() {
      return process.env.VUE_APP_CURRENCY_SIGN;
    },
    item() {
      return this.$store.state.buyItem;
    },
    visible: {
      get() {
        return this.$store.state.buyItem !== null;
      },
      set() {
        this.$store.state.buyItem = null;
      },
    },
    isTip() {
      return (
        this.$store.state.buyItem &&
        this.$store.state.buyItem.type === Payment.TYPE_TIP
      );
    },
    isNewSub() {
      return this.$store.state.buyItem.type === Payment.TYPE_SUBSCRIPTION_NEW;
    },
    months() {
      if (
        this.$store.state.buyItem &&
        this.$store.state.buyItem.type === Payment.TYPE_SUBSCRIPTION_NEW
      ) {
        return (
          this.$store.state.buyItem.bundle &&
          this.$store.state.buyItem.bundle.months
            ? this.$store.state.buyItem.bundle.months
            : '1'
        );
      }

      return null;
    },
    baseAmountFormatted() {
      if (this.$store.state.buyItem) {
        switch (this.$store.state.buyItem.type) {
          case Payment.TYPE_SUBSCRIPTION_NEW:
            return this.$store.state.buyItem.bundle
                ? this.$store.state.buyItem.bundle.baseAmountFormatted
                : this.$store.state.buyItem.user.baseAmountFormatted;
          case Payment.TYPE_POST:
            return this.$store.state.buyItem.post.baseAmountFormatted;
          case Payment.TYPE_MESSAGE:
            return this.$store.state.buyItem.message.baseAmountFormatted;
          case Payment.TYPE_TIP:
            return process.env.VUE_APP_CURRENCY_SIGN + this.calculatedTipWithVat.amount;
        }
      }

      return null;
    },
    amountWithVat() {
      if (this.$store.state.buyItem) {
        switch (this.$store.state.buyItem.type) {
          case Payment.TYPE_SUBSCRIPTION_NEW:
            return this.$store.state.buyItem.bundle
                ? this.$store.state.buyItem.bundle.vat_price.amount_with_vat
                : this.$store.state.buyItem.user.vat_price.amount_with_vat;
          case Payment.TYPE_POST:
            return this.$store.state.buyItem.post.vat_price.amount_with_vat;
          case Payment.TYPE_MESSAGE:
            return this.$store.state.buyItem.message.vat_price.amount_with_vat;
          case Payment.TYPE_TIP:
            return this.calculatedTipWithVat.amount_with_vat;
        }
      }

      return null;
    },
    formattedAmountWithVat() {
      if (this.$store.state.buyItem) {
        switch (this.$store.state.buyItem.type) {
          case Payment.TYPE_SUBSCRIPTION_NEW:
            return this.$store.state.buyItem.bundle
              ? this.$store.state.buyItem.bundle.priceFormatted
              : this.$store.state.buyItem.user.priceFormatted;
          case Payment.TYPE_POST:
            return this.$store.state.buyItem.post.priceFormatted;
          case Payment.TYPE_MESSAGE:
            return this.$store.state.buyItem.message.priceFormatted;
          case Payment.TYPE_TIP:
            return process.env.VUE_APP_CURRENCY_SIGN + this.calculatedTipWithVat.amount_with_vat;
        }
      }

      return null;
    },
    vatAmount() {
      if (this.$store.state.buyItem) {
        switch (this.$store.state.buyItem.type) {
          case Payment.TYPE_SUBSCRIPTION_NEW:
            // eslint-disable-next-line no-case-declarations
            const amount = this.$store.state.buyItem.bundle
                ? this.$store.state.buyItem.bundle.vat_price.vat_amount
                : this.$store.state.buyItem.user.vat_price.vat_amount;

            return process.env.VUE_APP_CURRENCY_SIGN + amount;
          case Payment.TYPE_POST:
            return process.env.VUE_APP_CURRENCY_SIGN + this.$store.state.buyItem.post.vat_price.vat_amount;
          case Payment.TYPE_MESSAGE:
            return process.env.VUE_APP_CURRENCY_SIGN + this.$store.state.buyItem.message.vat_price.vat_amount;
          case Payment.TYPE_TIP:
            return process.env.VUE_APP_CURRENCY_SIGN + this.calculatedTipWithVat.vat_amount;
        }
      }

      return null;
    },
    vatRate() {
      if (this.$store.state.buyItem) {
        switch (this.$store.state.buyItem.type) {
          case Payment.TYPE_SUBSCRIPTION_NEW:
            return this.$store.state.buyItem.bundle
                ? this.$store.state.buyItem.bundle.vat_price.vat_rate
                : this.$store.state.buyItem.user.vat_price.vat_rate;
          case Payment.TYPE_POST:
            return this.$store.state.buyItem.post.vat_price.vat_rate;
          case Payment.TYPE_MESSAGE:
            return this.$store.state.buyItem.message.vat_price.vat_rate;
          case Payment.TYPE_TIP:
            return this.calculatedTipWithVat.vat_rate;
        }
      }

      return null;
    },
    title() {
      if (this.$store.state.buyItem) {
        switch (this.$store.state.buyItem.type) {
          case Payment.TYPE_SUBSCRIPTION_NEW:
            return this.$t('general.subscribe');
          case Payment.TYPE_POST:
            return this.$t('general.unlock-post');
          case Payment.TYPE_MESSAGE:
            return this.$t('general.unlock-message');
          case Payment.TYPE_TIP:
            return this.$t('general.send-tip');
        }
      }
      return '';
    },
    description() {
      if (this.isTip) {
        return this.$t('general.support-creator-tip');
      }

      if (this.$store.state.buyItem) {
        switch (this.$store.state.buyItem.type) {
          case Payment.TYPE_POST:
            return this.$t('general.unlock-post-from-x-for-y', [
              this.$store.state.buyItem.post.user.name,
              this.$store.state.buyItem.post.priceFormatted,
            ]);
          case Payment.TYPE_MESSAGE:
            return this.$t('general.unlock-message-from-x-for-y', [
              this.$store.state.buyItem.message.user.name,
              this.$store.state.buyItem.message.priceFormatted,
            ]);
        }
      }

      return '';
    },
  },
  watch: {
    visible(visible) {
      if (visible) {
        this.init();
      }
    },
    useAnotherMethod() {
      this.errors = {};
    },
    amount() {
      this.errors = {};

      if (this.amount === null) {
        return;
      }

      if (this.amount < 5) {
        this.error(this.$t('general.payment-processing.min-tip-amount', { amount: 5 }));

        return;
      }

      if (this.amount > 100) {
        this.error(this.$t('general.payment-processing.max-tip-amount', { amount: 100 }));

        return;
      }

      this.$post(
        '/payments/vat',
        { amount: this.amount },
        ({ data }) => {
          this.calculatedTipWithVat = data;
        }
      )
    },
  },
  methods: {
    init() {
      this.message = '';
      this.amount = null;
      this.isTipReady = false;
      this.isSuccessModalOpen = false;
      this.loadMethod();
      this.isBuying = false;
    },
    loadMethod() {
      // For now, we now longer propose credit card vaulting as it's not supported by the bank.
      // this.$get(
      //   '/payment/method',
      //   (data) => {
      //     if (data.method !== null) {
      //       this.method = data.method;
      //       this.useAnotherMethod = false;
      //     }
      //   },
      //   (errors) => {
      //     console.log(errors);
      //   },
      // );
    },
    token(title, token) {
      this.proceed({
        gateway: 'cc',
        title: title,
        token: token,
      });
    },
    error(error) {
      if (error) {
        this.isBuying = false;
        this.errors = { _: [error] };
      }
    },
    success(props, callback = null) {
      this.isSuccessModalOpen = true;
      this.successModalProps = props;
      this.successModalConfirm = () => {
        if (callback) {
          callback();
        }
        this.isSuccessModalOpen = false;
        this.visible = false;
      };
    },
    subscribe(bundle) {
      this.$buyItem({
        type: Payment.TYPE_SUBSCRIPTION_NEW,
        user: this.item.user,
        bundle: bundle,
      });
    },
    buy() {
      this.isBuying = true;
      this.$refs.cc.save();
    },
    proceed(fields) {
      fields.type = this.item.type;

      switch (this.item.type) {
        case Payment.TYPE_SUBSCRIPTION_NEW:
          fields.sub_id = this.item.user.id;
          if (this.item.bundle) {
            fields.bundle_id = this.item.bundle.id;
          }
          break;
        case Payment.TYPE_POST:
          fields.post_id = this.item.post.id;
          break;
        case Payment.TYPE_MESSAGE:
          fields.message_id = this.item.message.id;
          break;
        case Payment.TYPE_TIP:
          fields.amount = this.amount;
          fields.message = this.message;
          fields.to_id = this.item.user.id;
          if (this.item.post) {
            fields.post_id = this.item.post.id;
          }
          break;
      }

      // fields.customer_id = this.useAnotherMethod ? null : this.method.gateway_customer;
      // fields.vault = this.isNewSub ? true : this.vaultCc;
      fields.vault = false;
      // Vault is currently disabled as ECP restriction

      this.$showSpinner();
      this.$post(
        '/payments',
        fields,
        (data) => {
          if (data.redirect) {
            location = data.redirect;
          } else if (data.tip) {
            this.success({
              icon: 'buy',
              title: this.$t('general.tip-sent'),
              button: this.$t('general.ok'),
            });
          } else if (data.post) {
            this.item.success(data.post);
            this.success({
              icon: 'unlock',
              title: this.$t('general.congratulations'),
              message: this.$t('general.unlock-successful'),
              button: this.$t('general.see-content'),
            });
          } else if (data.message) {
            this.item.success(data.message);
            this.success({
              icon: 'unlock',
              title: this.$t('general.congratulations'),
              message: this.$t('general.unlock-successful'),
              button: this.$t('general.see-content'),
            });
          } else if (data.user) {
            let u = new User(data.user);
            this.success({
              icon: 'star',
              title: this.$t('general.congratulations'),
              message: this.$t('general.subscribe-successful-to-x', [u.name]),
              button: this.$t('general.ok'),
            }, () => {
              this.$router.push(u.url).catch(() => {
                this.$router.go();
              });
            });
          } else {
            this.$router.go();
            this.visible = false;
            this.isBuying = false;
          }
        },
        (errors) => {
          this.errors = errors;
          this.isBuying = false;
        },
      );
    },
    onSuccessClose() {
      this.isSuccessModalOpen = false;
      this.visible = false;
    },
  },
};
</script>
