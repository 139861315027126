<template>
  <UiCard
    v-if="list !== null"
  >
    <UiNavigationBar
      bleed
      :title="list.title"
      @back="$router.go(-1)"
    />

    <div class="tw-space-y-6 sm:tw-hidden">
      <div
        v-for="(item, index) in users"
        :key="index"
      >
        <UiUserItem
          :removable="isEditable"
          :user="item"
          @remove="userToRemove = item"
        />
      </div>
    </div>

    <div class="tw-hidden sm:tw-grid tw-gap-6 tw-grid-cols-2 xl:tw-grid-cols-3">
      <div
        v-for="(item, index) in users"
        :key="index"
      >
        <UiUserCard
          :removable="isEditable"
          :user="item"
          @remove="userToRemove = item"
        />
      </div>
    </div>

    <UiAlertModal
      v-if="userToRemove !== null"
      :title="$t('general.remove-title')"
      :message="$t('general.remove-x-message', [userToRemove.name])"
      :cancel="$t('general.cancel')"
      :confirm="$t('general.yes-remove')"
      @cancel="userToRemove = null"
      @confirm="onRemoveUser"
    />
  </UiCard>
</template>

<script>
import List from '@/components/models/List';
import User from '@/components/models/User';

import UiCard from '@/components/ui/UiCard.vue';
import UiNavigationBar from '@/components/ui/UiNavigationBar.vue';
import UiUserCard from '@/components/ui/UiUserCard.vue';
import UiUserItem from '@/components/ui/UiUserItem.vue';
import UiAlertModal from '@/components/ui/UiAlertModal.vue';

export default {
  components: {
    UiAlertModal,
    UiUserItem,
    UiCard,
    UiNavigationBar,
    UiUserCard,
  },
  data: function () {
    return {
      list: null,
      users: [],
      page: 1,
      hasMore: false,
      isLoading: false,
      userToRemove: null,
    };
  },
  computed: {
    isEditable() {
      return ![
        List.DEFAULT_FANS,
        List.DEFAULT_RECENT,
      ].includes(this.list.id);
    },
  },
  mounted() {
    this.loadList();
    window.addEventListener('scroll', this.updateScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.updateScroll);
  },
  methods: {
    updateScroll() {
      const scrollPosition = window.innerHeight + window.scrollY;
      if (
        document.body.offsetHeight &&
        scrollPosition &&
        document.body.offsetHeight - scrollPosition <= 1000 &&
        !this.isLoading &&
        this.hasMore
      ) {
        this.loadMore();
      }
    },
    loadList() {
      this.isLoading = true;
      let users = [...this.users];
      this.$get(
        '/lists/' + this.$route.params.id + '?page=' + this.page,
        (data) => {
          this.list = new List(data.list, this);
          for (let u of data.users.data) {
            users.push(new User(u));
          }
          this.users = users;
          this.hasMore = data.users.next_page_url != null;
          this.isLoading = false;
        },
        (errors) => {
          console.log(errors);
        },
      );
    },
    loadMore() {
      if (this.hasMore) {
        this.page = this.page + 1;
        this.loadList();
      }
    },
    onRemoveUser() {
      this.$post(
        '/lists/' + this.userToRemove.id + '/' + this.list.id,
        {},
        (data) => {
          if (!data.status) {
            // User has been removed from the list
            this.users = this.users.filter((user) => user.id !== this.userToRemove.id);
          }

          this.userToRemove = null;
        },
        (errors) => {
          console.log(errors);
        },
      );
    },
  },
};
</script>
