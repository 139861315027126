<template>
  <tr
    class="tw-font-semibold md:tw-text-lg"
    :class="{ 'tw-border-b tw-border-gray-200 last:tw-border-b-0': divide }"
  >
    <slot />
  </tr>
</template>

<script>
export default {
  props: {
    divide: {
      type: Boolean,
      default: true,
    }
  }
}
</script>
