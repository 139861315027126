<template>
  <label class="tw-inline-flex tw-items-center tw-mb-0">
    <input
      :checked="active"
      :value="value"
      class="tw-peer tw-sr-only"
      type="radio"
      @change="(event) => active = event.target.value"
    >
    <span
      class="
        tw-relative tw-block tw-h-6 tw-w-6 tw-bg-white tw-rounded-full tw-border-[3px] tw-border-primary-500
        after:content-[''] after:tw-absolute after:tw-top-1/2 after:tw-left-1/2
        after:-tw-translate-y-1/2 after:-tw-translate-x-1/2 after:tw-h-3.5
        after:tw-w-3.5 after:tw-rounded-full after:tw-bg-primary-500
        after:tw-opacity-0 after:peer-checked:tw-opacity-100
      "
    />
    <span
      v-if="label"
      class="tw-ml-2"
    >
      {{ label }}
    </span>
  </label>
</template>

<script>
export default {
  props: {
    checked: [String, Number],
    value: [String, Number],
    label: String,
  },
  model: {
    prop: 'checked',
    event: 'change',
  },
  computed: {
    active: {
      get() {
        return this.checked === this.value;
      },
      set(value) {
        this.$emit('change', value);
      },
    },
  },
};
</script>
