<template>
  <div>
    <div
      v-for="(item, index) in notifications"
      :key="index"
      class="tw-my-3"
    >
      <div
        v-if="
            index === 0 ||
            (index > 0 &&
              !notifications[index - 1].created_at.isSame(
                item.created_at,
                'day'
              ))
          "
        class="tw-mb-4 tw-font-semibold md:tw-text-center md:tw-text-lg"
        :class="{ 'tw-mt-6': index > 0 }"
      >
        {{ item.created_at.format('ll') }}
      </div>

      <RouterLink
        v-if="item.items.user"
        class="tw-w-full tw-flex tw-items-center"
        :to="item.url"
      >
        <span
          v-if="!item.isViewed"
          class="tw-h-[6px] tw-w-[6px] tw-shrink-0 tw-bg-black tw-rounded-full tw-mr-4 tw-mt-1"
        />

        <UiAvatar
          :to="item.items.user.url"
          :text="item.items.user.initials"
          :src="item.items.user.avatar_url"
          size="sm"
        />

        <div class="tw-ml-3 tw-overflow-hidden">
          <UiUsername
            class="tw-overflow-hidden"
            :user="item.items.user"
          />
          <div class="tw-text-gray-400">
            {{ $t(item.description[0], item.description[1]) }}
          </div>
        </div>
      </RouterLink>

      <div
          v-else
          class="tw-w-full tw-flex tw-items-center"
      >
        <span
            v-if="!item.isViewed"
            class="tw-h-[6px] tw-w-[6px] tw-shrink-0 tw-bg-black tw-rounded-full tw-mr-4 tw-mt-1"
        />

        <div class="tw-ml-3 tw-overflow-hidden">
          <div
              class="tw-flex tw-items-center tw-text-base md:tw-text-lg"
          >
            <span
                class="tw-truncate tw-font-bold"
            >
              System
            </span>
          </div>
          <div class="tw-text-gray-400">
            {{ $t(item.description[0], item.description[1]) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Notification from '@/components/models/Notification';

import UiUsername from '@/components/ui/UiUsername.vue';
import UiAvatar from '@/components/ui/UiAvatar.vue';

export default {
  components: {
    UiAvatar,
    UiUsername,
  },
  data: function () {
    return {
      notifications: [],
      page: 1,
      hasMore: false,
      isLoading: false,
    };
  },
  mounted() {
    this.loadNotifications();
    window.addEventListener('scroll', this.updateScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.updateScroll);
  },
  methods: {
    updateScroll() {
      const scrollPosition = window.innerHeight + window.scrollY;
      if (
        document.body.offsetHeight &&
        scrollPosition &&
        document.body.offsetHeight - scrollPosition <= 1000 &&
        !this.isLoading &&
        this.hasMore
      ) {
        this.loadMore();
      }
    },
    loadNotifications() {
      this.isLoading = true;
      if (this.page == 1) {
        this.$showSpinner();
      }
      this.$get(
        '/notifications?page=' + this.page,
        (data) => {
          let ns = [...this.notifications];
          for (let obj of data.data) {
            ns.push(new Notification(obj));
          }
          this.notifications = ns;
          this.hasMore = data.next_page_url != null;
          this.isLoading = false;
        },
        (errors) => {
          console.log('error');
          console.log(errors);
        },
      );
    },
    loadMore() {
      if (this.hasMore) {
        this.page = this.page + 1;
        this.loadNotifications();
      }
    },
  },
};
</script>
