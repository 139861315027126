<template>
  <div>
    <div
      v-if="method !== null"
      class="tw-flex tw-items-center tw-justify-center tw-space-x-2"
    >
      <UiIcon
        class="tw-h-6 tw-w-6"
        :name="iconName"
        solid
      />
      <span>
        {{ info }}
      </span>
      <button
        class="tw-font-semibold tw-text-primary-500"
        @click="edit"
      >
        {{ $t('general.edit') }}
      </button>
    </div>

    <div
      class="tw-text-center"
      v-else
    >
      <button
        class="tw-font-semibold tw-text-primary-500"
        @click="edit"
      >
        {{ $t('general.add-payout-method') }}
      </button>
    </div>

    <UiModal
      v-if="editing"
      size="lg"
      :cancel="$t('general.cancel')"
      :confirm="$t('general.save')"
      :title="$t('general.add-payout-method')"
      @cancel="unedit"
      @confirm="promptSecurityCode = true"
    >
      <UiSecurityCodeModal
        v-if="promptSecurityCode"
        :title="$t('security-code.verify-header')"
        :cancel="true"
        @submit="save($event)"
        @cancel="promptSecurityCode = false"
        class="tw-text-sm"
      >
        <div class="tw-pb-4">
          {{ $t('security-code.verify-text') }}
        </div>
      </UiSecurityCodeModal>

      <div class="tw-flex tw-space-x-3 tw-my-5">
        <UiRadio
          v-model="gateway"
          :label="$t('general.bank')"
          value="bank"
        />
      </div>

      <div>
        <div class="tw-grid tw-gap-3 sm:tw-grid-cols-2 sm:tw-gap-4 xl:tw-grid-cols-2">
          <UiFormInput
              type="text"
              name="firstname"
              v-model="lastname"
              :errors="errors"
              :label="$t('payout.lastname')"
          />
          <UiFormInput
              type="text"
              name="lastname"
              v-model="firstname"
              :errors="errors"
              :label="$t('payout.firstname')"
          />
          <div>
            <UiFormSelect
              v-model="country"
              :label="$t('general.bank-country')"
              :options="countries"
              name="country"
            />
            <UiFormErrors
              id="country-errors"
              :errors="errors['country']"
            />
          </div>

          <UiFormInput
            type="text"
            name="name"
            v-model="name"
            :errors="errors"
            :label="$t('general.bank-name')"
          />
          <UiFormInput
              v-model="account"
              type="text"
              name="account"
              :errors="errors"
              :label="$t('general.account-number')"
          />
          <UiFormInput
            v-model="swift"
            type="text"
            name="swift"
            :errors="errors"
            :label="$t('general.swift')"
          />
        </div>
      </div>
    </UiModal>
  </div>
</template>

<script>
import PayoutMethod from '@/components/models/PayoutMethod';
import Countries from '@/components/helpers/Countries';

import UiFormInput from '@/components/ui/UiFormInput.vue';
import UiFormErrors from '@/components/ui/UiFormErrors.vue';
import UiRadio from '@/components/ui/UiRadio.vue';
import UiButton from '@/components/ui/UiButton.vue';
import UiMobileSelect from '@/components/ui/UiMobileSelect.vue';
import UiIcon from '@/components/ui/UiIcon.vue';
import UiModal from '@/components/ui/UiModal.vue';
import UiFormSelect from "@/components/ui/UiFormSelect.vue";
import UiSecurityCodeModal from "@/components/ui/UiSecurityCodeModal.vue";

export default {
  components: {
    UiSecurityCodeModal,
    UiFormSelect,
    UiModal,
    UiIcon,
    UiMobileSelect,
    UiButton,
    UiRadio,
    UiFormErrors,
    UiFormInput,
  },
  props: [
    'value',
    'gateways',
  ],
  data: function () {
    return {
      gateway: 'bank',
      firstname: this.value ? this.value.info.firstname : '',
      lastname: this.value ? this.value.info.lastname : '',
      name: this.value ? this.value.info.name : '',
      swift: this.value ? this.value.info.swift : '',
      account: this.value ? this.value.info.account : '',
      country: this.value ? this.value.country : '',
      errors: {},
      editing: false,
      promptSecurityCode: false,
    };
  },
  computed: {
    method: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    countries() {
      let countries = [];
      for (let k in Countries) {
        countries.push({
          value: k,
          text: Countries[k],
        });
      }
      return countries;
    },
    info() {
      switch (this.method.gateway) {
        case 'bank':
          return this.$t('general.bank-info', [
            this.method.info.name,
            this.method.info.account.slice(0, 2) +
            '...' +
            this.method.info.account.slice(-2),
          ]);
        default:
          return '';
      }
    },
    iconName() {
      return 'bank';
    },
  },
  methods: {
    edit() {
      this.editing = true;
    },
    unedit() {
      this.editing = false;
    },
    save(securityCode) {
      this.errors = {};
      let fields = { gateway: this.gateway };
      switch (this.gateway) {
        case 'bank':
          fields.firstname = this.firstname;
          fields.lastname = this.lastname;
          fields.name = this.name;
          fields.swift = this.swift;
          fields.account = this.account;
          fields.country = this.country;
          break;
      }
      fields.security_code = securityCode

      this.$post(
        '/payouts/method',
        fields,
        (data) => {
          this.method = new PayoutMethod(data);
          this.editing = false;
          this.promptSecurityCode = false;
          this.$toast({
            title: this.$t('general.success'),
            description: this.$t('general.payout-method-updated'),
            variant: 'success',
          })
        },
        (errors, { response }) => {
          this.promptSecurityCode = false;

          if (response.status === 403) {
            this.$toast({
              title: this.$t('general.error'),
              description: this.$t('security-code.verify-error'),
              variant: 'error',
            })
          } else if (response.status === 429) {
            this.$toast({
              title: this.$t('general.error'),
              description: this.$t('general.too-many-requests'),
              variant: 'error',
            })
          } else {
            this.errors = errors;
          }
        },
      );
    },
  },
};
</script>
