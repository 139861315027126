<template>
  <div class="tw-flex tw-items-center tw-space-x-2 tw-text-gray-500">
    <template
      v-for="(count, index) in counts"
    >
      <div
        :key="count.type"
        class="tw-flex tw-items-center tw-space-x-1"
      >
        <UiIcon
          class="tw-h-5 tw-w-5"
          :name="count.type"
          solid
        />
        <div class="tw-text-sm">
          {{ count.value }}
        </div>
      </div>

      <span
        v-if="index < counts.length - 1"
        :key="'separator' + count.type"
        class="tw-h-1 tw-w-1 tw-bg-gray-500 tw-rounded-full"
      />
    </template>
  </div>
</template>

<script>
import UiIcon from '@/components/ui/UiIcon.vue';

export default {
  components: {
    UiIcon,
  },
  props: {
    mediaCount: Number,
    videoCount: Number,
  },
  computed: {
    counts() {
      const counts = [
        { type: 'video', value: this.videoCount },
        { type: 'image-2', value: this.mediaCount },
      ];

      return counts.filter((count) => count.value > 0);
    },
  },
};
</script>