import axios from 'axios';
import Vapor from 'laravel-vapor';
import Cookie from 'universal-cookie';
import Media from '@/components/models/Media';
import User from '@/components/models/User';

export default {
  install(Vue) {
    Vue.prototype.$saveToken = function (data) {
      if (data == null) {
        localStorage.removeItem('token');
        this.$store.state.token = null;
      } else {
        localStorage.token = data;
        this.$store.state.token = data;
      }
    };

    Vue.prototype.$saveUser = function (data) {
      if (data == null) {
        localStorage.removeItem('impersonating');
        localStorage.removeItem('currentUser');
        this.$store.state.currentUser = null;
        this.$store.state.impersonating = false;
      } else {
        data.avatar_url = data.avatar_url ? data.avatar_url : null;
        localStorage.currentUser = JSON.stringify(data);
        this.$store.state.currentUser = new User(data);
      }
    };

    Vue.prototype.$isEnabled = function (name) {
      const flag = this.$store.state.featureFlags.find(featureFlag => featureFlag.name === name)

      return typeof flag !== 'undefined' ? flag.enabled : false;
    },

    Vue.prototype.$redirector = function () {
      const self = this; // Vue instance is not bound to the returned object's nested functions
      const key = 'app.redirect';
      const cookie = new Cookie();

      return {
        clear: function() {
          cookie.remove(key);
        },
        redirect: function (fallback) {
          const redirect = cookie.get(key);

          if (redirect || fallback) {
            cookie.remove(key);
            return self.$router.replace(redirect ?? fallback);
          }

          // No need to do anything if there isn't a redirect or a fallback.
        },
        push: function (path) {
          cookie.set(key, self.$route.fullPath);
          return self.$router.push(path);
        },
      };
    };

    Vue.prototype.$toast = function (data) {
      const toast = {
        _id: Math.random(),
        duration: 5000,
        ...data,
      };

      this.$store.state.toasts.push(toast);

      setTimeout(() => {
        const index = this.$store.state.toasts.findIndex((t) => t._id === toast._id);
        if (index > -1) {
          this.$store.state.toasts.splice(index, 1);
        }
      }, toast.duration);
    };

    Vue.prototype.$showSpinner = function () {
      this.$store.state.spinner = true;
    };

    Vue.prototype.$hideSpinner = function () {
      this.$store.state.spinner = false;
    };

    Vue.prototype.$showPhotoSwipe = function (media, id) {
      let items = [];
      let index = 0;
      console.log(media);

      for (let item of media) {
        if (item.type === Media.TYPE_VIDEO || item.type === 'video') {
          items.push({
            html: `
               <iframe
                  src="${item.player}"
                  class="tw-absolute tw-inset-0 tw-h-full tw-w-full"
                  width="100%"
                  height="100%"
                  frameborder="0"
                  scrolling="no"
                  allowfullscreen="true"
                >
                </iframe>
            `,
          });
        } else {
          items.push({
            src: item.url,
            w: 0,
            h: 0,
          });
        }
        if (item.id === id) {
          this.$store.state.swiperOptions = {
            index: index,
          };
        }
        index++;
      }
      this.$store.state.swiperItems = items;
    };

    Vue.prototype.$buyItem = function (data) {
      this.$store.state.buyItem = data;
    };

    Vue.prototype.$formatAmount = function (amount) {
      return process.env.VUE_APP_CURRENCY_SIGN + (amount).toLocaleString();
    };

    Vue.prototype.$escape = function (s) {
      return s.replace(/[\u00A0-\u9999<>&]/g, function (i) {
        return '&#' + i.charCodeAt(0) + ';';
      });
    };

    Vue.prototype.$get = function (url, success, failure) {
      this.$api('get', process.env.VUE_APP_API_URL + '/' + process.env.VUE_APP_API_VERSION + url, null, success, failure);
    };

    Vue.prototype.$post = function (url, data, success, failure) {
      this.$api('post', process.env.VUE_APP_API_URL + '/' + process.env.VUE_APP_API_VERSION + url, data, success, failure);
    };

    Vue.prototype.$patch = function (url, data, success, failure) {
      this.$api('patch', process.env.VUE_APP_API_URL + '/' + process.env.VUE_APP_API_VERSION + url, data, success, failure);
    };

    Vue.prototype.$delegatedUpload = function (file, onProgress, onSuccess) {
      Vapor.store(file, {
        signedStorageUrl: process.env.VUE_APP_API_URL + '/vapor/signed-storage-url',
        headers: {
          'Authorization': 'Bearer ' + this.$store.state.token,
        },
        progress: onProgress,
      }).then(onSuccess)
        .catch(err => {
          console.log(err);
        });
    };

    Vue.prototype.$laravelUrl = function (path) {
      if (path.startsWith('/')) {
        path = path.substring(1);
      }

      return process.env.VUE_APP_API_URL + '/' + path;
    };

    Vue.prototype.$api = function (method, url, data, success, failure) {
      let headers = {
        'Accept': 'application/json',
      };

      if (this.$store.state.token) {
        headers['Authorization'] = 'Bearer ' + this.$store.state.token;
      }

      const instance = axios.create();

      instance.interceptors.response.use(null, error => {
        console.log(error);
        this.$store.state.apiResponseCode = error.response.status;

        return Promise.reject(error);
      });

      instance(
        {
          method: method,
          url: url,
          data: data ? data : {},
          headers: headers,
        },
      )
        .then((response) => {
          if (response.data.updates) {
            this.$store.state.updates = {
              notifications: response.data.updates.notifications,
              messages: response.data.updates.messages,
            };
          }
          this.$hideSpinner();
          success(response.data);
        })
        .catch((error) => {
          this.$hideSpinner();
          if (error.response && error.response.status == 401) {
            this.$saveToken(null);
            this.$saveUser(null);
            location = process.env.VUE_APP_APP_URL;
          }

          var errs = {};
          if (error.response && error.response.data && error.response.data.errors) {
            for (let field in error.response.data.errors) {
              errs[field] = [];
              for (let e in error.response.data.errors[field]) {
                errs[field].push(error.response.data.errors[field][e]);
              }
            }
          }

          if (typeof failure !== 'undefined') {
            failure(errs, error);
          }
        });
    };

    Vue.directive('autogrow', {
      inserted: function (el) {
        const height = el.scrollHeight === 0 ? 48 : el.scrollHeight;
        el.style.height = `${height}px`;
        el.scrollTo(0, el.scrollHeight);
      },
      componentUpdated: function (el) {
        el.style.removeProperty('height');
        el.style.height = `${el.scrollHeight}px`;
        el.scrollTo(0, el.scrollHeight);
      },
    });

    Vue.directive('clickout', {
      bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
          if (!el.contains(event.target)) {
            vnode.context[binding.expression](event);
          }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
      },
      unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
      },
    });
  },
};
