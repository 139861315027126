<template>
  <div class="tw-flex tw-items-start tw-py-2 tw-px-5 tw-bg-success-50 tw-rounded-xl">
    <span class="tw-font-semibold">
      <slot />
    </span>

    <UiIcon
      v-if="icon"
      class="tw-shrink-0 tw-ml-auto tw-h-6 tw-w-6 tw-text-gray-500"
      :name="icon"
    />
  </div>
</template>

<script>
import UiIcon from '@/components/ui/UiIcon.vue';

export default {
  components: {
    UiIcon,
  },
  props: {
    icon: String,
  },
};
</script>
