<template>
  <div>
    <div class="tw-relative tw-z-modal">
      <transition
        enter-active-class="tw-ease-out tw-duration-300"
        enter-class="tw-opacity-0"
        enter-to-class="tw-opacity-100"
        leave-active-class="tw-ease-in tw-duration-200"
        leave-class="tw-opacity-100"
        leave-to-class="tw-opacity-0"
      >
        <div
          v-if="isMounted && backdrop"
          class="tw-fixed tw-inset-0 tw-bg-[#09101d]/70 tw-transition-opacity"
        />
      </transition>

      <div class="tw-fixed tw-inset-0 tw-overflow-hidden">
        <div
          class="tw-absolute tw-inset-0 tw-overflow-hidden"
          @click.self="$emit('close')"
        >
          <div
            class="tw-pointer-events-none tw-fixed tw-flex tw-max-w-full"
            :class="computedClasses.position"
          >
            <transition
              enterActiveClass="tw-transform tw-transition tw-ease-in-out tw-duration-500"
              :enterClass="computedClasses.startPosition"
              :enterToClass="computedClasses.endPosition"
              leaveActiveClass="tw-transform tw-transition tw-ease-in-out tw-duration-500"
              :leaveClass="computedClasses.endPosition"
              :leaveToClass="computedClasses.startPosition"
            >
              <div
                v-if="isMounted"
                class="tw-pointer-events-auto tw-relative tw-w-screen"
                :class="computedClasses.size"
              >
                <div
                  ref="container"
                  class="tw-flex tw-h-full tw-flex-col tw-overflow-y-scroll tw-bg-white tw-shadow-xl"
                  :class="{ 'tw-rounded-t-4xl': position === 'bottom' }"
                >
                  <div class="tw-relative tw-flex-1">
                    <slot
                      :container="container"
                    />
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    position: [String],
    backdrop: Boolean,
  },
  data() {
    return {
      container: null,
      isMounted: false,
    };
  },
  computed: {
    classes() {
      return {
        top: {
          position: 'tw-inset-x-0 tw-top-0',
          startPosition: '-tw-translate-y-full',
          endPosition: 'tw-translate-y-0',
        },
        start: {
          position: 'tw-inset-y-0 tw-left-0',
          size: 'tw-max-w-md',
          startPosition: '-tw-translate-x-full',
          endPosition: 'tw-translate-x-0',
        },
        end: {
          position: 'tw-inset-y-0 tw-right-0',
          size: 'tw-max-w-md',
          startPosition: 'tw-translate-x-full',
          endPosition: 'tw-translate-x-0',
        },
        bottom: {
          position: 'tw-inset-x-0 tw-bottom-0',
          startPosition: 'tw-translate-y-full',
          endPosition: 'tw-translate-y-0',
        },
      };
    },
    computedClasses() {
      return this.classes[this.position];
    },
  },
  mounted() {
    if (this.$store.state.dialogs <= 0) {
      document.body.style.overflow = 'hidden';
    }

    this.$store.state.dialogs++;
    this.isMounted = true;
    this.$emit('open');

    // Assign container ref in nextTick since it's under an isMounted condition
    // that is updated just before
    this.$nextTick(() => {
      this.container = this.$refs.container;
    })
  },
  beforeDestroy() {
    this.$store.state.dialogs--;

    if (this.$store.state.dialogs <= 0) {
      document.body.style.overflow = null;
    }
  },
};
</script>
