 <template>
  <div class="tw-w-full tw-py-5 tw-px-6 tw-bg-white tw-border tw-border-gray-200 tw-rounded-2xl">
    <div class="tw-relative tw-h-full tw-flex tw-items-start tw-gap-3 md:tw-flex-col md:tw-items-center md:tw-gap-4 md:tw-text-center">
      <UiAvatar
        class="tw-shrink-0"
        size="sm"
        :src="user.avatar_url"
        :text="user.initials"
        :to="user.url"
      />

      <div class="tw-grow tw-flex tw-flex-col tw-items-start md:tw-w-full md:tw-items-center">
        <RouterLink
          class="tw-font-semibold tw-text-lg"
          :to="user.url"
        >
          {{ user.username }}
        </RouterLink>

        <div
          v-if="subscription.isActive"
          class="tw-text-gray-700"
        >
          {{
            $t('general.subscribed-for-x', [
              subscription.amount
                ? subscription.priceFormatted
                : $t('general.free'),
            ])
          }}
        </div>

        <div
          v-if="subscription.expires"
          class="tw-text-gray-700"
        >
          <template v-if="subscription.pendingCancel">
            {{ $t('general.cancel-on', [subscription.expiresFormatted]) }}
          </template>
          <template v-else-if="subscription.isCanceled">
            {{ $t('general.canceled') }}
          </template>
          <template v-else>
            {{ $t('general.renews-on', [subscription.expiresFormatted]) }}
          </template>
        </div>

        <div
            v-if="subscription.isPastDue"
            class="tw-text-error tw-mt-4"
            v-html="$t('general.subscription-page.error-sub-renew')"
        >
        </div>

        <div
            v-if="subscription.frozen_at"
            class="tw-text-error tw-mt-4"
            v-html="$t('general.subscription-page.frozen')"
        >
        </div>

        <div class="tw-mt-auto tw-pt-3 md:tw-pt-4 md:tw-w-full">
          <div
              v-if="subscription.isActive"
              @click="onToggleSubscription"
          >
            <div class="tw-font-semibold tw-text-primary-500 md:tw-hidden">
              {{ $t('general.unsubscribe') }}
            </div>
            <div class="tw-hidden md:tw-block">
              <UiButton
                class="tw-w-full"
                variant="primary"
              >
                {{ $t('general.unsubscribe') }}
              </UiButton>
            </div>
          </div>
        </div>
      </div>
    </div>

    <UiAlertModal
      v-if="isModalOpen"
      :title="$t('general.unsubscribe')"
      :message="$t('general.are-you-sure-unsubscribe', [user.username])"
      :cancel="$t('general.cancel')"
      :confirm="$t('general.yes-unsubscribe')"
      @confirm="onUnsubscribe"
      @cancel="isModalOpen = false"
    />
  </div>
</template>

<script>
import Subscription from '../models/Subscription';
import User from '../models/User';

import UiAvatar from '@/components/ui/UiAvatar.vue';
import UiButton from '@/components/ui/UiButton.vue';
import UiAlertModal from '@/components/ui/UiAlertModal.vue';
import UiDropdownItem from '@/components/ui/UiDropdownItem.vue';
import UiDropdown from '@/components/ui/UiDropdown.vue';

export default {
  components: {
    UiDropdown,
    UiDropdownItem,
    UiAlertModal,
    UiButton,
    UiAvatar,
  },
  props: {
    user: User,
    subscription: Subscription,
  },
  data() {
    return {
      isModalOpen: false,
    };
  },
  methods: {
    copyLink() {
      const link = `${process.env.VUE_APP_APP_URL}${this.user.url}`;
      this.$copyText(link);
      this.$bvToast.toast(this.$t('general.link-copied'), {
        autoHideDelay: 2000,
        title: this.$t('general.link'),
        solid: true,
        toaster: 'b-toaster-bottom-left',
      });
    },
    addToList(user) {
      this.$store.state.addToListUser = user;
    },
    onToggleSubscription() {
      if (this.subscription.isActive) {
        this.isModalOpen = true;
      } else {
        this.$emit('subscribe', this.subscription);
      }
    },
    onUnsubscribe() {
      this.isModalOpen = false;
      this.$emit('unsubscribe', this.subscription);
    },
  },
};
</script>
