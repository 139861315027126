<template>
  <div class="settings">
    <div>
      <UiCard>
        <UiNavigationBar
          bleed
          :title="$t('general.language')"
          @back="$router.go(-1)"
        />

        <div class="tw-pt-1">
          <form
            class="tw-w-full"
            @submit.prevent="saveSettings"
          >
            <div class="tw-flex tw-flex-wrap tw-gap-4">
              <UiFormSelect
                class="tw-basis-full"
                id="country"
                v-model="locale"
                :options="locales"
              />
            </div>

            <UiButton
              class="tw-mt-6 tw-w-full sm:tw-w-auto sm:tw-min-w-[15rem] sm:tw-max-w-full"
              type="submit"
              variant="primary"
            >
              {{ $t('general.update') }}
            </UiButton>
          </form>
        </div>
      </UiCard>
    </div>
  </div>
</template>

<script>
import Locales from '../../locale';
import UiFormInput from '../ui/UiFormInput.vue';
import UiFormSelect from '../ui/UiFormSelect.vue';
import UiFormTextarea from '../ui/UiFormTextarea.vue';
import UiProfileCover from '@/components/ui/UiProfileCover.vue';
import UiAvatar from '@/components/ui/UiAvatar.vue';
import UiIcon from '@/components/ui/UiIcon.vue';
import UiCard from '@/components/ui/UiCard.vue';
import UiButton from '@/components/ui/UiButton.vue';
import UiNavigationBar from '@/components/ui/UiNavigationBar.vue';

export default {
  data: function () {
    return {
      locale: null,
      errors: {},
    };
  },
  computed: {
    locales() {
      let countries = [];

      for (const [key, value] of Object.entries(Locales)) {
        countries.push({
          value: key,
          text: value,
        });
      }

      return countries;
    },
  },
  components: {
    UiNavigationBar,
    UiButton,
    UiCard,
    UiIcon,
    UiAvatar,
    UiProfileCover,
    UiFormSelect,
    UiFormInput,
    UiFormTextarea,
  },
  mounted() {
    this.loadUser();
  },
  methods: {
    loadUser() {
      this.$get(
        '/auth/me',
        (data) => {
          this.locale = data.locale;
        },
        (errors) => {
          console.log(errors);
        },
      );
    },
    saveSettings() {
      this.errors = {};
      this.$post(
        '/profile/locale',
        {
          locale: this.locale,
        },
        () => {
          this.$root.$i18n.locale = this.locale;
          this.$bvToast.toast(this.$t('general.settings-saved'), {
            autoHideDelay: 2000,
            title: this.$t('general.success'),
            solid: true,
            toaster: 'b-toaster-bottom-left',
          });
        },
        (errors) => {
          this.errors = errors;
        },
      );
    },
  },
};
</script>
