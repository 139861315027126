<template>
  <div
    class="tw-shrink-0 tw-bg-gray-200"
    :class="{
      'tw-h-px tw-w-full': orientation === 'horizontal',
      'tw-w-px': orientation === 'vertical',
    }"
  >&nbsp;</div>
</template>

<script>
export default {
  props: {
    orientation: {
      type: String,  // 'vertical' | 'horizontal'
      default: 'horizontal',
    },
  },
};
</script>