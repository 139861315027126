<template>
  <div
    class="tw-py-4 tw-px-6 tw-flex tw-items-center tw-border tw-rounded-2xl tw-cursor-pointer"
    :class="active ? 'tw-bg-primary-50 tw-border-primary-500' : 'tw-bg-white tw-border-gray-200 hover:tw-bg-gray-50'"
    @click="active = !active"
  >
    <div class="tw-grow">
      <div class="tw-font-semibold">
        <slot />
      </div>
    </div>

    <UiIcon
      v-if="active"
      class="tw-text-primary-500"
      name="tick-square"
    />
  </div>
</template>

<script>
import UiIcon from '@/components/ui/UiIcon.vue';

export default {
  components: {
    UiIcon,
  },
  props: [
    'checked',
    'value',
  ],
  model: {
    prop: 'checked',
    event: 'change',
  },
  computed: {
    active: {
      get() {
        if (this.checked instanceof Array) {
          return this.checked.includes(this.value);
        }

        return this.checked;
      },
      set(active) {
        if (this.checked instanceof Array) {
          let newValue = [...this.checked];

          if (active) {
            newValue.push(this.value);
          } else {
            newValue.splice(newValue.indexOf(this.value), 1);
          }

          this.$emit('change', newValue);
        } else {
          this.$emit('change', active);
        }
      },
    },
  },
};
</script>
