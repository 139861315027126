<template>
  <PagePayoutsMain
    v-if="isLoaded && isVerified"
    :verification="verification"
  />
  <PageVerification
    v-else-if="isLoaded"
    :verification="verification"
  />
</template>

<script>
import Verification from '@/components/models/Verification';

import PageVerification from '@/components/pages/PageVerification.vue';
import PagePayoutsMain from '@/components/pages/PagePayoutsMain.vue';

export default {
  data: function () {
    return {
      verification: null,
      isLoaded: false,
    };
  },
  components: {
    PageVerification,
    PagePayoutsMain,
  },
  computed: {
    isVerified() {
      return this.$store.state.currentUser.isCreator;
    },
  },
  mounted() {
    this.loadInfo();
  },
  methods: {
    loadInfo() {
      this.$get(
        '/payouts/verification',
        (data) => {
          this.isLoaded = true;
          this.verification = data.verification
            ? new Verification(data.verification)
            : null;
        },
        (errors) => {
          console.log(errors);
        },
      );
    },
  },
};
</script>
