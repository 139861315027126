<template>
  <div
    class="tw-sticky tw-top-0 tw-bg-white tw-z-bar"
    :class="bleed ? '-tw-mt-4 tw-py-4 md:-tw-mt-6 md:tw-pt-6' : 'tw-py-3 tw-px-4'"
  >
    <div class="tw-flex tw-items-center tw-justify-between">
      <div class="tw-flex tw-items-center">
        <div
          v-if="$listeners.back || to"
          class="tw-flex tw-mr-3"
          :class="{ 'md:tw-hidden': responsive }"
        >
          <button
            v-if="$listeners.back"
            @click="$emit('back', $event)"
          >
            <UiIcon
              class="tw-h-6 tw-w-6"
              name="arrow-left"
            />
          </button>
          <RouterLink
            v-else-if="to"
            :to="to"
          >
            <UiIcon
              name="arrow-left"
            />
          </RouterLink>
        </div>

        <span class="tw-text-h6 tw-font-semibold">
          <slot name="title">
            {{ title }}
          </slot>
        </span>
      </div>

      <slot />
    </div>
  </div>
</template>

<script>
import UiIcon from '@/components/ui/UiIcon.vue';

export default {
  components: {
    UiIcon,
  },
  props: {
    back: Boolean,
    bleed: Boolean,
    responsive: Boolean,
    title: String,
    to: [Object, String],
  },
};
</script>
