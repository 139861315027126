<template>
  <div v-if="settings !== null">
    <div class="tw-flex tw-items-start tw-space-x-6">
      <UiCard class="tw-grow">
        <UiNavigationBar
          bleed
          :title="isActivityOpen ? $t('general.activity') : $t('general.payouts')"
          @back="onBack"
        >
          <button
            v-if="!isActivityOpen"
            class="xl:tw-hidden"
            @click="onActivity(true)"
          >
            <UiIcon
              class="tw-h-6 tw-w-6"
              name="activity"
            />
          </button>
        </UiNavigationBar>

        <UiPayoutsAnalytics
          v-if="isActivityOpen && billingStats !== null"
          class="xl:tw-hidden"
          :date-range="dateRange"
          :stats="billingStats"
        />

        <div :class="{ 'tw-hidden xl:tw-block': isActivityOpen }">
          <div class="tw-mb-6 tw-p-4 tw-bg-gray-50 tw-rounded-2xl xl:tw-hidden">
            <UiPayoutsStats
              v-model="stats"
              :settings="settings"
              :method="method"
            />
            <div
              v-if="stats.withdraw === null"
              class="tw-mt-4"
            >
              <UiPayoutsMethod
                v-model="method"
                :gateways="gateways"
                :verification="verification"
              />
            </div>
          </div>

          <div class="md:tw-border-t md:tw-border-gray-200">
            <div class="tw-flex">
              <UiTabItem
                :active="activeTab === tabs.earnings"
                @click="updateData(tabs.earnings)"
              >
                {{ $t('general.earnings') }}
              </UiTabItem>
              <UiTabItem
                :active="activeTab === tabs.payouts"
                @click="updateData(tabs.payouts)"
              >
                {{ $t('general.payouts') }}
              </UiTabItem>
              <UiTabItem
                :active="activeTab === tabs.chargebacks"
                @click="updateData(tabs.chargebacks)"
              >
                {{ $t('general.chargebacks') }}
              </UiTabItem>
            </div>

            <div class="tw-mt-6">
              <div class="tw-space-y-6">
                <div>
                  <UiDateListbox
                    :value="dateRange"
                    @input="updateDateRange"
                  />
                </div>

                <template v-if="
                  ((activeTab === tabs.earnings || activeTab === tabs.chargebacks) && payments.length > 0)
                  || (activeTab === tabs.payouts && payouts.length > 0)
                ">
                  <UiEarnings
                    v-if="activeTab === tabs.earnings"
                    :payments="payments"
                  />
                  <UiPayouts
                    v-else-if="activeTab === tabs.payouts"
                    :payouts="payouts"
                  />
                  <UiChargebacks
                    v-else-if="activeTab === tabs.chargebacks"
                    :payments="payments"
                  />
                </template>

                <div
                  v-else-if="!isLoading"
                  class="tw-text-center"
                >
                  {{ $t('general.payouts-no-results') }}
                </div>

                <div
                  v-if="isLoading"
                  class="tw-flex tw-justify-center"
                >
                  <UiSpinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </UiCard>

      <div class="tw-hidden xl:tw-block">
        <div class="tw-w-side tw-space-y-6">
          <UiCard>
            <UiPayoutsStats
              v-model="stats"
              :settings="settings"
              :method="method"
            />

            <div
              v-if="stats.withdraw === null"
              class="tw-mt-4"
            >
              <UiPayoutsMethod
                v-model="method"
                :gateways="gateways"
                :verification="verification"
              />
            </div>
          </UiCard>

          <UiCard v-if="billingStats !== null">
            <UiPayoutsAnalytics
              :stats="billingStats"
            />
          </UiCard>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Payout from '@/components/models/Payout';
import PayoutMethod from '@/components/models/PayoutMethod';
import Verification from '@/components/models/Verification';

import UiPayoutsStats from '@/components/ui/UiPayoutsStats.vue';
import UiPayoutsMethod from '@/components/ui/UiPayoutsMethod.vue';
import UiPayouts from '@/components/ui/UiPayouts.vue';
import UiEarnings from '@/components/ui/UiEarnings.vue';
import UiCard from '@/components/ui/UiCard.vue';
import UiNavigationBar from '@/components/ui/UiNavigationBar.vue';
import UiTabItem from '@/components/ui/UiTabItem.vue';
import UiPayoutsAnalytics from '@/components/ui/UiPayoutsAnalytics.vue';
import UiChargebacks from '@/components/ui/UiChargebacks.vue';
import UiIcon from '@/components/ui/UiIcon.vue';
import Payment from '@/components/models/Payment';
import UiSpinner from '@/components/ui/UiSpinner.vue';
import UiDateListbox from '@/components/ui/UiDateListbox.vue';

const TYPE_EARNINGS = 'earning';
const TYPE_PAYOUTS = 'payout';
const TYPE_CHARGEBACKS = 'chargeback';

export default {
  props: {
    verification: Verification,
  },
  components: {
    UiDateListbox,
    UiSpinner,
    UiIcon,
    UiChargebacks,
    UiPayoutsAnalytics,
    UiTabItem,
    UiNavigationBar,
    UiCard,
    UiPayoutsStats,
    UiPayoutsMethod,
    UiPayouts,
    UiEarnings,
  },
  data: function () {
    return {
      amount: '',
      method: null,
      stats: {},
      billingStats: null,
      settings: null,
      errors: {},
      gateways: [],
      activeTab: TYPE_EARNINGS,
      dateRange: null,
      isActivityOpen: false,
      payments: [],
      payouts: [],
      page: 1,
      hasMore: false,
      isLoading: false,
    };
  },
  computed: {
    tabs() {
      return {
        earnings: TYPE_EARNINGS,
        payouts: TYPE_PAYOUTS,
        chargebacks: TYPE_CHARGEBACKS,
      };
    },
  },
  created() {
    this.loadInfo();
    this.loadData();
    this.loadStats();
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.updateScroll);
  },
  methods: {
    updateScroll() {
      const scrollPosition = window.innerHeight + window.scrollY;
      if (
        document.body.offsetHeight &&
        scrollPosition &&
        document.body.offsetHeight - scrollPosition <= 1000 &&
        !this.isLoading &&
        this.hasMore
      ) {
        this.loadMore();
      }
    },
    reset() {
      this.isLoading = false;
      this.hasMore = false;
      this.page = 1;
      this.payouts = [];
      this.payments = [];
    },
    loadInfo() {
      this.$get(
        '/payouts/info',
        (data) => {
          this.method = data.method ? new PayoutMethod(data.method) : null;
          data.stats.withdraw = data.stats.withdraw
            ? new Payout(data.stats.withdraw)
            : null;
          this.stats = data.stats;
          this.settings = data.settings;
          this.gateways = data.gateways;
        },
        (errors) => {
          console.log(errors);
        },
      );
    },
    loadStats() {
      this.$get(
        '/billing-stats?'
        + (
          this.dateRange
            ? (
              '&from=' + this.dateRange.start.toISOString() +
              '&to=' + this.dateRange.end.toISOString()
            )
            : ''
        ),
        (data) => {
          try {
            this.billingStats = data.data;
          } catch (e) {
            console.log(e);
          }
        },
        (errors) => {
          console.log(errors);
        },
      );
    },
    loadPayments() {
      this.isLoading = true;

      this.$get(
        '/incomes?page=' + this.page
        + '&type=' + this.activeTab
        + (
          this.dateRange
            ? (
              '&from=' + this.dateRange.start.toISOString() +
              '&to=' + this.dateRange.end.toISOString()
            )
            : ''
        ),
        (data) => {
          try {
            let payments = [...this.payments];
            for (let obj of data.data) {
              payments.push(new Payment(obj));
            }
            this.payments = payments;
            this.hasMore = data.next_page_url != null;
            this.isLoading = false;
          } catch (e) {
            console.log(e);
          }
        },
        (errors) => {
          console.log(errors);
        },
      );
    },
    loadPayouts() {
      this.isLoading = true;

      this.$get(
        '/payouts?page=' + this.page
        + (
          this.dateRange
            ? (
              '&from=' + this.dateRange.start.toISOString() +
              '&to=' + this.dateRange.end.toISOString()
            )
            : ''
        ),
        (data) => {
          let payouts = [...this.payouts];
          for (let obj of data.data) {
            payouts.push(new Payout(obj));
          }
          this.payouts = payouts;
          this.hasMore = data.next_page_url != null;
          this.isLoading = false;
        },
        (errors) => {
          console.log(errors);
        },
      );
    },
    loadData() {
      if (
        this.activeTab === TYPE_EARNINGS ||
        this.activeTab === TYPE_CHARGEBACKS
      ) {
        this.loadPayments();
      } else {
        this.loadPayouts();
      }
    },
    loadMore() {
      if (this.hasMore) {
        this.page = this.page + 1;
        this.loadData();
      }
    },
    updateData(tab) {
      this.activeTab = tab;
      this.reset();
      this.loadData();
    },
    updateDateRange(dateRange) {
      this.dateRange = dateRange;
      this.reset();
      this.loadData();
      this.loadStats();
    },
    onBack() {
      if (this.isActivityOpen) {
        this.onActivity(false);
      } else {
        this.$router.go(-1);
      }
    },
    onActivity(open) {
      this.isActivityOpen = open;
      window.scrollTo(0, 0);
    },
  },
};
</script>
