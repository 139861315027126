<template>
  <div class="tw-h-screen tw-flex tw-justify-center tw-items-center md:tw--mt-[10rem] tw-p-4">
      <div class="tw-text-center">
        <div class="tw-font-semibold tw-text-xl md:tw-text-2xl">
          {{ $t('general.not-found-p1') }}
        </div>
        <div class="tw-text-h6 tw-font-semibold tw-pb-[0.5rem]">
          {{ $t('general.not-found-p2') }}
        </div>
        <div class="tw-mt-4 tw-whitespace-pre-line tw-text-gray-500">
          {{ $t('general.not-found-text') }}
        </div>
        <div class="tw-mt-2 tw-whitespace-pre-line">
          <UiLink
          to="/"
        >
          {{ $t('general.not-found-link') }}
        </UiLink>
        </div>
      </div>
  </div>
</template>

<script>
import UiLink from '@/components/ui/UiLink.vue';

export default {
  components: {
    UiLink,
  },
};
</script>
