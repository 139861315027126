import dayjs from "dayjs";
import User from "./User";
var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

export default class Subscription {
    id = 0;
    sub = null;
    user = null;
    status = null;
    amount = 0;
    frozen_at = null;
    expires = dayjs();
    created_at = dayjs();
    deleted_at = null;

    constructor(data) {
        this.id = data.id;
        this.user = data.user ? new User(data.user) : null;
        this.sub = data.sub ? new User(data.sub) : null;
        this.status = data.status;
        this.amount = data.amount;
        this.frozen_at = data.frozen_at ? dayjs(data.frozen_at) : null;
        this.expires = data.expires ? dayjs(data.expires) : null;
        this.created_at = dayjs(data.created_at);
        this.deleted_at = data.deleted_at ? dayjs(data.deleted_at) : null;
    }

    cancel() {
        this.status = 'pending_cancel';
    }

    get priceFormatted() {
        return process.env.VUE_APP_CURRENCY_SIGN + this.amount;
    }

    get expiresFormatted() {
        return this.expires.format('ll');
    }

    get pendingCancel() {
        return this.status === 'pending_cancel'
    }

    get isPastDue() {
        return this.status === 'past_due';
    }

    get isCanceled() {
        return this.status === 'canceled' || this.status === 'canceled_past_due'
    }

    get isActive() {
        return this.status === 'active' || this.status === 'past_due'
    }
}