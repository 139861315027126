var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.payments),function(item,index){return _c('div',{key:index,staticClass:"tw-my-6"},[(
          index === 0 ||
          (index > 0 &&
            !_vm.payments[index - 1].created_at.isSame(
              item.created_at,
              'day'
            ))
        )?_c('div',{staticClass:"tw-text-center tw-font-semibold md:tw-text-lg",class:{ 'tw-pt-6 tw-border-t tw-border-gray-200': index > 0 }},[_vm._v(" "+_vm._s(item.created_at.format('ll'))+" ")]):_vm._e(),_c('div',{staticClass:"tw-flex tw-items-center tw-justify-between"},[_c('div',{staticClass:"tw-font-semibold md:tw-text-lg"},[_vm._v(" "+_vm._s(item.to.name)+" ")]),_c('div',{staticClass:"tw-text-lg tw-font-semibold tw-text-error"},[_vm._v(" "+_vm._s(_vm.$formatAmount(item.amount_with_vat))),_c('br')])]),_c('div',{staticClass:"tw-mt-2 tw-text-gray-700"},[_c('i18n',{attrs:{"path":_vm.getDescription(item),"tag":"span"},scopedSlots:_vm._u([{key:"user",fn:function(){return [_c('RouterLink',{attrs:{"to":item.user.url}},[_vm._v(_vm._s(item.user.username))])]},proxy:true},{key:"to",fn:function(){return [_c('RouterLink',{attrs:{"to":item.to.url}},[_vm._v(_vm._s(item.to.username))])]},proxy:true},(item.items.post)?{key:"post",fn:function(){return [_c('RouterLink',{attrs:{"to":item.items.post.url}},[_vm._v(_vm._s(_vm.$t('general.post')))])]},proxy:true}:null,{key:"message",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('general.private-message'))+" ")]},proxy:true}],null,true)})],1)])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }