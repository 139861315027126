<template>
  <div>
    <UiCard>
      <UiNavigationBar
        bleed
        responsive
        :title="$t('general.security')"
        to="/"
      />

      <div class="tw-flex tw-flex-wrap tw-gap-6">
        <UiButton
          class="tw-w-full sm:tw-max-w-xs"
          to="/settings/password"
          variant="primary"
        >
          {{ $t('general.change-password') }}
        </UiButton>
<!--        <UiButton-->
<!--          class="tw-w-full sm:tw-max-w-xs"-->
<!--          to="/settings/email"-->
<!--          variant="primary"-->
<!--        >-->
<!--          {{ $t('general.change-email') }}-->
<!--        </UiButton>-->
      </div>
    </UiCard>
  </div>
</template>

<script>
import User from '@/components/models/User';

import UiCard from '@/components/ui/UiCard.vue';
import UiNavigationBar from '@/components/ui/UiNavigationBar.vue';
import UiButton from '@/components/ui/UiButton.vue';

export default {
  components: {
    UiButton,
    UiCard,
    UiNavigationBar,
  },
  data: function () {
    return {
      user: null,
      errors: {},
    };
  },
  mounted() {
    this.loadUser();
  },
  methods: {
    loadUser() {
      this.$get(
        '/auth/me',
        (data) => {
          this.user = new User(data);
        },
        (errors) => {
          console.log(errors);
        },
      );
    },
  },
};
</script>
