<template>
  <div
    class="tw-relative tw-w-full tw-p-2 tw-cursor-pointer tw-rounded-xl hover:tw-bg-primary-50"
    :class="{
      'tw-pr-10 tw-bg-primary-50': active,
    }"
    @click="$emit('click')"
  >
    <span class="tw-block tw-truncate tw-text-sm sm:tw-text-base">
      <slot />
    </span>

    <span
      v-if="active"
      class="tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-2 tw-pointer-events-none"
    >
      <UiIcon
        class="h-6 w-6"
        name="tick-square"
      />
    </span>
  </div>
</template>

<script>
import UiIcon from '@/components/ui/UiIcon.vue';

export default {
  components: {
    UiIcon,
  },
  props: {
    active: Boolean,
  },
};
</script>
